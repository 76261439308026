import { IStudentsFiltersData } from 'components/Students/interface';

export const NATIVE_SPEAKER_TYPE_ID = 2;

export const ENGLISH_LEVEL_INTERMEDIATE_LOW = 5;

export const DEFAULT_CURRENT_PAGE = 1;
const MAX_PER_PAGE = 7;

export const DEFAULT_PAGE_DATA = {
  current_page: DEFAULT_CURRENT_PAGE,
  max_per_page: MAX_PER_PAGE
};

export const DEFAULT_SORT_DATA = {
  sort_key: 'surname',
  sort_order: 4
};

export const DEFAULT_FILTERS: IStudentsFiltersData = {
  studentsStatuses: [],
  departmentGroups: [],
  groups: []
};
