import { getTestNames, getDepartmentGroups, getStudyGroupNames } from 'api/filtersData';

import { IUser } from 'components/Authentication/interfaces';
import { IListTestResultsType } from 'components/TestResults/types/index';

import { DEFAULT_FILTER_DATA } from 'components/TestResults/constants/store';
import {
  ADMIN, SUPER_ADMIN, STUDENT, GROUP_LEAD, TEACHER
} from 'constants/roles';
import * as filter from 'constants/select';

import { normalizeTestNamesData } from 'components/TestResults/utils';
import { normalizeFilterData, normalizeDepartmentGroups } from 'helpers/select';

export const updateTestResultsStatus = (testResultsData: IListTestResultsType[], indexUpdate: number) => {
  const testResult = testResultsData;

  return testResult.map((testResultData, index) => {
    const { isOpenDetailInfo } = testResultData;

    if (index === indexUpdate) {
      return { ...testResultData, isOpenDetailInfo: !isOpenDetailInfo, isEditResult: false };
    }

    return { ...testResultData, isOpenDetailInfo: false, isEditResult: false };
  });
};

export const updateEditStatusResults = (testResultsData: IListTestResultsType[], indexUpdate: number) => {
  const testResult = testResultsData;

  return testResult.map((testResultData, index) => {
    const { isEditResult } = testResultData;

    if (index === indexUpdate) {
      return { ...testResultData, isOpenDetailInfo: false, isEditResult: !isEditResult };
    }

    return { ...testResultData, isOpenDetailInfo: false, isEditResult: false };
  });
};

export const getFilterByTestNames = async () => {
  const testNames = await getTestNames();
  const normalizedTestNamesData = await normalizeTestNamesData(testNames.data.data);

  return normalizedTestNamesData;
};

export const getFiltersForStudent = async () => {
  const filterData = DEFAULT_FILTER_DATA;

  const testNamesList = await getFilterByTestNames();

  filterData.testNames = [filter.ALL_TESTS, ...testNamesList];

  return filterData;
};

export const getFiltersForAdmin = async () => {
  const filterData = DEFAULT_FILTER_DATA;

  const {
    data: { data }
  } = await getStudyGroupNames();

  filterData.testNames = await getFilterByTestNames();

  const normalizedStudyGroupNamesData = await normalizeFilterData(data.data_page);

  filterData.studyGroupNames = [filter.ALL_GROUPS, ...normalizedStudyGroupNamesData];

  return filterData;
};

export const getFiltersForGroupLead = async (user: IUser) => {
  const { departmentId } = user;

  const filterData = DEFAULT_FILTER_DATA;

  const departmentsGroups = await getDepartmentGroups({ department_id: departmentId });

  filterData.testNames = await getFilterByTestNames();

  filterData.departmentGroups = await normalizeDepartmentGroups(departmentsGroups.data.data);

  return filterData;
};

export const getFiltersForTeacher = async (user: IUser) => {
  const { id } = user;
  const filterData = DEFAULT_FILTER_DATA;

  const studyGroupNames = await getStudyGroupNames({ teacher_user_id: id });

  filterData.testNames = await getFilterByTestNames();

  const normalizedStudyGroupNamesData = await normalizeFilterData(studyGroupNames.data.data);

  filterData.studyGroupNames = [filter.ALL_GROUPS, ...normalizedStudyGroupNamesData];

  return filterData;
};

export const getFiltersDataByRole = async (user: IUser) => {
  const { role } = user;

  switch (role) {
    case STUDENT: {
      const studentFilterData = await getFiltersForStudent();

      return studentFilterData;
    }
    case ADMIN:
    case SUPER_ADMIN: {
      const adminFilterData = await getFiltersForAdmin();

      return adminFilterData;
    }
    case GROUP_LEAD: {
      const leadFilterData = await getFiltersForGroupLead(user);

      return leadFilterData;
    }
    case TEACHER: {
      const teacherFilterData = await getFiltersForTeacher(user);

      return teacherFilterData;
    }
    default:
      return DEFAULT_FILTER_DATA;
  }
};
