import React from 'react';

import Logo from 'components/common/Logo/index';
import RestorePassword from './RestorePassword';
import { AUTH_LABEL } from '../constants';

function RestorePasswordComponent() {
  return (
    <div className="auth">
      <div className="auth-content">
        <Logo />
        <RestorePassword />
        <span className="auth-label">{AUTH_LABEL}</span>
      </div>
    </div>
  );
}

export default RestorePasswordComponent;
