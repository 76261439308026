import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';

import { ISelectItem } from 'types/index';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { ISearchProps } from './interfaces';

const Search: React.FC<ISearchProps> = (props: ISearchProps) => {
  const [isOpen, toggleSearch] = useState(false);
  const [search, setSearch] = useState('');

  const {
    value, options, searchRequest, updateSelectedItem
  } = props;

  useEffect(() => {
    searchRequest('');
  }, []);

  const request = (searchString: string): void => {
    searchRequest(searchString);
  };

  const delayedQuery = useRef(debounce(request, 500)).current;

  const onSelectChange = (selectedOption: ISelectItem): void => {
    updateSelectedItem(selectedOption);
    setSearch('');
  };

  const onInputChange = (searchString: string): void => {
    setSearch(searchString);
    delayedQuery(searchString);
  };

  const onSearchBtnClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    toggleSearch(!isOpen);
  };

  const searchClassName = classnames('search', isOpen && 'is-open');

  return (
    <>
      <form className={searchClassName}>
        <Select
          value={value}
          onChange={onSelectChange}
          options={options}
          className="search-input"
          classNamePrefix="search-input"
          placeholder="Search..."
          onInputChange={onInputChange}
          inputValue={search}
          isMulti
        />
        <button
          className="search-button"
          type="submit"
          onClick={(e: React.MouseEvent<HTMLElement>) => onSearchBtnClick(e)}
        >
          <i className="material-icons search-icon">search</i>
        </button>
      </form>
    </>
  );
};

export default Search;
