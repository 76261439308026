import React, { useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import moment from 'moment';

import { formFields } from 'components/common/FormComponent/formFields';

import { eventFormFields } from '../../AddEvent/constants';
import { IMainEventFormProps, IMainEventFormValues } from './interface';

const MainEventForm: React.FC<IMainEventFormProps & FormikProps<IMainEventFormValues>> = (
  props: IMainEventFormProps & FormikProps<IMainEventFormValues>
) => {
  const {
    handleSubmit, setFieldTouched, setFieldValue, setIsFormValid
  } = props;

  const [dateForName, setDateForName] = useState(null);
  const minDate = moment()
    .startOf('year')
    .format();

  useEffect(() => {
    const isValid = Boolean(dateForName);

    setIsFormValid(isValid);
  }, [dateForName]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={eventFormFields.NAME}
            placeholder="Event date"
            openTo="year"
            component={formFields.FormDatePicker}
            views={['year', 'month']}
            value={dateForName}
            onBlur={() => setFieldTouched(eventFormFields.NAME)}
            onChange={(data: Date) => {
              setDateForName(data);
              setFieldValue(eventFormFields.NAME, data);
            }}
            minDate={minDate}
          />
        </div>
      </div>
    </form>
  );
};

export default MainEventForm;
