import { errorMassage } from 'constants/forms';

import { IValidGroupRequestParams, IGroupErrors } from './interface';

export const checkValidityGroupData = (values: IValidGroupRequestParams) => {
  const errors: IGroupErrors = {};

  if (!values.department_group_name) {
    errors.department_group_name = errorMassage.default;
  }

  if (!values.department_id) {
    errors.department_id = errorMassage.default;
  }

  if (!values.lead_user_ids) {
    errors.lead_user_id = errorMassage.default;
  }

  return errors;
};
