import React, { useContext } from 'react';

import RootStore from 'store';
import { IStudentData } from 'components/Students/interface';
import { addStudentStatus } from 'api/students';
import { ENGLISH_LEVEL_INTERMEDIATE_LOW, NATIVE_SPEAKER_TYPE_ID } from 'components/Students/constants';
import FormComponent from 'components/common/FormComponent';

import FormComponentEditStudentInfo from './FormComponentEditStudentInfo';
import { IModalProps } from '../../../Modals/interfaces';
import withModal from '../../../../hocs/WithModal';

const EditStudentInfo: React.FC<IStudentData & IModalProps> = ((props: IStudentData & IModalProps) => {
  const { recommendedEnglishLevelId, userId, teacherTypeId, toggleModal } = props;

  const {
    students: { getStudentsList, openModalErrorLevel }
  } = useContext(RootStore);

  const submitForm = async (data: any) => {
    const isNativeSpeakerTeacher = teacherTypeId === NATIVE_SPEAKER_TYPE_ID;
    const isValidEnglishLevel = data.recommended_english_level_id < ENGLISH_LEVEL_INTERMEDIATE_LOW;

    const isOpenErrorLevelModal = isNativeSpeakerTeacher && isValidEnglishLevel;

    if (isOpenErrorLevelModal) {
      openModalErrorLevel(userId);
    } else {
      await addStudentStatus(data);
      await getStudentsList();

      toggleModal();
    }
  };

  return (
    <FormComponent
      Component={FormComponentEditStudentInfo}
      otherProps={props}
      initialValues={{
        user_id: userId,
        recommended_english_level_id: recommendedEnglishLevelId
      }}
      onSubmit={(data: any) => submitForm(data)}
    />
  );
});

export default withModal(EditStudentInfo);
