import React, { useState } from 'react';
import {
  Menu, MenuItem, IconButton, Icon
} from '@material-ui/core';

import DeleteEvent from 'components/Modals/Event/DeleteEvent';
import EditEvent from 'components/Modals/Event/EditEvent';

import { IEventMenuProps } from './interface';

const EventMenu: React.FC<IEventMenuProps> = ({ deleteEvent, openCancelEventConfirmation }: IEventMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalEdit, setIsOpenEditModal] = useState(false);

  const isOpen = Boolean(anchorEl);

  const onOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const openModal = () => {
    setIsOpenModal(true);
    onCloseMenu();
  };

  const openEditModal = () => {
    setIsOpenEditModal(true);
    onCloseMenu();
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setIsOpenEditModal(false);
  };

  const openCancelEventModal = () => {
    openCancelEventConfirmation();
    onCloseMenu();
  };

  return (
    <>
      <IconButton onClick={onOpenMenu}>
        <Icon>more_horiz</Icon>
      </IconButton>
      <Menu open={isOpen} anchorEl={anchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={openEditModal}>Edit event</MenuItem>
        <MenuItem onClick={openCancelEventModal}>Cancel</MenuItem>
        <MenuItem onClick={openModal}>Remove</MenuItem>
      </Menu>
      <DeleteEvent isOpen={isOpenModal} closeModal={closeModal} deleteEvent={deleteEvent} />
      <EditEvent isOpen={isOpenModalEdit} closeModal={closeModal} />
    </>
  );
};

export default EventMenu;
