import React from 'react';

import withModal from 'hocs/WithModal';

import { IFilterContainerProps } from './interfaces';

const FiltersContainerMobile:React.FC<IFilterContainerProps> = (props: IFilterContainerProps) => {
  const { children, toggleModal, onApply } = props;

  return (
    <div className="modal">
      <div className="filters-modal">
        <div className="filters-header">
          <div className="modal-title">Filters</div>
          <button className="modal-close" type="button" onClick={() => toggleModal()}>
            <i className="material-icons">clear</i>
          </button>
        </div>
        {children}
        <div className="filters-footer">
          <button
            className="btn-default apply-filters"
            type="button"
            onClick={() => onApply()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>

  );
};

export default withModal(FiltersContainerMobile);
