import { IEventType } from './interfaces';

export const DRAG_STUDENT_TYPE = 'studentCard';

export const eventsType = {
  WRITING: 'writing',
  SPEAKING: 'speaking',
  LESSON: 'lesson'
};

export const WRITING_TEST_ID = '2';

export const EVENT_TYPES: IEventType[] = [
  {
    id: WRITING_TEST_ID,
    value: 'Writing',
    name: eventsType.WRITING
  },
  {
    id: '3',
    value: 'Speaking',
    name: eventsType.SPEAKING
  }
];
