import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import * as role from 'constants/roles';
import * as selectOption from 'constants/select';
import { format } from 'constants/date';
import { getAdditionalParams } from 'components/Calendar/helper';
import { updateFormatDate } from 'helpers/actionsWithDates';
import { EventsRequestParamType } from 'components/Calendar/types/storeTypes';
import ReactSelect from 'components/common/FormFields/Selects';
import FiltersContainer from 'components/common/FiltersContainer';
import { SELECT_OPTIONS_TYPE } from 'components/common/FormFields/Selects/constant';

import { IFiltersProps } from './interface';

const CalendarFilters:React.FC<IFiltersProps> = (props: IFiltersProps) => {
  const {
    permissions, updateModalState, isMobileFiltersOpen, toggleIsMobileFiltersOpen, setIsMobileFiltersOpen
  } = props;
  const {
    calendarStore,
    authenticationStore,
    addEventStore: { getEventTestTypes },
    serviceStore: { isMobile }
  } = useContext(RootStore);

  const { user } = authenticationStore;
  const { id, departmentGroupId, departmentGroupName } = user;

  const {
    getMonthEvents,
    setDefaultCalendarState,
    selectedMonth,
    filters,
    updateFilters
  } = calendarStore;
  const {
    teachers, studyGroups, departments, departmentsGroups
  } = filters;

  const teachersLength = teachers && teachers.length;
  const [selectedTeachers, changeTeachers] = useState(selectOption.ALL_TEACHERS);

  const studyGroupLength = studyGroups && studyGroups.length;
  const [selectedStudyGroup, changeStudyGroup] = useState(selectOption.ALL_GROUPS);

  const departmentsLength = departments && departments.length;
  const [selectedDepartment, changeDepartment] = useState(null);

  const departmentsGroupsLength = departmentsGroups && departmentsGroups.length;
  const [selectedDepartmentGroup, changeDepartmentGroup] = useState(null);

  useEffect(() => {
    getEventTestTypes();
  }, []);

  useEffect(() => {
    updateFilters(user, selectedDepartment);
  }, [updateFilters, user, selectedDepartment]);

  useEffect(() => {
    setDefaultCalendarState();
  }, [selectedMonth]);

  useEffect(() => {
    if (teachersLength) {
      changeTeachers(teachers[0]);
    }

    if (studyGroupLength) {
      changeStudyGroup(studyGroups[0]);
    }

    if (departmentsLength) {
      changeDepartment(departments[0]);
    }
  }, [teachersLength, studyGroupLength, departmentsLength]);

  useEffect(() => {
    if (departmentsGroupsLength) {
      if (permissions([role.GROUP_LEAD])) {
        changeDepartmentGroup({
          value: departmentGroupId,
          label: departmentGroupName
        });
      } else {
        changeDepartmentGroup(departmentsGroups[0]);
      }
    }
  }, [departmentsGroupsLength]);

  const fetchData = async () => {
    const additionalParams = getAdditionalParams(user, {
      selectedTeachers,
      selectedStudyGroup,
      selectedDepartment,
      selectedDepartmentGroup
    });

    const params: EventsRequestParamType = {
      user_id: id,
      month_year: updateFormatDate(selectedMonth, format.MONTH_YEAR),
      ...additionalParams
    };

    await getMonthEvents(params);

    setIsMobileFiltersOpen();
  };

  useEffect(() => {
    if (isMobile) { return; }

    fetchData();
  }, [
    id,
    selectedMonth,
    selectedTeachers.value,
    selectedStudyGroup.value,
    selectedDepartment,
    selectedDepartmentGroup
  ]);

  const toggleAddModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    updateModalState(true);
  };

  return (
    <FiltersContainer isOpen={isMobileFiltersOpen} toggleModal={toggleIsMobileFiltersOpen} onApply={fetchData}>
      <div className="title-content">
        <div className="inputs-group">
          {permissions([role.ADMIN, role.SUPER_ADMIN]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-medium"
                values={teachers}
                selectedValue={selectedTeachers}
                updateData={changeTeachers}
                optionType={SELECT_OPTIONS_TYPE.WITH_PHOTO}
              />
            </div>
          )}
          {permissions([role.ADMIN, role.SUPER_ADMIN, role.TEACHER]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-medium"
                values={studyGroups}
                selectedValue={selectedStudyGroup}
                updateData={changeStudyGroup}
              />
            </div>
          )}
          {permissions([role.SUPER_ADMIN]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-medium"
                values={departments}
                selectedValue={selectedDepartment}
                updateData={changeDepartment}
              />
            </div>
          )}
          {permissions([role.GROUP_LEAD, role.SUPER_ADMIN]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-big"
                values={departmentsGroups}
                selectedValue={selectedDepartmentGroup}
                updateData={changeDepartmentGroup}
              />
            </div>
          )}
          {permissions([role.ADMIN, role.SUPER_ADMIN]) && !isMobile && (
            <div className="inputs-group-item">
              <button
                className="btn-default"
                type="button"
                onClick={toggleAddModal}
              >
                + Add New Event
              </button>
            </div>
          )}
        </div>
      </div>
    </FiltersContainer>
  );
};

export default observer(CalendarFilters);
