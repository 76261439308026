import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { Field } from 'formik';

import RootStore from 'store';

import FormSelect from 'components/common/FormComponent/formFields/FormSelect';
import FormInput from 'components/common/FormComponent/formFields/FormInput';
import FormTextarea from 'components/common/FormComponent/formFields/FormTextarea';

import { PLACEHOLDER_COMMITS } from 'components/Modals/AddTestResult/constants';

import { IEditTestResultProps } from '../interface';

const EditFormSpeakingTestResult: React.FC<IEditTestResultProps> = observer(props => {
  const { initialTestResultData, setFieldValue } = props;

  const {
    recommendedEnglishLevelId,
    speakingEnglishLevelId,
    writingEnglishLevelId,
    recommendedEnglishLevelName,
    speakingEnglishLevelName,
    writingEnglishLevelName
  } = initialTestResultData;

  const { modalsAddTestResult } = useContext(RootStore);
  const { testLevelsList } = modalsAddTestResult;

  const [selectedSpeakingEnglishLevel, changeSpeakingEnglishLevel] = useState({
    label: speakingEnglishLevelName,
    value: speakingEnglishLevelId
  });

  const [selectedWritingEnglishLevel, changeWritingEnglishLevel] = useState({
    label: writingEnglishLevelName,
    value: writingEnglishLevelId
  });

  const [selectedRecommendedEnglishLevel, changeRecommendedEnglishLevel] = useState({
    label: recommendedEnglishLevelName,
    value: recommendedEnglishLevelId
  });

  const { input, textarea } = PLACEHOLDER_COMMITS;

  return (
    <div className="accordion-group w-25">
      <div className="panel-item">
        <div className="accordion-info">
          <Field
            name="speaking_english_level_id"
            component={FormSelect}
            options={testLevelsList}
            value={selectedSpeakingEnglishLevel}
            placeholder="Speaking level"
            onChange={(data: any) => {
              changeSpeakingEnglishLevel(data);
              setFieldValue('speaking_english_level_id', data.value);
            }}
          />
        </div>
        <div className="accordion-info">
          <Field
            name="writing_english_level_id"
            component={FormSelect}
            options={testLevelsList}
            value={selectedWritingEnglishLevel}
            placeholder="Writing level"
            onChange={(data: any) => {
              changeWritingEnglishLevel(data);
              setFieldValue('writing_english_level_id', data.value);
            }}
          />
        </div>
        <div className="accordion-info">
          <Field
            name="recommended_english_level_id"
            component={FormSelect}
            options={testLevelsList}
            value={selectedRecommendedEnglishLevel}
            placeholder="Recommended level"
            onChange={(data: any) => {
              changeRecommendedEnglishLevel(data);
              setFieldValue('recommended_english_level_id', data.value);
            }}
          />
        </div>
      </div>
      <div className="panel-item">
        <div className="accordion-info">
          <Field name="holding" placeholder={input} component={FormInput} label="What is holding your back" />
        </div>
        <div className="accordion-info">
          <Field name="comment" placeholder={textarea} component={FormTextarea} label="Comment" id="comment" rows={5} />
        </div>
      </div>
    </div>
  );
});

export default EditFormSpeakingTestResult;
