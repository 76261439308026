import { observable, action } from 'mobx';

import { getListTestResults, getDetailsTestResults } from 'api/testResult';

import { IUser } from 'components/Authentication/interfaces';
import { ISortingData } from 'components/common/ColumnValueInTable/interface';
import { getSortingStatus } from 'components/common/ColumnValueInTable/helpers';
import { removeNullFields } from 'services/helper';

import { DEFAULT_PAGE_DATA, DEFAULT_SORT_DATA, MAX_PER_PAGE } from './constants/store';
import { updateTestResultsStatus, getFiltersDataByRole, updateEditStatusResults } from './helpers';
import { normalizeTestResultRequest, normalizeTestResultsData } from './utils';

import {
  IListTestResultsType, IPagesDataType, ISortedDataType, IFilterType, IParamsType
} from './types';

export default class CalendarStore {
  @observable listTestResults: IListTestResultsType[] = [];

  @observable previousTestResults: IListTestResultsType | null = null;

  @observable pagesData: IPagesDataType = DEFAULT_PAGE_DATA;

  @observable sortedData: ISortedDataType = DEFAULT_SORT_DATA;

  @observable filter: IFilterType = {
    testNames: [],
    departmentGroups: [],
    studyGroupNames: [],
    student: []
  };

  @observable deleteTestResultData: IListTestResultsType;

  @observable isLoading = false;

  @action updateDeleteTestResultData = (data: IListTestResultsType) => {
    this.deleteTestResultData = data;
  };

  @action updateListTestResults = async (params: IParamsType) => {
    this.isLoading = true;

    try {
      params = {
        ...params,
        ...this.sortedData,
        max_per_page: MAX_PER_PAGE,
        current_page: this.pagesData.selectedPage
      };

      const { data } = await getListTestResults(removeNullFields(params));

      const { dataPage, numberPages } = normalizeTestResultRequest(data);

      this.listTestResults = dataPage;
      this.pagesData = {
        ...this.pagesData,
        countPages: numberPages
      };
      return null;
    } catch (e) {
      console.error(e);
      return e;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateSelectedPage = (selectedPage: number) => {
    this.pagesData = {
      ...this.pagesData,
      selectedPage: selectedPage + 1
    };
  };

  @action updateSortedRequest = (updatedSortedData: ISortingData[]) => {
    const { value, sortingArrow } = updatedSortedData.find((sortedValue) => sortedValue.isActive);

    const sortingState = getSortingStatus(sortingArrow);

    this.sortedData = {
      ...this.sortedData,
      sort_key: value,
      sort_order: sortingState
    };
  };

  @action updateFilterData = async (user: IUser) => {
    this.filter = await getFiltersDataByRole(user);
  };

  @action updateDetailsInfoStatus = async (indexUpdate: number) => {
    this.listTestResults = await updateTestResultsStatus(this.listTestResults, indexUpdate);
  };

  @action updateEditStatus = async (indexUpdate: number) => {
    this.listTestResults = await updateEditStatusResults(this.listTestResults, indexUpdate);
  };

  @action getPreviousTestResults = async (previousTestResultId: number, updatedState: boolean) => {
    this.isLoading = true;
    try {
      if (updatedState) {
        const params = {
          test_result_id: previousTestResultId
        };

        const { data } = await getDetailsTestResults(params);

        this.previousTestResults = await normalizeTestResultsData(data);
      } else {
        this.previousTestResults = null;
      }
      return null;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateTestResultList = async (testResult: any, indexTestResult: number) => {
    this.listTestResults[indexTestResult] = {
      ...this.listTestResults[indexTestResult],
      ...testResult
    };
  };
}
