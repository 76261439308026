import { EXPAND_LESS, EXPAND_MORE } from 'components/common/ColumnValueInTable/constants';

import { ISortingData } from 'components/common/ColumnValueInTable/interface';

export const getUpdatedSortedData = (sortedData: ISortingData[], titleSort: string) => sortedData.map((sortValue) => {
  const { title } = sortValue;

  if (titleSort === title) {
    return {
      ...sortValue,
      sortingArrow: sortValue.sortingArrow === EXPAND_MORE ? EXPAND_LESS : EXPAND_MORE
    };
  }

  return sortValue;
});
