import { IGetAttendanceLogParams } from 'components/AttendanceLog/interfaces';
import request from './request';
import api from './index';

export const getAttendance = (params: IGetAttendanceLogParams): Promise<any> => request({
  url: api.ATTENDANCE_LOG,
  method: 'GET',
  params
});

export const updateStudentAttendance = (data: any): Promise<any> => request({
  url: api.UPDATE_ATTENDANCE_LOG,
  method: 'PATCH',
  data
});

export const getAttendanceLogStudents = (params: any): Promise<any> => request({
  url: api.ATTENDANCE_LOG_STUDENTS_SEARCH,
  method: 'GET',
  params
});
