import { observable, action, computed } from 'mobx';

import * as filterApi from 'api/filtersData';
import * as studentsApi from 'api/students';

import { IRootStore } from 'store/interfaces';
import { SelectData } from 'types/filters';
import { IStudentData, IGetStudentsParams } from 'components/Students/interface';
import { IShortStudentData } from 'components/Modals/Student/DeleteStudentFromGroup/interface';

import { sortByKey } from 'components/AddToGroup/helpers';
import { normalizeDepartment } from 'helpers/select';

import * as type from './interfaces';
import * as constant from './constant';
import * as helpers from './helpers';
import * as utils from './utils';

export default class DepartmentsStore {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @observable students: IStudentData[] = [];

  @observable departmentGroups: type.IDepartmentGroup[] = [];

  @observable filter: type.IFiltersData = constant.DEFAULT_FILTER;

  @observable modalsData: type.IModalsData = constant.DEFAULT_MODAL_DATA;

  @observable isLoadingDepartments = false;

  @observable isLoadingDepartmentGroups = false;

  @observable isLoadingStudents = false;

  @computed get isLoading() {
    return this.isLoadingDepartmentGroups
      || this.isLoadingDepartments
      || this.isLoadingStudents;
  }

  @action setRemoveStudentModalState = (modalData?: IShortStudentData) => {
    if (modalData) {
      this.modalsData.removeStudentModal = {
        ...this.modalsData.removeStudentModal,
        state: true,
        studentData: modalData
      };
    } else {
      this.modalsData.removeStudentModal = {
        ...this.modalsData.removeStudentModal,
        state: false,
        studentData: null
      };
    }
  };

  @action getDepartments = async () => {
    try {
      this.isLoadingDepartments = true;
      const {
        data: { data }
      } = await filterApi.getDepartments();

      const normalizedDepartment: SelectData[] = normalizeDepartment(data);

      this.filter.departments = {
        ...this.filter.departments,
        selected: normalizedDepartment[0],
        options: normalizedDepartment
      };

      this.setStudentsWithoutDepartmentGroup();
      this.getDepartmentsGroups();

      return data;
    } catch (error) {
      return error;
    } finally {
      this.isLoadingDepartments = false;
    }
  };

  @action changeSelectedFilterDepartment = (selectedDepartment: SelectData) => {
    this.filter.departments = {
      ...this.filter.departments,
      selected: selectedDepartment
    };

    this.setStudentsWithoutDepartmentGroup();
    this.getDepartmentsGroups();
  };

  @action getStudents = async (params: IGetStudentsParams) => {
    try {
      this.isLoadingStudents = true;

      const {
        data: { data }
      } = await studentsApi.getStudentsList(params);

      return utils.normalizeStudentsList(data.data_page);
    } catch (error) {
      return error;
    } finally {
      this.isLoadingStudents = false;
    }
  };

  @action setStudentsWithoutDepartmentGroup = async () => {
    const params: IGetStudentsParams = {
      department_id: this.filter.departments.selected.value
    };

    const students = await this.getStudents(params);

    this.students = helpers.getStudentsWithoutDepartmentGroup(students);
  };

  @action setStudentsDepartmentGroup = async (departmentGroupId: number) => {
    const params: IGetStudentsParams = {
      department_id: this.filter.departments.selected.value,
      department_group_id: departmentGroupId
    };

    const studentsDepartmentGroup = await this.getStudents(params);

    const leads = helpers.getUsersByRole(studentsDepartmentGroup, constant.ROLE.lead);
    const students = helpers.getUsersByRole(studentsDepartmentGroup, constant.ROLE.student);

    return { leads, students };
  };

  @action getDetailGroupInfo = async (departmentGroupId: number) => {
    try {
      return await this.setStudentsDepartmentGroup(departmentGroupId);
    } catch (error) {
      return error;
    }
  };

  @action getGeneralGroupsInfo = async () => {
    try {
      const params: IGetStudentsParams = {
        department_id: this.filter.departments.selected.value
      };

      const {
        data: { data }
      } = await filterApi.getDepartmentGroups(params);

      return utils.normalizeDepartmentGroups(data);
    } catch (error) {
      return error;
    }
  };

  @action getDepartmentsGroups = async (): Promise<void> => {
    try {
      this.isLoadingDepartmentGroups = true;
      this.departmentGroups = [];

      const generalGroupsInfo = await this.getGeneralGroupsInfo();

      const fullGroupsInfo = generalGroupsInfo.map(async (group: type.IDepartmentGroup) => {
        const { departmentGroupId } = group;

        const detailGroupInfo = await this.getDetailGroupInfo(departmentGroupId);

        return { ...group, ...detailGroupInfo };
      });

      Promise.all(fullGroupsInfo).then((arr) => {
        this.departmentGroups = sortByKey(arr, 'departmentGroupId');
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingDepartmentGroups = false;
    }
  };
}
