import { IFilterType } from '../types';

export const MAX_PER_PAGE = 10;

export const DEFAULT_PAGE_DATA = {
  countPages: 1,
  selectedPage: 1
};

export const DEFAULT_SORT_DATA = {
  sort_key: 'event_parent_id',
  sort_order: 3
};

export const DEFAULT_FILTER_DATA: IFilterType = {
  testNames: [],
  departmentGroups: [],
  studyGroupNames: [],
  student: []
};
