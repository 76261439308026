import React from 'react';

import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';
import MultiplyMobileSelect from 'components/common/MultiplyMobileSelect';
import { useStateModal } from 'components/Modals/helper';

import { IStudentsSelectionMobile } from './interfaces';

const StudentsSelectionMobile: React.FC<IStudentsSelectionMobile> = (
  props: IStudentsSelectionMobile
) => {
  const [isOpenModal, toggleModal]: any = useStateModal(false);

  const { addStudent, students, canSelect } = props;

  const items = students.map(({ fullName, userId, photo }: any) => (
    { label: fullName, value: userId, icon: photo }
  ));

  const onSubmit = (values: []) => {
    values.forEach((userId: number) => {
      addStudent({ userData: { userId } });
    });
  };

  const openAddModal = () => {
    if (!students.length) { return; }

    toggleModal();
  };

  return (
    <>
      <MultiplyMobileSelect
        onSubmit={onSubmit}
        items={items}
        toggleModal={toggleModal}
        isOpen={isOpenModal}
        selectedItems={[]}
        title="Students"
      />
      {canSelect && <EmptyRow onClick={openAddModal} />}
    </>
  );
};

export default StudentsSelectionMobile;
