import React from 'react';

export default function EmptyRow({ onClick }: any) {
  return (
    <div
      role="button"
      tabIndex={0}
      className="panel-row"
      onClick={onClick}
    >
      <div className="panel-content">
        <div className="panel-item w-100">
          <div className="card-user is-empty">
            <i className="material-icons">add</i>
          </div>
        </div>
      </div>
    </div>
  );
}
