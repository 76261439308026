import { toJS } from 'mobx';

import { MonthEventsType, DayEventsType } from 'types/calendarTypes';
import { TIME_LINE_DATE } from 'components/Calendar/constants/eventSetting';
import { DetailEventType } from '../types/storeTypes';

export const getDayTimeEvents = (dataEvents: MonthEventsType[]): DayEventsType[] => {
  const events: DayEventsType[] = [];

  if (dataEvents.length === 0) {
    return [];
  }

  dataEvents.forEach((item: MonthEventsType) => {
    const updateItem = toJS(item);
    const { time, group, id } = updateItem;

    const eventTime = time.split(':');

    const groupDate: DayEventsType = { group, id, eventsGroup: [] };

    TIME_LINE_DATE.forEach((data: MonthEventsType) => {
      if (data.time === eventTime[0]) {
        return groupDate.eventsGroup.push(updateItem);
      }

      return groupDate.eventsGroup.push(data);
    });

    return events.push(groupDate);
  });

  return events;
};

export const getFirstEvent = (dayEvents: DetailEventType[]): MonthEventsType => {
  const allDayEvents: MonthEventsType[] = [];

  dayEvents.forEach((groupEvents) => {
    const { eventsGroup } = groupEvents;

    eventsGroup.forEach((event) => {
      if ('id' in event) {
        allDayEvents.push(event);
      }
    });
  });

  allDayEvents[0].isActive = true;

  return allDayEvents[0];
};

export const updateActiveEvent = (groupEvent: MonthEventsType, id: number): MonthEventsType => {
  if (groupEvent.id === id) {
    groupEvent.isActive = true;

    return groupEvent;
  }
  if ('isActive' in groupEvent) {
    groupEvent.isActive = false;

    return groupEvent;
  }

  return groupEvent;
};

export const editDayEvent = (
  dayEvents: DetailEventType[],
  detailEvent: MonthEventsType
) => dayEvents.map((dayEvent) => {
  if (dayEvent.id === detailEvent.id) {
    const [updatedTimeLine] = getDayTimeEvents([detailEvent]);

    return {
      ...updatedTimeLine
    };
  }

  return dayEvent;
});
