import React from 'react';

import { ITeacherData } from 'components/Teachers/interface';

const TeacherMobileDetails: React.FC<ITeacherData> = (props: ITeacherData) => {
  const {
    email, phoneNumber, skype
  } = props;

  return (
    <>
      <div className="panel-item">
        <div className="panel-label">Email</div>
        {email}
      </div>
      <div className="panel-item">
        <div className="panel-label">Phone Number</div>
        {phoneNumber}
      </div>
      <div className="panel-item">
        <div className="panel-label">Skype</div>
        {skype}
      </div>
    </>
  );
};

export default TeacherMobileDetails;
