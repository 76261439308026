import React from 'react';
import Status from 'components/common/Status';

function StatusHint(props) {
  // eslint-disable-next-line react/prop-types
  const { status } = props;
  return (
    <div className="status-hint">
      <Status status={status} />
      <span className="status-hint-label">{status}</span>
    </div>
  );
}

export default StatusHint;
