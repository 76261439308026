import classNames from 'classnames';
import { components } from 'react-select';
import React from 'react';

import { SELECT_OPTIONS_TYPE } from 'components/common/FormFields/Selects/constant';

import User from 'components/common/User';

export const renderOption = (optionProps: any, optionType: string) => {
  const { data: { photo }, isSelected, isFocused } = optionProps;

  switch (optionType) {
    case SELECT_OPTIONS_TYPE.WITH_PHOTO:
      return (
        <div className={classNames('custom-select-option', {
          'is-selected': isSelected,
          'is-focused': isFocused
        })}
        >
          <User userIcon={photo} />
          <components.Option {...optionProps} />
        </div>
      );
    case SELECT_OPTIONS_TYPE.MULTI:
      return (
        <div className={classNames('custom-select-option multi', {
          'is-focused': isFocused
        })}
        >
          <components.Option {...optionProps} />
          <input type="checkbox" checked={isSelected} />
        </div>
      );
    default:
      return <components.Option {...optionProps} />;
  }
};
