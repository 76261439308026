import React from 'react';

import { INormalAdminData } from 'components/Admins/interface';
import User from 'components/common/User';
import Actions from 'components/Admins/AdminsTable/AdminInfo/Actions';

const AdminInfo: React.FC<INormalAdminData> = (props: INormalAdminData) => {
  const { username, email } = props;

  return (
    <>
      <div className="panel-header">
        <div className="panel-item w-20">
          <User hasRole={false} userName={username} />
        </div>
        <div className="panel-item w-20">{email}</div>
      </div>
      <Actions {...props} />
    </>
  );
};

export default AdminInfo;
