import { IStudentData } from 'components/Students/interface';

export const getStudentsWithoutDepartmentGroup = (students: IStudentData[]) => {
  const studentsWithoutDepartmentGroup = students.filter(
    ({ departmentGroupId }) => !departmentGroupId
  );
  return studentsWithoutDepartmentGroup;
};

export const getUsersByRole = (
  users: IStudentData[],
  role: number
) => users.filter((user: IStudentData) => user.roleId === role);
