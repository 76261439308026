import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Notifications from 'components/common/Notifications';

import { setDateFormat } from 'services/date';

import MainRouter from './Router';

setDateFormat();

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f4f5f9',
      main: '#0589ff',
      dark: '#192c5d'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Notifications />
          <MainRouter />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
