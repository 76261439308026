/* eslint camelcase: 0 */
import { INoNormalTeacherData } from 'components/Teachers/interface';

export const normalizeTeachersData = (teachersData: INoNormalTeacherData[]) => teachersData.map((teacherData) => {
  const {
    active,
    email,
    name,
    phone_number,
    role_id,
    role_name,
    skype,
    surname,
    type_id,
    type_name,
    user_id,
    username
  } = teacherData;

  return {
    active,
    email,
    name,
    surname,
    fullName: `${surname} ${name}`,
    phoneNumber: phone_number,
    roleId: role_id,
    roleName: role_name,
    skype,
    typeId: type_id,
    typeName: type_name,
    userId: user_id,
    username,
    isEdit: false
  };
});
