import React from 'react';

import FullGroupInfoTeacher from './FullGroupInfoTeacher';
import FullGroupInfoStudent from './FullGroupInfoStudent';
import { IFullGroupInfoProps } from './interfaces';
import { IGroupsStudentData } from '../interfaces';
import EmptyState from '../../common/EmptyState';

const FullGroupInfo: React.FC<IFullGroupInfoProps> = (props: IFullGroupInfoProps) => {
  const {
    teacher, students, deleteStudent, isAdmin
  } = props;

  const studentsList = students.length ? (
    students.map((student: IGroupsStudentData):JSX.Element => (
      <FullGroupInfoStudent
        student={student}
        deleteStudent={deleteStudent}
        key={`Student-${student.id}`}
        isAdmin={isAdmin}
      />
    ))
  ) : (
    <EmptyState
      title="There are no students in the group"
      additionalClassName="inline"
    />
  );

  return (
    <div className="accordion-content">
      <div className="panel-secondary">
        <div className="panel-label">Teacher</div>
        <FullGroupInfoTeacher teacher={teacher} />
      </div>
      <div className="panel-secondary">
        <div className="panel-label">Students</div>
        {studentsList}
      </div>
    </div>
  );
};

export default FullGroupInfo;
