import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import Spinner from '../common/Spinner';

const Loader: React.FC = () => {
  const stores: { [key: string]: any } = useContext(RootStore);

  const isLoading = Object.keys(stores).some((store) => stores[store].isLoading);

  return <Spinner isLoading={isLoading} />;
};

export default observer(Loader);
