import { STUDY_GROUP_TITLE } from '../AddGroup/constants';

export const validation = (values: any): any => {
  const errors: any = {};

  if (!values[STUDY_GROUP_TITLE]) {
    errors[STUDY_GROUP_TITLE] = 'Title is required';
  }

  return errors;
};
