import { action } from 'mobx';

import {
  editEventLesson, editEventTest
} from 'api/events';
import { IRootStore } from 'store/interfaces';
import { successNotification } from 'components/common/Notifications/helper';

import { eventsType } from '../AddEvent/constants';
import { serializeLessonData, serializeTestData } from './helpers';

export default class EditEventStore {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action editEvent = async (data: any, type: string, closeModal: () => void) => {
    try {
      let result;

      if (type === eventsType.LESSON) {
        const serializedLessonData = serializeLessonData(data);

        result = await editEventLesson(serializedLessonData);

        this.rootStore.calendarStore.updateEvent(result.data.data);

        return result;
      }

      const serializedTestData = serializeTestData(data, type);

      result = await editEventTest(serializedTestData);
      this.rootStore.calendarStore.updateEvent(result.data.data);

      successNotification('event was updated', type);

      return result;
    } catch (error) {
      return error;
    } finally {
      closeModal();
    }
  };
}
