import { PROFILE_FORM } from 'constants/forms';
import { confirmationPassword } from 'services/validation';

export default (values: any): any => {
  let errors: any = {};

  if (PROFILE_FORM.password in values) {
    errors = { ...errors, ...confirmationPassword(values) };
  }

  return errors;
};
