import routes from 'constants/routes';
import { INavigationItem, INavigationItems } from './interfaces';

export const navigationItems: INavigationItems = {
  calendar: {
    route: routes.CALENDAR_ROUTE,
    iconName: 'event',
    name: 'Calendar'
  },
  students: {
    route: routes.STUDENTS_ROUTE,
    iconName: 'people',
    name: 'Students'
  },
  results: {
    route: routes.TEST_RESULTS_ROUTE,
    iconName: 'assignment_turned_in',
    name: 'Test results'
  },
  attendance: {
    route: routes.ATTENDANCE_LOG_ROUTE,
    iconName: 'date_range',
    name: 'Attendance Log'
  },
  schedule: {
    route: routes.TEST_SCHEDULE_ROUTE,
    iconName: 'description',
    name: 'Test Schedule'
  },
  addToGroup: {
    route: routes.ADD_TO_GROUP_ROUTE,
    iconName: 'group_add',
    name: 'Add to Group'
  },
  groups: {
    route: routes.GROUPS_ROUTE,
    iconName: 'recent_actors',
    name: 'Groups'
  },
  teachers: {
    route: routes.TEACHERS_ROUTE,
    iconName: 'school',
    name: 'Teachers'
  },
  admins: {
    route: routes.ADMINS_ROUTE,
    iconName: 'person',
    name: 'Admins'
  },
  departments: {
    route: routes.DEPARTMENTS_ROUTE,
    iconName: 'recent_actors',
    name: 'Departments'
  },
  settings: {
    route: routes.SETTINGS_ROUTE,
    iconName: 'settings',
    name: 'Settings'
  },
  logOut: {
    route: '',
    iconName: 'power_settings_new',
    name: 'Log out'
  }
};

export const adminNavigationList: INavigationItem[] = [
  navigationItems.calendar,
  navigationItems.results,
  navigationItems.addToGroup,
  navigationItems.groups,
  navigationItems.teachers,
  navigationItems.students,
  navigationItems.attendance
];

export const groupLeadNavigationList: INavigationItem[] = [
  navigationItems.calendar,
  navigationItems.students,
  navigationItems.results,
  navigationItems.attendance,
  navigationItems.schedule
];

export const studentNavigationList: INavigationItem[] = [
  navigationItems.calendar,
  navigationItems.results,
  navigationItems.attendance
];

export const teacherNavigationList: INavigationItem[] = [
  navigationItems.calendar,
  navigationItems.groups
];

export const superAdminNavigationList: INavigationItem[] = [
  navigationItems.calendar,
  navigationItems.results,
  navigationItems.addToGroup,
  navigationItems.groups,
  navigationItems.admins,
  navigationItems.teachers,
  navigationItems.students,
  navigationItems.attendance,
  navigationItems.schedule,
  navigationItems.departments
];

export const COMMON_ROUTES = [
  routes.FORBIDDEN,
  routes.SERVER_ERROR,
  routes.PAGE_NOT_FOUND,
  routes.INDEX,
  routes.PROFILE,
  routes.SETTINGS_ROUTE,
  routes.RESTORE_PASSWORD
];

