import React from 'react';

import { StatusType } from './interface';

const Status = (props: StatusType) => {
  const { status, label } = props;

  return (
    <div className={`status ${status}`}>
      <div className={`status-icon ${status}`} />
      {label && <div className="status-label">{label}</div>}
    </div>
  );
};

export default Status;
