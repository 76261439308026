import api from 'api';
import request from 'api/request';

import {
  IGetStudentsParams,
  IGetAvailableStudyGroupsParams,
  IAddStudentStatusParams,
  IDeleteStudentFromGroupParams,
  IAddStudentToGroupParams
} from 'components/Students/interface';

export const getStudentsList = (params: IGetStudentsParams): Promise<any> => request({
  url: api.STUDENTS,
  method: 'GET',
  params
});

export const getAvailableStudyGroups = (params: IGetAvailableStudyGroupsParams): Promise<any> => request({
  url: api.AVAILABLE_STUDY_GROUPS,
  method: 'GET',
  params
});

export const addStudentStatus = (data: IAddStudentStatusParams): Promise<any> => request({
  url: api.ADD_STUDENT_STATUS,
  method: 'PATCH',
  data
});

export const deleteStudentFromGroup = (params: IDeleteStudentFromGroupParams): Promise<any> => request({
  url: api.STUDENT_GROUP,
  method: 'DELETE',
  params
});

export const addStudentToGroup = (params: IAddStudentToGroupParams): Promise<any> => request({
  url: api.STUDENT_GROUP,
  method: 'POST',
  params
});
