import React from 'react';
import classNames from 'classnames';

import { IMonthCountDay } from '../interfaces';

const AttendanceLogCalendarCell: React.FC<IMonthCountDay> = (props: IMonthCountDay) => {
  const { day, date, currentDay, style } = props;
  const isToday: boolean = date === currentDay;

  const attendanceCellClassName: string = classNames(
    'attendance-cell-item',
    isToday && 'is-active',
  );

  return (
    <th className={attendanceCellClassName} style={style}>
      <div className="attendance-item">
        <span className="calendar-month">{day}</span>
        <span className="calendar-day">{date}</span>
      </div>
    </th>
  );
};

export default AttendanceLogCalendarCell;
