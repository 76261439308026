import React, { useRef } from 'react';
import moment from 'moment';
import CalendarDay from './CalendarDay';

import { ICalendarLineProps } from './interface';

const CalendarLine: React.FC<ICalendarLineProps> = (props: ICalendarLineProps) => {
  const { monthData, onDaySelect, selectedDate } = props;
  const calendarRef = useRef(null);
  if (calendarRef.current) {
    calendarRef.current.scrollLeft = 100;
  }

  const calendarDays = monthData.map((dayData) => (
    <CalendarDay
      {...dayData}
      key={dayData.fullDate}
      onDaySelect={onDaySelect}
      isCurrent={moment(dayData.fullDate).isSame(new Date(), 'day')}
      isActive={moment(dayData.fullDate).isSame(selectedDate)}
    />
  ));

  return <div className="test-calendar" ref={calendarRef}>{calendarDays}</div>;
};

export default CalendarLine;
