import React from 'react';

import CustomDateTime from 'components/Calendar/CustomDateTime';
import FilterCalendarData from './FilterCalendarData';

const ScheduleCalendar = () => (
  <>
    <div className="card-body">
      <CustomDateTime />
    </div>
    <div className="calendar-footer">
      <FilterCalendarData />
    </div>
  </>
);

export default ScheduleCalendar;
