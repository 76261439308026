import { observable, computed, action } from 'mobx';

import {
  login, logout, updateProfileRequest, passwordRecoveryRequest, changePasswordRequest, deletePhoto
} from 'api/authentication';
import history from 'services/history';
import { removeFromLocalStorage, saveToLocalStorage, loadFromLocalStorage } from 'services/localStorage';
import routes from 'constants/routes';
import {
  ACCESS_TOKEN, REFRESH_TOKEN, USER_DATA
} from 'constants/localStorage';

import {
  IAuthError, IToken, IUser, IRestore, IRestorePassword, IUserUpdateData
} from './interfaces';
import { PROFILE_REQUEST } from '../../constants/forms';
import { successNotification } from '../common/Notifications/helper';
import { serializeUser } from './utils';

export default class AuthenticationStore {
  @observable userDataInitialized = false;

  @observable isLoading = false;

  @observable user: IUser = {
    id: null,
    name: '',
    surname: '',
    departmentId: null,
    departmentGroupId: null,
    departmentGroupName: '',
    photo: '',
    role: ''
  };

  @observable token: IToken = {
    accessToken: '',
    refreshToken: ''
  };

  @observable isAuthenticated: boolean = false;

  @observable authError: object = {};

  @observable isAuthError: boolean = false;

  @computed get UserToken(): IToken {
    return this.token;
  }

  @computed get AuthError(): IAuthError {
    return {
      authError: this.authError,
      isAuthError: this.isAuthError
    };
  }

  @action setAuthError = (error: object): void => {
    this.authError = error;
    this.isAuthError = true;
  };

  @computed get UserData(): IUser {
    return this.user;
  }

  @action setTokenFromStorage = async (tokenData: IToken): Promise<void> => {
    try {
      this.setTokenToStore(tokenData);
      this.isAuthenticated = true;

      saveToLocalStorage(ACCESS_TOKEN, this.UserToken.refreshToken);
      saveToLocalStorage(REFRESH_TOKEN, this.UserToken.accessToken);
    } catch (error) {
      console.error(error);
    }
  };

  @action loginRequest = async (userData: object): Promise<void> => {
    try {
      this.isLoading = true;
      const { data } = await login(userData);
      const {
        access_token: accessToken,
        refresh_token: refreshToken
      } = data;

      this.user = serializeUser(data);

      this.setTokenToStore({
        accessToken,
        refreshToken
      });

      this.isAuthenticated = true;

      saveToLocalStorage(ACCESS_TOKEN, accessToken);
      saveToLocalStorage(REFRESH_TOKEN, refreshToken);
      saveToLocalStorage(USER_DATA, this.user);

      history.replace(routes.INDEX);
    } catch (error) {
      this.resetToken();
      const { response, message } = error;

      if (response) {
        const { data } = response;

        if (data && data.errors) {
          this.setAuthError(data.errors.description);
        }
      } else {
        this.setAuthError(message);
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action logoutRequest = async (): Promise<void> => {
    try {
      await logout({ refresh_token: this.UserToken.refreshToken });
    } catch (error) {
      console.error(error);
    } finally {
      this.resetToken();

      history.replace(routes.LOGIN_ROUTE);
    }
  };

  @action setTokenToStore = (tokenData: IToken): void => {
    this.token = tokenData;
  };

  @action resetToken = (): void => {
    this.token = {
      accessToken: '',
      refreshToken: ''
    };

    this.isAuthenticated = false;

    removeFromLocalStorage(ACCESS_TOKEN);
    removeFromLocalStorage(REFRESH_TOKEN);
    removeFromLocalStorage(USER_DATA);
  };

  @action setUserData = (data: IUser): void => {
    this.user = data;
    this.userDataInitialized = true;
  };

  @action loadUserRoleFromLocalStorage = (): void => {
    const userData = loadFromLocalStorage(USER_DATA);

    if (userData) {
      this.user = userData;
      this.userDataInitialized = true;
    }
  };

  @action updateProfile = async (formValues: IUserUpdateData) => {
    try {
      const formData = new FormData();

      Object.keys(formValues).forEach((key: string) => {
        if (!formValues[key]) { return; }

        // @ts-ignore
        const value = formValues[key].value || formValues[key];

        if (value) {
          const field: string = PROFILE_REQUEST[key];
          if (field) {
            formData.set(field, value);
          }
        }
      });

      const { data: { data } } = await updateProfileRequest(formData);

      this.user = serializeUser(data);

      saveToLocalStorage(USER_DATA, this.user);
    } catch (error) {
      console.error(error);
    }
  };

  @action recoveryPassword = async (data: IRestore, switchToLogin: () => void) => {
    try {
      await passwordRecoveryRequest(data);

      successNotification('We have sent an e-mail with link to set new password. Check it, please.');
      switchToLogin();
    } catch (e) {
      console.error(e);
    }
  };

  @action changePassword = async (data: IRestorePassword) => {
    try {
      await changePasswordRequest(data);
      history.push(routes.LOGIN_ROUTE);
    } catch (e) {
      console.error(e);
    }
  };

  @action deletePhoto = async () => {
    try {
      await deletePhoto();

      this.user.photo = null;
      saveToLocalStorage(USER_DATA, this.user);
    } catch (error) {
      console.error(error);
    }
  }
}
