import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import FormComponent from 'components/common/FormComponent';

import LessonForm from './LessonForm';
import { ILessonProps } from './interface';
import { IEventData } from '../../AddEvent/interface';
import { getInitial } from './helpers';

const Lesson: React.FC<ILessonProps> = observer((props: ILessonProps) => {
  const {
    setFormData, setIsFormValid, initialData, editMode, validate
  } = props;
  const { addEventStore } = useContext(RootStore);
  const { getStudyGroups, studyGroups } = addEventStore;

  const bindSubmitForm = (data: IEventData) => {
    setFormData(data);
  };

  useEffect(() => {
    getStudyGroups();
  }, []);

  return (
    <FormComponent
      Component={LessonForm}
      bindSubmitForm={bindSubmitForm}
      otherProps={{
        setIsFormValid,
        studyGroups,
        editMode
      }}
      initialValues={getInitial(initialData)}
      validate={(values: any) => validate(values)}
    />
  );
});

export default Lesson;
