import moment from 'moment';
import { format } from 'constants/date';

export const getNowTime = () => moment().format(format.TIME);

export const getTodayDate = () => moment()
  .startOf('day')
  .format(format.YEAR_MONTH_DAY);

export const getMonth = () => moment()
  .startOf('month')
  .format(format.YEAR_MONTH_DAY);

export const changeMonthInDate = (nowDate: string, value: number): string => {
  if (value > 0) {
    const nextMonth = moment(nowDate)
      .add(1, 'M')
      .format(format.YEAR_MONTH_DAY);

    return nextMonth;
  }

  const prevMonth = moment(nowDate)
    .subtract(1, 'M')
    .format(format.YEAR_MONTH_DAY);

  return prevMonth;
};

export const updateFormatDate = (
  date: string, newFormatDate: string
): string => moment(date).format(newFormatDate);

export const getDaysInMonth = (date: string): number => moment(date).daysInMonth();
