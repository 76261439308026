import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import RootStore from 'store';

import { IStudentData } from 'components/Students/interface';
import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import DragItem from 'components/common/DrugAndDrop/DragItem';

import { DRAG_TYPE_STUDENT } from '../constant';
import EmptyState from '../../common/EmptyState';

const StudentList: React.FC<IGradientData> = (props: IGradientData) => {
  const { bottom, top, toggleGradient, gradientInitialized } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { addToGroupStore } = useContext(RootStore);
  const { students } = addToGroupStore;

  const dragListClassName = classNames('drag-list-inner', {
    'has-bottom-gradient': !isReachedBottom && gradientInitialized,
    'has-top-gradient': !isReachedTop
  });

  const dragItemsStudents = students.map((student: IStudentData) => (
    <DragItem
      data={student}
      transmittedData={{ userId: student.userId }}
      dragType={DRAG_TYPE_STUDENT}
      key={student.userId}
    />
  ));

  const content = students.length
    ? (
      <PerfectScrollbar
        className="perfect-scrollbar flex-grow-1"
        onYReachStart={removeTopGradient}
        onYReachEnd={removeBottomGradient}
        onScrollY={toggleGradient}
      >
        {dragItemsStudents}
      </PerfectScrollbar>
    ) : (
      <EmptyState
        icon="sentiment_satisfied"
        title="All student are already assigned to groups"
        additionalClassName="card has-spacings"
      />
    );

  return (
    <div className="drag-list">
      <div className="title">Students</div>
      <div className={dragListClassName}>
        {content}
      </div>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(StudentList);
