import React from 'react';

import User from 'components/common/User';
import { FullGroupInfoUserProps } from './interfaces';

const FullGroupInfoTeacher: React.FC<FullGroupInfoUserProps> = (props: FullGroupInfoUserProps) => {
  const { teacher } = props;
  const {
    name,
    type,
    photo,
    email,
    surname
  } = teacher;

  return (
    <div className="panel-summary">
      <div className="panel-header">
        <div className="panel-item w-200">
          <User hasRole={false} userIcon={photo} userName={`${name} ${surname}`} />
        </div>
        <div className="panel-item w-200">{email}</div>
        <div className="panel-item">
          <span className="panel-label">Type</span>
          {type}
        </div>
      </div>
    </div>
  );
};

export default FullGroupInfoTeacher;
