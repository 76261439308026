import React from 'react';

import FormComponent from 'components/common/FormComponent';
import FormComponentAddTestResult from './FormComponentAddTestResult';

import { validTestResult } from './validate';
import { initialValueNames } from './constants';
import { IFormAddTestResultProps } from './interface';

export default (props: IFormAddTestResultProps) => {
  const { bindSubmitForm, testId, studentId } = props;

  return (
    <FormComponent
      Component={FormComponentAddTestResult}
      otherProps={props}
      onSubmit={() => {}}
      bindSubmitForm={bindSubmitForm}
      validate={(values: any) => validTestResult(values)}
      initialValues={{
        [initialValueNames.EVENT_PARENT_ID]: testId,
        [initialValueNames.EVENT_LEVEL_ID]: 1,
        [initialValueNames.USER_ID]: studentId,
        [initialValueNames.HOLDING]: '',
        [initialValueNames.COMMENT]: '',
        [initialValueNames.RECOMMENDED_ENGLISH_LEVEL_ID]: undefined,
        [initialValueNames.SPEAKING_ENGLISH_LEVEL_ID]: undefined,
        [initialValueNames.FLUENCY]: 0,
        [initialValueNames.ACCURACY]: 0,
        [initialValueNames.VOCABULARY]: 0,
        [initialValueNames.SPEAKING_GRAMMAR]: 0,
        [initialValueNames.COMMUNICATIVENESS]: 0,
        [initialValueNames.WRITING_ENGLISH_LEVEL_ID]: undefined,
        [initialValueNames.LISTENING]: 0,
        [initialValueNames.WRITING_GRAMMAR]: 0,
        [initialValueNames.WRITING]: 0,
        [initialValueNames.READING]: 0,
        [initialValueNames.PARAPHRASE]: 0,
        [initialValueNames.ESSAY]: 0
      }}
    />
  );
};
