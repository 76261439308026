import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { ITotalResultsProps } from './interface';

import TotalResult from './TotalResult';

const TotalResults: React.FC<ITotalResultsProps> = observer(({ indexTestResult }) => {
  const { testResults } = useContext(RootStore);
  const { listTestResults, previousTestResults } = testResults;
  const {
    speakingEnglishLevelName,
    writingEnglishLevelName,
    holding,
    comment,
    recommendedEnglishLevelName
  } = listTestResults[indexTestResult];

  const totalResults = [
    {
      value: speakingEnglishLevelName,
      prevValue: previousTestResults ? previousTestResults.speakingEnglishLevelName : null,
      label: 'Speaking level'
    },
    {
      value: writingEnglishLevelName,
      prevValue: previousTestResults ? previousTestResults.writingEnglishLevelName : null,
      label: 'Writing level'
    },
    {
      value: recommendedEnglishLevelName,
      prevValue: previousTestResults ? previousTestResults.recommendedEnglishLevelName : null,
      label: 'Recommended level'
    }
  ];

  return (
    <div className="accordion-group w-35">
      <div className="panel-item">
        {totalResults.map((speakingResult) => {
          const { label } = speakingResult;

          return <TotalResult {...speakingResult} key={label} />;
        })}
      </div>
      <div className="panel-item">
        <div className="accordion-info">
          <span className="accordion-subtitle">What is holding your back</span>
          <span className="accordion-text">{holding || '-'}</span>
        </div>
        <div className="accordion-info">
          <span className="accordion-subtitle">Comment</span>
          <span className="accordion-text">{comment || '-'}</span>
        </div>
      </div>
    </div>
  );
});

export default TotalResults;
