import { isEqual } from 'lodash';
import moment from 'moment';

import { toDate } from 'services/date';

import { eventFormFields } from '../../AddEvent/constants';
import { ITestInitialValues } from './interface';

export const getInitial = (initialData: ITestInitialValues = {}) => {
  const {
    time,
    date,
    group,
    teacher,
    endTime,
    roomNumber,
    studyGroupId,
    eventLevelId,
    teacherUserId,
    eventParentId,
    eventLevelName,
    eventParentName
  } = initialData;
  if (isEqual(initialData, {})) { return {}; }

  const weekDay = moment(date).format('dddd');
  const eventLevel = eventLevelId
    ? { value: eventLevelId, label: eventLevelName }
    : null;

  return {
    [eventFormFields.EVENT_PARENT]: { value: eventParentId, label: eventParentName },
    [eventFormFields.TEACHER]: { value: teacherUserId, label: teacher },
    [eventFormFields.EVENT_LEVEL]: eventLevel,
    [eventFormFields.DATE]: toDate(date),
    [eventFormFields.GROUP]: { value: studyGroupId, label: group },
    [eventFormFields.ROOM]: roomNumber,
    [eventFormFields.DAY]: { label: weekDay, value: weekDay },
    [eventFormFields.BEGIN_TIME]: toDate(`${date} ${time}`),
    [eventFormFields.END_TIME]: toDate(`${date} ${endTime}`)
  };
};
