import { SelectData } from 'types/filters';

export const DEFAULT_OPTION: SelectData[] = [
  {
    label: 'Loading...',
    value: null,
    disabled: true,
    isLoading: true
  }
];

export const NO_AVAILABLE_RESULTS: SelectData[] = [
  {
    label: 'No available results',
    value: null,
    disabled: true,
    isLoading: true
  }
];

export const SELECT_OPTIONS_TYPE = {
  WITH_PHOTO: 'withPhoto',
  MULTI: 'multi'
};
