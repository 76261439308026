export const errorMassage = {
  default: 'Required field',
  phone: 'Phone number should be in format +38XXXXXXXXXX'
};

export const PLACEHOLDERS = {
  name: 'Name',
  surname: 'Surname',
  username: 'Username',
  email: 'Email',
  password: 'Password',
  skype: 'Skype',
  phone: 'Phone',
  lessonType: 'Lesson type',
  groupName: 'Group name',
  department: 'Department',
  lead: 'Lead'
};

export const PROFILE_FORM: any = {
  name: 'name',
  surname: 'surname',
  username: 'username',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  skype: 'skype',
  phone: 'phone',
  type: 'type'
};

export const PROFILE_REQUEST: any = {
  name: 'name',
  surname: 'surname',
  username: 'username',
  email: 'email',
  password: 'password',
  skype: 'skype',
  phone: 'phone_number',
  photo: 'photo',
  type: 'lesson_type'
};
