import { EXPAND_LESS, EXPAND_MORE } from 'components/common/ColumnValueInTable/constants';
import { eventsType } from 'components/Modals/Event/AddEvent/constants';
import * as INTERFACES from './interfaces';

export const USER_ICONS_TO_RENDER: number = 4;

export const DEFAULT_SORT_ITEMS = [
  {
    title: 'Group',
    value: 'name',
    sortingArrow: EXPAND_MORE,
    itemWidth: 'w-200',
    displayType: 'm-hidden',
    isActive: true
  },
  {
    title: 'Teacher',
    value: 'teacher_user_surname',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-200',
    isActive: false
  },
  {
    title: 'Students',
    value: 'students',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-200',
    displayType: 'm-hidden',
    isActive: false
  }
];

export const DEFAULT_SORT_PARAMS: INTERFACES.ISortParams = {
  value: 'name',
  sortOrder: 3
};

export const addGroupButtonsGroupData = [
  {
    name: eventsType.LESSON,
    id: 'lesson',
    value: 'Lesson'
  },
  {
    name: eventsType.MAIN,
    id: 'main-test',
    value: 'Test'
  }
];

export const LESSON_TYPES: INTERFACES.ITeacherTypes = {
  'native speaker': 'Native',
  standard: 'Intensive'
};

export const NATIVE_SPEAKER_TEACHER_ID: number = 2;

export const NATIVE_TYPE_ID: number = 2;

export const LOADING_SELECT_STATE: INTERFACES.ISelectValues[] = [{
  value: null,
  label: 'Loading...',
  disabled: true
}];

export const REMOVE_STUDENT_PARAM_FOR_ADMIN: number = 1;
