import React from 'react';

import { TableHeaderType } from 'components/Calendar/EventsTable/interface';

import GroupNames from './GroupNames';

const TableHeader = (props: TableHeaderType) => {
  const { scrollX, dayEvents } = props;

  return (
    <thead className="timeline-thead">
      <tr>
        <th className="timeline-head-cell has-icon">
          <div className="timeline-cell-content">
            <i className="material-icons">people</i>
          </div>
        </th>
        <GroupNames dayEvents={dayEvents} scrollX={scrollX} />
      </tr>
    </thead>
  );
};

export default TableHeader;
