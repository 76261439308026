import React from 'react';

import { format } from 'constants/date';
import { updateFormatDate, changeMonthInDate } from 'helpers/actionsWithDates';

import StatusHint from 'components/common/StatusHint';

import { IHeaderProps } from './interface';

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { date, changeDate, eventTypes } = props;

  const monthData = updateFormatDate(date, format.MONTH_STR_FULL_YEAR);

  const setNextMonth = () => {
    const nextMonth = changeMonthInDate(date, 1);

    changeDate(nextMonth);
  };

  const setPrevMonth = () => {
    const prevMonth = changeMonthInDate(date, 0);

    changeDate(prevMonth);
  };

  const EventTypes = eventTypes.map((type: string) => <StatusHint status={type} key={type} />);

  return (
    <div className="row attendance-header">
      <div className="col-xs-6 col-md-4 attendance-navigation">
        <span className="rdtSwitch">{monthData}</span>
        <button className="attendance-prev" type="button" onClick={setPrevMonth} />
        <button className="attendance-next" type="button" onClick={setNextMonth} />
      </div>
      <div className="col-xs-6 col-md-4 hints">
        {EventTypes}
      </div>
    </div>
  );
};

export default Header;
