import React, { useState } from 'react';
import cn from 'classnames';

import User from 'components/common/User';
import { IGroupRowProps } from './interfaces';
import StudentsIcons from '../StudentsIcons';
import FullGroupInfo from '../FullGroupInfo';
import DeleteGroupModal from '../Modals/DeleteGroup';
import EditStudyGroupModal from '../Modals/EditStudyGroup';

const GroupRow: React.FC<IGroupRowProps> = (props: IGroupRowProps) => {
  const {
    group,
    deleteStudent,
    deleteStudyGroup,
    setEditingStudyGroup,
    editingStudyGroup,
    setEditingStudyGroupTitle,
    setEditingStudyGroupTeacher,
    updateStudyGroup,
    getTeachersList,
    teachersList,
    isAdmin,
    isMobile
  } = props;

  const {
    id,
    name,
    students,
    teacher
  } = group;

  const [isDeleteStudyGroupModalOpen, setDeleteStudyGroupModalState] = useState(false);
  const [isEditStudyGroupModalOpen, setEditStudyGroupModalState] = useState(false);

  const toggleDeleteStudyGroupModal = () => setDeleteStudyGroupModalState(!isDeleteStudyGroupModalOpen);
  const toggleEditStudyGroupModal = () => setEditStudyGroupModalState(!setEditStudyGroupModalState);

  const openDeleteStudyGroupModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDeleteStudyGroupModalState(true);
  };

  const openEditStudyGroupModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setEditingStudyGroup(group);
    setEditStudyGroupModalState(true);
  };

  const panelNavigationClassName = cn('panel-navigation', {
    'is-hidden': !isMobile
  });

  return (
    <>
      <details className="accordion-thumb">
        <summary className="panel-summary">
          <div className="panel-header">
            {!isMobile && <div className="panel-item w-200">{name}</div>}
            <div className="panel-item w-200">
              <User
                hasRole={false}
                userIcon={teacher.photo}
                userName={`${teacher.name} ${teacher.surname}`}
              />
            </div>
            <div className="panel-item w-200">
              <div className="user-group">
                <StudentsIcons students={students} />
              </div>
            </div>
          </div>
          {isAdmin && (
            <div className={panelNavigationClassName}>
              <button className="accordion-button-edit" type="button" onClick={openEditStudyGroupModal}>
                <i className="material-icons">edit</i>
              </button>
              <button className="accordion-button-edit" type="button" onClick={openDeleteStudyGroupModal}>
                <i className="material-icons">delete</i>
              </button>
              <i className="material-icons accordion-arrow">keyboard_arrow_down</i>
            </div>
          )}
        </summary>
        <FullGroupInfo teacher={teacher} students={students} deleteStudent={deleteStudent} isAdmin={isAdmin} />
      </details>
      <DeleteGroupModal
        isOpen={isDeleteStudyGroupModalOpen}
        toggleModal={toggleDeleteStudyGroupModal}
        studyGroupName={name}
        studyGroupId={id}
        deleteStudyGroup={deleteStudyGroup}
      />
      {editingStudyGroup && (
        <EditStudyGroupModal
          isOpen={isEditStudyGroupModalOpen}
          toggleModal={toggleEditStudyGroupModal}
          studyGroup={editingStudyGroup}
          setEditingStudyGroupTitle={setEditingStudyGroupTitle}
          setEditingStudyGroupTeacher={setEditingStudyGroupTeacher}
          updateStudyGroup={updateStudyGroup}
          getTeachersList={getTeachersList}
          teachersList={teachersList}
        />
      )}
    </>
  );
};

export default GroupRow;
