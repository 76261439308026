import React from 'react';

import { initialAddAdmin } from 'components/Modals/Admin/AddNew/Form/constants';

import { validFormAddAdmin } from 'components/Modals/Admin/AddNew/Form/validate';

import { IAddNewAdminProps, IInitialData } from 'components/Modals/Admin/AddNew/Form/interface';

import FormComponent from 'components/common/FormComponent';
import FormComponentAddAdmin from 'components/Modals/Admin/AddNew/Form/FormComponent';

export default (props: IAddNewAdminProps) => {
  const { setFormData } = props;

  const bindSubmitForm = (data: IInitialData) => {
    setFormData(data);
  };

  return (
    <FormComponent
      Component={FormComponentAddAdmin}
      otherProps={{ ...props }}
      bindSubmitForm={bindSubmitForm}
      validate={(values: IInitialData) => validFormAddAdmin(values)}
      initialValues={initialAddAdmin}
    />
  );
};
