import { MonthEventsType, StudentsListType } from 'types/calendarTypes';
import { IPatchAttendanceParams } from 'components/Calendar/interface';
import { DetailEventType } from '../types/storeTypes';

export const getDetailEventById = (dayEvents: DetailEventType[], index: number): MonthEventsType => {
  let detailEvent: MonthEventsType;

  const groupEvents = dayEvents[index];
  const { eventsGroup } = groupEvents;

  eventsGroup.forEach((groupData: MonthEventsType) => {
    if ('id' in groupData) {
      detailEvent = groupData;
    }
  });

  return detailEvent;
};

export const setDetailEventAttendance = (
  students: StudentsListType[],
  changedData: IPatchAttendanceParams
) => students.map((student) => {
  if (student.eventStudentId === changedData.event_student_id) {
    return { ...student, attendance: !!changedData.attendance };
  }

  return student;
});
