import { errorMassage } from 'constants/forms';
import { IErrorFormData, IInitialData } from 'components/Modals/Admin/AddNew/Form/interface';

export const validFormAddAdmin = (values: IInitialData) => {
  const errors: IErrorFormData = {};

  if (!values.password) {
    errors.password = errorMassage.default;
  }

  if (!values.name) {
    errors.name = errorMassage.default;
  }

  if (!values.surname) {
    errors.surname = errorMassage.default;
  }

  if (!values.username) {
    errors.username = errorMassage.default;
  }

  if (!values.email) {
    errors.email = errorMassage.default;
  }

  return errors;
};
