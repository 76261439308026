import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';
import {
  ADMIN, SUPER_ADMIN, GROUP_LEAD, TEACHER
} from 'constants/roles';
import { ITestResultParamsProps } from 'components/TestResults/types';
import { IPermissions } from 'types';
import WithPermissions from 'hocs/WithPermissions';
import Header from 'components/common/Header';
import Pagination from 'components/common/Pagination';
import AddTestResult from 'components/Modals/AddTestResult';
import DeleteTestResult from 'components/Modals/DeleteTestResult';
import GeneralInfoTestResult from 'components/TestResults/GeneralInfoTestResult/index';
import EmptyState from 'components/common/EmptyState';

import Filters from './Filters';
import SortPanel from './SortPanel';
import { useStateModal } from '../Modals/helper';

const TestResults: React.FC<IPermissions> = observer((props) => {
  const { permissions } = props;

  const { testResults, authenticationStore, modalsAddTestResult, serviceStore: { isMobile } } = useContext(RootStore);
  const { getSelectsData } = modalsAddTestResult;
  const { user } = authenticationStore;
  const { id, departmentGroupId, departmentGroupName } = user;
  const {
    listTestResults,
    pagesData,
    updateListTestResults,
    updateSelectedPage,
    sortedData,
    updateFilterData,
    filter
  } = testResults;
  const { countPages, selectedPage } = pagesData;
  const { testNames, studyGroupNames } = filter;

  const defaultDepartmentGroupData = { label: departmentGroupName, value: departmentGroupId };
  const testNamesLength = testNames.length;
  const studyGroupNamesLength = studyGroupNames.length;
  const isRenderPagination = countPages > 1;

  const [selectedTest, changeTest] = useState(null);
  const [selectedDepartmentGroup, changeDepartmentGroup] = useState(defaultDepartmentGroupData);
  const [selectedStudyGroup, changeStudyGroup] = useState(null);
  const [isOpenModalAddTestResult, updateModalAddTestResultState] = useState(false);
  const [isOpenModalDeleteTestResult, updateModalDeleteTestResultState] = useState(false);
  const [isMobileFiltersOpen, toggleIsMobileFiltersOpen, setIsMobileFiltersOpen]: any = useStateModal(false);

  useEffect(() => {
    if (permissions([ADMIN, SUPER_ADMIN])) {
      getSelectsData();
    }
  }, []);

  const fetchData = (selectedTestValue?: any) => {
    if (selectedTest || selectedTestValue) {
      const event_parent_id = selectedTestValue
        ? selectedTestValue.value
        : selectedTest.value;

      const params: ITestResultParamsProps = {
        user_id: id,
        event_parent_id,
        department_group_id: null,
        study_group_id: null
      };

      if (permissions([GROUP_LEAD])) {
        params.department_group_id = selectedDepartmentGroup.value;
      }

      if (permissions([ADMIN, SUPER_ADMIN, TEACHER])) {
        if (selectedStudyGroup) {
          params.study_group_id = selectedStudyGroup.value;
        }
      }

      updateListTestResults(params);
      setIsMobileFiltersOpen(false);
    }
  };

  useEffect(() => {
    if (testNamesLength) {
      changeTest(testNames[0]);
    }

    if (studyGroupNamesLength) {
      changeStudyGroup(studyGroupNames[0]);
    }
  }, [testNamesLength, studyGroupNamesLength]);

  useEffect(() => {
    const firstTestInList = testNames.length && testNames[0];

    if (isMobile && firstTestInList && selectedStudyGroup) {
      fetchData(firstTestInList);
    }
  }, [
    id,
    sortedData,
    selectedPage,
    isOpenModalAddTestResult,
    isOpenModalDeleteTestResult,
    departmentGroupId,
    selectedStudyGroup,
    selectedDepartmentGroup,
    testNamesLength
  ]);

  useEffect(() => {
    updateFilterData(user);

    if (isMobile) { return; }

    fetchData();
  }, [
    id,
    sortedData,
    selectedPage,
    isOpenModalAddTestResult,
    isOpenModalDeleteTestResult,
    departmentGroupId,
    selectedStudyGroup,
    selectedDepartmentGroup,
    selectedTest
  ]);

  const isAdmin = permissions([ADMIN, SUPER_ADMIN]);

  return (
    <>
      <Header
        pageTitle="Test Results"
        onAddClick={isAdmin && (() => { updateModalAddTestResultState(true); })}
        onFiltersClick={toggleIsMobileFiltersOpen}
      >
        <Filters
          selectedDepartmentGroup={selectedDepartmentGroup}
          changeDepartmentGroup={changeDepartmentGroup}
          selectedStudyGroup={selectedStudyGroup}
          changeStudyGroup={changeStudyGroup}
          selectedTest={selectedTest}
          changeTest={changeTest}
          permissions={permissions}
          isOpen={isMobileFiltersOpen}
          toggleIsMobileFiltersOpen={toggleIsMobileFiltersOpen}
          onApply={fetchData}
        />
      </Header>
      <div className="page-content">
        <SortPanel isAdmin={isAdmin} openModal={updateModalAddTestResultState} />
        <div className="accordion flex-grow-1">
          <PerfectScrollbar className="perfect-scrollbar">
            {listTestResults.length ? (
              listTestResults.map((testResult, index) => {
                const { testResultId } = testResult;

                return (
                  <GeneralInfoTestResult
                    permissions={permissions}
                    updateModalDeleteTestResultState={updateModalDeleteTestResultState}
                    indexTestResult={index}
                    key={testResultId}
                  />
                );
              })
            ) : (
              <EmptyState
                icon="sentiment_dissatisfied"
                title="No test result yet"
                additionalClassName="card has-spacings"
              />
            )}
          </PerfectScrollbar>
        </div>
        {isRenderPagination && (
          <Pagination
            countPages={countPages}
            selectedPage={selectedPage}
            updateSelectedPage={updateSelectedPage}
            paginationClassName="pagination-holder"
          />
        )}
        <AddTestResult isOpen={isOpenModalAddTestResult} toggleModal={updateModalAddTestResultState} />
        <DeleteTestResult isOpen={isOpenModalDeleteTestResult} toggleModal={updateModalDeleteTestResultState} />
      </div>
    </>
  );
});

export default WithPermissions(TestResults);
