import React from 'react';

import FormComponent from 'components/common/FormComponent';

import FormComponentEditTestResult from './FormComponentEditTestResult';

import { IEditTestResultProps } from './interface';

const EditTestResult: React.FC<IEditTestResultProps> = (props: IEditTestResultProps) => {
  const { initialTestResultData, setFormData } = props;

  const bindSubmitForm = (data: any) => {
    setFormData(data);
  };

  const {
    accuracy,
    comment,
    communicativeness,
    essay,
    testResultId,
    fluency,
    holding,
    listening,
    paraphrase,
    reading,
    recommendedEnglishLevelId,
    speakingEnglishLevelId,
    speakingGrammar,
    vocabulary,
    writing,
    writingEnglishLevelId,
    writingGrammar
  } = initialTestResultData;

  return (
    <FormComponent
      Component={FormComponentEditTestResult}
      otherProps={props}
      bindSubmitForm={bindSubmitForm}
      validate={(values: any) => {
        const errors: any = {};

        if (!values.recommended_english_level_id) {
          errors.recommended_english_level_id = 'Selected recommended level';
        }

        if (!values.speaking_english_level_id) {
          errors.speaking_english_level_id = 'Selected speaking level';
        }

        if (!values.writing_english_level_id) {
          errors.writing_english_level_id = 'Selected writing level';
        }

        return errors;
      }}
      initialValues={{
        test_result_id: testResultId,
        holding,
        comment,
        recommended_english_level_id: recommendedEnglishLevelId,
        speaking_english_level_id: speakingEnglishLevelId,
        fluency,
        accuracy,
        vocabulary,
        speaking_grammar: speakingGrammar,
        communicativeness,
        writing_english_level_id: writingEnglishLevelId,
        listening,
        writing_grammar: writingGrammar,
        writing,
        reading,
        paraphrase,
        essay
      }}
    />
  );
};

export default EditTestResult;
