import React from 'react';

import { IButtonsGroupProps, IButtonsGroupData } from './interface';

const ButtonsGroup: React.FC<IButtonsGroupProps> = (props: IButtonsGroupProps) => {
  const { data, updateData, selectedValue } = props;

  return (
    <div className="buttons-group">
      {data.map((buttonData: IButtonsGroupData) => {
        const { id, name, value } = buttonData;

        return (
          <div className="buttons-w-25" key={id} onClick={() => { updateData(name, buttonData); }}>
            <input
              className="buttons-group-input visually-hidden"
              type="radio"
              name="typeEvent"
              id={`${id}`}
              checked={name === selectedValue}
            />
            <label htmlFor={`${id}`} className="buttons-group-label">
              {value}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default ButtonsGroup;
