import React from 'react';
import ReactPaginate from 'react-paginate';

import { PaginationType } from './types';

const Pagination: React.FC<PaginationType> = (props: PaginationType) => {
  const {
    paginationClassName, countPages, updateSelectedPage, selectedPage
  } = props;

  const forcePage = selectedPage - 1;

  return (
    <div className={paginationClassName}>
      <ReactPaginate
        pageCount={countPages}
        activeClassName="active"
        containerClassName="pagination"
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        breakLabel="..."
        previousLabel=""
        nextLabel=""
        breakClassName="pagination-ellipsis"
        breakLinkClassName="pagination-link"
        pageClassName="pagination-item"
        pageLinkClassName="pagination-link"
        previousClassName="pagination-previous"
        nextClassName="pagination-next"
        previousLinkClassName="pagination-link"
        nextLinkClassName="pagination-link"
        forcePage={forcePage}
        onPageChange={({ selected }) => updateSelectedPage(selected)}
      />
    </div>
  );
};

export default Pagination;
