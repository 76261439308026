import moment from 'moment';

import { isTimeNotInRange, isDifferenceBetweenDatesInvalid } from 'services/validation';

import {
  ISelectValues, IEventData, ISpeakingData, ILessonData
} from './interface';
import { ILessonFormValues } from '../components/Lesson/interface';
import { eventsType } from './constants';
import {
  INVALID_BEGIN_TIME,
  INVALID_END_TIME,
  INVALID_RANGE_BETWEEN_DATES
} from '../../../../constants/validationMessages';

export const isSelectedTab = (selectedTab: string, tab: string): boolean => selectedTab === tab;

export const getOptionsForSelect = (values: Array<any>): Array<ISelectValues> => (
  values.map((value: any) => ({ value: value.id, label: value.name }))
);

export const formatTime = (date: string | Date): string => moment(date).format('HH:mm');

export const formatDate = (date: string | Date): string => moment(date).format('DD-MM-YYYY');

export const isWriting = (type: string): boolean => type === eventsType.WRITING;

export const getMainEventDataForDB = (data: any) => {
  const name = `${moment(data.name).format('MMMM')} ${moment(data.name).year()}`;

  return { name };
};

const getSpeakingDataForDB = (data: ISpeakingData, beginTime: Date, endTime: Date) => {
  const begin = moment(beginTime);
  const end = moment(endTime);
  const speakingData: ISpeakingData[] = [];

  while (end.diff(begin, 'minutes') >= 15) {
    speakingData.push({
      ...data,
      begin_time: formatTime(begin.format()),
      end_time: formatTime(begin.add(15, 'minutes').format())
    });
  }

  return speakingData;
};

export const getEventDataForDB = (eventType: string, data: IEventData) => {
  const eventDataForDB = {
    name: data.name,
    room: data.room,
    event_parent_id: data.eventParent.value,
    event_type_id: data.currentEventType.id,
    teacher_user_id: data.teacherUserId,
    date: formatDate(data.date)
  };

  if (isWriting(eventType)) {
    return [
      {
        ...eventDataForDB,
        event_level_id: data.eventLevel.value,
        begin_time: formatTime(data.beginTime),
        end_time: formatTime(data.endTime)
      }
    ];
  }

  return getSpeakingDataForDB(eventDataForDB, data.beginTime, data.endTime);
};

export const getLessonDataForDB = (data: ILessonFormValues) => {
  const lessonDataForDB = {
    study_group_id: data.groupId,
    room: data.room,
    begin_time: formatTime(data.beginTime),
    end_time: formatTime(data.endTime)
  };

  const selectedWeekDays = data.day || [];

  return selectedWeekDays.reduce((lessonsData: ILessonData[], day: string) => {
    const firstLesson = moment(data.beginDate).day(day);
    const lastLesson = moment(data.endDate).day(day);

    if (firstLesson < moment(data.beginDate)) {
      firstLesson.add(1, 'week');
    }

    while (lastLesson.diff(firstLesson, 'week') >= 0) {
      lessonsData.push({
        ...lessonDataForDB,
        date: formatDate(firstLesson.format())
      });

      firstLesson.add(1, 'week');
    }

    return lessonsData;
  }, []);
};

export const validChildEvent = (values: any) => {
  const errors: any = {};

  const isEventParentDefined = values.eventParent && values.eventParent.value;
  const isEventLevelDefined = values.eventLevel && values.eventLevel.value;

  if (!isEventParentDefined) {
    errors.eventParent = 'Select parent event';
  }

  if (!isEventLevelDefined) {
    errors.eventLevel = 'Select event level';
  }

  if (!values.teacherUserId) {
    errors.teacherUserId = 'Select teacher';
  }

  if (!values.room) {
    errors.room = 'Enter room';
  }

  if (!values.date) {
    errors.date = 'Enter date';
  }

  if (!values.beginTime) {
    errors.beginTime = 'Enter time';
  }

  if (moment(values.beginTime).isAfter(values.endTime)) {
    errors.endTime = 'End time can\'t be less then start time';
  }

  if (values.beginTime && isTimeNotInRange(values.beginTime)) {
    errors.beginTime = INVALID_BEGIN_TIME;
  }

  if (values.endTime && isTimeNotInRange(values.endTime)) {
    errors.endTime = INVALID_END_TIME;
  }

  if (isDifferenceBetweenDatesInvalid(values.beginTime, values.endTime)) {
    errors.endTime = INVALID_RANGE_BETWEEN_DATES;
  }

  return errors;
};

export const validLesson = (values: any) => {
  const errors: any = {};

  if (!values.groupId) {
    errors.groupId = 'Select group';
  }

  if (!values.beginDate) {
    errors.beginDate = 'Enter begin date';
  }

  if (!values.endDate) {
    errors.endDate = 'Enter end date';
  }

  if (!values.day) {
    errors.day = 'Enter day';
  }

  if (!values.room) {
    errors.room = 'Enter room';
  }

  if (!values.beginTime) {
    errors.beginTime = 'Enter begin time';
  }

  if (!values.endTime) {
    errors.endTime = 'Enter end time';
  }

  if (values.beginTime && isTimeNotInRange(values.beginTime)) {
    errors.beginTime = INVALID_BEGIN_TIME;
  }

  if (values.endTime && isTimeNotInRange(values.endTime)) {
    errors.endTime = INVALID_END_TIME;
  }

  if (moment(values.beginTime).isAfter(values.endTime)) {
    errors.endTime = 'End time can\'t be less then start time';
  }

  if (isDifferenceBetweenDatesInvalid(values.beginTime, values.endTime)) {
    errors.endTime = INVALID_RANGE_BETWEEN_DATES;
  }

  return errors;
};

export const getName = (data: IEventData, type: string) => {
  const { eventParent, eventLevel } = data;

  return `${eventParent.label} ${type}${
    eventLevel && eventLevel.label ? ` ${eventLevel.label}` : ''
  }`;
};
