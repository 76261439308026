import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { putTestResult } from 'api/testResult';
import HeaderTestResult from 'components/TestResults/GeneralInfoTestResult/HeaderTestResult';
import EditTestResult from 'components/TestResults/GeneralInfoTestResult/EditTestResult';
import { removeNullFields } from 'services/helper';

import DetailsTestResult from './DetailsTestResult';

import { updateFormaData } from './helper';
import { IGeneralInfoTestResultsProps } from './interface';

const GeneralInfoTestResults: React.FC<IGeneralInfoTestResultsProps> = observer((props) => {
  const { indexTestResult, updateModalDeleteTestResultState, permissions } = props;

  const { testResults } = useContext(RootStore);

  const { listTestResults, updateTestResultList } = testResults;
  const { previousTestResultId, isOpenDetailInfo, isEditResult } = listTestResults[indexTestResult];

  const [formData, setFormData] = useState(null);

  const submitForm = async () => {
    const updatedFormaData = updateFormaData(formData);

    await putTestResult(removeNullFields(formData));

    await updateTestResultList(updatedFormaData, indexTestResult);
  };

  return (
    <div className="accordion-thumb">
      <HeaderTestResult
        isEditResult={isEditResult}
        permissions={permissions}
        updateModalDeleteTestResultState={updateModalDeleteTestResultState}
        indexTestResult={indexTestResult}
        submitForm={submitForm}
      />
      {isOpenDetailInfo && (
        <DetailsTestResult
          permissions={permissions}
          previousTestResultId={previousTestResultId}
          indexTestResult={indexTestResult}
        />
      )}
      {isEditResult && (
        <EditTestResult initialTestResultData={listTestResults[indexTestResult]} setFormData={setFormData} />
      )}
    </div>
  );
});

export default GeneralInfoTestResults;
