import React, { useContext, useEffect } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';
import { IGradientData } from 'hocs/WithGradient/interface';
import WithGradient from 'hocs/WithGradient';

import StudentItem from './StudentItem';
import EmptyState from '../../../common/EmptyState';

const StudentsList: React.FC<IGradientData> = (props: IGradientData) => {
  const { bottom, top, toggleGradient } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { students, testSchedule } = useContext(RootStore);
  const { studentsList, getStudentsList } = students;
  const { setAvailableStudents, notAssignedStudents } = testSchedule;

  useEffect(() => {
    getStudentsList();
  }, []);

  useEffect(() => {
    setAvailableStudents(studentsList);
  }, [studentsList]);

  const studentsListClassName = classNames('studentsList', {
    'has-bottom-gradient': !isReachedBottom,
    'has-top-gradient': !isReachedTop
  });

  return (
    <div className={studentsListClassName}>
      <PerfectScrollbar
        className="perfect-scrollbar"
        onYReachStart={removeTopGradient}
        onYReachEnd={removeBottomGradient}
        onScrollY={toggleGradient}
      >
        {notAssignedStudents.length ? (
          <div className="test-schedule-inner">
            {notAssignedStudents.map(student => (
              <StudentItem
                key={student.userId}
                student={student}
              />
            ))}
          </div>
        ) : (
          <EmptyState
            icon="sentiment_satisfied"
            title="All student are already assigned for a test"
            additionalClassName="card has-spacings"
          />
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(StudentsList);
