import React, { useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';

import { normalizeFilterByTeachers } from 'helpers/select';
import { SELECT_OPTIONS_TYPE } from 'components/common/FormFields/Selects/constant';
import { formFields } from 'components/common/FormComponent/formFields';

import { IChildFormValues, IChildFormProps } from './interface';
import { ISelectValues } from '../../AddEvent/interface';
import { getOptionsForSelect, isWriting } from '../../AddEvent/helpers';
import { eventFormFields } from '../../AddEvent/constants';

const ChildTestForm: React.FC<IChildFormProps & FormikProps<IChildFormValues>> = (
  props: IChildFormProps & FormikProps<IChildFormValues>
) => {
  const {
    testLevels,
    teachers,
    mainEventList,
    setFieldValue,
    handleSubmit,
    childEventType,
    errors,
    setFieldTouched,
    setIsFormValid,
    initialValues
  } = props;

  const [mainEvent, setMainEvent] = useState(initialValues.eventParent || null);
  const [eventLevel, setEventLevel] = useState(initialValues.eventLevel || null);
  const [teacher, setTeacher] = useState(initialValues.teacherUserId || null);
  const [date, setDate] = useState(initialValues.date || null);
  const [beginTime, setBeginTime] = useState(initialValues.beginTime || null);
  const [endTime, setEndTime] = useState(initialValues.endTime || null);

  const minDate = new Date(mainEvent && mainEvent.label);

  useEffect(() => {
    const isFormValid = Boolean(mainEvent && teacher && date && beginTime && endTime);
    const isWritingFormValid = isFormValid && !!eventLevel && !Object.keys(errors).length;
    const isSpeakingFormValid = isFormValid && Object.keys(errors).length <= 1;

    if (isWriting(childEventType)) {
      setIsFormValid(isWritingFormValid);
    } else {
      setIsFormValid(isSpeakingFormValid);
    }
  }, [mainEvent, eventLevel, teacher, date, beginTime, endTime, errors]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row modal-row form-group">
        <div className={`col-xs-12 ${isWriting(childEventType) ? 'col-sm-6' : ''} modal-col`}>
          <Field
            name={eventFormFields.EVENT_PARENT}
            placeholder="Test"
            component={formFields.FormSelect}
            options={getOptionsForSelect(mainEventList)}
            value={mainEvent}
            onBlur={() => setFieldTouched(eventFormFields.EVENT_PARENT)}
            onChange={(data: ISelectValues) => {
              setMainEvent(data);
              setFieldValue(eventFormFields.EVENT_PARENT, data);
            }}
          />
        </div>
        {isWriting(childEventType) && (
          <div className="col-xs-12 col-sm-6 modal-col">
            <Field
              name={eventFormFields.EVENT_LEVEL}
              placeholder="Level"
              options={getOptionsForSelect(testLevels)}
              component={formFields.FormSelect}
              value={eventLevel}
              onBlur={() => setFieldTouched(eventFormFields.EVENT_LEVEL)}
              onChange={(data: ISelectValues) => {
                setEventLevel(data);
                setFieldValue(eventFormFields.EVENT_LEVEL, data);
              }}
            />
          </div>
        )}
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={eventFormFields.TEACHER}
            placeholder="Teacher"
            options={normalizeFilterByTeachers(teachers)}
            component={formFields.FormSelect}
            value={teacher}
            onBlur={() => setFieldTouched(eventFormFields.TEACHER)}
            onChange={(data: ISelectValues) => {
              setTeacher(data);
              setFieldValue(eventFormFields.TEACHER, data.value);
            }}
            optionType={SELECT_OPTIONS_TYPE.WITH_PHOTO}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-6 modal-col">
          {/*  TODO delete year from toolbar */}
          <Field
            name={eventFormFields.DATE}
            placeholder="Date"
            component={formFields.FormDatePicker}
            views={['date']}
            minDate={minDate}
            value={date}
            onBlur={() => setFieldTouched(eventFormFields.DATE)}
            onChange={(data: Date) => {
              setDate(data);
              setFieldValue(eventFormFields.DATE, data);
            }}
            isDisabled={!mainEvent}
          />
        </div>
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field name="room" placeholder="Room" component={formFields.FormInput} />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-6 modal-col">
          <Field
            name={eventFormFields.BEGIN_TIME}
            placeholder="Start"
            component={formFields.FormTimePicker}
            value={beginTime}
            onBlur={() => setFieldTouched(eventFormFields.BEGIN_TIME)}
            onChange={(data: Date) => {
              setBeginTime(data);
              setFieldValue(eventFormFields.BEGIN_TIME, data);
            }}
          />
        </div>
        <div className="col-xs-6 modal-col">
          <Field
            name={eventFormFields.END_TIME}
            placeholder="End"
            component={formFields.FormTimePicker}
            value={endTime}
            onBlur={() => setFieldTouched(eventFormFields.END_TIME)}
            onChange={(data: Date) => {
              setEndTime(data);
              setFieldValue(eventFormFields.END_TIME, data);
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default ChildTestForm;
