import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AuthGuard from 'hocs/AuthGuard';
import AddToGroup from 'components/AddToGroup';
import Calendar from 'components/Calendar';
import AttendanceLog from 'components/AttendanceLog';
import routes from 'constants/routes';
import TestResults from 'components/TestResults';
import TestSchedule from 'components/TestSchedule/index';
import Groups from 'components/Groups';
import Teachers from 'components/Teachers';
import Aside from 'components/Aside/index';
import Students from 'components/Students';
import Admins from 'components/Admins';
import Departments from 'components/Departments';
import Profile from 'components/Profile';
import RootStore from 'store';
import StudentProfileModal from 'components/Profile/StudentProfileModal';

import Loader from './Loader';

const Main: React.FC = () => {
  const { authenticationStore, serviceStore: { isMobile } } = useContext(RootStore);
  const { loadUserRoleFromLocalStorage } = authenticationStore;

  useEffect(() => {
    loadUserRoleFromLocalStorage();
  }, [loadUserRoleFromLocalStorage]);

  document.body.className = isMobile ? 'mobile-view' : '';

  return (
    <div className="page-container">
      <Loader />
      <Aside />
      <StudentProfileModal />
      <div className="content">
        <Switch>
          <Redirect exact from="/" to={routes.CALENDAR_ROUTE} />
          <Route exact path={routes.CALENDAR_ROUTE} component={Calendar} />
          <Route exact path={routes.ATTENDANCE_LOG_ROUTE} component={AttendanceLog} />
          <Route exact path={routes.TEST_RESULTS_ROUTE} component={TestResults} />
          <Route exact path={routes.TEST_SCHEDULE_ROUTE} component={TestSchedule} />
          <Route exact path={routes.ADD_TO_GROUP_ROUTE} component={AddToGroup} />
          <Route exact path={routes.GROUPS_ROUTE} component={Groups} />
          <Route exact path={routes.TEACHERS_ROUTE} component={Teachers} />
          <Route exact path={routes.STUDENTS_ROUTE} component={Students} />
          <Route exact path={routes.ADMINS_ROUTE} component={Admins} />
          <Route exact path={routes.DEPARTMENTS_ROUTE} component={Departments} />
          <Route exact path={routes.PROFILE} component={Profile} />
          <Redirect from="*" to={routes.PAGE_NOT_FOUND} />
        </Switch>
      </div>
    </div>
  );
};

export default AuthGuard(Main);
