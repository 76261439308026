import request from './request';
import api from './index';

export const getEvents = (params: object) => request({
  url: api.GET_TEST_EVENTS,
  method: 'GET',
  params
});

export const addStudentToTest = (data: object) => request({
  url: api.STUDENT_TO_TEST,
  method: 'POST',
  data
});

export const deleteStudentFromTest = (data: object) => request({
  url: api.STUDENT_TO_TEST,
  method: 'DELETE',
  data
});
