import React, { useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';

import { initialValueNames } from 'components/Modals/AddTestResult/FormAddTestResult/constants';

import FormSelect from 'components/common/FormComponent/formFields/FormSelect';
import ButtonsGroup from 'components/common/BtnsGroup';
import { SELECT_OPTIONS_TYPE } from 'components/common/FormFields/Selects/constant';

import AddRecommendedLevelTestResult from './AddRecommendedLevel';
import AddSpeakingTestResult from './AddSpeakingTestResult';
import AddWritingTestResult from './AddWritingTestResult';
import AddCommitsTestResult from './AddCommitsTestResult';

import { buttonsGroupData, testLevel } from './constants';
import { IFormComponentAddTestResultProps, IChildFormValues } from './interface';

const FormComponentAddTestResult: React.FC<
  IFormComponentAddTestResultProps & FormikProps<IChildFormValues>
> = (props: IFormComponentAddTestResultProps & FormikProps<IChildFormValues>) => {
  const {
    testNamesList,
    changeTest,
    selectedTest,
    studentsList,
    changeStudent,
    selectedStudent,
    testLevelsList,
    handleSubmit,
    setFieldTouched,
    setFieldValue
  } = props;

  const [levelButtonGroup, setLevelButtonGroup] = useState(testLevel.LOW.name);

  const isLowTest = testLevel[levelButtonGroup].value === testLevel.LOW.value;

  useEffect(() => {
    setFieldValue(initialValueNames.EVENT_LEVEL_ID, testLevel[levelButtonGroup].value);
  }, [levelButtonGroup, setFieldValue]);

  return (
    <form onSubmit={handleSubmit} className="container modal-container">
      <div className="form-group">
        <ButtonsGroup
          data={buttonsGroupData}
          updateData={setLevelButtonGroup}
          selectedValue={levelButtonGroup}
        />
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field
            name={initialValueNames.EVENT_PARENT_ID}
            component={FormSelect}
            options={testNamesList}
            value={selectedTest}
            onChange={(data: any) => {
              changeTest(data);
              setFieldValue(initialValueNames.EVENT_PARENT_ID, data.value);
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field
            name={initialValueNames.USER_ID}
            component={FormSelect}
            options={studentsList}
            value={selectedStudent}
            onChange={(data: any) => {
              changeStudent(data);
              setFieldValue(initialValueNames.USER_ID, data.value);
            }}
            optionType={SELECT_OPTIONS_TYPE.WITH_PHOTO}
          />
        </div>
      </div>
      <AddRecommendedLevelTestResult
        testLevelsList={testLevelsList}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <AddSpeakingTestResult setFieldValue={setFieldValue} />
      <AddWritingTestResult setFieldValue={setFieldValue} isLowTest={isLowTest} />
      <AddCommitsTestResult />
    </form>
  );
};

export default FormComponentAddTestResult;
