import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import RootStore from 'store';

import { ADMIN, SUPER_ADMIN } from 'constants/roles';

import { IHeaderActionsProps } from './interface';

const HeaderActions: React.FC<IHeaderActionsProps> = observer((props) => {
  const {
    isEditResult, indexTestResult, submitForm, permissions, updateModalDeleteTestResultState
  } = props;

  const { testResults, modalsAddTestResult, serviceStore: { isMobile } } = useContext(RootStore);

  const {
    updateDeleteTestResultData, listTestResults, updateDetailsInfoStatus, updateEditStatus
  } = testResults;

  const { isOpenDetailInfo } = listTestResults[indexTestResult];

  const { getSelectsData } = modalsAddTestResult;

  const openDetails = () => {
    updateDetailsInfoStatus(indexTestResult);
  };

  const editFormData = () => {
    updateEditStatus(indexTestResult);
  };

  const saveResult = async () => {
    await submitForm();
    updateDetailsInfoStatus(indexTestResult);
  };

  const deleteResult = async () => {
    await updateDeleteTestResultData(listTestResults[indexTestResult]);

    await getSelectsData();

    updateModalDeleteTestResultState(true);
  };

  const editBtns = (
    <>
      <button className="accordion-button-edit" type="button" onClick={deleteResult}>
        <i className="material-icons">delete</i>
      </button>
      <button className="accordion-button-edit" type="button" onClick={editFormData}>
        <i className="material-icons">edit</i>
      </button>
    </>
  );

  const saveBtn = (
    <button className="btn-border green" type="submit" onClick={saveResult}>
      Save
    </button>
  );

  const EditComponent = isEditResult ? saveBtn : editBtns;

  const panelNavigationClassName = cn('panel-navigation', {
    'is-hidden': !isMobile
  });

  return (
    <div className={panelNavigationClassName}>
      {permissions([ADMIN, SUPER_ADMIN]) && EditComponent}
      <button className="btn-down" type="button" onClick={openDetails}>
        {isOpenDetailInfo ? (
          <i className="material-icons accordion-arrow">keyboard_arrow_up</i>
        ) : (
          <i className="material-icons accordion-arrow">keyboard_arrow_down</i>
        )}
      </button>
    </div>
  );
});

export default HeaderActions;
