import moment from 'moment';

import { format } from 'constants/date';
import { eventsType } from 'components/TestSchedule/constants';

import { MonthEventsType } from 'types/calendarTypes';

import * as actionsWithDates from 'helpers/actionsWithDates';

import { THIS_MONTH_VALUE } from './constants';
import { IAccumulatorType } from './interface';

export const getSelectedDay = (selectedMonth: string, events: MonthEventsType[]) => {
  const numberMonthEvents = events.length;
  const thisMonth = actionsWithDates.getMonth();
  const todayDate = actionsWithDates.getTodayDate();

  if (numberMonthEvents) {
    const monthValue = moment(thisMonth).diff(selectedMonth, 'month');

    if (monthValue < THIS_MONTH_VALUE) {
      return events[0].date;
    }

    if (monthValue > THIS_MONTH_VALUE) {
      return events[numberMonthEvents - 1].date;
    }

    if (monthValue === THIS_MONTH_VALUE) {
      const dateLastEvent = events[numberMonthEvents - 1].date;
      const dayValue = moment(todayDate).diff(dateLastEvent, 'day');
      const todayEvent = events.find(({ date }) => date === todayDate);

      if (todayEvent) {
        return todayEvent.date;
      }

      if (monthValue > dayValue) {
        const dateNextEvent = events.find(({ date }) => {
          const dateEvent = moment(todayDate).diff(date, 'day');

          if (monthValue > dateEvent) {
            return date;
          }

          return null;
        });

        return dateNextEvent.date;
      }

      if (monthValue < dayValue) {
        return events[numberMonthEvents - 1].date;
      }
    }
    return null;
  }

  return null;
};

export const getMonthData = (date: string, numberOfDays: number) => {
  const monthData = [{ fullDate: date }];

  for (let i = 1; i < numberOfDays; i += 1) {
    monthData.push({
      fullDate: moment(date)
        .add(i, 'days')
        .format(format.YEAR_MONTH_DAY)
    });
  }

  return monthData;
};

export const updateMonthEvents = (monthEvents: MonthEventsType[]) => {
  const updatedMonthEvents = monthEvents.reduce(
    (accumulator: IAccumulatorType, dayEvent: MonthEventsType) => {
      const hasProperty = Object.prototype.hasOwnProperty.call(accumulator, dayEvent.date);

      if (hasProperty) {
        return {
          ...accumulator,
          [dayEvent.date]: [...accumulator[dayEvent.date], dayEvent]
        };
      }

      return {
        ...accumulator,
        [dayEvent.date]: [dayEvent]
      };
    },
    {}
  );

  return updatedMonthEvents;
};

export const addEventToMonth = (
  monthData: any[],
  monthEvents: { [key: string]: MonthEventsType[] }
) => monthData.reduce((
  accumulator: any[], dayData: any, index: number
) => {
  accumulator[index] = { ...accumulator[index], eventsTypes: [], isActive: false };

  const { fullDate } = dayData;

  if (monthEvents[fullDate]) {
    const isHasTypeSpiking = monthEvents[fullDate].find(
      (eventType) => eventType.type === eventsType.SPEAKING
    );
    const isHasTypeWriting = monthEvents[fullDate].find(
      (eventType) => eventType.type === eventsType.WRITING
    );
    const isHasTypeLesson = monthEvents[fullDate].find(
      (eventType) => eventType.type === eventsType.LESSON
    );

    if (isHasTypeWriting) {
      accumulator[index].eventsTypes.push(eventsType.WRITING);
    }

    if (isHasTypeSpiking) {
      accumulator[index].eventsTypes.push(eventsType.SPEAKING);
    }

    if (isHasTypeLesson) {
      accumulator[index].eventsTypes.push(eventsType.LESSON);
    }
  }

  return accumulator;
},
monthData);

export const getMonthDatesWithEvents = (date: string, monthEvents: MonthEventsType[]) => {
  const numberOfDays = actionsWithDates.getDaysInMonth(date);

  const updatedMonthData = getMonthData(date, numberOfDays);
  const updatedMonthEvents = updateMonthEvents(monthEvents);

  return addEventToMonth(updatedMonthData, updatedMonthEvents);
};
