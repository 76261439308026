export default {
  LOGIN: '/authenticate',
  EVENTS: '/events',
  RESULTS_LIST: '/results_list',
  RESULTS_DETAILS: '/result/details',
  GET_TEST_NAMES: '/components/event_parents',
  CREATE_TEST_NAME: '/components/event_parent',
  LOGOUT: '/logout_refresh_token',
  REFRESH_TOKEN: '/refresh_token',
  STUDY_GROUPS: '/study_groups',
  DEPARTMENT_GROUPS: '/department_groups',
  DEPARTMENT_GROUP: '/department_group',
  DEPARTMENTS: '/departments',
  STUDENTS_BY_TEST_ID: '/event/test/students',
  ENGLISH_LEVELS: '/components/english_levels',
  TEST_LEVELS: '/components/event_levels',
  TEST_RESULT: '/result',
  TEACHERS_LIST: '/teachers',
  TEACHER: '/teacher',
  GET_TEACHER_TYPES: '/components/lesson_types',
  SEARCH_USERS: '/users',
  ATTENDANCE_LOG: '/events/students',
  UPDATE_ATTENDANCE_LOG: '/attendance/event/student',
  EVENT_TEST_TYPES: '/components/event_types',
  ATTENDANCE_LOG_STUDENTS_SEARCH: '/search/students',
  STUDENTS: '/students',
  STUDENT_STATUS: '/components/student_status',
  ADD_STUDENT_STATUS: '/student',
  AVAILABLE_STUDY_GROUPS: '/available_study_groups',
  ADMINS: '/admins',
  ADMIN: '/admin',
  DELETE_EVENT: '/event',
  STUDY_GROUP: '/study_group',
  STUDENT_GROUP: '/study_group/student',
  STUDY_GROUP_STUDENT: '/study_group/student',
  LESSON: '/event/lesson',
  EVENT_TEST: '/event/test',
  STUDENT_TO_TEST: '/event/test/student',
  GET_TEST_EVENTS: '/tests/list',
  PROFILE: '/profile',
  PASSWORD_RECOVERY: '/recovery-password',
  CHANGE_PASSWORD: '/change-password',
  USER_DEPARTMENT_GROUP: '/user/department_group',
  USER_DEPARTMENT: '/user/department',
  CANCEL_EVENT: '/event/cancel',
  DELETE_PHOTO: '/profile/photo'
};
