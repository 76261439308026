import React, { useContext, useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { initialAddAdminKey } from 'components/Modals/Admin/AddNew/Form/constants';
import { PLACEHOLDERS } from 'constants/forms';

import { IInitialData, IAddNewAdminProps } from 'components/Modals/Admin/AddNew/Form/interface';

import { formFields } from 'components/common/FormComponent/formFields';

const FormComponentAddTestResult: React.FC<IAddNewAdminProps & FormikProps<IInitialData>> = observer(
  (props: IAddNewAdminProps & FormikProps<IInitialData>) => {
    const { handleSubmit, isValid, setButtonState } = props;

    const { admins } = useContext(RootStore);
    const { isOpenModalAddNewAdmin, resetAdminData } = admins;

    useEffect(() => {
      setButtonState(!isValid);
    }, [isValid]);

    useEffect(() => {
      resetAdminData();
    }, [isOpenModalAddNewAdmin]);

    return (
      <form onSubmit={handleSubmit} className="container modal-container">
        <Field name={initialAddAdminKey.NAME} placeholder={PLACEHOLDERS.name} component={formFields.FormInput} />
        <Field name={initialAddAdminKey.SURNAME} placeholder={PLACEHOLDERS.surname} component={formFields.FormInput} />
        <Field
          name={initialAddAdminKey.USERNAME}
          placeholder={PLACEHOLDERS.username}
          component={formFields.FormInput}
        />
        <Field name={initialAddAdminKey.EMAIL} placeholder={PLACEHOLDERS.email} component={formFields.FormInput} />
        <Field
          name={initialAddAdminKey.PASSWORD}
          placeholder={PLACEHOLDERS.password}
          component={formFields.FormInput}
        />
      </form>
    );
  }
);

export default FormComponentAddTestResult;
