import React, { useEffect, useState, useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import RootStore from 'store';

import { getMonthDatesWithEvents } from 'components/TestSchedule/components/TestCalendar/helpers';
import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import Header from 'components/TestSchedule/components/TestCalendar/Header';
import CalendarLine from 'components/TestSchedule/components/TestCalendar/CalendarLine';

import { EVENTS_TYPE } from './constants';

const MobileCalendar: React.FC<IGradientData> = (props: IGradientData) => {
  const { left, right, toggleGradient } = props;
  const { isReachedLeft, removeLeftGradient } = left;
  const { isReachedRight, removeRightGradient } = right;

  const { calendarStore } = useContext(RootStore);

  const {
    selectedDay,
    selectedMonth,
    setSelectedMonth,
    setSelectedDay,
    monthEvents,
    getDayEvents
  } = calendarStore;

  const [daysOfMonth, changeDaysOfMonth] = useState([]);

  const onDaySelect = (selectedDate: string) => {
    setSelectedDay(selectedDate);
    getDayEvents(selectedDate);
  };

  const attendanceContentClassName = classNames('row header-calendar-area has-horizontal-gradient', {
    'has-left-gradient': !isReachedLeft,
    'has-right-gradient': !isReachedRight
  });

  useEffect(() => {
    const monthDatesWithEvents = getMonthDatesWithEvents(selectedMonth, monthEvents);

    changeDaysOfMonth(monthDatesWithEvents);
  }, [selectedMonth, monthEvents]);

  return (
    <div className="header-calendar">
      <Header date={selectedMonth} changeDate={setSelectedMonth} eventTypes={EVENTS_TYPE} />
      <div className={attendanceContentClassName}>
        <PerfectScrollbar
          className="perfect-scrollbar timeline-scrollable"
          onXReachStart={removeLeftGradient}
          onXReachEnd={removeRightGradient}
          onScrollX={toggleGradient}
        >
          <CalendarLine
            isReachedLeft={isReachedLeft}
            isReachedRight={isReachedRight}
            monthData={daysOfMonth}
            onDaySelect={onDaySelect}
            selectedDate={selectedDay}
          />
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(MobileCalendar);
