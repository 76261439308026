import React from 'react';
import { observer } from 'mobx-react';

import groupMember from 'assets/img/face-01.png';

import { StudentsListType } from 'types/calendarTypes';
import { getStudentInitials } from './helpers';

const StudentImg = observer((props: StudentsListType) => {
  const { studentUserName = '', studentUserPhoto, studentUserSurname = '' } = props;

  const studentInitials = getStudentInitials(studentUserSurname, studentUserName);

  if (studentUserPhoto) {
    return (
      <div className="event-card-members-icon">
        <img className="event-card-members-icon-img" src={studentUserPhoto} alt="group-member" />
      </div>
    );
  }

  if (studentUserName && studentUserSurname) {
    return <div className="event-card-members-icon student-initials">{studentInitials}</div>;
  }

  return (
    <div className="event-card-members-icon">
      <img className="event-card-members-icon-img" src={groupMember} alt="group-member" />
    </div>
  );
});

export default StudentImg;
