import { DEFAULT_INITIAL_VALUES } from './constant';
import { IModalDepartmentGroupData } from './interface';

export const getEditInitialValues = (modalData: IModalDepartmentGroupData) => {
  const { departmentGroupId, departmentGroupName, departmentId, leadUserId } = modalData;

  return {
    ...DEFAULT_INITIAL_VALUES,
    department_group_id: departmentGroupId,
    department_group_name: departmentGroupName,
    department_id: departmentId,
    lead_user_id: leadUserId
  };
};
