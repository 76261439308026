import React from 'react';
import { Formik } from 'formik';

import { IProps } from './interfaces';

export default (props: IProps) => {
  const {
    Component, onSubmit, initialValues, validate, otherProps, bindSubmitForm
  } = props;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {(propsFormik) => {
        const { values } = propsFormik;

        if (bindSubmitForm) {
          bindSubmitForm(values);
        }

        return <Component {...propsFormik} {...otherProps} initialValues={initialValues} />;
      }}
    </Formik>
  );
};
