import React from 'react';

import { CheckboxDateType } from './interface';

const Checkbox = (props: CheckboxDateType) => {
  const {
    label, checkboxState, isReverse, updateCheckboxState
  } = props;

  const reverseClass = isReverse ? 'is-reverse' : '';

  return (
    <label className={`checkbox-label ${reverseClass}`}>
      <div className="checkbox-label-text">{label}</div>
      <div className="checkbox-check-icon">
        <input
          type="checkbox"
          className="checkbox-input visually-hidden"
          onChange={() => updateCheckboxState(!checkboxState)}
          checked={checkboxState}
        />
        <div className="checkbox-mark" />
      </div>
    </label>
  );
};

export default Checkbox;
