/* eslint camelcase: 0 */
export const normalizeFilterData = (requestData: any[]) => requestData.map((value: any) => {
  const { id, name } = value;

  return {
    value: id,
    label: name
  };
});

export const normalizeFilterByTeachers = (requestData: any[]) => requestData.map((teacherData: any) => {
  const { name, surname, user_id, photo } = teacherData;

  return { label: `${name} ${surname}`, value: user_id, photo };
});

export const normalizeDepartment = (requestData: any[]) => requestData.map((departmentGroup: any) => {
  const { department_id, department_name } = departmentGroup;

  return { label: department_name, value: department_id };
});

export const normalizeDepartmentGroups = (requestData: any[]) => requestData.map((departmentGroup: any) => {
  const { department_group_id, department_group_name } = departmentGroup;

  return { label: department_group_name, value: department_group_id };
});
