import React from 'react';

import User from 'components/common/User';

import { IUserCardProps } from './interface';

const UserData: React.FC<IUserCardProps> = (props: IUserCardProps) => {
  const {
    id, fullName, groupName, photo, englishLevel, deleteUser
  } = props;

  const deleteUserFromGroup = () => {
    deleteUser({ userId: id, fullName, studyGroupName: groupName });
  };

  return (
    <div className="panel-row">
      <div className="card-user">
        <div className="panel-content">
          <div className="panel-item w-40">
            <User hasRole={false} userIcon={photo} userName={fullName} />
          </div>
          {englishLevel && <div className="panel-item">{englishLevel}</div>}
          {deleteUser && (
            <div className="panel-item panel-navigation">
              <button
                className="panel-action dangerous"
                type="button"
                onClick={deleteUserFromGroup}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserData;
