import React from 'react';
import { Field, FormikProps } from 'formik';
import classNames from 'classnames';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import Button from 'components/common/FormFields/Button';
import { IFormValues, IOtherProps } from '../interfaces';

const RestorePasswordForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { handleSubmit, errors } = props;
  const shouldDisableSubmit = Boolean(Object.keys(errors).length);
  const btnClassName = classNames('btn-default btn-wide', { 'btn-disabled': shouldDisableSubmit });

  return (
    <form onSubmit={handleSubmit} className="auth-form sign-in">
      <h1 className="auth-title">Restore Password</h1>
      <Field
        name="password"
        inputType="password"
        placeholder="Password"
        component={FormInput}
        autoComplete="new-password"
      />
      <Field
        name="confirmPassword"
        inputType="password"
        placeholder="Confirm Password"
        component={FormInput}
        autoComplete="new-password"
      />
      <Button
        className={btnClassName}
        disabled={shouldDisableSubmit}
      >
        Save
      </Button>
    </form>
  );
};

export default RestorePasswordForm;
