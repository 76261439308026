import { EXPAND_MORE } from 'components/common/ColumnValueInTable/constants';
import { ISortingData } from 'components/common/ColumnValueInTable/interface';

export const getTableValues = (isMobile: boolean): ISortingData[] => [
  {
    title: 'Name',
    value: 'surname',
    sortingArrow: EXPAND_MORE,
    itemWidth: isMobile ? 'w-50' : 'w-15',
    isActive: true
  },
  {
    title: 'Email',
    itemWidth: 'w-15 m-hidden'
  },
  {
    title: 'Phone',
    itemWidth: 'w-15 m-hidden'
  },
  {
    title: 'Skype',
    itemWidth: 'w-15 m-hidden'
  },
  {
    title: 'Type',
    itemWidth: isMobile ? 'w-50' : 'w-15'
  }
];
