import React, { useState, useContext } from 'react';
import { toJS } from 'mobx';

import RootStore from 'store';

import Container from '../../ModalContainer';
import ChildTest from '../components/ChildTest';
import Lesson from '../components/Lesson';
import { eventsType } from '../AddEvent/constants';
import { IEditEvent } from './interface';
import { validLesson } from './helpers';

const EditEvent: React.FC<IEditEvent> = ({ isOpen, closeModal }: IEditEvent) => {
  const {
    editEventStore: { editEvent },
    calendarStore: { detailEvent }
  } = useContext(RootStore);

  const event = toJS(detailEvent);
  const { id, type } = event;

  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const setData = (data: any) => {
    setFormData(data);
  };

  const editEventRequest = () => {
    editEvent({ id, ...formData }, type, closeModal);
  };

  const header = (
    <>
      <div className="modal-title">{`Edit ${type}`}</div>
      <button className="modal-close" type="button" onClick={closeModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const renderEvent = () => {
    switch (type) {
      case eventsType.LESSON:
        return (
          <Lesson
            setFormData={setData}
            setIsFormValid={setIsFormValid}
            initialData={event}
            editMode
            validate={validLesson}
          />
        );
      case eventsType.WRITING:
        return (
          <ChildTest
            setFormData={setData}
            setIsFormValid={setIsFormValid}
            childEventType={eventsType.WRITING}
            initialData={event}
          />
        );
      case eventsType.SPEAKING:
        return (
          <ChildTest
            setFormData={setData}
            setIsFormValid={setIsFormValid}
            childEventType={eventsType.SPEAKING}
            initialData={event}
          />
        );
      default:
        return null;
    }
  };

  const body = (
    <>
      <div className="container modal-container">
        {renderEvent()}
      </div>
    </>
  );

  const footer = (
    <>
      <button
        className={`${isFormValid ? 'btn-default' : 'btn-disabled'}`}
        type="button"
        onClick={editEventRequest}
        disabled={!isFormValid}
      >
        Save
      </button>
    </>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      additionalClassName="add-event"
      toggleModal={closeModal}
    />
  );
};

export default EditEvent;
