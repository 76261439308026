import React, { useState } from 'react';
import classnames from 'classnames';

import FormComponent from 'components/common/FormComponent';
import EditStudyGroupForm from './EditStudyGroupForm';
import { IEditGroupModalProps } from './interfaces';
import { validation } from './utils';
import Container from '../../../Modals/ModalContainer';

const EditGroupModal: React.FC<IEditGroupModalProps> = (props: IEditGroupModalProps) => {
  const {
    isOpen,
    studyGroup,
    toggleModal,
    setEditingStudyGroupTitle,
    setEditingStudyGroupTeacher,
    updateStudyGroup,
    getTeachersList,
    teachersList
  } = props;
  const { name } = studyGroup;

  const onEditStudyGroup = async () => {
    await updateStudyGroup();
    toggleModal();
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const header = (
    <>
      <div className="modal-title">{`Edit ${name} Study Group`}</div>
      <button className="modal-close" type="button" onClick={toggleModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = (
    <div className="container modal-container">
      <div className="form-group">
        <FormComponent
          Component={EditStudyGroupForm}
          otherProps={{
            studyGroup,
            teachersList,
            getTeachersList,
            setEditingStudyGroupTitle,
            setEditingStudyGroupTeacher,
            setIsFormValid
          }}
          initialValues={{
            study_group_title: name
          }}
          validate={validation}
        />
      </div>
    </div>
  );

  const btnClassName = classnames('btn-default', { 'btn-disabled': !isFormValid });

  const footer = (
    <div className="inputs-group">
      <div className="inputs-group-item">
        <button className={btnClassName} type="button" onClick={onEditStudyGroup} disabled={!isFormValid}>
          Edit Group
        </button>
      </div>
    </div>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      toggleModal={toggleModal}
    />
  );
};

export default EditGroupModal;
