import React from 'react';

import * as INTERFACES from './interfaces';
import { ERROR_PAGES } from './constants';
import './styles.scss';

const ErrorPage: React.FC<INTERFACES.IErrorPageProps> = (props: INTERFACES.IErrorPageProps) => {
  const { code, actionBtn } = props;
  const { status, title, subtitle, icon } = ERROR_PAGES[code];

  return (
    <div className="errorpage">
      <div className="errorpage-container">
        {status && <span className="errorpage-status">{status}</span>}
        <h1 className="errorpage-title">{title}</h1>
        {subtitle && <p className="errorpage-subtitle">{subtitle}</p>}
        {actionBtn}
        <div className={`errorpage-img ${icon}`} />
      </div>
    </div>
  );
};

export default ErrorPage;
