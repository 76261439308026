import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Container from 'components/Modals/ModalContainer';
import ButtonsGroup from 'components/common/BtnsGroup';
import FormComponent from 'components/common/FormComponent';
import AddGroupForm from './AddGroupForm';
import { IAddGroupModalProps } from '../../interfaces';
import {
  STUDENT_USERS_IDS,
  STUDY_GROUP_TITLE,
  ENGLISH_LEVEL_ID,
  TEACHER_USER_ID
} from './constants';
import { validate } from './utils';
import { NATIVE_SPEAKER_TEACHER_ID } from '../../constants';

const AddGroupModal: React.FC<IAddGroupModalProps> = (props: IAddGroupModalProps) => {
  const {
    isOpen,
    closeModal,
    getTeacherTypes,
    teacherTypes,
    selectedTeacherType,
    selectTeacherType,
    teachersList,
    getTeachersList,
    getStudents,
    selectedTeacher,
    setSelectedTeacher,
    newGroupTitle,
    setNewGroupTitle,
    getEnglishLevels,
    levelsList,
    setLevel,
    selectedLevel,
    onStudentsInputChange,
    selectedStudents,
    studentsList,
    studentsSearchString,
    setStudentsSearchString,
    setSelectedStudent,
    resetStudentsList,
    createStudyGroup
  } = props;

  const { id: selectedTeacherTypeId } = selectedTeacherType;
  const isNativeSpeaker: boolean = selectedTeacherTypeId && selectedTeacherTypeId === NATIVE_SPEAKER_TEACHER_ID;

  useEffect(() => {
    (async () => {
      await getTeacherTypes();
    })();
  }, []);

  const addGroupInitialValues: any = {
    [STUDENT_USERS_IDS]: [],
    [STUDY_GROUP_TITLE]: '',
    [TEACHER_USER_ID]: null
  };

  const onCloseModal = () => {
    setNewGroupTitle(addGroupInitialValues[STUDY_GROUP_TITLE]);
    setSelectedTeacher(addGroupInitialValues[TEACHER_USER_ID]);
    setSelectedStudent(addGroupInitialValues[STUDENT_USERS_IDS]);

    closeModal();
  };

  const onCreateStudyGroup = async () => {
    await createStudyGroup();
    onCloseModal();
  };

  const header = (
    <>
      <div className="modal-title">Add New Group</div>
      <button className="modal-close" type="button" onClick={onCloseModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  if (!isNativeSpeaker) {
    addGroupInitialValues[ENGLISH_LEVEL_ID] = null;
  }

  const [isFormValid, setIsFormValid] = useState(false);

  const body = (
    <div className="container modal-container">
      <div className="form-group">
        <ButtonsGroup
          data={teacherTypes}
          updateData={selectTeacherType}
          selectedValue={selectedTeacherType.name}
        />
      </div>
      <FormComponent
        Component={AddGroupForm}
        otherProps={{
          teachersList,
          getTeachersList,
          getStudents,
          selectedTeacher,
          setSelectedTeacher,
          setNewGroupTitle,
          newGroupTitle,
          getEnglishLevels,
          levelsList,
          setLevel,
          selectedLevel,
          selectedTeacherType,
          onStudentsInputChange,
          selectedStudents,
          studentsList,
          studentsSearchString,
          setStudentsSearchString,
          setSelectedStudent,
          resetStudentsList,
          isNativeSpeaker,
          setIsFormValid
        }}
        initialValues={addGroupInitialValues}
        validate={(values: any) => validate(values)}
      />
    </div>
  );

  const btnClassName = classnames('btn-default', { 'btn-disabled': !isFormValid });

  const footer = (
    <div className="inputs-group">
      <div className="inputs-group-item">
        <button className={btnClassName} type="button" onClick={onCreateStudyGroup} disabled={!isFormValid}>
          Add Group
        </button>
      </div>
    </div>
  );

  return <Container header={header} body={body} footer={footer} isOpen={isOpen} toggleModal={onCloseModal} />;
};

export default AddGroupModal;
