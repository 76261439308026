import React from 'react';
import { observer } from 'mobx-react';

import WithPermissions from 'hocs/WithPermissions';
import { ADMIN } from 'constants/roles';
import * as removeType from 'components/Students/StudentsTable/StudentInfo/Actions/constants';
import ConfirmationModal from 'components/Modals//ConfirmationModal';
import { successNotification } from 'components/common/Notifications/helper';

import { IDeleteStudentModalProps } from './interface';

const DeleteStudentFromGroup = observer((props: IDeleteStudentModalProps) => {
  const {
    isOpen, closeModal, studentData, deleteStudentFromGroup, permissions
  } = props;
  const { userId, fullName, studyGroupName } = studentData;

  const changeStudentStatus = async () => {
    const removeValue = permissions([ADMIN])
      ? removeType.CHANGE_GROUP
      : removeType.REMOVE_FROM_GROUP;

    const params = { student_user_id: userId, remove: removeValue };

    await deleteStudentFromGroup(params);

    successNotification('Student was successfully removed');
    closeModal();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Delete student"
      text={`Are you sure you want to remove student ${fullName} from the ${studyGroupName} group?`}
      toggleModal={closeModal}
      onSubmit={changeStudentStatus}
      okText="Delete"
      cancelText="Back"
    />
  );
});

export default WithPermissions(DeleteStudentFromGroup);
