import React from 'react';

import { IHeaderValueProps } from './interface';

const HeaderValue = (props: IHeaderValueProps): JSX.Element => {
  const { value, displayType, itemWidth } = props;

  return <div className={`panel-item ${displayType} ${itemWidth}`}>{value}</div>;
};

export default HeaderValue;
