import { observable, action } from 'mobx';

import { getStudentsByTestId, getTestNames, getTestLevels } from 'api/filtersData';

import { normalizeListEnglishLevels, normalizeStudentsList } from 'components/Modals/AddTestResult/utils';
import { normalizeFilterData } from 'helpers/select';

import { ISelectItem } from 'types/index';

export default class AddTestResultStore {
  @observable testNamesList: ISelectItem[] | [] = [];

  @observable studentsList: ISelectItem[] | [] = [];

  @observable testLevelsList: ISelectItem[] | [] = [];

  @action getSelectsData = async () => {
    const testLevels = await getTestLevels();

    this.testLevelsList = normalizeListEnglishLevels(testLevels.data.data);

    const testNames = await getTestNames({ filter: 1 });

    const normalizedFilterData = await normalizeFilterData(testNames.data.data);

    if (normalizedFilterData.length) {
      const firstTestId = normalizedFilterData[0].value;

      const studentsList = await getStudentsByTestId({
        event_parent_id: firstTestId
      });

      this.studentsList = await normalizeStudentsList(studentsList.data.data);
    }

    this.testNamesList = normalizedFilterData;
  };

  @action getStudentsList = async (eventId: number) => {
    if (this.testNamesList.length) {
      const studentsList = await getStudentsByTestId({
        event_parent_id: eventId
      });

      this.studentsList = await normalizeStudentsList(studentsList.data.data);
    }
  };

  @action getNextTestResult = async (
    isLastStudent: boolean,
    formData: { selectedStudent: number; selectedTest: number }
  ) => {
    const { selectedStudent, selectedTest } = formData;

    if (isLastStudent) {
      const updatedTestNamesList = this.testNamesList;

      const newTestNamesList = updatedTestNamesList.filter((testName: ISelectItem) => testName.value !== selectedTest);

      this.testNamesList = newTestNamesList;

      const { value } = newTestNamesList[0];

      this.getStudentsList(value);
    } else {
      const updatedStudentsList = this.studentsList;

      this.studentsList = updatedStudentsList.filter(
        (studentData: ISelectItem) => studentData.value !== selectedStudent
      );
    }
  };
}
