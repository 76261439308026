import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { ITeacherData, IPatchTeacherParams } from 'components/Teachers/interface';
import withModal from 'hocs/WithModal';
import FormComponent from 'components/common/FormComponent';

import FormComponentEditTeacherInfo from './FormComponentEditTeacherInfo';
import { IModalProps } from '../../../Modals/interfaces';

const EditTeacherInfo: React.FC<ITeacherData & IModalProps> = observer((props: ITeacherData & IModalProps) => {
  const {
    email, phoneNumber, skype, typeId, userId, toggleModal
  } = props;

  const {
    teachers: { saveFormData }
  } = useContext(RootStore);

  const submitForm = async (data: IPatchTeacherParams) => {
    await saveFormData(data);
    toggleModal();
  };

  return (
    <FormComponent
      Component={FormComponentEditTeacherInfo}
      otherProps={props}
      initialValues={{
        teacher_user_id: userId,
        email,
        skype,
        phone_number: phoneNumber,
        type_id: typeId
      }}
      onSubmit={(data: IPatchTeacherParams) => submitForm(data)}
    />
  );
});

export default withModal(EditTeacherInfo);
