import { observable, action } from 'mobx';

import { getTestNames, getTeachersList, getStudyGroupNames } from 'api/filtersData';
import {
  setMainEventTest,
  setEventTest,
  setLesson,
  getWritingLevels,
  getEventTestTypes
} from 'api/events';
import { IRootStore } from 'store/interfaces';
import { successNotification } from 'components/common/Notifications/helper';

import {
  IResponseData, ISelectValues, IEventType, IEventData
} from './interface';
import { ILessonFormValues } from '../components/Lesson/interface';
import { eventsType } from './constants';
import {
  getEventDataForDB, getLessonDataForDB, getMainEventDataForDB, getName
} from './helpers';

export default class AddEventStore {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @observable testLevels: ISelectValues[] = [];

  @observable mainEventList: ISelectValues[] = [];

  @observable eventTestTypes: [] = [];

  @observable teachers: [] = [];

  @observable studyGroups: [] = [];

  @action setMainEventTest = async (data: any) => {
    const mainEventDataForDB = getMainEventDataForDB(data);

    const result = await setMainEventTest(mainEventDataForDB);

    return result;
  };

  @action setEventTest = async (eventType: string, data: IEventData) => {
    const name = getName(data, eventType);

    const currentEventType: IEventType = this.eventTestTypes.find(
      (event: IEventType) => event.name === eventType
    );

    const eventDataForDB = getEventDataForDB(eventType, { ...data, name, currentEventType });

    const result = await setEventTest(eventDataForDB);

    return result;
  };

  @action setLesson = async (data: ILessonFormValues) => {
    const lessonDataForDB = getLessonDataForDB(data);

    const result = await setLesson(lessonDataForDB);
    return result;
  };

  @action getWritingLevels = async () => {
    try {
      const {
        data: { data }
      } = await getWritingLevels();

      this.testLevels = data;
      return data;
    } catch (error) {
      return error;
    }
  };

  @action getEventTestTypes = async () => {
    try {
      const {
        data: { data }
      } = await getEventTestTypes();

      this.eventTestTypes = data;

      return data;
    } catch (error) {
      return error;
    }
  };

  @action getCommonTeachers = async () => {
    try {
      const {
        data: { data }
      } = await getTeachersList({ no_type_id: 2 });

      this.teachers = data;

      return data;
    } catch (error) {
      return error;
    }
  };

  @action getMainEventList = async () => {
    const {
      data: { data }
    } = await getTestNames();

    this.mainEventList = data;
  };

  @action getStudyGroups = async () => {
    try {
      const {
        data: { data }
      } = await getStudyGroupNames();

      this.studyGroups = data.data_page;
      return data.data_page;
    } catch (error) {
      return error;
    }
  };

  addEvent = async (eventType: string, data: any, closeModal: () => void): Promise<IResponseData> => {
    try {
      let result = [];

      switch (eventType) {
        case eventsType.MAIN: {
          await this.setMainEventTest(data);
          successNotification('Main event was created');

          return null;
        }
        case eventsType.WRITING:
        case eventsType.SPEAKING: {
          result = await this.setEventTest(eventType, data);
          break;
        }
        case eventsType.LESSON: {
          result = await this.setLesson(data);
          break;
        }
        default:
          break;
      }

      const resultData = result && result.data && result.data.data;

      if (resultData) {
        this.rootStore.calendarStore.updateEvents(resultData);
      }

      successNotification('event was created', eventType);
      return null;
    } catch (error) {
      return error;
    } finally {
      closeModal();
    }
  };
}
