export const TIME_COEFFICIENT: { [key: string]: number } = {
  8: 0,
  9: 1,
  10: 2,
  11: 3,
  12: 4,
  13: 5,
  14: 6,
  15: 7,
  16: 8,
  17: 9,
  18: 10,
  19: 11,
  20: 12
};

export const START_POSITION: number = 95;

export const MINUTES_IN_HOUR: number = 60;
