import { MonthEventsType } from 'types/calendarTypes';

import { TIME_LINE_DATE } from 'components/Calendar/constants/eventSetting';
import { getTimeAsNumber } from 'helpers/calendar';
import { MINUTES_IN_HOUR } from 'constants/date';
import { STARTED_HEIGHT } from './Event/constants';

export const getHeightTimelineCellContent = (heightTableBody: number): number => {
  const numberOfHours = TIME_LINE_DATE.length;

  return heightTableBody / numberOfHours;
};

export const convertTimeToPixels = (time: string, height: number): number => {
  const timeLikeNumber = getTimeAsNumber(time, ':');
  const timeCoefficientToHeight = height / MINUTES_IN_HOUR;

  return timeLikeNumber.minutes * timeCoefficientToHeight + STARTED_HEIGHT;
};

export const getLessonDuration = (endTime: string, time: string) => {
  if (endTime) {
    const endTimeLesson = getTimeAsNumber(endTime, ':');
    const startTimeLesson = getTimeAsNumber(time, ':');

    let hourLessonDuration = endTimeLesson.hour - startTimeLesson.hour;
    let minutesLessonDuration = endTimeLesson.minutes - startTimeLesson.minutes;

    if (minutesLessonDuration < 0) {
      hourLessonDuration -= 1;
      minutesLessonDuration = 60 + minutesLessonDuration;
    }

    return `${hourLessonDuration}:${minutesLessonDuration}`;
  }

  return null;
};

export const getHeightEventCard = (heightTimelineCellContent: any, lessonDuration: any) => {
  const timeCoefficient = getTimeAsNumber(lessonDuration, ':');

  const heightByMinutes = (heightTimelineCellContent / MINUTES_IN_HOUR) * timeCoefficient.minutes;

  return heightTimelineCellContent * timeCoefficient.hour + heightByMinutes;
};

export const getDayEvent = (dayEvents: MonthEventsType[]): MonthEventsType => dayEvents.find((dayEvent) => {
  const { id } = dayEvent;

  return id !== undefined;
});
