import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import * as filter from 'constants/select';
import ReactSelect from 'components/common/FormFields/Selects';
import FiltersContainer from 'components/common/FiltersContainer';

const Filters: React.FC<any> = observer((props:any) => {
  const { isOpen, toggleModal } = props;
  const { teachers } = useContext(RootStore);
  const { filters, selectedTeacherType, changeTeacherType, getTeachersList } = teachers;

  const { teachersTypes } = filters;

  const teachersTypesLength = teachersTypes.length;

  const fetchData = async () => {
    await getTeachersList();
    toggleModal();
  };

  return (
    <FiltersContainer onApply={fetchData} isOpen={isOpen} toggleModal={toggleModal}>
      <div className="title-content">
        <div className="inputs-group">
          {!!teachersTypesLength && (
            <div className="inputs-group-item">
              <ReactSelect
                values={[filter.ALL_TEACHER_TYPES, ...teachersTypes]}
                selectedValue={selectedTeacherType}
                updateData={changeTeacherType}
              />
            </div>
          )}
        </div>
      </div>
    </FiltersContainer>
  );
});

export default Filters;
