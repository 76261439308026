import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import RootStore from 'store';

import { assignStudentToDepartmentGroup } from 'api/department';

import { DRAG_TYPE } from 'components/Departments/constant';
import { IStudentData } from 'components/Students/interface';

import EmptyCard from 'components/common/DrugAndDrop/EmptyCard';
import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';
import StudentsSelectionMobile from 'components/common/MobileUsersSelect/StudentsSelection';

import { IStudentsProps, IDropUserData } from './interfaces';

import Student from './Student';

const Students: React.FC<IStudentsProps> = (props: IStudentsProps) => {
  const { students, departmentId, departmentGroupId } = props;

  const { departmentsStore, serviceStore: { isMobile } } = useContext(RootStore);
  const { getDepartments, students: storeStudents } = departmentsStore;

  const dropStudentToGroup = async (dropData: IDropUserData) => {
    try {
      const params = {
        user_id: dropData.userData.userId,
        department_id: departmentId,
        department_group_id: departmentGroupId
      };

      await assignStudentToDepartmentGroup(params);
      await getDepartments();

      return params;
    } catch (error) {
      return error;
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: [DRAG_TYPE.student, DRAG_TYPE.lead],
    drop: dropStudentToGroup,
    collect: (monitor: any) => ({
      isOver: monitor.isOver()
    })
  });

  const scheduleCardClassNames = classNames('panel-row', { 'is-dragging': isOver });

  const dragItemsStudents = students.map((student: IStudentData) => (
    <Student {...student} key={student.userId} />
  ));

  const desktopContent = !students.length ? (
    <EmptyCard />
  ) : (
    <>
      {dragItemsStudents}
      <EmptyRow />
    </>
  );

  const mobileContent = (
    <>
      {dragItemsStudents}
      <StudentsSelectionMobile
        addStudent={dropStudentToGroup}
        students={storeStudents}
      />
    </>
  );

  const content = isMobile ? mobileContent : desktopContent;

  return (
    <div ref={drop} className={scheduleCardClassNames}>
      <span className="subtitle">Students</span>
      {content}
    </div>
  );
};

export default observer(Students);
