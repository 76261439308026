import React from 'react';

import { IGroupItemProps } from './interface';

const GroupItem: React.FC<IGroupItemProps> = (props: IGroupItemProps) => {
  const { groupName, isOpenDetailInfo, toggleDetailInfo, setModalState } = props;

  const openModal = () => {
    setModalState(true);
  };

  return (
    <div className="panel-summary">
      <div className="panel-header">
        <div className="card-title">{groupName}</div>
        <div className="panel-navigation">
          <button className="accordion-button-edit" type="button" onClick={openModal}>
            <i className="material-icons">edit</i>
          </button>
          <button className="btn-down" type="button" onClick={toggleDetailInfo}>
            {isOpenDetailInfo ? (
              <i className="material-icons accordion-arrow">keyboard_arrow_up</i>
            ) : (
              <i className="material-icons accordion-arrow">keyboard_arrow_down</i>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupItem;
