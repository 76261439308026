import React from 'react';
import Select from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { IReactSelectProps } from 'components/common/FormFields/Selects/interface';
import { DEFAULT_OPTION } from 'components/common/FormFields/Selects/constant';

import FormatOptionLabel from 'components/common/FormFields/Selects/FormatOptionLabel';
import { renderOption } from './renderers';

const ReactSelect = (props: IReactSelectProps) => {
  const {
    values,
    updateData,
    placeholder,
    selectedValue,
    isDisabled,
    inputLabel,
    onMenuOpen,
    onMenuClose,
    additionalClassName,
    optionType
  } = props;

  const hasError = false;
  const options = values && values.length ? values : DEFAULT_OPTION;
  const selectClassName = classNames('select', additionalClassName);

  const handleCloseMenuOnScroll = (e: Event) => {
    const target = e.target as HTMLElement;
    const scrollableElement = target.parentElement;

    return !(scrollableElement && (scrollableElement.classList.contains('react-select__menu')));
  };

  const renderSelectMenu = (data: any) => (
    <PerfectScrollbar className="perfect-scrollbar react-select__menu-list">{data.children}</PerfectScrollbar>
  );

  return (
    <>
      <div className="form-field">
        {inputLabel && (
          <label htmlFor="" className="input-label">
            {inputLabel}
          </label>
        )}
        <Select
          className={selectClassName}
          classNamePrefix="react-select"
          placeholder={placeholder || 'Select value'}
          options={options}
          value={selectedValue}
          isDisabled={isDisabled}
          formatOptionLabel={FormatOptionLabel}
          isOptionDisabled={(option) => option.disabled}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={handleCloseMenuOnScroll}
          components={{
            MenuList: renderSelectMenu,
            Option: (optionProps) => renderOption(optionProps, optionType)
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={(value: any) => {
            updateData(value);
          }}
        />
        {hasError && <span className="form-group-error">Error</span>}
      </div>
    </>
  );
};

export default ReactSelect;
