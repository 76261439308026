import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextarea from './FormTextarea';
import FormDatePicker from './FormDatePicker';
import FormTimePicker from './FormTimePicker';

export const formFields = {
  FormInput,
  FormSelect,
  FormTextarea,
  FormDatePicker,
  FormTimePicker
};
