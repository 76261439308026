import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { IPermissions } from 'types';

import WithPermissions from 'hocs/WithPermissions';

import ColumnValueInTable from 'components/common/ColumnValueInTable';

import { getUpdatedSortedData } from 'components/common/ColumnValueInTable/helpers';

import { getDefaultSortedPanel } from './helper';

const TableHeader: React.FC<IPermissions> = observer((props: IPermissions) => {
  const { permissions } = props;

  const { students, serviceStore: { isMobile } } = useContext(RootStore);
  const { changeSorted } = students;

  const defaultSortedPanel = getDefaultSortedPanel(permissions, isMobile);

  const [sortedPanel, updateSortedPanel] = useState(defaultSortedPanel);

  const selectedFilter = async (titleSort: string) => {
    const updatedSortData = getUpdatedSortedData(sortedPanel, titleSort);

    updateSortedPanel(updatedSortData);
    changeSorted(updatedSortData);
  };

  return (
    <div className="filter-panel">
      {sortedPanel.map((value) => (
        <ColumnValueInTable {...value} selectedFilter={selectedFilter} key={value.title} />
      ))}
    </div>
  );
});

export default WithPermissions(TableHeader);
