import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import FiltersContainer from 'components/common/FiltersContainer';

import { IFiltersProps } from './interfaces';
import * as ROLES from '../../constants/roles';
import Select from '../common/FormFields/Selects';

const Filters:React.FC<IFiltersProps> = (props: IFiltersProps) => {
  const { attendanceLog, authenticationStore } = useContext(RootStore);

  const { StudyGroups, selectStudyGroup, getAttendanceLog } = attendanceLog;
  const { UserData: { id } } = authenticationStore;
  const {
    permissions, isMobileFiltersOpen, toggleFiltersModal
  } = props;

  const fetchData = () => {
    getAttendanceLog({ user_id: id });
    toggleFiltersModal();
  };

  const isGroupFilterShown = !permissions([ROLES.STUDENT, ROLES.GROUP_LEAD]);

  return (
    <FiltersContainer isOpen={isMobileFiltersOpen} toggleModal={toggleFiltersModal} onApply={fetchData}>
      <div className="title-content">
        <div className="inputs-group">
          {isGroupFilterShown && (
          <div className="inputs-group-item">
            <Select
              additionalClassName="is-medium"
              values={StudyGroups}
              updateData={selectStudyGroup}
            />
          </div>
          )}
        </div>
      </div>
    </FiltersContainer>
  );
};

export default observer(Filters);
