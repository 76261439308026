import React from 'react';
import { useDrag } from 'react-dnd';

import User from 'components/common/User';
import { DRAG_STUDENT_TYPE } from 'components/TestSchedule/constants';

import { IStudentItemProps } from '../../interfaces';

const StudentItem: React.FC<IStudentItemProps> = ({ student }: IStudentItemProps) => {
  const {
    userId, fullName, writingEnglishLevelName, studentUserName, studentUserSurname
  } = student;

  const data = {
    fullName, writingEnglishLevelName, userId, studentUserName, studentUserSurname
  };

  const [, drag] = useDrag({
    item: { type: DRAG_STUDENT_TYPE, data }
  });

  return (
    <div ref={drag} className="card is-draggable">
      <div className="panel-summary">
        <div className="panel-header">
          <div className="panel-drag-icon" />
          <div className="panel-item w-40">
            <User hasRole={false} userIcon="" userName={fullName} />
          </div>
          <div className="panel-item">{writingEnglishLevelName}</div>
        </div>
      </div>
    </div>
  );
};

export default StudentItem;
