import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { IGradientData } from 'hocs/WithGradient/interface';
import WithGradient from 'hocs/WithGradient';

import { IScheduleProps } from '../../interfaces';
import EmptyState from '../../../common/EmptyState';

import WritingScheduleItem from './WritingScheduleItem';

const WritingSchedule: React.FC<IScheduleProps & IGradientData> = (
  props: IScheduleProps & IGradientData
) => {
  const {
    bottom, top, toggleGradient, events, gradientInitialized
  } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const testEventClassName = classNames('test-schedule-events', {
    'has-bottom-gradient': !isReachedBottom && gradientInitialized,
    'has-top-gradient': !isReachedTop && gradientInitialized
  });

  const schedules = events.map((event) => (
    <WritingScheduleItem key={event.id} event={event} />
  ));

  return (
    <>
      <div className={testEventClassName}>
        <PerfectScrollbar
          className="perfect-scrollbar"
          onYReachStart={removeTopGradient}
          onYReachEnd={removeBottomGradient}
          onScrollY={toggleGradient}
          options={{
            suppressScrollX: true
          }}
        >
          {schedules.length ? (
            <div className="test-schedule-inner">
              {schedules}
            </div>
          ) : (
            <EmptyState
              icon="sentiment_dissatisfied"
              title="No events planned yet"
              additionalClassName="card has-spacings"
            />
          )}
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default WithGradient(WritingSchedule);
