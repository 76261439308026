import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import RootStore from 'store';

import { IStudentData } from 'components/Students/interface';
import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import DragItem from 'components/common/DrugAndDrop/DragItem';
import EmptyState from 'components/common/EmptyState';

import { DRAG_TYPE } from '../constant';

const StudentList: React.FC<IGradientData> = (props: IGradientData) => {
  const { bottom, top, toggleGradient } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { departmentsStore } = useContext(RootStore);
  const { students } = departmentsStore;

  const dragListClassName = classNames('drag-list-inner', {
    'has-bottom-gradient': !isReachedBottom,
    'has-top-gradient': !isReachedTop
  });

  const dragItemsStudents = students.map((student: IStudentData) => (
    <DragItem
      data={student}
      transmittedData={{ userId: student.userId }}
      dragType={DRAG_TYPE.student}
      key={student.userId}
    />
  ));

  const content = students.length ? (
    dragItemsStudents
  ) : (
    <EmptyState
      icon="sentiment_dissatisfied"
      title="No students are existing"
      additionalClassName="card has-spacings"
    />
  );

  return (
    <div className="drag-list">
      <div className="title">Students</div>
      <div className={dragListClassName}>
        <PerfectScrollbar
          className="perfect-scrollbar flex-grow-1"
          onYReachStart={removeTopGradient}
          onYReachEnd={removeBottomGradient}
          onScrollY={toggleGradient}
        >
          {content}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(StudentList);
