import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import moment from 'moment';

import { getBackend, getBackendOptions } from 'components/common/DrugAndDrop/service';
import Header from 'components/common/Header';
import ButtonsGroup from 'components/common/BtnsGroup';
import RootStore from 'store';

import WritingSchedule from './components/WritingSchedule/WritingSchedule';
import SpeakingSchedule from './components/SpeakingSchedule/SpeakingSchedule';
import TestCalendar from './components/TestCalendar';
import StudentsList from './components/StudentsList/StudentsList';

import { eventsType, EVENT_TYPES, WRITING_TEST_ID } from './constants';
import { isSelectedTab } from './helpers';
import { format } from '../../constants/date';
import { updateFormatDate } from '../../helpers/actionsWithDates';

const TestSchedule: React.FC = () => {
  const {
    testSchedule,
    authenticationStore: { user },
    serviceStore: { isMobile }
  } = useContext(RootStore);

  const {
    getMonthEvents,
    monthEvents,
    selectedDay,
    setSelectedDay,
    selectedMonth,
    setSelectedMonth
  } = testSchedule;

  const { id } = user;

  const [typeButtonGroup, setTypeButtonGroup] = useState({
    id: WRITING_TEST_ID,
    name: eventsType.WRITING
  });

  const setType = (name: any, data: any) => {
    setTypeButtonGroup(data);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    const params = {
      month_year: updateFormatDate(selectedMonth, format.MONTH_YEAR),
      user_id: id,
      event_type_id: typeButtonGroup.id
    };

    getMonthEvents(params);
  }, [id, selectedMonth, typeButtonGroup.id]);

  const currentDayEvents = monthEvents.filter((event) => moment(event.date).isSame(selectedDay));

  return (
    <>
      <Header pageTitle="Test Schedule">
        <div className="title-content">
          <ButtonsGroup
            data={EVENT_TYPES}
            updateData={setType}
            selectedValue={typeButtonGroup.name}
          />
        </div>
      </Header>

      <div className="page-content">
        <div className="test-schedule">
          <DndProvider backend={getBackend(isMobile)} options={getBackendOptions(isMobile)}>
            <div className="page-col">
              <StudentsList />
            </div>
            <div className="page-col is-stretched d-flex flex-column">
              <TestCalendar
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                monthEvents={monthEvents}
              />

              {isSelectedTab(typeButtonGroup.name, eventsType.WRITING) && (
                <WritingSchedule events={currentDayEvents} />
              )}
              {isSelectedTab(typeButtonGroup.name, eventsType.SPEAKING) && (
                <SpeakingSchedule events={currentDayEvents} />
              )}
            </div>
          </DndProvider>
        </div>
      </div>
    </>
  );
};

export default observer(TestSchedule);
