/* eslint camelcase: 0 */
import { ITeacherType } from 'components/Groups/interfaces';
import * as type from './interfaces';

export const normalizeLessonsTypes = (lessonsTypes: ITeacherType[]) => lessonsTypes.map((lessonType) => ({
  ...lessonType,
  value: lessonType.name
}));

const normalizeStudentDataInGroup = (student: type.INoNormalStudentType) => {
  const {
    recommended_english_level_id,
    recommended_english_level_name,
    speaking_english_level_id,
    speaking_english_level_name,
    student_user_department_id,
    student_user_department_name,
    student_user_email,
    student_user_id,
    student_user_name,
    student_user_photo,
    student_user_surname,
    writing_english_level_id,
    writing_english_level_name
  } = student;

  return {
    id: student_user_id,
    name: student_user_name,
    surname: student_user_surname,
    fullName: `${student_user_surname} ${student_user_name}`,
    departmentId: student_user_department_id,
    departmentName: student_user_department_name,
    email: student_user_email,
    photo: student_user_photo,
    recommendedEnglishLevelId: recommended_english_level_id,
    recommendedEnglishLevelName: recommended_english_level_name,
    writingEnglishLevelId: writing_english_level_id,
    writingEnglishLevelName: writing_english_level_name,
    speakingEnglishLevelId: speaking_english_level_id,
    speakingEnglishLevelName: speaking_english_level_name
  };
};

const normalizeStudyGroup = (studyGroup: type.INoNormalStudyGroupType) => {
  const {
    english_level_id,
    english_level_name,
    id,
    name,
    students,
    lesson_type,
    teacher_type_name,
    teacher_user_email,
    teacher_user_id,
    teacher_user_name,
    teacher_user_photo,
    teacher_user_surname
  } = studyGroup;

  return {
    id,
    name,
    englishLevelId: english_level_id,
    englishLevelName: english_level_name,
    teacher: {
      id: teacher_user_id,
      name: teacher_user_name,
      surname: teacher_user_surname,
      fullName: `${teacher_user_surname} ${teacher_user_surname}`,
      typeId: lesson_type,
      typeName: teacher_type_name,
      email: teacher_user_email,
      photo: teacher_user_photo
    },
    students: students.map((student: type.INoNormalStudentType) => normalizeStudentDataInGroup(student))
  };
};

export const normalizeStudyGroups = (
  studyGroups: type.INoNormalStudyGroupType[]
) => studyGroups.map((studyGroup) => normalizeStudyGroup(studyGroup));
