/* eslint camelcase: 0 */
const getStudents = (students) => {
  if (!students) {
    return [];
  }

  return students.map((studentData) => ({
    attendance: studentData.attendance,
    eventStudentId: studentData.event_student_id,
    studentUserId: studentData.student_user_id,
    studentUserName: studentData.student_user_name,
    studentUserPhoto: studentData.student_user_photo,
    studentUserSurname: studentData.student_user_surname
  }));
};

export const normalizeEventData = (eventData) => {
  const {
    begin_time,
    date,
    end_time,
    event_id,
    room,
    study_group_name,
    teacher_user_name,
    teacher_user_surname,
    students,
    event_parent_id,
    event_parent_name,
    event_type_id,
    event_type_name,
    teacher_user_id,
    study_group_id,
    event_level_id,
    event_level_name,
    canceled
  } = eventData;

  return {
    eventLevelId: event_level_id,
    eventLevelName: event_level_name,
    id: event_id,
    type: event_type_name,
    date,
    time: begin_time,
    endTime: end_time,
    teacher: `${teacher_user_name} ${teacher_user_surname}`,
    roomNumber: room,
    group: study_group_name || `${event_parent_name} ${event_type_name} ${teacher_user_name}`,
    eventParentId: event_parent_id,
    eventParentName: event_parent_name,
    eventTypeId: event_type_id,
    teacherUserId: teacher_user_id,
    students: getStudents(students),
    isActive: false,
    studyGroupId: study_group_id,
    canceled
  };
};

export const normalizeStudentEventData = (requestData) => {
  const { data } = requestData;

  return data.map((eventData) => {
    const normalizedEventData = normalizeEventData(eventData);

    return {
      ...normalizedEventData,
      attendance: eventData.attendance
    };
  });
};

export const normalizeAllEventData = (requestData) => {
  const { data } = requestData;

  return data.map((eventData) => normalizeEventData(eventData));
};
