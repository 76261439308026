import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { initialStudentInfo } from 'components/Admins/AdminsTable/EditAdminInfo/constant';
import { INormalAdminData } from 'components/Admins/interface';
import { formFields } from 'components/common/FormComponent/formFields';

import { IModalProps } from '../../../../Modals/interfaces';

const EditStudentInfo: React.FC<INormalAdminData & FormikProps<INormalAdminData> & IModalProps> = (
  props: INormalAdminData & FormikProps<INormalAdminData> & IModalProps
) => {
  const { submitForm, username, toggleModal } = props;

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-title">{username}</div>
        <button
          className="modal-close"
          type="button"
          onClick={() => {
            toggleModal();
          }}
        >
          <i className="material-icons">clear</i>
        </button>
      </div>
      <div className="modal-body">
        <div className="container modal-container">
          <div className="form-group">
            <div className="row modal-row">
              <div className="form-group col-xs-12 modal-col">
                <Field name={initialStudentInfo.EMAIL} placeholder="Admin email" component={formFields.FormInput} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <button className="btn-border green" type="button" onClick={submitForm}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EditStudentInfo);
