import moment from 'moment';

import * as INTERFACES from './interfaces';
import * as STATUSES from './constants';

export const normalizeAttendanceLog = (data: any): INTERFACES.IAttendanceLog => data.map((item: any) => {
  const {
    student_user_id: studentUserId,
    student_user_name: studentUserName,
    student_user_surname: studentUserSurname,
    student_user_photo: studentUserPhoto,
    events
  } = item;
  const userEvents = events.map((event: any): INTERFACES.IAttendanceLogEvent => {
    const {
      attendance,
      date,
      event_id: eventId,
      event_parent_id: eventParentId,
      event_parent_name: eventParentName,
      event_student_id: eventStudentId,
      event_type_id: eventTypeId,
      room,
      teacher_user_id: teacherUserId,
      teacher_user_name: teacherUserName,
      teacher_user_surname: teacherUserSurname
    } = event;

    return {
      attendance,
      date,
      eventId,
      eventParentId,
      eventParentName,
      eventStudentId,
      eventTypeId,
      room,
      teacherUserId,
      teacherUserName,
      teacherUserSurname
    };
  });

  return {
    studentUserId,
    studentUserName,
    studentUserSurname,
    studentUserPhoto,
    userEvents
  };
});

export const setEventStatus = (
  currentDay: INTERFACES.IMonthCountDay,
  events: INTERFACES.IAttendanceLogEvent[]
): INTERFACES.IEventStatus => {
  const isEventExist = events.some((event: INTERFACES.IAttendanceLogEvent): boolean => {
    const { date } = event;
    const eventDate: string = moment(date).format('DD');

    return currentDay.date === eventDate;
  });

  if (!isEventExist) {
    return {
      status: STATUSES.DEFAULT_LIGHT_STATUS,
      eventStudentId: null
    };
  }

  let existedEvent: INTERFACES.IAttendanceLogEvent = null;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < events.length; i++) {
    const eventDate: string = moment(events[i].date).format('DD');

    if (eventDate === currentDay.date) {
      existedEvent = events[i];
    }
  }

  const isAfterEventWasInPast: boolean = moment(new Date()).isAfter(existedEvent.date);

  if (!isAfterEventWasInPast) {
    return {
      status: STATUSES.DEFAULT_STATUS,
      eventStudentId: existedEvent.eventStudentId
    };
  }

  if (existedEvent.attendance) {
    return {
      status: STATUSES.LESSON_STATUS,
      eventStudentId: existedEvent.eventStudentId
    };
  }

  return {
    status: STATUSES.ABSENT_STATUS,
    eventStudentId: existedEvent.eventStudentId
  };
};

export const normalizeStudentsList = (students: any[]): any[] => students.map(student => {
  const { student_user_surname_name, student_user_id } = student;

  return {
    value: student_user_id,
    label: student_user_surname_name
  };
});

export const prepareStudentsUserId = (selectedStudents: any): string => {
  const indexes: number[] = selectedStudents.map((selectedStudent: any) => {
    const { value } = selectedStudent;

    return value;
  });

  return JSON.stringify(indexes);
};
