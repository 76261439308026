import React, { useContext, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import RootStore from 'store';
import * as ROLES from 'constants/roles';
import WithGradient from 'hocs/WithGradient';
import WithPermissions from 'hocs/WithPermissions';
import Pagination from 'components/common/Pagination';
import Header from 'components/common/Header';
import Search from 'components/common/FormFields/Search';
import ButtonsGroup from 'components/common/BtnsGroup';
import EmptyState from 'components/common/EmptyState';

import * as INTERFACES from './interfaces';
import * as CONSTANTS from './constants';
import Filters from './Filters';
import AttendanceLogCalendarCell from './AttendenceLogCalenderCell';
import StatusHint from '../common/StatusHint';
import { useStateModal } from '../Modals/helper';
import AttendanceLogTableDesktopView from './AttendanceTable/DesktopView';
import AttendanceLogTableMobileView from './AttendanceTable/MobileView';

const ATTENDANCE_CELL_ITEM_WIDTH = 30;

const AttendanceLog: React.FC<INTERFACES.IAttendanceLogProps> = (
  props: INTERFACES.IAttendanceLogProps
) => {
  const { permissions } = props;

  const { attendanceLog, authenticationStore, serviceStore: { isMobile } } = useContext(RootStore);
  const [isMobileFiltersOpen, toggleFiltersModal]:any = useStateModal(false);
  const calendarMobileRowRef = useRef(null);

  const {
    DateParams,
    getAttendanceLog,
    setCurrentDate,
    decrementDate,
    incrementDate,
    AttendanceLogEvents,
    MonthDays,
    PaginationParams,
    setCurrentPage,
    setEventType,
    getEventType,
    getStudyGroupNames,
    SelectedStudyGroup,
    getListOfStudents,
    SearchOptions,
    setSelectedStudent,
    SelectedStudent,
    currentDay
  } = attendanceLog;

  const { UserData } = authenticationStore;
  const { id } = UserData;
  const { month, year, daysCount } = DateParams;
  const { currentPage, countOfPages } = PaginationParams;

  if (calendarMobileRowRef.current && isMobile) {
    const { offsetWidth } = calendarMobileRowRef.current;
    const offsetDays = ATTENDANCE_CELL_ITEM_WIDTH * Number(currentDay);

    calendarMobileRowRef.current.scrollLeft = offsetDays - (offsetWidth / 2);
  }

  const fetchData = () => {
    if (Number.isNaN(daysCount)) {
      setCurrentDate();
    }

    if (id && !Number.isNaN(daysCount)) {
      getAttendanceLog({ user_id: id });
    }
  };

  useEffect(() => {
    if (isMobile) {
      fetchData();
    }
  }, [
    month,
    id,
    year,
    getAttendanceLog,
    daysCount,
    setCurrentDate,
    currentPage,
    getEventType,
    SelectedStudent
  ]);

  useEffect(() => {
    if (isMobile) { return; }
    fetchData();
  }, [
    month,
    id,
    year,
    getAttendanceLog,
    daysCount,
    setCurrentDate,
    currentPage,
    getEventType,
    SelectedStudyGroup,
    SelectedStudent
  ]);

  useEffect(() => {
    (async () => {
      if (permissions([ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.TEACHER])) {
        await getStudyGroupNames();
      }
    })();
  }, [getStudyGroupNames, permissions]);

  const searchRequest = (search_string: string) => {
    getListOfStudents({ user_id: id, search_string });
  };

  const isPaginationShown: boolean = countOfPages > 1;
  const isSearchShown = !permissions([ROLES.STUDENT]);
  const attendanceLogCalendarCellStyle = {
    minWidth: isMobile ? `${ATTENDANCE_CELL_ITEM_WIDTH}px` : 'initial'
  };

  const calendarMothRow = (
    MonthDays
    && MonthDays.map((item: INTERFACES.IMonthCountDay) => (
      <AttendanceLogCalendarCell
        {...item}
        style={attendanceLogCalendarCellStyle}
        currentDay={currentDay}
        key={`AttendanceLogCalendarCell-${item.date}-${item.day}`}
      />
    ))
  );

  const table = isMobile
    ? <AttendanceLogTableMobileView />
    : <AttendanceLogTableDesktopView />;

  return (
    <>
      <Header pageTitle="Attendance Log" onFiltersClick={toggleFiltersModal}>
        <div className="title-content">
          <div className="inputs-group">
            {isSearchShown && (
              <div className="inputs-group-item">
                <Search
                  value={SelectedStudent}
                  options={SearchOptions}
                  searchRequest={searchRequest}
                  updateSelectedItem={setSelectedStudent}
                />
              </div>
            )}
          </div>

          <div className="inputs-group-item">
            <Filters
              isMobileFiltersOpen={isMobileFiltersOpen}
              toggleFiltersModal={toggleFiltersModal}
              permissions={permissions}
            />
          </div>
        </div>
      </Header>
      <div className="page-content">
        <div className="container card attendance">
          <div className="row attendance-header">
            <div className="col-xs-6 col-md-4 attendance-navigation">
              <span className="rdtSwitch">{`${DateParams.monthName} ${DateParams.year}`}</span>
              <button className="rdtPrev" type="button" onClick={decrementDate} />
              <button className="rdtNext" type="button" onClick={incrementDate} />
            </div>
            {isMobile && (
              <div className="calendar-mobile-row" ref={calendarMobileRowRef}>
                {calendarMothRow}
              </div>
            )}
            <div className="col-xs-12 col-md-4 attendance-buttons">
              <ButtonsGroup
                data={CONSTANTS.EVENT_TYPES}
                updateData={setEventType}
                selectedValue={getEventType.value}
              />
            </div>
            <div className="col-xs-6 col-md-4 hints">
              <StatusHint status="attended" />
              <StatusHint status="absent" />
            </div>
          </div>
          <div className="row attendance-container">
            {table}
            {!AttendanceLogEvents.length && (
              <EmptyState
                additionalClassName="has-spacings"
                icon="sentiment_dissatisfied"
                title="Unfortunately, no students were found by your request :("
              />
            )}
          </div>
          {isPaginationShown && (
            <Pagination
              countPages={countOfPages}
              selectedPage={currentPage}
              updateSelectedPage={setCurrentPage}
              paginationClassName="pagination-holder"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default compose(
  WithPermissions,
  WithGradient,
  observer
)(AttendanceLog);
