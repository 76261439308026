/* eslint camelcase: 0 */
import { INoNormalAdminData } from 'components/Admins/interface';

export const normalizeAdminsData = (adminsData: INoNormalAdminData[]) => adminsData.map((adminData) => {
  const {
    user_id, email, name, surname, username
  } = adminData;

  return {
    email,
    name,
    surname,
    username,
    userId: user_id,
    isEdit: false
  };
});
