import React from 'react';

import { ValueType } from './interface';

function Value(props: ValueType) {
  const { label, value } = props;

  return (
    <tr>
      <td className="event-details-cell event-details-property">{`${label}:`}</td>
      <td className="event-details-cell event-details-value">{value}</td>
    </tr>
  );
}

export default Value;
