import React from 'react';

import StudentImg from 'components/common/StudentImg';

import { StudentsListType } from 'types/calendarTypes';
import { RENDER_STUDENT_IMG } from './constants';

const StudentsImg = ({ students }: { students: StudentsListType[] }) => {
  const otherStudentsLength = students.length - RENDER_STUDENT_IMG;

  const firstThreeStudents = students.slice(0, RENDER_STUDENT_IMG);

  return (
    <div className="event-card-members">
      {firstThreeStudents.map((studentData) => {
        const { eventStudentId } = studentData;

        return <StudentImg {...studentData} key={eventStudentId} />;
      })}
      {otherStudentsLength > 0 && (
        <div className="event-card-members-counter">{`+${otherStudentsLength}`}</div>
      )}
    </div>
  );
};

export default StudentsImg;
