import React, { useContext, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { initialTeacherInfo } from 'components/Teachers/TeachersTable/EditTeacherInfo/constants';
import { ITeacherData } from 'components/Teachers/interface';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import FormSelect from 'components/common/FormComponent/formFields/FormSelect';

import { IModalProps } from '../../../../Modals/interfaces';

const EditTeacherInfo: React.FC<ITeacherData> = (props: ITeacherData & FormikProps<ITeacherData> & IModalProps) => {
  const {
    typeId, typeName, name, surname, setFieldValue, submitForm, toggleModal
  } = props;

  const { teachers } = useContext(RootStore);
  const {
    filters: { teachersTypes }
  } = teachers;

  const [selectedType, changeType] = useState({ label: typeName, value: typeId });

  const fullName = `${surname} ${name}`;

  const submitFormData = async () => {
    await submitForm();
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-title">{fullName}</div>
        <button className="modal-close" type="button" onClick={() => { toggleModal(); }}>
          <i className="material-icons">clear</i>
        </button>
      </div>
      <div className="modal-body">
        <div className="container modal-container">
          <div className="form-group">
            <div className="row modal-row">
              <div className="form-group col-xs-12 modal-col">
                <Field name={initialTeacherInfo.EMAIL} placeholder="" component={FormInput} />
              </div>
              <div className="form-group col-xs-12 modal-col">
                <Field name={initialTeacherInfo.PHONE_NUMBER} placeholder="" component={FormInput} />
              </div>
              <div className="form-group col-xs-12 modal-col">
                <Field name={initialTeacherInfo.SKYPE} placeholder="" component={FormInput} />
              </div>
              <div className="form-group col-xs-12 modal-col">
                <Field
                  name={initialTeacherInfo.TYPE_ID}
                  component={FormSelect}
                  options={teachersTypes}
                  value={selectedType}
                  onChange={(data: any) => {
                    changeType(data);
                    setFieldValue('type_id', data.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <button className="btn-border green" type="button" onClick={submitFormData}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EditTeacherInfo);
