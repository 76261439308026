import { observable, action } from 'mobx';

import { getEvents } from 'api/calendar';
import { updateStudentAttendance } from 'api/attendanceLog';
import { deleteEvent, cancelEvent } from 'api/events';
import { normalizeAllEventData, normalizeEventData } from 'components/Calendar/utils';
import { DetailEventType, EventsRequestParamType } from 'components/Calendar/types/storeTypes';
import { IUser } from 'components/Authentication/interfaces';
import { IPatchAttendanceParams } from 'components/Calendar/interface';
import { MonthEventsType } from 'types/calendarTypes';
import { Filters, SelectData } from 'types/filters';
import * as actionsWithDates from 'helpers/actionsWithDates';
import { getSelectedDay } from 'components/TestSchedule/components/TestCalendar/helpers';
import { getFiltersDataByRole } from 'components/Calendar/helper';
import { DEFAULT_FILTER_DATA } from 'components/Calendar/constants/filterSetting';
import { successNotification } from 'components/common/Notifications/helper';

import { format } from '../../constants/date';
import * as dayHelpers from './helper/dayEvents';
import { getDetailEventById, setDetailEventAttendance } from './helper/detailEvents';
import { getEvent, validateMonthEvents, setMonthEventsAttendance } from './helper/monthEvent';

export default class CalendarStore {
  @observable selectedMonth: string = actionsWithDates.getMonth();

  @observable selectedDay: string;

  @observable monthEvents: MonthEventsType[] = [];

  @observable dayEvents: DetailEventType[];

  @observable detailEvent: MonthEventsType;

  @observable filters: Filters = DEFAULT_FILTER_DATA;

  @observable isLoading = false;

  @action setSelectedMonth = (value: number | string) => {
    if (typeof value === 'number') {
      this.selectedMonth = actionsWithDates.changeMonthInDate(this.selectedMonth, value);
    } else {
      this.selectedMonth = value;
    }
  };

  @action setSelectedDay = (date: string) => {
    this.selectedDay = date;
  };

  @action updateEvent = (event: object) => {
    const normalizedEvent = normalizeEventData(event);

    this.detailEvent = normalizedEvent;
    this.dayEvents = dayHelpers.editDayEvent(this.dayEvents, normalizedEvent);
    this.monthEvents = this.monthEvents.map((item: MonthEventsType) => {
      if (normalizedEvent.id === item.id) {
        return normalizedEvent;
      }

      return item;
    });
  };

  @action updateEvents = (events: []) => {
    if (!events.length) {
      return;
    }

    const serializedEvents = events.map(normalizeEventData);

    this.monthEvents = [...this.monthEvents, ...serializedEvents];
  };

  @action setStudentsAttendance = async (params: IPatchAttendanceParams) => {
    const {
      data: { errors }
    } = await updateStudentAttendance(params);

    if (!errors) {
      this.detailEvent = {
        ...this.detailEvent,
        students: setDetailEventAttendance(this.detailEvent.students, params)
      };

      this.dayEvents = dayHelpers.editDayEvent(this.dayEvents, this.detailEvent);

      this.monthEvents = setMonthEventsAttendance(this.monthEvents, this.detailEvent);
    }
  };

  @action setDefaultCalendarState = () => {
    this.monthEvents = [];
    this.dayEvents = null;
    this.detailEvent = null;
  };

  @action updateFilters = async (user: IUser, selectedDepartment: SelectData) => {
    this.filters = await getFiltersDataByRole(user, selectedDepartment);
  };

  @action getMonthEvents = async (params: EventsRequestParamType): Promise<void> => {
    this.isLoading = true;

    try {
      const { data } = await getEvents(params);

      const normalizedEventData = normalizeAllEventData(data);

      const validatedMonthEvents = validateMonthEvents(normalizedEventData);

      this.setSelectedDay(getSelectedDay(this.selectedMonth, validatedMonthEvents));
      this.monthEvents = validatedMonthEvents;
      getEvent(validatedMonthEvents, this.getDayEvents);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action getDayEvents = (value: string) => {
    const events = this.monthEvents.filter((event) => {
      const { date } = event;

      return date === value;
    });

    this.dayEvents = dayHelpers.getDayTimeEvents(events);
    this.detailEvent = dayHelpers.getFirstEvent(this.dayEvents);
  };

  @action updateDayEvents = (id: number) => {
    this.dayEvents.forEach(({
      eventsGroup
    }) => eventsGroup.forEach((groupEvent) => dayHelpers.updateActiveEvent(groupEvent, id)));
  };

  @action getDetailEvents = (id: number, index: number) => {
    this.updateDayEvents(id);
    this.detailEvent = getDetailEventById(this.dayEvents, index);
  };

  @action deleteEvent = async (eventId: number, userId: number) => {
    try {
      const {
        data: { data }
      } = await deleteEvent({ event_id: eventId });

      this.getMonthEvents({
        user_id: userId,
        month_year: actionsWithDates.updateFormatDate(this.selectedMonth, format.MONTH_YEAR)
      });

      successNotification('event was deleted', this.detailEvent.type);

      this.monthEvents = this.monthEvents.filter((item) => item.id !== eventId);
      this.detailEvent = null;

      return data;
    } catch (err) {
      return err;
    }
  };

  @action cancelEvent = async (event_id: number, cancelModalToggle?: () => void) => {
    try {
      const { data: { data } } = await cancelEvent({ event_id });

      this.updateEvent(data);

      successNotification('event was canceled', this.detailEvent.type);

      return true;
    } catch (error) {
      return error;
    } finally {
      cancelModalToggle();
    }
  }
}
