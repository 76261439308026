import React from 'react';

import { FilterEventType } from 'types/calendarTypes';

import { TYPES_EVENTS, TYPES_EVENTS_FOR_STUDENT } from 'components/Calendar/constants/filterSetting';
import { STUDENT } from 'constants/roles';

import WithPermissions, { IWithPermissionsProps } from 'hocs/WithPermissions';

import Status from 'components/common/Status';

const FilterCalendarData = ({ permissions }: IWithPermissionsProps) => {
  const typesEvent = permissions([STUDENT]) ? TYPES_EVENTS_FOR_STUDENT : TYPES_EVENTS;

  return (
    <ul className="calendar-filters">
      {typesEvent.map((data: FilterEventType) => {
        const { id, status, label } = data;

        return (
          <li className="calendar-filters-item" key={id}>
            <Status status={status} />
            <div className="calendar-filters-label">{label}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default WithPermissions(FilterCalendarData);
