import React, { useEffect, useState, useContext } from 'react';

import RootStore from 'store';
import ColumnValueInTable from 'components/common/ColumnValueInTable';
import { getUpdatedSortedData } from 'components/common/ColumnValueInTable/helpers';
import * as INTERFACE from '../interfaces';
import { DEFAULT_SORT_ITEMS } from '../constants';

const SortPanel: React.FC<INTERFACE.ISortPanelProps> = (props: INTERFACE.ISortPanelProps) => {
  const { updateSortParams, openAddGroupModal, isAdmin } = props;
  const { serviceStore: { isMobile } } = useContext(RootStore);
  const [sortedData, updateSortedData] = useState([]);

  const selectedFilter = (titleSort: string) => {
    const updatedSortData = getUpdatedSortedData(sortedData, titleSort);

    updateSortedData(updatedSortData);
    updateSortParams(updatedSortData);
  };

  useEffect(() => {
    updateSortedData(DEFAULT_SORT_ITEMS);
  }, []);

  const openAddGroupModalHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    openAddGroupModal();
  };

  return (
    <div className="filter-panel">
      {sortedData.map((sortingValues: any) => (
        <ColumnValueInTable
          {...sortingValues}
          selectedFilter={selectedFilter}
          key={`Groups-sort-${sortingValues.title}`}
        />
      ))}
      <div className="d-flex flex-grow-1 justify-content-end">
        <div className="filter-button-group">
          {isAdmin && !isMobile && (
            <button className="btn-default" onClick={openAddGroupModalHandler} type="button">
              + Add New
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SortPanel;
