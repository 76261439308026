import React, { useState } from 'react';

import User from 'components/common/User';
import { FullGroupInfoStudentProps } from './interfaces';
import DeleteStudentModal from '../Modals/RemoveStudent';

const FullGroupInfoStudent: React.FC<FullGroupInfoStudentProps> = (props: FullGroupInfoStudentProps) => {
  const { student, deleteStudent, isAdmin } = props;
  const {
    name,
    photo,
    email,
    surname,
    recommendedEnglishLevelName,
    studentUserDepartmentName
  } = student;

  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  const toggleDeleteModal = () => setDeleteModalState(!isDeleteModalOpen);

  return (
    <div className="panel-summary">
      <DeleteStudentModal
        isOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        deleteStudent={deleteStudent}
        student={student}
      />
      <div className="panel-header">
        <div className="panel-item w-200">
          <User hasRole={false} userIcon={photo} userName={`${name} ${surname}`} />
        </div>
        <div className="panel-item w-200">{email}</div>
        {studentUserDepartmentName && (
          <div className="panel-item">
            <span className="panel-label">Department</span>
            {studentUserDepartmentName}
          </div>
        )}
        {recommendedEnglishLevelName && (
          <div className="panel-item w-200">
            <span className="panel-label">Level</span>
            {recommendedEnglishLevelName}
          </div>
        )}
        {isAdmin && (
          <div className="panel-navigation is-hidden">
            <button className="accordion-button-edit" type="button" onClick={toggleDeleteModal}>
              <i className="material-icons">delete</i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FullGroupInfoStudent;
