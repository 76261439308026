import api from 'api';
import request from 'api/request';

import { IPostAdminParams, IPatchAdminParams, IDeleteAdminParams } from 'components/Admins/interface';

export const getAdminsList = (): Promise<any> => request({
  url: api.ADMINS,
  method: 'GET'
});

export const createAdmin = (data: IPostAdminParams): Promise<any> => request({
  url: api.ADMIN,
  method: 'POST',
  data
});

export const editAdminInfo = (data: IPatchAdminParams): Promise<any> => request({
  url: api.ADMIN,
  method: 'PATCH',
  data
});

export const deleteAdmin = (params: IDeleteAdminParams): Promise<any> => request({
  url: api.ADMIN,
  method: 'DELETE',
  params
});
