import moment from 'moment';

import { name } from './patterns';
import {
  REQUIRED, USER_NAME, PASSWORD, PASSWORDS_DOESNT_MATCH
} from '../constants/validationMessages';
import { email, passwordPattern } from '../constants/patterns';

export const userName = (values, isRequired = true) => {
  const errors = {};

  if (!values.username && isRequired) {
    errors.username = REQUIRED;
  } else if (!name.test(values.username)) {
    errors.username = USER_NAME;
  }
  return errors;
};

export const userPassword = (values, isRequired) => {
  const errors = {};

  if (!values.password && isRequired) {
    errors.password = REQUIRED;
  }

  return errors;
};

export const validateEmail = (values, isRequired = true) => {
  const errors = {};

  if (!values.email && isRequired) {
    errors.email = REQUIRED;
  }

  if (values.email && !email.test(values.email)) {
    errors.email = PASSWORDS_DOESNT_MATCH;
  }

  return errors;
};

export const confirmationPassword = (values, isRequired = true) => {
  const { password, confirmPassword } = values;

  const errors = {};

  if (!password && isRequired) {
    errors.password = REQUIRED;
  }

  if (!confirmPassword && isRequired) {
    errors.confirmPassword = REQUIRED;
  }

  if (password && !passwordPattern.test(password)) {
    errors.password = PASSWORD;
  }

  if (password !== confirmPassword) {
    errors.confirmPassword = PASSWORDS_DOESNT_MATCH;
  }

  return errors;
};

export const isTimeNotInRange = (time) => {
  if (!time) {
    return false;
  }

  const currentTime = moment(time);
  const startTime = moment('08:00', 'HH:mm');
  const endTime = moment('20:01', 'HH:mm');

  return (
    !currentTime.isBetween(startTime, endTime)
  );
};

export const isDifferenceBetweenDatesInvalid = (start, end) => (
  moment(end).diff(moment(start), 'minutes') < 10
);
