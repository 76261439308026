import { IPostTeacherParams } from 'components/Teachers/interface';

export const initialAddTeacherKey = {
  PASSWORD: 'password',
  NAME: 'name',
  SURNAME: 'surname',
  USERNAME: 'username',
  EMAIL: 'email',
  SKYPE: 'skype',
  PHONE_NUMBER: 'phone_number',
  TYPE_ID: 'type_id'
};

export const initialAddTeacher: IPostTeacherParams = {
  password: '',
  name: '',
  surname: '',
  username: '',
  email: '',
  skype: '',
  phone_number: '',
  type_id: null
};
