import { getStringifyData } from 'helpers';

import { NATIVE_SPEAKER_TYPE_ID, ENGLISH_LEVEL_INTERMEDIATE_LOW } from 'components/Students/constants';

import { SelectData } from 'types/filters';

export const getStatusIds = (selectedStatus: SelectData, defaultValue: string) => {
  const { value } = selectedStatus;

  if (value) {
    return getStringifyData([value]);
  }

  return defaultValue;
};

export const checkAvailableTeachersTypes = (
  teacherTypes: SelectData[],
  recommendedEnglishLevelId: number
) => teacherTypes.map((teacherType) => {
  const { value } = teacherType;

  if (!recommendedEnglishLevelId) {
    return teacherType;
  }

  if (value === NATIVE_SPEAKER_TYPE_ID && recommendedEnglishLevelId < ENGLISH_LEVEL_INTERMEDIATE_LOW) {
    return { ...teacherType, disabled: true };
  }

  return teacherType;
});
