import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';

import RootStore from 'store';
import App from 'App';

import * as serviceWorker from './serviceWorker';
import history from './services/history';
import './scss/App.scss';

const app = (
  <Provider
    rootStore={RootStore}
  >
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
