import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import RootStore from 'store';
import { IActionsProps } from 'components/Admins/AdminsTable/AdminInfo/Actions/interface';

import EditAdminInfo from '../../EditAdminInfo';
import { useStateModal } from '../../../../Modals/helper';

const Actions: React.FC<IActionsProps> = observer((props: IActionsProps) => {
  const { userId } = props;
  const [isEditModalOpen, toggleEditModal]: any = useStateModal(false);

  const { admins, serviceStore: { isMobile } } = useContext(RootStore);
  const { updateModalDeleteAdminState, setAdminData } = admins;

  const removeAdmin = () => {
    setAdminData(userId);

    updateModalDeleteAdminState(true);
  };

  const editAdminInfo = () => {
    toggleEditModal();
  };

  const panelNavigationClassName = cn('panel-navigation', {
    'is-hidden': !isMobile
  });

  return (
    <div className={panelNavigationClassName}>
      <button className="accordion-button-edit" type="button" onClick={editAdminInfo}>
        <i className="material-icons">edit</i>
      </button>
      <button className="accordion-button-edit" type="button" onClick={removeAdmin}>
        <i className="material-icons">delete</i>
      </button>
      <EditAdminInfo {...props} isOpen={isEditModalOpen} toggleModal={toggleEditModal} />
    </div>
  );
});

export default Actions;
