import React from 'react';

import { SelectData } from 'types/filters';

export default (option: SelectData) => {
  const { label, isLoading } = option;

  if (isLoading) {
    return (
      <div className="react-select react-select-loader">
        <div>{label}</div>
      </div>
    );
  }

  return (
    <div className="react-select">
      <div>{label}</div>
    </div>
  );
};
