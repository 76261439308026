import { useRef, useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const createRootElement = (id: string): Element => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem: Element): void => {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
};

const usePortal = (id: string, isOpen: boolean, scrollTarget?: Element): Element => {
  const rootElemRef = useRef(null);
  const existingParent = document.querySelector(`#${id}`);
  const parentElem = existingParent || createRootElement(id);

  useEffect(() => {
    if (!existingParent) {
      addRootElement(parentElem);
    }

    parentElem.appendChild(rootElemRef.current);
    disableBodyScroll(scrollTarget);

    if (!isOpen) {
      rootElemRef.current.remove();
      parentElem.remove();
      clearAllBodyScrollLocks();
    }
  }, [isOpen]);

  const getRootElem = (): Element => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }

    return rootElemRef.current;
  };

  return getRootElem();
};

export default usePortal;
