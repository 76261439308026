import React, { useContext } from 'react';

import { IStudentData } from 'components/Students/interface';
import { GROUP_LEAD } from 'constants/roles';
import WithPermissions from 'hocs/WithPermissions';
import { IPermissions } from 'types';
import User from 'components/common/User';
import Label from 'components/Calendar/Label';
import Actions from 'components/Students/StudentsTable/StudentInfo/Actions';
import RootStore from 'store';
import StudentMobileDetails from './StudentMobileDetails';

const StudentInfo: React.FC<IStudentData & IPermissions> = (props: IStudentData & IPermissions) => {
  const { serviceStore: { isMobile } } = useContext(RootStore);
  const {
    permissions,
    departmentGroupName,
    departmentName,
    email,
    photo,
    recommendedEnglishLevelId,
    recommendedEnglishLevelName,
    speakingEnglishLevelName,
    writingEnglishLevelName,
    statusId,
    statusName,
    studyGroupName,
    studyGroupId,
    teacherTypeName,
    teacherTypeId,
    fullName,
    userId
  } = props;

  const label = !recommendedEnglishLevelId ? statusName : teacherTypeName;

  return (
    <details className="accordion-thumb">
      <summary className="panel-summary">
        <div className="panel-header">
          <div className="panel-item w-15">
            <User hasRole={false} userIcon={photo} userName={fullName} />
          </div>
          <div className="panel-item w-15 m-hidden">{email}</div>
          <div className="panel-item w-10">
            <Label label={label} status={statusName} />
          </div>
          <div className="panel-item w-10 m-hidden">{studyGroupName}</div>
          {permissions([GROUP_LEAD]) ? (
            <>
              <div className="panel-item w-15 m-hidden">{speakingEnglishLevelName}</div>
              <div className="panel-item w-15 m-hidden">{writingEnglishLevelName}</div>
              <div className="panel-item w-15 m-hidden">{recommendedEnglishLevelName}</div>
            </>
          ) : (
            <>
              <div className="panel-item w-15 m-hidden">{recommendedEnglishLevelName}</div>
              <div className="panel-item w-15 m-hidden">{`${departmentName} / ${departmentGroupName}`}</div>
            </>
          )}
        </div>
        <Actions
          student={props}
          userId={userId}
          statusId={statusId}
          studyGroupId={studyGroupId}
          recommendedEnglishLevelId={recommendedEnglishLevelId}
          teacherTypeId={teacherTypeId}
        />
      </summary>
      {isMobile && (
        <div className="accordion-content">
          <StudentMobileDetails {...props} />
        </div>
      )}
    </details>
  );
};

export default WithPermissions(StudentInfo);
