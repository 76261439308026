import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { ADMIN, SUPER_ADMIN, GROUP_LEAD } from 'constants/roles';
import WithPermissions from 'hocs/WithPermissions';
import ReactSelect from 'components/common/FormFields/Selects';
import FiltersContainer from 'components/common/FiltersContainer';
import { IFilters } from '../interface';

const Filters: React.FC<IFilters> = observer((props: IFilters) => {
  const { permissions, isMobileFiltersOpen, toggleIsMobileFiltersOpen } = props;

  const { students, authenticationStore } = useContext(RootStore);
  const {
    user: { departmentId }
  } = authenticationStore;
  const {
    filters: { groups, departmentGroups, studentsStatuses },
    getStudyGroup,
    getDepartmentGroups,
    filterByGroup,
    filterByDepartmentGroup,
    filterByStatus,
    changeFilterGroup,
    changeFilterDepartmentGroup,
    changeFilterStudentsStatus,
    getStudentsList
  } = students;

  const getGroupOptions = () => {
    if (!groups.length) {
      getStudyGroup();
    }
  };

  const getDepartmentGroupOptions = () => {
    if (!departmentGroups.length) {
      getDepartmentGroups({ department_id: departmentId });
    }
  };

  const fetchData = () => {
    getStudentsList();
    toggleIsMobileFiltersOpen();
  };

  return (
    <FiltersContainer onApply={fetchData} isOpen={isMobileFiltersOpen} toggleModal={toggleIsMobileFiltersOpen}>
      <div className="title-content">
        <div className="inputs-group">
          <div className="inputs-group-item">
            {permissions([ADMIN, SUPER_ADMIN]) && (
              <ReactSelect
                additionalClassName="is-medium"
                values={groups}
                selectedValue={filterByGroup}
                updateData={changeFilterGroup}
                onMenuOpen={getGroupOptions}
              />
            )}
          </div>
          <div className="inputs-group-item">
            {permissions([GROUP_LEAD]) && (
              <ReactSelect
                additionalClassName="is-big"
                values={departmentGroups}
                selectedValue={filterByDepartmentGroup}
                updateData={changeFilterDepartmentGroup}
                onMenuOpen={getDepartmentGroupOptions}
              />
            )}
          </div>
          <div className="inputs-group-item">
            <ReactSelect
              values={studentsStatuses}
              selectedValue={filterByStatus}
              updateData={changeFilterStudentsStatus}
            />
          </div>
        </div>
      </div>
    </FiltersContainer>
  );
});

export default WithPermissions(Filters);
