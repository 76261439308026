import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { deleteAdmin } from 'api/admins';
import { IModalProps } from 'components/Modals/interfaces';
import Container from 'components/Modals/ModalContainer';
import { successNotification } from 'components/common/Notifications/helper';

const DeleteAdmin: React.FC<IModalProps> = observer((props: IModalProps) => {
  const { isOpen } = props;

  const { admins } = useContext(RootStore);
  const { adminInfo, updateModalDeleteAdminState, getAdminList } = admins;
  const { userId, username } = adminInfo;

  const closeModal = () => {
    updateModalDeleteAdminState(false);
  };

  const deleteResult = async () => {
    try {
      await deleteAdmin({ admin_user_id: userId });

      updateModalDeleteAdminState(false);
      getAdminList();
      successNotification('Admin was successfully deleted');

      return null;
    } catch (e) {
      return e;
    }
  };

  const header = (
    <>
      <div className="modal-title">Delete Admin</div>
      <button className="modal-close" type="button" onClick={closeModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = (
    <div className="container modal-container">{`Are you sure you want to delete ${username}?`}</div>
  );

  const footer = (
    <div className="inputs-group">
      <div className="inputs-group-item">
        <button className="btn-border" type="button" onClick={closeModal}>
          Back
        </button>
      </div>
      <div className="inputs-group-item">
        <button className="btn-danger" type="button" onClick={deleteResult}>
          Delete
        </button>
      </div>
    </div>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      toggleModal={() => { updateModalDeleteAdminState(false); }}
    />
  );
});

export default DeleteAdmin;
