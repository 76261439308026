import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import RootStore from 'store';

import { MonthEventsType } from 'types/calendarTypes';
import { STUDENT } from 'constants/roles';

import WithPermissions from 'hocs/WithPermissions';

import Status from 'components/common/Status';

import { getStatusEvent, getEventsStatuses } from './helper';
import { IEventComponentProps } from './interface';

const EventComponent: React.FC<IEventComponentProps> = observer((props: IEventComponentProps) => {
  const { value, currentDate, permissions } = props;

  const { calendarStore } = useContext(RootStore);
  const { monthEvents } = calendarStore;

  const day = currentDate.date();
  const dateEvent = currentDate.toDate();

  const isStudent = permissions([STUDENT]);

  const dayEvents = monthEvents.filter((monthEvent: MonthEventsType) => {
    const { date } = monthEvent;

    return moment(date).isSame(dateEvent, 'day');
  });

  const eventsStatuses = getEventsStatuses(dayEvents);

  const { style } = getStatusEvent(dayEvents[0], isStudent);

  return (
    <td {...value} style={style}>
      <div>{day}</div>
      <div className="calendar-status">
        {eventsStatuses.map((status: string) => (
          <Status status={status} key={`${dateEvent}${status}`} />
        ))}
      </div>
    </td>
  );
});

export default WithPermissions(EventComponent);
