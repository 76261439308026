import React, { useContext } from 'react';
import cn from 'classnames';

import RootStore from 'store';
import { ITeacherData } from 'components/Teachers/interface';
import User from 'components/common/User';

import EditTeacherInfo from '../EditTeacherInfo';
import { useStateModal } from '../../../Modals/helper';
import TeacherMobileDetails from './TeacherMobileDetails';

const Teacher: React.FC<ITeacherData> = (props: ITeacherData) => {
  const {
    email, name, phoneNumber, roleName, skype, surname, typeName, userId
  } = props;
  const [isEditModalOpen, toggleEditModal]: any = useStateModal(false);
  const { teachers, serviceStore: { isMobile } } = useContext(RootStore);
  const { updateModalDeleteTeacherState } = teachers;

  const fullName = `${surname} ${name}`;

  const editTeacherData = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    toggleEditModal();
  };

  const deleteTeacher = () => {
    updateModalDeleteTeacherState(true, userId);
  };

  const panelNavigationClassName = cn('panel-navigation', {
    'is-hidden': !isMobile
  });

  return (
    <>
      <details className="accordion-thumb">
        <summary className="panel-summary">
          <div className="panel-header">
            <div className="panel-item w-15">
              <User hasRole={false} userIcon="" userName={fullName} userRole={roleName} />
            </div>
            <div className="panel-item w-15 m-hidden">{email}</div>
            <div className="panel-item w-15 m-hidden">{phoneNumber}</div>
            <div className="panel-item w-15 m-hidden">{skype}</div>
            <div className="panel-item w-15">{typeName}</div>
          </div>
          <div className={panelNavigationClassName}>
            <button className="accordion-button-edit" type="button" onClick={editTeacherData}>
              <i className="material-icons">edit</i>
            </button>
            <button className="accordion-button-edit" type="button" onClick={deleteTeacher}>
              <i className="material-icons">delete</i>
            </button>
            {isMobile && <i className="material-icons accordion-arrow">keyboard_arrow_down</i>}
          </div>
        </summary>
        {isMobile && (
          <div className="accordion-content">
            <TeacherMobileDetails {...props} />
          </div>
        )}
      </details>
      <EditTeacherInfo {...props} isOpen={isEditModalOpen} toggleModal={toggleEditModal} />
    </>
  );
};

export default Teacher;
