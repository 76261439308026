import { observable, action, computed } from 'mobx';

import { IRootStore } from 'store/interfaces';

import * as filterApi from 'api/filtersData';
import { getTeacherList } from 'api/teachers';
import * as studentsApi from 'api/students';

import * as studentType from 'components/Students/interface';
import { ITeacherType } from 'components/Groups/interfaces';
import { ITeacherData } from 'components/Teachers/interface';
import { IShortStudentData } from 'components/Modals/Student/DeleteStudentFromGroup/interface';

import { normalizeTeachersData } from 'components/Teachers/utils';
import { normalizeStudentsList } from 'components/Students/utils';

import { INormalStudyGroupType } from './interfaces';

import * as utils from './utils';
import { sortByKey } from './helpers';
import { DEFAULT_LESSON_TYPE } from './constant';

export default class AddToGroupStore {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @observable lessonsTypes: ITeacherType[] = [];

  @observable selectedLessonType: ITeacherType = DEFAULT_LESSON_TYPE;

  @observable teachers: ITeacherData[] = [];

  @observable students: studentType.IStudentData[] = [];

  @observable groups: INormalStudyGroupType[] = [];

  @observable modalData: IShortStudentData = null;

  @observable isOpenModalDelete = false;

  @observable isLoadingLessons = false;

  @observable isLoadingStudents = false;

  @observable isLoadingGetData = false;

  @observable isLoadingAddStudentGroups = false;

  @computed get isLoading() {
    return this.isLoadingStudents
      || this.isLoadingAddStudentGroups
      || this.isLoadingLessons
      || this.isLoadingGetData;
  }

  @observable isLoadingGetStudyGroups = false;

  @action getData = async () => {
    this.isLoadingGetData = true;

    const selectedLessonTypeId = this.selectedLessonType.id;

    await this.getTeachers(selectedLessonTypeId);

    await this.getStudents(selectedLessonTypeId);

    await this.getStudyGroups(selectedLessonTypeId);

    this.isLoadingGetData = false;
  };

  @action getLessonsTypes = async (): Promise<void> => {
    try {
      this.isLoadingLessons = true;

      const {
        data: { data }
      } = await filterApi.getTeacherTypes();

      const normalizedLessonsTypes = utils.normalizeLessonsTypes(sortByKey(data, 'id'));

      this.lessonsTypes = normalizedLessonsTypes;

      this.setSelectedLessonType(normalizedLessonsTypes[0]);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingLessons = false;
    }
  };

  @action setSelectedLessonType = (lessonType: ITeacherType) => {
    this.selectedLessonType = lessonType;
  };

  @action getTeachers = async (lessonTypeId: number): Promise<void> => {
    try {
      const {
        data: { data }
      } = await getTeacherList({
        active: 1,
        type_id: lessonTypeId
      });

      this.teachers = normalizeTeachersData(data);

      return data;
    } catch (error) {
      return error;
    }
  };

  @action getStudents = async (lessonTypeId: number) => {
    try {
      const waitingType = JSON.stringify([1]);

      const {
        data: { data }
      } = await studentsApi.getStudentsList({
        status_ids: waitingType,
        type_id: lessonTypeId
      });

      this.students = normalizeStudentsList(data.data_page);

      return data;
    } catch (error) {
      return error;
    }
  };

  @action addStudentToGroup = async (params: studentType.IAddStudentToGroupParams) => {
    try {
      this.isLoadingAddStudentGroups = true;
      const request = await studentsApi.addStudentToGroup(params);

      this.getData();

      return request;
    } catch (error) {
      return error;
    } finally {
      this.isLoadingAddStudentGroups = false;
    }
  };

  @action deleteStudentFromGroup = async (params: studentType.IDeleteStudentFromGroupParams) => {
    try {
      const request = await studentsApi.deleteStudentFromGroup(params);

      this.setModalData();
      this.getData();

      return request;
    } catch (error) {
      return error;
    }
  };

  @action getStudyGroups = async (lessonTypeId: number) => {
    try {
      const {
        data: { data }
      } = await filterApi.getStudyGroupNames({ lesson_type_id: lessonTypeId });

      this.groups = utils.normalizeStudyGroups(data.data_page);

      return data;
    } catch (error) {
      return error;
    }
  };

  @action setModalData = (modalData?: IShortStudentData) => {
    if (modalData) {
      this.modalData = modalData;
      this.isOpenModalDelete = true;
    } else {
      this.modalData = null;
      this.isOpenModalDelete = false;
    }
  };
}
