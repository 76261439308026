import * as INTERFACES from './interfaces';

export const ERROR_PAGES: INTERFACES.IErrorPageData = {
  404: {
    title: 'Page not found',
    subtitle: 'We can\'t seem to find the page you\'re looking for.\n',
    icon: 'not-found'
  },
  403: {
    status: '403',
    title: 'Forbidden',
    subtitle: 'Access to this resources on the server is denied!',
    icon: 'permissions-denied'
  },
  500: {
    status: '500',
    title: 'Internal Server Error',
    subtitle: 'We\'re working towards creating something better. We won\'t be long.',
    icon: 'server-error'
  }
};
