import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import RootStore from 'store';

import { ITeacherData } from 'components/Teachers/interface';
import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import DragItem from 'components/common/DrugAndDrop/DragItem';

import { DRAG_TYPE_TEACHER } from '../constant';

const TeacherList: React.FC<IGradientData> = (props: IGradientData) => {
  const { bottom, top, toggleGradient, gradientInitialized } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { addToGroupStore } = useContext(RootStore);
  const { teachers } = addToGroupStore;

  const dragListClassName = classNames('drag-list-inner',
    { 'has-bottom-gradient': !isReachedBottom && gradientInitialized },
    { 'has-top-gradient': !isReachedTop });

  const dragItemsTeacher = teachers.map((teacher: ITeacherData) => (
    <DragItem
      data={teacher}
      transmittedData={{ userId: teacher.userId }}
      dragType={DRAG_TYPE_TEACHER}
      key={teacher.userId}
    />
  ));

  return (
    <div className="drag-list">
      <div className="title">Teachers</div>
      <div className={dragListClassName}>
        <PerfectScrollbar
          className="perfect-scrollbar flex-grow-1"
          onYReachStart={removeTopGradient}
          onYReachEnd={removeBottomGradient}
          onScrollY={toggleGradient}
        >
          {dragItemsTeacher}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(TeacherList);
