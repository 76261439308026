import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import Datetime from 'react-datetime';
import RootStore from 'store';

import { format } from 'constants/date';
import * as calendar from 'components/Calendar/CustomDateTime/constants';
import { updateFormatDate } from 'helpers/actionsWithDates';

import EventComponent from 'components/Calendar/CustomDateTime/EventComponent/index';

const CustomDateTime = observer(() => {
  const { calendarStore } = useContext(RootStore);
  const {
    selectedMonth,
    selectedDay,
    setSelectedMonth,
    setSelectedDay,
    getDayEvents,
    setDefaultCalendarState
  } = calendarStore;

  const renderDay = (value: any, currentDate: any): JSX.Element => {
    const activeDay = updateFormatDate(selectedDay, format.MONTH_DAY);

    if (activeDay && activeDay === value.key) {
      value.className = `rdtDay ${calendar.CLASS_ACTIVE}`;
    } else {
      value.className = value.className.replace(calendar.CLASS_ACTIVE, '');
    }

    return <EventComponent value={value} currentDate={currentDate} key={value.key} />;
  };

  const renderMonth = (props: any, month: number, year: number): JSX.Element => {
    const { monthName, monthNumber } = calendar.MONTH_DATA[month];

    const customOnClick = (e: React.MouseEvent<HTMLElement>) => {
      const { onClick } = props;

      onClick(e);

      const customSelectedDate = `${year}-${monthNumber}-${calendar.FIRST_DAY_OF_MONTH}`;

      setSelectedMonth(customSelectedDate);
    };

    return (
      <td {...props} role="presentation" onClick={customOnClick}>
        {monthName}
      </td>
    );
  };

  const onChange = (data: any): void => {
    const selectedDate = updateFormatDate(data.toDate(), format.YEAR_MONTH_DAY);

    setSelectedDay(selectedDate);
    getDayEvents(selectedDate);
  };

  const changeSearchDate = (monthValue: number) => {
    setSelectedDay(null);
    setDefaultCalendarState();
    setSelectedMonth(monthValue);
  };

  return (
    <Datetime
      className="calendar"
      input={false}
      timeFormat={false}
      viewDate={selectedMonth}
      onNavigateBack={(monthValue) => {
        changeSearchDate(-monthValue);
      }}
      onNavigateForward={(monthValue) => {
        changeSearchDate(monthValue);
      }}
      onChange={onChange}
      renderDay={renderDay}
      renderMonth={renderMonth}
      utc
    />
  );
});

export default CustomDateTime;
