import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import RootStore from 'store';

import { IStudentData } from 'components/Students/interface';
import { SelectData } from 'types/filters';

import { formFields } from 'components/common/FormComponent/formFields';

import { initialStudentInfo } from './constant';
import { IModalProps } from '../../../../Modals/interfaces';

const EditStudentInfo: React.FC<IStudentData & FormikProps<IStudentData> & IModalProps> = observer(
  (props: IStudentData & FormikProps<IStudentData> & IModalProps) => {
    const {
      recommendedEnglishLevelName,
      toggleModal,
      setFieldValue,
      submitForm,
      fullName
    } = props;

    const { students } = useContext(RootStore);
    const { getEnglishLevels, englishLevels } = students;

    const [selectedValue, changeValue] = useState({
      label: recommendedEnglishLevelName || 'Select level'
    });

    useEffect(() => {
      getEnglishLevels();
    }, []);

    return (
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">{fullName}</div>
          <button className="modal-close" type="button" onClick={() => { toggleModal(); }}>
            <i className="material-icons">clear</i>
          </button>
        </div>
        <div className="modal-body">
          <div className="container modal-container">
            <div className="form-group">
              <div className="row modal-row">
                <div className="form-group col-xs-12 modal-col">
                  <Field
                    name={initialStudentInfo.RECOMMENDED_ENGLISH_LEVEL_ID}
                    component={formFields.FormSelect}
                    options={englishLevels}
                    value={selectedValue}
                    onChange={(data: SelectData) => {
                      changeValue(data);
                      setFieldValue(initialStudentInfo.RECOMMENDED_ENGLISH_LEVEL_ID, data.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <button className="btn-border green" type="button" onClick={submitForm}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default EditStudentInfo;
