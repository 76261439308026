import React from 'react';

import ProgressBar from 'components/common/ProgressBar';
import { initialValueNames } from 'components/Modals/AddTestResult/FormAddTestResult/constants';

import { IFormikProps } from 'components/Modals/AddTestResult/FormAddTestResult/FormComponentAddTestResult/interface';
import { IAddWritingTestResultProps } from './interface';

const AddWritingTestResult: React.FC<IAddWritingTestResultProps&IFormikProps> = (
  props: IAddWritingTestResultProps&IFormikProps
) => {
  const { isLowTest, setFieldValue } = props;

  return (
    <div className="modal-content">
      <h2 className="modal-subtitle">Writing</h2>
      <div className="row modal-row">
        <div className="col-xs-12 col-sm-6 modal-col">
          <ProgressBar
            name={initialValueNames.LISTENING}
            setFieldValue={setFieldValue}
            progressClass="progress-listen"
            progressValue={0}
            progressLabel="Listening"
            disabled={false}
          />
          <ProgressBar
            name={initialValueNames.READING}
            setFieldValue={setFieldValue}
            progressClass="progress-red"
            progressValue={0}
            progressLabel="Reading"
            disabled={false}
          />
        </div>
        {isLowTest ? (
          <div className="col-xs-12 col-sm-6 modal-col">
            <ProgressBar
              name={initialValueNames.WRITING}
              setFieldValue={setFieldValue}
              progressClass="progress-write"
              progressValue={0}
              progressLabel="Writing"
              disabled={false}
            />
            <ProgressBar
              name={initialValueNames.WRITING_GRAMMAR}
              setFieldValue={setFieldValue}
              progressClass="progress-read"
              progressValue={0}
              progressLabel="Grammar"
              disabled={false}
            />
          </div>
        ) : (
          <div className="col-xs-12 col-sm-6 modal-col">
            <ProgressBar
              name={initialValueNames.PARAPHRASE}
              setFieldValue={setFieldValue}
              progressClass="progress-write"
              progressValue={0}
              progressLabel="Paraph"
              disabled={false}
            />
            <ProgressBar
              name={initialValueNames.ESSAY}
              setFieldValue={setFieldValue}
              progressClass="progress-read"
              progressValue={0}
              progressLabel="Essay"
              disabled={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddWritingTestResult;
