import React from 'react';

import { GroupNameType } from 'components/Calendar/EventsTable/interface';

const GroupName = (props: GroupNameType) => {
  const { groupName, scrollX } = props;

  return (
    <th className="timeline-head-cell">
      <div className="timeline-cell-content" style={{ right: scrollX }}>
        {groupName}
      </div>
    </th>
  );
};

export default GroupName;
