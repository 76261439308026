import { IFiltersData, IModalsData } from './interfaces';

export const DRAG_TYPE = {
  student: 'student',
  lead: 'lead'
};

export const DEFAULT_FILTER: IFiltersData = {
  departments: null
};

export const ROLE = {
  student: 1,
  lead: 3
};

export const DEFAULT_MODAL_DATA: IModalsData = {
  removeStudentModal: {
    state: false,
    studentData: null
  }
};
