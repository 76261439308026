import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import FormComponent from 'components/common/FormComponent';
import { userName } from 'services/validation';

import { IProps, IRestore } from '../interfaces';
import { validate } from '../utils';
import LoginFormComponent from './LoginFormComponent';
import ForgotPasswordForm from './ForgotPasswordForm';

export default observer(() => {
  const { authenticationStore } = useContext(RootStore);
  const { loginRequest, recoveryPassword, AuthError } = authenticationStore;
  const [isLoginForm, setIsLoginForm] = useState(true);

  const submit = (data: IProps): void => {
    loginRequest(data);
  };

  const switchToLogin = () => {
    setIsLoginForm(true);
  };

  const sendEmail = (data: IRestore) => {
    recoveryPassword(data, switchToLogin);
  };

  if (isLoginForm) {
    return (
      <FormComponent
        Component={LoginFormComponent}
        otherProps={{ AuthError, setIsLoginForm }}
        onSubmit={submit}
        initialValues={{ username: '', password: '' }}
        validate={validate}
      />
    );
  }

  return (
    <FormComponent
      Component={ForgotPasswordForm}
      otherProps={{ setIsLoginForm }}
      onSubmit={sendEmail}
      initialValues={{ username: '' }}
      validate={userName}
    />
  );
});
