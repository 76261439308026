import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import User from 'components/common/User';
import RootStore from 'store';
import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';
import EmptyCard from 'components/common/DrugAndDrop/EmptyCard';
import StudentsSelectionMobile from 'components/common/MobileUsersSelect/StudentsSelection';

import { IEvent, IStudentDropCard, StudentsListType } from '../../interfaces';
import { IStudentData } from '../../../Students/interface';
import { DRAG_STUDENT_TYPE } from '../../constants';

const SpeakingScheduleItem: React.FC<IEvent> = ({ event }: IEvent) => {
  const {
    serviceStore: { isMobile },
    testSchedule: { addStudentToTest, deleteStudentFromTest, notAssignedStudents }
  } = useContext(RootStore);

  const {
    id, time, endTime, roomNumber, teacher, students
  } = event;

  const [{ isOver }, drop] = useDrop({
    accept: DRAG_STUDENT_TYPE,
    drop: ({ data }: IStudentDropCard) => {
      const isUserExistInTest = students.some(item => item.userId === data.userId);
      if (!isUserExistInTest && students.length < 2) {
        const params = {
          event_id: id,
          student_user_id: data.userId,
          student: data
        };

        addStudentToTest(params);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  const onDelete = (student: StudentsListType) => {
    const { userId } = student;
    const params = {
      event_id: id,
      student_user_id: userId,
      student
    };

    deleteStudentFromTest(params);
  };

  const addStudent = (data: any) => {
    const {
      userData: { userId }
    } = data;
    const isUserExistInTest = students.some((item: StudentsListType) => (
      item.userId === data.userId
    ));
    if (!isUserExistInTest && students.length < 2) {
      const student = notAssignedStudents.find(item => item.userId === userId);
      const params = {
        event_id: id,
        student_user_id: userId,
        student
      };

      addStudentToTest(params);
    }
  };

  const renderFilled = (student?: IStudentData) => {
    if (!student) {
      return <EmptyRow />;
    }

    const { userId, fullName, writingEnglishLevelName } = student;

    return (
      <div className="panel-row" key={userId}>
        <div className="card-user">
          <div className="panel-content">
            <div className="panel-item w-40">
              <User userIcon="" userName={fullName} />
            </div>
            <div className="panel-item">{writingEnglishLevelName}</div>
            <div className="panel-navigation">
              <button
                className="panel-action dangerous"
                type="button"
                onClick={() => onDelete(student)}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTime = () => (
    <div className="panel-time">
      <span className="panel-time-start">{time}</span>
      <span className="panel-time-end">{endTime}</span>
    </div>
  );

  const renderSpeakingInfo = () => (
    <div className="speaking-info">
      <div className="speaking-details">
        <span className="panel-label">Room:</span>
        <span className="panel-value">{roomNumber}</span>
      </div>
      <div className="speaking-details">
        <span className="panel-label">Teacher:</span>
        <span className="panel-value">{teacher}</span>
      </div>
    </div>
  );

  const scheduleCardClassNames = classNames('card test-schedule-card', {
    'is-dragging': isOver
  });

  const desktopContent = students.length ? (
    <>
      {renderFilled(students[0])}
      {renderFilled(students[1])}
    </>
  ) : (
    <EmptyCard />
  );

  const mobileContent = (
    <>
      {students.map(item => (renderFilled(item)))}
      <StudentsSelectionMobile
        addStudent={addStudent}
        students={notAssignedStudents}
        canSelect={students.length < 2}
      />
    </>
  );

  const content = isMobile ? mobileContent : desktopContent;

  return (
    <div className={scheduleCardClassNames} ref={drop}>
      <div className="card-speaking">
        {renderTime()}
        {renderSpeakingInfo()}
        <div className="card-speaking-content">
          {content}
        </div>
      </div>
    </div>
  );
};

export default observer(SpeakingScheduleItem);
