import React from 'react';

import withModal from 'hocs/WithModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IConfirmationModal } from './interfaces';

const ConfirmationModal = (props: IConfirmationModal) => {
  const {
    toggleModal,
    onSubmit,
    title,
    text = 'Are your sure?',
    cancelText = 'Cancel',
    okText = 'ok'
  } = props;

  const closeModal = () => {
    toggleModal();
  };

  const header = (
    <>
      <div className="modal-title">{title}</div>
      <button className="modal-close" type="button" onClick={toggleModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = (
    <div className="modal-container">{text}</div>
  );

  const footer = (
    <div className="inputs-group">
      <div className="inputs-group-item">
        <button className="btn-border" type="button" onClick={closeModal}>
          {cancelText}
        </button>
      </div>
      <div className="inputs-group-item">
        <button className="btn-danger" type="button" onClick={onSubmit}>
          {okText}
        </button>
      </div>
    </div>
  );

  return (
    <div className="modal modal-confirmation">
      {header && <div className="modal-header">{header}</div>}
      <PerfectScrollbar className="perfect-scrollbar modal-body">{body}</PerfectScrollbar>
      <div className="modal-footer">{footer}</div>
    </div>
  );
};

export default withModal(ConfirmationModal);
