import React, { useState } from 'react';
import classnames from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';

import Status from 'components/common/Status';
import StatusOption from 'components/AttendanceLog/AttendanceLogEventCell/StatusOption';

import * as CONSTANTS from '../constants';
import * as INTERFACES from './interfaces';

const AttendanceLogEventCell: React.FC<INTERFACES.IAttendanceLogEventCellProps> = (
  props: INTERFACES.IAttendanceLogEventCellProps
) => {
  const {
    canUserEdit, status, label, eventStudentId, updateAttendance, options
  } = props;

  const [isOpen, toggleSelect] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isEventEditable = status === CONSTANTS.LESSON_STATUS || status === CONSTANTS.ABSENT_STATUS;
  const isPossibleToEditEvent = isEventEditable && canUserEdit;

  const selectClassName = classnames(
    isPossibleToEditEvent && 'select',
    isOpen && 'is-open'
  );

  const setAttendance = (value: number): void => {
    updateAttendance({
      event_student_id: eventStudentId,
      attendance: value
    });

    toggleSelect(false);
    setAnchorEl(null);
  };

  const onMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    toggleSelect(!isOpen);
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className="attendance-item">
      <div role="presentation" className={selectClassName} onClick={onMenuOpen}>
        <div className="select-trigger">
          <div className="select-trigger-label">
            <Status status={status} label={label} />
          </div>
          {isPossibleToEditEvent && <i className="select-trigger-arrow-icon material-icons">keyboard_arrow_down</i>}
        </div>
        {isPossibleToEditEvent && (
          <Menu open={isOpen} anchorEl={anchorEl}>
            {options.map((option) => (
              <MenuItem key={option.value}>
                <StatusOption {...option} setAttendance={setAttendance} />
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    </div>
  );
};

export default AttendanceLogEventCell;
