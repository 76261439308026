import * as INTERFACES from 'components/Groups/interfaces';
import request from './request';
import api from './index';

export const removeStudentFromStudyGroup = (
  params: INTERFACES.IRemoveStudentFromStudyGroupProps
): Promise<any> => request({
  url: api.STUDY_GROUP_STUDENT,
  method: 'DELETE',
  params
});

export const createStudyGroup = (data: INTERFACES.ICreateStudyGroupProps): Promise<any> => request({
  url: api.STUDY_GROUP,
  method: 'POST',
  data
});

export const deleteStudyGroup = (params: INTERFACES.IDeleteStudyGroupProps): Promise<any> => request({
  url: api.STUDY_GROUP,
  method: 'DELETE',
  params
});

export const updateStudyGroup = (data: INTERFACES.IUpdateStudyGroupProps): Promise<any> => request({
  url: api.STUDY_GROUP,
  method: 'PATCH',
  data
});
