/* eslint camelcase: 0 */
import { INoNormalStudentData } from 'components/Students/interface';

import { IDepartmentGroupsRequest } from './interfaces';

export const normalizeStudent = (student: INoNormalStudentData) => {
  const {
    department_group_id,
    department_group_name,
    department_id,
    department_name,
    name,
    photo,
    role_id,
    surname,
    user_id
  } = student;

  return {
    userId: user_id,
    fullName: `${name} ${surname}`,
    roleId: role_id,
    photo,
    departmentGroupId: department_group_id,
    departmentGroupName: department_group_name,
    departmentId: department_id,
    departmentName: department_name
  };
};

export const normalizeStudentsList = (
  students: INoNormalStudentData[]
) => students.map((student) => normalizeStudent(student));

const normalizeDepartmentGroup = (departmentGroup: IDepartmentGroupsRequest) => {
  const {
    department_group_id,
    department_group_name,
    department_id,
    department_name
  } = departmentGroup;

  return {
    departmentGroupId: department_group_id,
    departmentGroupName: department_group_name,
    departmentId: department_id,
    departmentName: department_name
  };
};

export const normalizeDepartmentGroups = (
  departmentGroups: IDepartmentGroupsRequest[]
) => departmentGroups.map((departmentGroup) => normalizeDepartmentGroup(departmentGroup));
