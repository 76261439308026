import React, { useState } from 'react';

import CropModal from './CropModal';

type CropImageUploaderProps = {
  onSelect: (image: string) => void;
}

const CropImageUploader: React.FC<CropImageUploaderProps> = ({ onSelect }) => {
  const [src, setSrc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsModalOpen(true);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const closeCropModal = () => {
    setIsModalOpen(false);
    setSrc(null);
  };

  return (
    <>
      <input
        className="avatar-input"
        type="file"
        name="file"
        accept="image/x-png,image/gif,image/jpeg"
        onChange={onSelectFile}
      />
      <CropModal
        src={src}
        isOpen={src && isModalOpen}
        toggleModal={closeCropModal}
        onSelect={onSelect}
      />
    </>
  );
};

export default CropImageUploader;
