import { observable, action } from 'mobx';

import { editAdminInfo as editAdminInfoRequest, getAdminsList } from 'api/admins';

import { INormalAdminData, IPatchAdminParams } from 'components/Admins/interface';

import { normalizeAdminsData } from 'components/Admins/utils';

export default class AdminsStore {
  @observable adminsList: INormalAdminData[] = [];

  @observable adminInfo: INormalAdminData = null;

  @observable isOpenModalAddNewAdmin = false;

  @observable isOpenModalDeleteAdmin = false;

  @observable isLoading = false;

  @action getAdminList = async (): Promise<void> => {
    try {
      this.isLoading = true;
      const {
        data: { data }
      } = await getAdminsList();

      this.adminsList = normalizeAdminsData(data);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action setAdminData = (adminId: number) => {
    this.adminInfo = this.adminsList.find(({ userId }) => userId === adminId);
  };

  @action resetAdminData = () => {
    this.adminInfo = null;
  };

  @action updateModalAddNewAdminState = (modalState: boolean) => {
    this.isOpenModalAddNewAdmin = modalState;
  };

  @action updateModalDeleteAdminState = (modalState: boolean) => {
    this.isOpenModalDeleteAdmin = modalState;
  };

  @action editAdminInfo = async (data: IPatchAdminParams): Promise<void> => {
    try {
      await editAdminInfoRequest(data);
      await this.getAdminList();
    } catch (e) {
      console.error(e);
    }
  };
}
