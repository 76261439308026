import React from 'react';
import classNames from 'classnames';

const EmptyState = (props: any) => {
  const { icon, title, additionalClassName } = props;
  const iconClassName = classNames('empty-icon', 'material-icons', icon);
  const containerClassName = classNames('empty', additionalClassName);

  return (
    <div className={containerClassName}>
      <span className={iconClassName}>{icon}</span>
      <span className="empty-title">{title}</span>
    </div>
  );
};

export default EmptyState;
