import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import {
  ADMIN, SUPER_ADMIN, GROUP_LEAD, STUDENT, TEACHER
} from 'constants/roles';

import { IHeaderTestResultProps } from './interface';

import HeaderValue from './HeaderValue/Index';
import HeaderActions from './HeaderActions';

const HeaderTestResult: React.FC<IHeaderTestResultProps> = observer((props) => {
  const {
    isEditResult,
    permissions,
    updateModalDeleteTestResultState,
    indexTestResult,
    submitForm
  } = props;

  const { testResults } = useContext(RootStore);

  const { listTestResults } = testResults;

  const {
    eventParentName,
    speakingEnglishLevelName,
    writingEnglishLevelName,
    recommendedEnglishLevelName,
    studentUserName,
    studentUserSurname,
    studyGroupName
  } = listTestResults[indexTestResult];

  const fullStudentName = `${studentUserSurname} ${studentUserName} `;

  return (
    <summary className="panel-summary">
      <div className="panel-header">
        {permissions([STUDENT]) && (
          <>
            <HeaderValue displayType="w-25" value={eventParentName} />
            <HeaderValue displayType="w-25" itemWidth="m-hidden" value={speakingEnglishLevelName} />
            <HeaderValue displayType="w-25" itemWidth="m-hidden" value={writingEnglishLevelName} />
            <HeaderValue displayType="w-25" itemWidth="m-hidden" value={recommendedEnglishLevelName} />
          </>
        )}
        {permissions([ADMIN, SUPER_ADMIN, TEACHER]) && (
          <>
            <HeaderValue displayType="w-20" value={fullStudentName} />
            <HeaderValue displayType="w-20" itemWidth="m-hidden" value={eventParentName} />
            <HeaderValue displayType="w-25" itemWidth="m-hidden" value={studyGroupName} />
          </>
        )}
        {permissions([GROUP_LEAD]) && (
          <>
            <HeaderValue displayType="w-25" value={fullStudentName} />
            <HeaderValue displayType="w-25" itemWidth="m-hidden" value={eventParentName} />
          </>
        )}
      </div>
      <HeaderActions
        isEditResult={isEditResult}
        permissions={permissions}
        updateModalDeleteTestResultState={updateModalDeleteTestResultState}
        indexTestResult={indexTestResult}
        submitForm={submitForm}
      />
    </summary>
  );
});

export default HeaderTestResult;
