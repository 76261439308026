import React from 'react';

import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';

import { ITeacherProps } from './interface';

import UserCard from '../UserCard';

const Teacher: React.FC<ITeacherProps> = (props: ITeacherProps) => {
  const { id, fullName, photo } = props;

  return (
    <div className="panel-row">
      <span className="subtitle">Teacher</span>
      {id ? <UserCard id={id} fullName={fullName} photo={photo} /> : <EmptyRow />}
    </div>
  );
};

export default Teacher;
