export const getTableValues = (isMobile: boolean): { title: string; itemWidth: string }[] => [
  {
    title: 'Name',
    itemWidth: isMobile ? 'w-50' : 'w-20'
  },
  {
    title: 'Email',
    itemWidth: isMobile ? 'w-50' : 'w-20'
  }
];
