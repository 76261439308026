/* eslint-disable camelcase */
import { IChildFormValues } from './FormAddTestResult/FormComponentAddTestResult/interface';

export const getIsHasValidationErrors = (formData: IChildFormValues) => {
  if (!formData) {
    return true;
  }

  const checkedParameters = {
    userId: formData.user_id,
    levelId: formData.event_level_id,
    parentId: formData.event_parent_id,
    writingLevelId: formData.writing_english_level_id,
    speakingLevelId: formData.speaking_english_level_id,
    recommendedLevelId: formData.recommended_english_level_id
  };

  return !Object.values(checkedParameters).every(Boolean);
};
