import React, { useEffect, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { observer } from 'mobx-react';

import RootStore from 'store';

import Header from 'components/common/Header';
import TeachersTable from 'components/Teachers/TeachersTable';
import Filters from 'components/Teachers/Filters';
import AddNewTeacher from 'components/Modals/AddNewTeacher';
import DeleteTeacher from 'components/Modals/DeleteTeacher';

import { useStateModal } from '../Modals/helper';

const Teachers: React.FC = observer(() => {
  const { teachers, serviceStore: { isMobile } } = useContext(RootStore);
  const [isMobileFiltersOpen, toggleIsMobileFiltersOpen]: any = useStateModal(false);

  const {
    getTeachersTypes, getTeachersList, selectedTeacherType, sortedData
  } = teachers;

  useEffect(() => {
    if (isMobile) {
      getTeachersList();
    }
    getTeachersTypes();
  }, []);

  useEffect(() => { getTeachersList(); }, [sortedData]);

  useEffect(() => {
    if (selectedTeacherType && !isMobile) {
      getTeachersList();
    }
  }, [selectedTeacherType]);

  return (
    <>
      <Header pageTitle="Teachers" onFiltersClick={toggleIsMobileFiltersOpen}>
        <Filters isOpen={isMobileFiltersOpen} toggleModal={toggleIsMobileFiltersOpen} />
      </Header>
      <div className="page-content">
        <PerfectScrollbar className="perfect-scrollbar">
          <TeachersTable />
        </PerfectScrollbar>
      </div>
      <AddNewTeacher />
      <DeleteTeacher />
    </>
  );
});

export default Teachers;
