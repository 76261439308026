import * as INTERFACES from './interfaces';
import * as CONSTANTS from './constants';

export const normalizeGroupsData = (groups: any[]): INTERFACES.IGroupsData[] => groups.map((group: any) => {
  const {
    id,
    name,
    teacher_user_id: teacherUserId,
    teacher_user_name: teacherUserName,
    teacher_user_photo: teacherUserPhoto,
    teacher_user_surname: teacherUserSurname,
    teacher_type_name: teacherTypeName,
    teacher_user_email: teacherUserEmail,
    students
  } = group;

  return {
    id,
    name,
    students: students.map((student: any): INTERFACES.IGroupsStudentData => {
      const {
        student_user_id: studentUserId,
        student_user_name: studentUserName,
        student_user_photo: studentUserPhoto,
        student_user_surname: studentUserSurname,
        speaking_english_level_id: speakingEnglishLevelId,
        speaking_english_level_name: speakingEnglishLevelName,
        student_user_department_id: studentUserDepartmentId,
        student_user_department_name: studentUserDepartmentName,
        student_user_email: studentUserEmail,
        writing_english_level_id: writingEnglishLevelId,
        writing_english_level_name: writingEnglishLevelName,
        recommended_english_level_id: recommendedEnglishLevelId,
        recommended_english_level_name: recommendedEnglishLevelName
      } = student;

      return {
        id: studentUserId,
        name: studentUserName,
        photo: studentUserPhoto,
        surname: studentUserSurname,
        studentUserDepartmentId,
        studentUserDepartmentName,
        email: studentUserEmail,
        speakingEnglishLevelId,
        speakingEnglishLevelName,
        writingEnglishLevelId,
        writingEnglishLevelName,
        recommendedEnglishLevelId,
        recommendedEnglishLevelName
      };
    }),
    teacher: {
      id: teacherUserId,
      name: teacherUserName,
      photo: teacherUserPhoto,
      surname: teacherUserSurname,
      type: teacherTypeName,
      email: teacherUserEmail
    }
  };
});

export const normalizeTeacherTypes = (types: any[]): any => types.map((type: any): any => {
  const { name } = type;

  return {
    ...type,
    value: CONSTANTS.LESSON_TYPES[name]
  };
});

export const defineTeacherType = (existedTypes: INTERFACES.ITeacherType[], type: string): INTERFACES.ITeacherType => {
  let currentType = {} as INTERFACES.ITeacherType;

  existedTypes.every(existedType => {
    const { name } = existedType;

    if (name === type) {
      currentType = { ...existedType };

      return false;
    }

    return true;
  });

  return currentType;
};

export const normalizeTeachersData = (teachers: any) => teachers.map((teacher: any): INTERFACES.ISelectValues => {
  // eslint-disable-next-line camelcase
  const { user_id, name, surname } = teacher;

  return {
    value: user_id,
    label: `${name} ${surname}`
  };
});

export const normalizeLevels = (levels: any) => levels.map((level: any): INTERFACES.ISelectValues => {
  const { id, name } = level;

  return {
    value: id,
    label: name
  };
});

export const teacherToSelect = (user: INTERFACES.IGroupsTeacherData): INTERFACES.ISelectValues => {
  const { id, name, surname } = user;

  return {
    value: id,
    label: `${name} ${surname}`
  };
};
