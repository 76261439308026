import request from './request';
import api from './index';

export const login = data => request({
  url: api.LOGIN,
  method: 'POST',
  data
});

export const logout = data => request({
  url: api.LOGOUT,
  method: 'POST',
  data
});

export const refreshToken = data => request({
  url: api.REFRESH_TOKEN,
  method: 'POST',
  data
});

// Can't upload file in backend side if type of request isn't POST.
export const updateProfileRequest = data => request({
  url: api.PROFILE,
  method: 'POST',
  data
});

export const passwordRecoveryRequest = data => request({
  url: api.PASSWORD_RECOVERY,
  method: 'POST',
  data
});

export const changePasswordRequest = data => request({
  url: api.CHANGE_PASSWORD,
  method: 'POST',
  data
});

export const deletePhoto = () => request({
  url: api.DELETE_PHOTO,
  method: 'DELETE'
});
