import { EXPAND_MORE, EXPAND_LESS } from 'components/common/ColumnValueInTable/constants';

export const FILTER_PANEL_DATA_FOR_STUDENT = [
  {
    title: 'Date',
    value: 'test_result_id',
    sortingArrow: EXPAND_MORE,
    itemWidth: 'w-25',
    isActive: true
  },
  {
    title: 'Speaking level',
    value: 'speaking_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-25',
    displayType: 'm-hidden',
    isActive: false
  },
  {
    title: 'Writing level',
    value: 'writing_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-25',
    displayType: 'm-hidden',
    isActive: false
  },
  {
    title: 'Recommended level',
    value: 'recommended_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-25',
    displayType: 'm-hidden',
    isActive: false
  }
];

export const FILTER_PANEL_DATA_FOR_ADMIN = [
  {
    title: 'Name',
    value: 'student_user_surname',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-20',
    displayType: 'm-hidden',
    isActive: false
  },
  {
    title: 'Date',
    value: 'test_result_id',
    sortingArrow: EXPAND_MORE,
    itemWidth: 'w-20',
    isActive: true
  },
  {
    title: 'Group',
    value: 'study_group_name',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-20',
    displayType: 'm-hidden',
    isActive: false
  }
];

export const FILTER_PANEL_DATA_FOR_GROUP_LEAD = [
  {
    title: 'Name',
    value: 'student_user_surname',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-25',
    displayType: 'm-hidden',
    isActive: false
  },
  {
    title: 'Date',
    value: 'test_result_id',
    sortingArrow: EXPAND_MORE,
    itemWidth: 'w-25',
    isActive: true
  }
];
