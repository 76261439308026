import React from 'react';

import { OPTIONS } from 'components/AttendanceLog/AttendanceLogUserRow/constants';

import User from 'components/common/User';
import AttendanceLogEventCell from '../AttendanceLogEventCell';
import { setEventStatus } from '../utils';
import { IAttendanceLogUserRowProps } from './interfaces';

const AttendanceLogUserRowMobile: React.FC<IAttendanceLogUserRowProps> = (
  props: IAttendanceLogUserRowProps
) => {
  const {
    canUserEdit, attendances, updateAttendance, currentDay
  } = props;

  const {
    studentUserName, studentUserSurname, studentUserPhoto, userEvents
  } = attendances;

  const { status, eventStudentId } = setEventStatus({ date: currentDay }, userEvents);

  return (
    <tr>
      <td className="fixed-cell attendance-cell">
        <User
          hasRole={false}
          userIcon={studentUserPhoto}
          userName={`${studentUserName} ${studentUserSurname}`}
          userRole="name"
        />
      </td>
      <td className="attendance-cell" key={`AttendanceLogUserRow-${currentDay}-${currentDay}`}>
        <AttendanceLogEventCell
          status={status}
          options={OPTIONS}
          eventStudentId={eventStudentId}
          updateAttendance={updateAttendance}
          canUserEdit={canUserEdit}
        />
      </td>
    </tr>
  );
};

export default AttendanceLogUserRowMobile;
