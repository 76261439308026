export const ADMIN: string = 'ROLE_ADMIN';

export const GROUP_LEAD: string = 'ROLE_GROUP_LEAD';

export const STUDENT: string = 'ROLE_STUDENT';

export const TEACHER: string = 'ROLE_TEACHER';

export const SUPER_ADMIN: string = 'ROLE_SUPER_ADMIN';

interface IRoleMapper {
  [key: string]: string
}

export const roleMapper: IRoleMapper = {
  [ADMIN]: 'Administrator',
  [GROUP_LEAD]: 'Group Lead',
  [STUDENT]: 'Student',
  [TEACHER]: 'Teacher',
  [SUPER_ADMIN]: 'Super Administrator'
};
