import moment from 'moment';

export const setDateFormat = () => {
  moment.locale('ru', {
    week: {
      dow: 1
    },
    weekdaysMin: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_')
  });
};

export const toDate = (date) => (date ? new Date(date) : null);
