import React, { useState, useEffect, useRef, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { IGradientData } from 'hocs/WithGradient/interface';
import WithGradient from 'hocs/WithGradient';
import RootStore from 'store';
import moment from 'moment';

import { getMonthDatesWithEvents } from './helpers';
import { EVENTS_TYPE } from './constants';
import { ITestCalendarProps } from './interface';
import Header from './Header';
import CalendarLine from './CalendarLine';

const ATTENDANCE_CELL_ITEM_WIDTH = 44;

const TestCalendar: React.FC<ITestCalendarProps & IGradientData> = (
  props: ITestCalendarProps & IGradientData
) => {
  const {
    selectedMonth,
    setSelectedMonth,
    selectedDay,
    setSelectedDay,
    monthEvents,
    left,
    right,
    toggleGradient
  } = props;
  const { isReachedLeft, removeLeftGradient } = left;
  const { isReachedRight, removeRightGradient } = right;

  const { serviceStore: { isMobile } } = useContext(RootStore);
  const [daysOfMonth, changeDaysOfMonth] = useState([]);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (calendarRef.current && selectedDay && isMobile) {
      const { offsetWidth } = calendarRef.current._container;
      const offsetDays = ATTENDANCE_CELL_ITEM_WIDTH * Number(moment(selectedDay).format('DD'));

      calendarRef.current._container.scrollLeft = offsetDays - (offsetWidth / 2);
    }
  }, [selectedDay]);

  const changeDate = (data: any) => {
    setSelectedMonth(data);
  };

  useEffect(() => {
    const monthDatesWithEvents = getMonthDatesWithEvents(selectedMonth, monthEvents);

    changeDaysOfMonth(monthDatesWithEvents);
  }, [selectedMonth, monthEvents]);

  const attendanceContentClassName = classNames('attendance-content has-horizontal-gradient', {
    'has-left-gradient': !isReachedLeft,
    'has-right-gradient': !isReachedRight
  });

  return (
    <div className="attendance-brief card">
      <Header date={selectedMonth} changeDate={changeDate} eventTypes={EVENTS_TYPE} />
      <div className="row attendance-container">
        <div className={attendanceContentClassName}>
          <PerfectScrollbar
            className="perfect-scrollbar calendarLine-scrollbar-paddingBottom"
            onXReachStart={removeLeftGradient}
            onXReachEnd={removeRightGradient}
            onScrollX={toggleGradient}
            ref={calendarRef}
            component="div"
          >
            <CalendarLine
              isReachedLeft={isReachedLeft}
              isReachedRight={isReachedRight}
              monthData={daysOfMonth}
              onDaySelect={setSelectedDay}
              selectedDate={selectedDay}
            />
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

export default WithGradient(TestCalendar);
