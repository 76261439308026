import React, { useState, useContext, useEffect } from 'react';
import RootStore from 'store';

import { getUpdatedSortedData } from 'components/common/ColumnValueInTable/helpers';
import { getFilterPanelView } from 'components/TestResults/SortPanel/helper';
import { IFiltersPanelProps } from 'components/TestResults/SortPanel/types';

import ColumnValueInTable from 'components/common/ColumnValueInTable';

const FiltersPanel = (props: IFiltersPanelProps) => {
  const { openModal, isAdmin } = props;

  const { testResults, authenticationStore, serviceStore: { isMobile } } = useContext(RootStore);
  const { user } = authenticationStore;
  const { updateSortedRequest } = testResults;

  const [sortedData, updateSortedData] = useState([]);

  const selectedFilter = (titleSort: string) => {
    const updatedSortData = getUpdatedSortedData(sortedData, titleSort);

    updateSortedData(updatedSortData);
    updateSortedRequest(updatedSortData);
  };

  useEffect(() => {
    const filterPanelView = getFilterPanelView(user.role);

    updateSortedData(filterPanelView);
  }, [user.role]);

  return (
    <div className="filter-panel">
      {sortedData.map((sortingValues) => (
        <ColumnValueInTable {...sortingValues} selectedFilter={selectedFilter} key={`Filter${sortingValues.title}`} />
      ))}
      {isAdmin && !isMobile && (
        <div className="d-flex flex-grow-1 justify-content-end">
          <div className="filter-button-group">
            <button className="btn-default" onClick={() => openModal(true)} type="button">
              + Add New
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersPanel;
