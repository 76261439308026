export const MONTH_DATA = [
  {
    monthName: 'Jan',
    monthNumber: '01'
  },
  {
    monthName: 'Feb',
    monthNumber: '02'
  },
  {
    monthName: 'Mar',
    monthNumber: '03'
  },
  {
    monthName: 'Apr',
    monthNumber: '04'
  },
  {
    monthName: 'May',
    monthNumber: '05'
  },
  {
    monthName: 'Jun',
    monthNumber: '06'
  },
  {
    monthName: 'Jul',
    monthNumber: '07'
  },
  {
    monthName: 'Aug',
    monthNumber: '08'
  },
  {
    monthName: 'Sep',
    monthNumber: '09'
  },
  {
    monthName: 'Oct',
    monthNumber: '10'
  },
  {
    monthName: 'Nov',
    monthNumber: '11'
  },
  {
    monthName: 'Dec',
    monthNumber: '12'
  }
];

export const FIRST_DAY_OF_MONTH = '01';

export const CLASS_ACTIVE = 'rdtActive';
