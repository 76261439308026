import { ITeacherType } from 'components/Groups/interfaces';

export const DEFAULT_LESSON_TYPE: ITeacherType = {
  id: null,
  name: null,
  value: null
};

export const DRAG_TYPE_STUDENT: string = 'student';
export const DRAG_TYPE_TEACHER: string = 'teacher';
