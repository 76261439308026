import { MonthEventsType } from 'types/calendarTypes';

export const getStatusEvent = (dayEvent: MonthEventsType, isStudent: boolean) => {
  if (!dayEvent) {
    return { style: { pointerEvents: 'none' } };
  }
  const { type, attendance } = dayEvent;

  if (isStudent) {
    const typeEvent = attendance ? type : 'absent';

    return { type: typeEvent };
  }

  return { type };
};

export const getEventsStatuses = (dayEvents: MonthEventsType[]) => dayEvents.reduce(
  (accumulator: string[], event: MonthEventsType) => {
    const eventsStatuses = accumulator;
    const { type } = event;

    const hasKey = eventsStatuses.every((status) => status !== type);

    if (hasKey) {
      eventsStatuses.push(type);
    }

    return eventsStatuses;
  }, []
);

