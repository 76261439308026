export const validTestResult = (values: any) => {
  const errors: any = {};

  if (!values.recommended_english_level_id) {
    errors.recommended_english_level_id = 'Selected recommended level';
  }

  if (!values.speaking_english_level_id) {
    errors.speaking_english_level_id = 'Selected speaking level';
  }

  if (!values.writing_english_level_id) {
    errors.writing_english_level_id = 'Selected writing level';
  }

  return errors;
};
