import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import withModal from 'hocs/WithModal';

import { DetailEventType } from './interfaces';

const ModalContainer = (props: DetailEventType) => {
  const {
    isOpen, header, body, footer, additionalClassName
  } = props;

  const modalClassName = classnames('modal', additionalClassName);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={modalClassName}>
      {header && <div className="modal-header">{header}</div>}
      <PerfectScrollbar className="perfect-scrollbar modal-body">{body}</PerfectScrollbar>
      <div className="modal-footer">{footer}</div>
    </div>
  );
};

export default withModal(ModalContainer);
