import React, { useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';

import { formFields } from 'components/common/FormComponent/formFields';
import classNames from 'classnames';

import { ILessonFormProps, ILessonFormValues } from './interface';
import { ISelectValues } from '../../AddEvent/interface';
import { eventFormFields, days } from '../../AddEvent/constants';
import { getOptionsForSelect } from '../../AddEvent/helpers';

const LessonForm: React.FC<ILessonFormProps & FormikProps<ILessonFormValues>> = (
  props: ILessonFormProps & FormikProps<ILessonFormValues>
) => {
  const {
    handleSubmit, setFieldTouched, setFieldValue, setIsFormValid, studyGroups, initialValues, editMode, errors
  } = props;

  const [group, setGroup] = useState(initialValues.groupId || null);
  const [beginDate, setBeginDate] = useState(initialValues.beginDate || null);
  const [endDate, setEndDate] = useState(initialValues.beginDate || null);
  const [day, setDay] = useState(initialValues.day || null);
  const [beginTime, setBeginTime] = useState(initialValues.beginTime || null);
  const [endTime, setEndTime] = useState(initialValues.endTime || null);

  useEffect(() => {
    const isValid = Boolean(group && beginDate && endDate && day && beginTime && endTime)
      && !Object.keys(errors).length;

    setIsFormValid(isValid);
  }, [group, beginDate, endDate, day, beginTime, endTime, errors]);

  const beginDateClassName = classNames('col-xs-12 modal-col', {
    'col-sm-6': !editMode,
    'col-sm-12': editMode
  });

  const onDayChange = (data: ISelectValues[]) => {
    const serializedDay = data && data.reduce((result, item) => {
      result.value.push(item.value);
      return result;
    }, { label: eventFormFields.DAY, value: [] });

    setDay(data);
    const value = serializedDay && serializedDay.value;
    setFieldValue(eventFormFields.DAY, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={eventFormFields.GROUP}
            placeholder="Group"
            component={formFields.FormSelect}
            options={getOptionsForSelect(studyGroups)}
            value={group}
            onBlur={() => setFieldTouched(eventFormFields.GROUP)}
            onChange={(data: ISelectValues) => {
              setGroup(data);
              setFieldValue(eventFormFields.GROUP, data.value);
            }}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className={beginDateClassName}>
          <Field
            name={eventFormFields.BEGIN_DATE}
            placeholder="Begin date"
            component={formFields.FormDatePicker}
            views={['date']}
            value={beginDate}
            onBlur={() => setFieldTouched(eventFormFields.BEGIN_DATE)}
            onChange={(data: Date) => {
              setBeginDate(data);
              setFieldValue(eventFormFields.BEGIN_DATE, data);
            }}
          />
        </div>
        {!editMode
          && (
          <div className="col-xs-12 col-sm-6 modal-col">
            <Field
              name={eventFormFields.END_DATE}
              placeholder="End date"
              component={formFields.FormDatePicker}
              views={['date']}
              value={endDate}
              minDate={beginDate}
              isDisabled={!beginDate}
              onBlur={() => setFieldTouched(eventFormFields.END_DATE)}
              onChange={(data: Date) => {
                setEndDate(data);
                setFieldValue(eventFormFields.END_DATE, data);
              }}
            />
          </div>
          )
        }
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field
            name={eventFormFields.DAY}
            placeholder="Day"
            component={formFields.FormSelect}
            value={day}
            options={days}
            onBlur={() => setFieldTouched(eventFormFields.DAY)}
            onChange={onDayChange}
            isMulti
            closeMenuOnSelect={false}
          />
        </div>
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field name="room" placeholder="Room" component={formFields.FormInput} />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-6 modal-col">
          <Field
            name={eventFormFields.BEGIN_TIME}
            placeholder="Start"
            component={formFields.FormTimePicker}
            value={beginTime}
            onBlur={() => setFieldTouched(eventFormFields.BEGIN_TIME)}
            onChange={(data: Date) => {
              setBeginTime(data);
              setFieldValue(eventFormFields.BEGIN_TIME, data);
            }}
          />
        </div>
        <div className="col-xs-6 modal-col">
          <Field
            name={eventFormFields.END_TIME}
            placeholder="End"
            component={formFields.FormTimePicker}
            value={endTime}
            onBlur={() => setFieldTouched(eventFormFields.END_TIME)}
            onChange={(data: Date) => {
              setEndTime(data);
              setFieldValue(eventFormFields.END_TIME, data);
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default LessonForm;
