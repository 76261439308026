import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Main from 'components/Main';
import Login from 'components/Authentication/Login';
import RestorePassword from 'components/Authentication/RestorePassword';
import NotFound404 from 'components/StaticPages/NotFound404';
import Forbidden403 from 'components/StaticPages/Forbidden403';
import ServerError500 from 'components/StaticPages/ServerError500';
import routes from 'constants/routes';

const MainRouter: React.FC = () => (
  <Switch>
    <Route path={routes.LOGIN_ROUTE} component={Login} />
    <Route path={routes.RESTORE_PASSWORD} component={RestorePassword} />
    <Route path={routes.FORBIDDEN} component={Forbidden403} />
    <Route path={routes.PAGE_NOT_FOUND} component={NotFound404} />
    <Route path={routes.SERVER_ERROR} component={ServerError500} />
    <Route path="/" component={Main} />
    <Redirect from="*" to={routes.PAGE_NOT_FOUND} />
  </Switch>
);

export default MainRouter;
