import React from 'react';
import classNames from 'classnames';

import { format } from 'constants/date';
import { updateFormatDate } from 'helpers/actionsWithDates';

import Status from 'components/common/Status';

import { ICalendarDayProps } from './interface';

const CalendarDay: React.FC<ICalendarDayProps> = (props: ICalendarDayProps) => {
  const {
    fullDate, isActive, eventsTypes, onDaySelect, isCurrent
  } = props;

  const dayClassName = classNames('test-calendar-cell', {
    'is-clickable': eventsTypes.length,
    'is-active': isActive,
    'is-current': isCurrent
  });

  const dayData = updateFormatDate(fullDate, format.DAY);
  const dayOfWeek = updateFormatDate(fullDate, format.DAY_OF_WEEK);

  const statuses = eventsTypes.map((eventType) => {
    const key = `${fullDate}${eventType}`;

    return <Status status={eventType} key={key} />;
  });

  const onClick = () => {
    if (!eventsTypes.length) { return; }
    onDaySelect(fullDate);
  };

  return (
    <div
      tabIndex={0}
      onKeyPress={onClick}
      role="button"
      className={dayClassName}
      onClick={onClick}
    >
      <div className="test-calendar-item">
        <span className="test-calendar-day-month">{dayOfWeek}</span>
        <span className="test-calendar-day">{dayData}</span>
        <div className="calendar-status">
          {statuses}
        </div>
      </div>
    </div>
  );
};

export default CalendarDay;
