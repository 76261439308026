import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import { DRAG_STUDENT_TYPE } from 'components/TestSchedule/constants';
import User from 'components/common/User';
import StudentsImg from 'components/Calendar/EventsTable/Events/TableBody/Event/StudentsImg';
import RootStore from 'store';
import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';
import EmptyCard from 'components/common/DrugAndDrop/EmptyCard';
import StudentsSelectionMobile from 'components/common/MobileUsersSelect/StudentsSelection';

import { IEvent, IStudentDropCard, StudentsListType } from '../../interfaces';
import { IStudentData } from '../../../Students/interface';

const DEFAULT_WRITING_TEST_CAPACITY = 32;

const WritingScheduleItem: React.FC<IEvent> = ({ event }: IEvent) => {
  const {
    serviceStore: { isMobile },
    testSchedule: { addStudentToTest, deleteStudentFromTest, notAssignedStudents }
  } = useContext(RootStore);

  const {
    id, time, endTime, roomNumber, teacher, students
  } = event;

  const [isOpenDetailInfo, setOpenDetailInfo] = useState(true);

  const isStudentsCapacityAllowToAdd = students.length < DEFAULT_WRITING_TEST_CAPACITY;
  const placesLeft = DEFAULT_WRITING_TEST_CAPACITY - students.length;
  const isEmptyRowDisplay = Boolean(students.length && isStudentsCapacityAllowToAdd);

  const [{ isOver }, drop] = useDrop({
    accept: DRAG_STUDENT_TYPE,
    drop: ({ data }: IStudentDropCard) => {
      const isUserExistInTest = students.some((item: StudentsListType) => (
        item.userId === data.userId
      ));
      if (!isUserExistInTest && isStudentsCapacityAllowToAdd) {
        const params = {
          event_id: id,
          student_user_id: data.userId,
          student: data
        };

        addStudentToTest(params);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  const toggleDetailInfo = () => {
    setOpenDetailInfo(!isOpenDetailInfo);
  };

  const addStudent = (data: any) => {
    const {
      userData: { userId }
    } = data;
    const isUserExistInTest = students.some((item: StudentsListType) => (
      item.userId === data.userId
    ));
    if (!isUserExistInTest && isStudentsCapacityAllowToAdd) {
      const student = notAssignedStudents.find(item => item.userId === userId);
      const params = {
        event_id: id,
        student_user_id: userId,
        student
      };

      addStudentToTest(params);
    }
  };

  const onDelete = (student: StudentsListType) => {
    const { userId } = student;
    const params = {
      event_id: id,
      student_user_id: userId,
      student
    };

    deleteStudentFromTest(params);
  };

  const renderTime = () => (
    <div className="panel-time">
      <span className="panel-time-start">{time}</span>
      <span className="panel-time-end">{endTime}</span>
    </div>
  );

  const renderWritingInfo = () => (
    <div className="writing-info">
      <div className="writing-details">
        <span className="writing-label">Room:</span>
        <span className="writing-value">{roomNumber}</span>
      </div>
      <div className="writing-details">
        <span className="writing-label">Teacher:</span>
        <span className="writing-value">{teacher}</span>
      </div>
      <div className="writing-details">
        <span className="writing-label">Places left:</span>
        <span className="writing-value">{placesLeft}</span>
      </div>
      <div className="writing-details">
        <StudentsImg students={students} />
      </div>
    </div>
  );

  const renderPanelSummary = () => (
    <div className="panel-summary">
      <div className="panel-header">
        {renderTime()}
        {renderWritingInfo()}
        <div className="panel-navigation">
          <button className="btn-down" type="button" onClick={toggleDetailInfo}>
            {isOpenDetailInfo ? (
              <i className="material-icons accordion-arrow">keyboard_arrow_up</i>
            ) : (
              <i className="material-icons accordion-arrow">keyboard_arrow_down</i>
            )}
          </button>
        </div>
      </div>
    </div>
  );

  const renderFilled = (student: IStudentData) => {
    const { userId, fullName, writingEnglishLevelName, photo } = student;

    return (
      <div className="panel-row" key={userId}>
        <div className="card-user">
          <div className="panel-content">
            <div className="panel-item w-40">
              <User hasRole={false} userIcon={photo} userName={fullName} />
            </div>
            <div className="panel-item">{writingEnglishLevelName}</div>
            <div className="panel-navigation">
              <button
                className="panel-action dangerous"
                type="button"
                onClick={() => onDelete(student)}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const studentsItems = students.map(renderFilled);

  const desktopContent = (
    <>
      {studentsItems}
      {isEmptyRowDisplay && <EmptyRow />}
      {!studentsItems.length && <EmptyCard />}
    </>
  );

  const mobileContent = (
    <>
      {studentsItems}
      <StudentsSelectionMobile
        addStudent={addStudent}
        students={notAssignedStudents}
        canSelect={isStudentsCapacityAllowToAdd}
      />
    </>
  );

  const content = isMobile ? mobileContent : desktopContent;

  const scheduleCardClassNames = classNames('card test-schedule-card', {
    'is-dragging': isOver
  });

  return (
    <div className="accordion-thumb">
      {renderPanelSummary()}

      {isOpenDetailInfo && (
      <div className="panel-details">
        <div ref={drop} className={scheduleCardClassNames}>
          {content}
        </div>
      </div>
      )}
    </div>
  );
};

export default observer(WritingScheduleItem);
