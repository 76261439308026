import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';

import { getBackend, getBackendOptions } from 'components/common/DrugAndDrop/service';
import RootStore from 'store';
import { ITeacherType } from 'components/Groups/interfaces';
import Header from 'components/common/Header';
import ButtonsGroup from 'components/common/BtnsGroup';
import DeleteStudentFromGroup from 'components/Modals/Student/DeleteStudentFromGroup';

import TeacherList from './TeacherList';
import StudentList from './StudentList';
import GroupList from './GroupList';

const AddToGroup: React.FC = () => {
  const { addToGroupStore, serviceStore: { isMobile } } = useContext(RootStore);
  const {
    getData,
    getLessonsTypes,
    setSelectedLessonType,
    lessonsTypes,
    selectedLessonType,
    isOpenModalDelete,
    setModalData,
    modalData,
    deleteStudentFromGroup
  } = addToGroupStore;

  useEffect(() => {
    getLessonsTypes();
  }, []);

  useEffect(() => {
    if (selectedLessonType.id) {
      getData();
    }
  }, [selectedLessonType]);

  const setLessonType = (name: string, data: ITeacherType) => {
    setSelectedLessonType(data);
  };

  const closeModal = () => {
    setModalData();
  };

  return (
    <>
      <Header pageTitle="Add to Group">
        <div className="title-content">
          <ButtonsGroup
            data={lessonsTypes}
            selectedValue={selectedLessonType.name}
            updateData={setLessonType}
          />
        </div>
      </Header>
      <div className="page-content">
        <div className="groups">
          <DndProvider backend={getBackend(isMobile)} options={getBackendOptions(isMobile)}>
            <div className="page-col d-flex flex-column">
              <TeacherList />
              <StudentList />
            </div>
            <div className="page-col is-stretched d-flex flex-column">
              <GroupList />
            </div>
          </DndProvider>
        </div>
      </div>
      {isOpenModalDelete && (
        <DeleteStudentFromGroup
          isOpen={isOpenModalDelete}
          closeModal={closeModal}
          studentData={modalData}
          deleteStudentFromGroup={deleteStudentFromGroup}
        />
      )}
    </>
  );
};

export default observer(AddToGroup);
