import React from 'react';

interface Type {
  className: string,
  children: string,
  disabled: boolean,
}

function Button(props: Type) {
  const {
    className = 'btn-default btn-wide', children, ...restProps
  } = props;
  return (
    <button
      className={className}
      type="submit"
      {...restProps}
    >
      {children}
    </button>
  );
}

export default Button;
