import { errorMassage } from 'constants/forms';
import { phone } from 'services/patterns';

export default (values: any) => {
  const errors: any = {};

  if (!values.skype) {
    errors.skype = errorMassage.default;
  }

  if (!values.phone) {
    errors.phone = errorMassage.default;
  }

  if (values.phone) {
    const isPhoneHasInvalidFormat = !phone.test(values.phone);

    if (isPhoneHasInvalidFormat) {
      errors.phone = errorMassage.phone;
    }
  }

  return errors;
};
