import React, { useState } from 'react';
import { throttle } from 'lodash';

import { IGradientData } from './interface';

const withGradient = <T extends object>(
  Component: React.ComponentType<T & IGradientData>
) => {
  const WithGradient: React.FC<T> = (props: any) => {
    const [gradientInitialized, setInitialized] = useState(false);
    const [isReachedTop, setIsReachedTop] = useState(true);
    const removeTopGradient = () => setIsReachedTop(true);

    const [isReachedBottom, setIsReachedBottom] = useState(false);
    const removeBottomGradient = () => setIsReachedBottom(true);

    const [isReachedLeft, setIsReachedLeft] = useState(true);
    const removeLeftGradient = () => setIsReachedLeft(true);

    const [isReachedRight, setIsReachedRight] = useState(false);
    const removeRightGradient = () => setIsReachedRight(true);

    const toggleXAxis = () => {
      if (isReachedLeft) {
        setIsReachedLeft(false);
      }

      if (isReachedRight) {
        setIsReachedRight(false);
      }
    };

    const toggleYAxis = () => {
      if (isReachedTop) {
        setIsReachedTop(false);
      }

      if (isReachedBottom) {
        setIsReachedBottom(false);
      }
    };

    const toggleGradient = () => {
      setInitialized(true);
      toggleXAxis();
      toggleYAxis();
    };

    const DELAY = 1000;
    const throttledToggleGradient = throttle(toggleGradient, DELAY);
    const throttledTToggleXAxis = throttle(toggleXAxis, DELAY);
    const throttledTToggleYAxis = throttle(toggleYAxis, DELAY);

    const gradientData = {
      toggleGradient: throttledToggleGradient,
      toggleXAxis: throttledTToggleXAxis,
      toggleYAxis: throttledTToggleYAxis,
      top: {
        isReachedTop,
        removeTopGradient
      },
      bottom: {
        isReachedBottom,
        removeBottomGradient
      },
      left: {
        isReachedLeft,
        removeLeftGradient
      },
      right: {
        isReachedRight,
        removeRightGradient
      },
      gradientInitialized
    };

    return <Component {...gradientData} {...props} />;
  };

  return WithGradient;
};

export default withGradient;
