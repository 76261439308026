import React from 'react';

import { IFormTextareaProps } from './interface';

export default ({ field, form: { touched, errors }, ...props }: IFormTextareaProps) => {
  const { label, id, rows = 5 } = props;
  // @ts-ignore
  const error = errors[field.name];
  // @ts-ignore
  const fieldTouchedHasError = touched[field.name] && error;

  return (
    <div className="form-group">
      <div className="textarea">
        {label && (
          <label className="textarea-label" htmlFor={id}>
            {label}
          </label>
        )}
        <textarea className="textarea-control" {...field} {...props} rows={rows} />
      </div>
      {fieldTouchedHasError && <span className="form-group-error">{error}</span>}
    </div>
  );
};
