import React from 'react';

import { TableHeaderType } from 'components/Calendar/EventsTable/interface';

import GroupName from './GroupName';

const GroupNames = (props: TableHeaderType) => {
  const { scrollX, dayEvents } = props;

  if (dayEvents.length === 0) {
    return <GroupName groupName="No Events" scrollX={scrollX} />;
  }

  return (
    <>
      {dayEvents.map((dayEvent) => {
        const { group, id } = dayEvent;

        return <GroupName groupName={group} scrollX={scrollX} key={id} />;
      })}
    </>
  );
};

export default GroupNames;
