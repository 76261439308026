import React from 'react';
import { observer } from 'mobx-react';

import Container from 'components/Modals/ModalContainer';
import * as INTERFACES from '../interfaces';

const RemoveStudentModal: React.FC<INTERFACES.IDeleteStudentModalProps> = observer(
  (props: INTERFACES.IDeleteStudentModalProps) => {
    const {
      student, toggleModal, deleteStudent, isOpen
    } = props;
    const { id, name, surname } = student;

    const onDeleteStudent = async () => {
      await deleteStudent(id);
      toggleModal();
    };

    const header = (
      <>
        <div className="modal-title">Delete user from Study Group</div>
        <button className="modal-close" type="button" onClick={() => toggleModal()}>
          <i className="material-icons">clear</i>
        </button>
      </>
    );

    const body = (
      <div className="container modal-container">
        {`Are you sure you want to delete student ${name} ${surname}?`}
      </div>
    );

    const footer = (
      <div className="inputs-group">
        <div className="inputs-group-item">
          <button className="btn-border" type="button" onClick={() => toggleModal()}>
            Back
          </button>
        </div>
        <div className="inputs-group-item">
          <button className="btn-danger" type="button" onClick={onDeleteStudent}>
            Delete
          </button>
        </div>
      </div>
    );

    return <Container header={header} body={body} footer={footer} isOpen={isOpen} toggleModal={toggleModal} />;
  }
);

export default RemoveStudentModal;
