import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { IGradientData } from 'hocs/WithGradient/interface';
import WithGradient from 'hocs/WithGradient';

import SpeakingScheduleItem from './SpeakingScheduleItem';
import { IScheduleProps } from '../../interfaces';
import EmptyState from '../../../common/EmptyState';

const SpeakingSchedule: React.FC<IScheduleProps & IGradientData> = (
  props: IScheduleProps & IGradientData
) => {
  const { bottom, top, toggleGradient, events } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const testEventClassName = classNames('test-schedule-events', {
    'has-bottom-gradient': isReachedBottom,
    'has-top-gradient': isReachedTop,
    'without-gradient': isReachedTop && isReachedBottom
  });

  useEffect(() => {
    toggleGradient();
  }, [events]);

  const schedules = events.map((event) => (
    <SpeakingScheduleItem event={event} key={event.id} />
  ));

  return (
    <div className={testEventClassName}>
      <PerfectScrollbar
        className="perfect-scrollbar"
        onYReachStart={removeTopGradient}
        onYReachEnd={removeBottomGradient}
        onScrollY={toggleGradient}
      >
        {schedules.length ? (
          <div className="test-schedule-inner">{schedules}</div>
        ) : (
          <EmptyState
            icon="sentiment_dissatisfied"
            title="No events planned yet"
            additionalClassName="card has-spacings"
          />
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default WithGradient(SpeakingSchedule);
