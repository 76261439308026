import moment from 'moment';

import { isDifferenceBetweenDatesInvalid, isTimeNotInRange } from 'services/validation';

import { ILessonFormValues } from '../components/Lesson/interface';
import { getName, formatTime, formatDate } from '../AddEvent/helpers';
import {
  INVALID_BEGIN_TIME,
  INVALID_END_TIME,
  INVALID_RANGE_BETWEEN_DATES
} from '../../../../constants/validationMessages';

export const serializeLessonData = (data: ILessonFormValues) => ({
  event_id: data.id,
  study_group_id: data.groupId.value,
  room: data.room,
  begin_time: formatTime(data.beginTime),
  end_time: formatTime(data.endTime),
  date: formatDate(data.beginDate)
});

export const serializeTestData = (data: any, type: string) => {
  const name = getName(data, type);

  return {
    event_id: data.id,
    name,
    event_parent_id: data.eventParent.value,
    teacher_user_id: data.teacherUserId.value,
    date: formatDate(data.date),
    room: data.room,
    begin_time: formatTime(data.beginTime),
    end_time: formatTime(data.endTime),
    event_level_id: data.eventLevel.value,
    event_level_name: data.eventLevel.label
  };
};

export const validLesson = (values: any) => {
  const errors: any = {};

  if (!values.groupId) {
    errors.groupId = 'Select group';
  }

  if (!values.beginDate) {
    errors.beginDate = 'Enter begin date';
  }

  if (!values.day) {
    errors.day = 'Enter day';
  }

  if (!values.room) {
    errors.room = 'Enter room';
  }

  if (!values.beginTime) {
    errors.beginTime = 'Enter begin time';
  }

  if (!values.endTime) {
    errors.endTime = 'Enter end time';
  }

  if (values.beginTime && isTimeNotInRange(values.beginTime)) {
    errors.beginTime = INVALID_BEGIN_TIME;
  }

  if (values.endTime && isTimeNotInRange(values.endTime)) {
    errors.endTime = INVALID_END_TIME;
  }

  if (moment(values.beginTime).isAfter(values.endTime)) {
    errors.endTime = 'End time can\'t be less then start time';
  }

  if (isDifferenceBetweenDatesInvalid(values.beginTime, values.endTime)) {
    errors.endTime = INVALID_RANGE_BETWEEN_DATES;
  }

  return errors;
};
