import React from 'react';

import ConfirmationModal from 'components/Modals//ConfirmationModal';

import { IModalProps } from '../../interfaces';
import { IDeleteEventProps } from './interface';

const DeleteEvent: React.FC<IModalProps & IDeleteEventProps> = (props: IModalProps & IDeleteEventProps) => {
  const { isOpen, closeModal, deleteEvent } = props;

  const onDelete = () => {
    deleteEvent();
    closeModal();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Delete Event"
      text="Are you sure you want to delete this event?"
      toggleModal={closeModal}
      onSubmit={onDelete}
      okText="Delete"
      cancelText="Back"
    />
  );
};

export default DeleteEvent;
