import { normalizeFilterData } from 'helpers/select';

/* eslint camelcase: 0 */

export const normalizeTestResultsData = (requestData) => {
  const { data } = requestData;

  const {
    fluency,
    accuracy,
    vocabulary,
    speaking_grammar,
    communicativeness,
    speaking_english_level_id,
    speaking_english_level_name,

    listening,
    reading,
    writing,
    writing_grammar,
    essay,
    paraphrase,
    writing_english_level_name,
    writing_english_level_id,

    holding,
    comment,
    recommended_english_level_id,
    recommended_english_level_name,

    student_user_id,
    student_user_name,
    student_user_surname,
    study_group_id,
    study_group_name,
    test_result_id,
    previous_test_result_id,

    event_level_id,
    event_level_name,
    event_parent_id,
    event_parent_name
  } = data;

  return {
    fluency,
    accuracy,
    vocabulary,
    speakingGrammar: speaking_grammar,
    communicativeness,
    speakingEnglishLevelId: speaking_english_level_id,
    speakingEnglishLevelName: speaking_english_level_name,

    listening,
    reading,
    writing,
    essay,
    paraphrase,
    writingGrammar: writing_grammar,
    writingEnglishLevelName: writing_english_level_name,
    writingEnglishLevelId: writing_english_level_id,

    holding,
    comment,
    recommendedEnglishLevelId: recommended_english_level_id,
    recommendedEnglishLevelName: recommended_english_level_name,

    testResultId: test_result_id,
    studentUserId: student_user_id,
    studentUserName: student_user_name,
    studentUserSurname: student_user_surname,
    studyGroupId: study_group_id,
    studyGroupName: study_group_name,
    previousTestResultId: previous_test_result_id,

    eventParentId: event_parent_id,
    eventParentName: event_parent_name,
    eventLevelId: event_level_id,
    eventLevelName: event_level_name
  };
};

export const normalizeTestResultRequest = (requestData) => {
  const { data } = requestData;
  const { number_pages, data_page } = data;

  if (data_page) {
    return {
      numberPages: number_pages,
      dataPage: data_page.map((value) => normalizeTestResultsData({ data: value }))
    };
  }

  return {
    numberPages: null,
    dataPage: null
  };
};

export const normalizeTestNamesData = (requestData) => {
  const filteredData = requestData.filter((el) => el.id !== 1);

  return normalizeFilterData(filteredData);
};
