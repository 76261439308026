import React, { useState, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { IModalProps } from 'components/Modals/interfaces';
import withModal from 'hocs/WithModal';

interface Props extends IModalProps {
  src: string;
  onSelect: (image: string) => void;
}

const CropModal: React.FC<Props> = ({ src, toggleModal, onSelect }) => {
  const initialCrop: Crop = {
    unit: '%',
    width: 30,
    aspect: 1
  };
  const [crop, setCrop] = useState(initialCrop);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);

  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image;
  };

  const getCroppedImg = (image: HTMLImageElement, cropData: Crop, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropData.width;
    canvas.height = cropData.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      cropData.x * scaleX,
      cropData.y * scaleY,
      cropData.width * scaleX,
      cropData.height * scaleY,
      0,
      0,
      cropData.width,
      cropData.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(blob => {
        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg', 1);
    });
  };

  const makeClientCrop = async (cropData: Crop) => {
    if (imageRef.current && cropData.width && cropData.height) {
      const imageUrl = await getCroppedImg(
        imageRef.current,
        cropData,
        'newFile.jpeg'
      );

      setCroppedImageUrl(imageUrl);
    }
  };

  const onCropComplete = (cropData: Crop) => {
    makeClientCrop(cropData);
  };

  const onCropChange = (cropData: Crop) => {
    setCrop(cropData);
  };

  const saveFile = async () => {
    onSelect(croppedImageUrl);
    toggleModal();
  };

  return (
    <div className="crop-modal">
      <ReactCrop
        src={src}
        crop={crop}
        ruleOfThirds
        onImageLoaded={onImageLoaded}
        onComplete={onCropComplete}
        onChange={onCropChange}
      />
      <div>
        <button
          className="accordion-button-edit"
          type="button"
          onClick={saveFile}
        >
          Ok
        </button>
        <button
          className="accordion-button-edit"
          type="button"
          onClick={() => { toggleModal(); }}
        >
         Cancel
        </button>
      </div>
    </div>
  );
};

export default withModal(CropModal);
