import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { deleteTestResult } from 'api/testResult';

import { IDeleteTestResultProps } from './interface';
import { successNotification } from '../../common/Notifications/helper';
import ConfirmationModal from '../ConfirmationModal';

const DeleteTestResult = observer((props: IDeleteTestResultProps) => {
  const { isOpen, toggleModal } = props;
  const { testResults, modalsAddTestResult } = useContext(RootStore);
  const { deleteTestResultData } = testResults;
  const { getSelectsData } = modalsAddTestResult;

  if (!deleteTestResultData) { return null; }

  const { studentUserSurname, studentUserName } = deleteTestResultData;

  const msg = `Are you sure you want to delete the test results of ${studentUserSurname} ${studentUserName}?`;

  const closeModal = () => {
    toggleModal(false);
  };

  const deleteResult = async (): Promise<void> => {
    try {
      await deleteTestResult({ test_result_id: deleteTestResultData.testResultId });
      await getSelectsData();

      closeModal();
      successNotification('Test Result was successfully deleted');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Delete Test Result"
      text={msg}
      toggleModal={closeModal}
      onSubmit={deleteResult}
      okText="Delete"
      cancelText="Back"
    />
  );
});

export default DeleteTestResult;
