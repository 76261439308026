import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import User from 'components/common/User';
import { roleMapper } from 'constants/roles';
import RootStore from 'store';
import { renderNavLinkElement, renderNavigationList } from './NavigationList';
import { INavigationItem } from './interfaces';
import * as navigationLists from './navigationItemsList';
import { setNavigationItemsList } from './services';

function NavigationMenu(): JSX.Element {
  const { authenticationStore } = useContext(RootStore);
  const { logoutRequest, UserData } = authenticationStore;
  const { surname, name, role } = UserData;
  const navigationItemsToRender: INavigationItem[] = setNavigationItemsList(UserData.role);
  const userRole: string = roleMapper[role];

  return (
    <nav className="nav">
      <PerfectScrollbar
        className="perfect-scrollbar flex-grow-1"
        options={{ suppressScrollX: true }}
      >
        <ul className="nav-list">{renderNavigationList(navigationItemsToRender)}</ul>
      </PerfectScrollbar>
      <ul className="nav-list">
        <li className="nav-list-item">
          <span
            tabIndex={0}
            onKeyPress={logoutRequest}
            role="button"
            onClick={logoutRequest}
            className="nav-list-item-link"
          >
            <i className="nav-list-item-icon material-icons">
              {navigationLists.navigationItems.logOut.iconName}
            </i>
            {navigationLists.navigationItems.logOut.name}
          </span>
        </li>
        {renderNavLinkElement(navigationLists.navigationItems.settings)}
        <User hasRole userName={`${name} ${surname}`} userRole={userRole} userProfile />
      </ul>
    </nav>
  );
}

export default observer(NavigationMenu);
