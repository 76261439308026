import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { deleteTeacher } from 'api/teachers';

import { successNotification } from '../../common/Notifications/helper';
import ConfirmationModal from '../ConfirmationModal';

const DeleteTestResult = observer(() => {
  const { teachers } = useContext(RootStore);
  const { isOpenModalDeleteTeacher, updateModalDeleteTeacherState, teacherInfo } = teachers;

  if (!teacherInfo) { return null; }

  const { name, surname, userId } = teacherInfo;
  const teacherFullName = `${surname} ${name}`;

  const closeModal = () => {
    updateModalDeleteTeacherState(false);
  };

  const deleteResult = async (): Promise<void> => {
    try {
      await deleteTeacher({ teacher_user_id: userId });

      updateModalDeleteTeacherState(false);
      successNotification('Teacher was successfully deleted');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpenModalDeleteTeacher}
      title="Delete Teacher"
      text={`Are you sure you want to delete ${teacherFullName}?`}
      toggleModal={closeModal}
      onSubmit={deleteResult}
      okText="Delete"
      cancelText="Back"
    />
  );
});

export default DeleteTestResult;
