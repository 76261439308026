import React from 'react';

import { ILabelProps } from 'components/Calendar/Label/interface';

const Label: React.FC<ILabelProps> = (props: ILabelProps) => {
  const { status, label } = props;

  const statusLabel = label || status;

  return <div className={`label ${status}`}>{statusLabel}</div>;
};

export default Label;
