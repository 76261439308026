import React from 'react';

import User from 'components/common/User';

import { IGroupLead } from '../interface';

const GroupLead: React.FC<IGroupLead> = (props: IGroupLead) => {
  const { fullName, photo } = props;

  return (
    <div className="panel-row">
      <div className="card-user">
        <div className="panel-content">
          <div className="panel-item w-100">
            <User hasRole={false} userIcon={photo} userName={fullName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupLead;
