import React from 'react';
import { observer } from 'mobx-react';

import FormComponent from 'components/common/FormComponent';

import { IMainEventFormValues, IMainEventProps } from './interface';
import { eventFormFields } from '../../AddEvent/constants';
import MainEventForm from './MainEventForm';

const initialValues = {
  [eventFormFields.NAME]: ''
};

const MainEvent: React.FC<IMainEventProps> = observer(
  ({ setFormData, setIsFormValid }: IMainEventProps) => {
    const bindSubmitForm = (data: IMainEventFormValues) => {
      setFormData(data);
    };

    return (
      <FormComponent
        Component={MainEventForm}
        bindSubmitForm={bindSubmitForm}
        otherProps={{ setIsFormValid }}
        initialValues={initialValues}
        validate={(values: any) => {
          const errors: any = {};

          if (!values.name) {
            errors.name = 'Enter date';
          }

          return errors;
        }}
      />
    );
  }
);

export default MainEvent;
