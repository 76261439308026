import axios from 'axios';
import Qs from 'qs';
import { toast } from 'react-toastify';

import { loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from 'services/localStorage';
import {
  ACCESS_TOKEN, REFRESH_TOKEN, USER_DATA
} from 'constants/localStorage';
import history from 'services/history';
import routes from 'constants/routes';
import { removeNullFields } from 'services/helper';

import api from './index';

const serverUrl = process.env.REACT_APP_API_URL;

const refreshTokenAxios = axios.create();
refreshTokenAxios.defaults.baseURL = serverUrl;
refreshTokenAxios.defaults.headers.common['Content-Type'] = 'application/json';

const resetAuthData = () => {
  removeFromLocalStorage(ACCESS_TOKEN);
  removeFromLocalStorage(REFRESH_TOKEN);
  removeFromLocalStorage(USER_DATA);
};

const statusProcessor = (status) => {
  const currentRoute = window.location.pathname;

  switch (status) {
    case 401:
      if (currentRoute === routes.LOGIN_ROUTE) { break; }

      resetAuthData();
      history.replace(routes.LOGIN_ROUTE);
      break;
    case 403:
      if (currentRoute === routes.FORBIDDEN) { break; }
      history.replace(routes.FORBIDDEN);
      break;
    case 500:
      if (currentRoute === routes.SERVER_ERROR) { break; }

      history.replace(routes.SERVER_ERROR);
      break;
    default:
      break;
  }
};

const notificationErrorShow = (error, status) => {
  if ([422, 400, 405].includes(status)) {
    const message = error.response
      && error.response.data
      && error.response.data.errors
      && error.response.data.errors.description;

    toast.error(message || error.message);
  }
};

axios.interceptors.request.use(
  (config) => {
    const token = loadFromLocalStorage(ACCESS_TOKEN);

    if (!token) {
      return config;
    }

    const newConfig = {
      headers: {},
      paramsSerializer: Qs.stringify,
      ...config,
      params: removeNullFields(config.params)
    };

    newConfig.headers.Token = token;
    return newConfig;
  },
  (e) => Promise.reject(e)
);

axios.interceptors.response.use(
  (r) => r,
  async (error) => {
    const refreshToken = loadFromLocalStorage(REFRESH_TOKEN);
    const status = error && error.response && error.response.status;
    const retry = error && error.config && error.config.retry;

    notificationErrorShow(error, status);

    if (
      !refreshToken
      || status !== 401
      || retry
    ) {
      statusProcessor(status);

      throw error;
    }

    try {
      const refreshRequest = refreshTokenAxios.post(`${serverUrl}${api.REFRESH_TOKEN}`, {
        refresh_token: refreshToken
      });

      const { data } = await refreshRequest;
      const { access_token, refresh_token } = data;

      saveToLocalStorage(ACCESS_TOKEN, access_token);
      saveToLocalStorage(REFRESH_TOKEN, refresh_token);

      const newRequest = {
        ...error.config,
        retry: true
      };

      return axios(newRequest);
    } catch (e) {
      resetAuthData();
      window.location.reload();

      return e;
    }
  }
);

axios.defaults.baseURL = serverUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default (requestOptions) => axios(requestOptions);
