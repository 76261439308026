import React from 'react';

import ProgressBar from 'components/common/ProgressBar';

import { IEditTestResultProps } from '../interface';

const EditFormSpeakingTestResult: React.FC<IEditTestResultProps> = (props: IEditTestResultProps) => {
  const { initialTestResultData, setFieldValue } = props;

  const {
    accuracy, communicativeness, fluency, speakingGrammar, vocabulary
  } = initialTestResultData;

  return (
    <>
      <div className="panel-item w-25">
        <ProgressBar
          name="fluency"
          setFieldValue={setFieldValue}
          progressClass="progress-listen"
          progressValue={fluency}
          progressLabel="Fluency"
          disabled={false}
        />
        <ProgressBar
          name="accuracy"
          setFieldValue={setFieldValue}
          progressClass="progress-write"
          progressValue={accuracy}
          progressLabel="Accuracy"
          disabled={false}
        />
        <ProgressBar
          name="vocabulary"
          setFieldValue={setFieldValue}
          progressClass="progress-read"
          progressValue={vocabulary}
          progressLabel="Vocabulary"
          disabled={false}
        />
      </div>
      <div className="panel-item w-25">
        <ProgressBar
          name="speaking_grammar"
          setFieldValue={setFieldValue}
          progressClass="progress-read"
          progressValue={speakingGrammar}
          progressLabel="Grammar"
          disabled={false}
        />
        <ProgressBar
          name="communicativeness"
          setFieldValue={setFieldValue}
          progressClass="progress-red"
          progressValue={communicativeness}
          progressLabel="Communicativeness"
          disabled={false}
        />
      </div>
    </>
  );
};

export default EditFormSpeakingTestResult;
