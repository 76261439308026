import { toast } from 'react-toastify';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const successNotification = (message: string, name?: string) => {
  if (!name) {
    toast.success(message);
    return;
  }

  toast.success(`${capitalize(name)} ${message}`);
};
