import { IInitialData } from 'components/Modals/Admin/AddNew/Form/interface';

export const initialAddAdminKey = {
  PASSWORD: 'password',
  NAME: 'name',
  SURNAME: 'surname',
  USERNAME: 'username',
  EMAIL: 'email'
};

export const initialAddAdmin: IInitialData = {
  password: '',
  name: '',
  surname: '',
  username: '',
  email: ''
};
