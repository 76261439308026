import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import ProgressBarItem from '../ProgressBarItem';

import { LOW_LEVEL } from './constants';

interface Props {
  indexTestResult: number;
  isEdit: boolean;
}

const WritingResult = observer(
  (props: Props): JSX.Element => {
    const { indexTestResult, isEdit } = props;
    const { testResults } = useContext(RootStore);
    const { listTestResults, previousTestResults } = testResults;
    const {
      eventLevelId, listening, reading, writing, writingGrammar, essay, paraphrase
    } = listTestResults[
      indexTestResult
    ];

    const isDisable = !isEdit;

    let writingResults = [
      {
        progressClass: 'progress-listen',
        value: listening || 0,
        prevValue: previousTestResults ? previousTestResults.listening : null,
        label: 'Listening',
        name: 'listening',
        disabled: isDisable
      },
      {
        progressClass: 'progress-read',
        value: reading || 0,
        prevValue: previousTestResults ? previousTestResults.reading : null,
        label: 'Reading',
        name: 'reading',
        disabled: isDisable
      }
    ];

    const lowWritingResults = [
      {
        progressClass: 'progress-write',
        value: writingGrammar || 0,
        prevValue: previousTestResults ? previousTestResults.writingGrammar : null,
        label: 'Writing Grammar',
        name: 'writing_grammar',
        disabled: isDisable
      },
      {
        progressClass: 'progress-red',
        value: writing || 0,
        prevValue: previousTestResults ? previousTestResults.writing : null,
        label: 'Writing',
        name: 'writing',
        disabled: isDisable
      }
    ];

    const highWritingResults = [
      {
        progressClass: 'progress-write',
        value: paraphrase || 0,
        prevValue: previousTestResults ? previousTestResults.paraphrase : null,
        label: 'Paraphrase',
        name: 'paraphrase',
        disabled: isDisable
      },
      {
        progressClass: 'progress-red',
        value: essay || 0,
        prevValue: previousTestResults ? previousTestResults.essay : null,
        label: 'Essay',
        name: 'essay',
        disabled: isDisable
      }
    ];

    writingResults = eventLevelId === LOW_LEVEL
      ? [...writingResults, ...lowWritingResults]
      : [...writingResults, ...highWritingResults];

    return (
      <div className="panel-item accordion-separator w-20">
        {writingResults.map((writingResult) => {
          const { label } = writingResult;

          return <ProgressBarItem {...writingResult} key={label} />;
        })}
      </div>
    );
  }
);

export default WritingResult;
