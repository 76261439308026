import React, { useContext } from 'react';

import RootStore from 'store';

import { IFilterContainerProps } from './interfaces';
import FiltersContainerMobile from './FiltersContainerMobile';

const FiltersContainer:React.FC<IFilterContainerProps> = (props:IFilterContainerProps) => {
  const { serviceStore: { isMobile } } = useContext(RootStore);

  const { children, isOpen, toggleModal, onApply } = props;

  if (isMobile) {
    return (
      <FiltersContainerMobile isOpen={isOpen} toggleModal={toggleModal} onApply={onApply}>
        {children}
      </FiltersContainerMobile>
    );
  }

  return <>{children}</>;
};

export default FiltersContainer;
