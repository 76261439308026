import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import ReactSelect from 'components/common/FormFields/Selects';
import FiltersContainer from 'components/common/FiltersContainer';

type Props = {
  isMobileFiltersOpen: boolean;
  toggleMobileFilters: () => void;
  toggleAddNew: () => void;
}

const Filters: React.FC<Props> = (props) => {
  const { isMobileFiltersOpen, toggleMobileFilters, toggleAddNew } = props;
  const { departmentsStore, serviceStore: { isMobile } } = useContext(RootStore);
  const { filter, changeSelectedFilterDepartment, getDepartments } = departmentsStore;
  const { departments } = filter;

  return (
    <FiltersContainer
      onApply={getDepartments}
      isOpen={isMobileFiltersOpen}
      toggleModal={toggleMobileFilters}
    >

      <div className="inputs-group">
        {departments && (
          <div className="inputs-group-item">
            <ReactSelect
              additionalClassName="is-big"
              values={departments.options}
              selectedValue={departments.selected}
              updateData={changeSelectedFilterDepartment}
            />
          </div>
        )}
        {!isMobile && (
        <div className="inputs-group-item">
          <button className="btn-default" type="button" onClick={toggleAddNew}>
            + Add New Group
          </button>
        </div>
        )}
      </div>
    </FiltersContainer>
  );
};

export default observer(Filters);
