import { Filters } from 'types/filters';

export const TYPES_EVENTS = [
  {
    id: 1,
    label: 'Lessons',
    status: 'lesson'
  },
  {
    id: 2,
    label: 'Writing',
    status: 'writing'
  },
  {
    id: 3,
    label: 'Speaking',
    status: 'speaking'
  }
];

export const TYPES_EVENTS_FOR_STUDENT = [
  {
    id: 0,
    label: 'Absent',
    status: 'absent'
  },
  ...TYPES_EVENTS
];

export const DEFAULT_FILTER_DATA: Filters = {
  teachers: [],
  studyGroups: [],
  departments: [],
  departmentsGroups: [],
  student: []
};
