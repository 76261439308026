import React from 'react';

export default function EmptyCard() {
  return (
    <div className="card-drag">
      <div className="card-drag-icon">
        <i className="material-icons">person_add</i>
      </div>
      <div className="card-drag-title">Drag and Drop</div>
      <div className="card-drag-subtitle">drag student from waiting list drop here</div>
    </div>
  );
}
