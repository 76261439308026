import React, { useEffect } from 'react';
import { Field } from 'formik';

import { formFields } from 'components/common/FormComponent/formFields';
import { SELECT_OPTIONS_TYPE } from 'components/common/FormFields/Selects/constant';

import {
  STUDY_GROUP_TITLE,
  STUDENT_USERS_IDS,
  TEACHER_USER_ID,
  ENGLISH_LEVEL_ID
} from './constants';
import { IAddGroupFormProps } from './interfaces';
import { ISelectValues } from '../../interfaces';

const AddGroupForm = (props: IAddGroupFormProps) => {
  const {
    handleSubmit,
    teachersList,
    getTeachersList,
    getStudents,
    selectedTeacher,
    setSelectedTeacher,
    newGroupTitle,
    setNewGroupTitle,
    getEnglishLevels,
    levelsList,
    setLevel,
    selectedLevel,
    selectedTeacherType,
    onStudentsInputChange,
    selectedStudents,
    studentsList,
    studentsSearchString,
    setSelectedStudent,
    resetStudentsList,
    setFieldValue,
    resetForm,
    isNativeSpeaker,
    setIsFormValid,
    isValid
  } = props;

  const { id: selectedTeacherTypeId } = selectedTeacherType;

  const isStudentsFieldActive: boolean = isNativeSpeaker || !!selectedLevel;

  useEffect(() => {
    resetForm();
  }, [selectedTeacherTypeId]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  const onStudyGroupTitleChange = (event: any): void => {
    const { value } = event.target;

    setNewGroupTitle(value);
    setFieldValue(STUDY_GROUP_TITLE, value);
  };

  const onTeacherUserIdChange = (value: ISelectValues): void => {
    setSelectedTeacher(value);
    setFieldValue(TEACHER_USER_ID, value);
  };

  const onEnglishLevelIdChange = (value: ISelectValues): void => {
    setLevel(value);
    setFieldValue(ENGLISH_LEVEL_ID, value);
  };

  const onStudentUsersIdsChange = (value: ISelectValues[]): void => {
    setSelectedStudent(value);
    setFieldValue(STUDENT_USERS_IDS, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={STUDY_GROUP_TITLE}
            placeholder="Study Group Title"
            component={formFields.FormInput}
            value={newGroupTitle}
            onChange={onStudyGroupTitleChange}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={TEACHER_USER_ID}
            placeholder="Teacher"
            component={formFields.FormSelect}
            options={teachersList}
            value={selectedTeacher}
            onChange={onTeacherUserIdChange}
            onMenuOpen={getTeachersList}
            optionType={SELECT_OPTIONS_TYPE.WITH_PHOTO}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={ENGLISH_LEVEL_ID}
            placeholder={isNativeSpeaker ? 'Intermediate or higher' : 'Level'}
            component={formFields.FormSelect}
            options={levelsList}
            value={selectedLevel}
            onChange={onEnglishLevelIdChange}
            onMenuOpen={getEnglishLevels}
            isDisabled={isNativeSpeaker}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={STUDENT_USERS_IDS}
            placeholder="Students"
            component={formFields.FormSelect}
            options={studentsList}
            inputValue={studentsSearchString}
            value={selectedStudents}
            onChange={onStudentUsersIdsChange}
            onInputChange={onStudentsInputChange}
            onMenuOpen={getStudents}
            onMenuClose={resetStudentsList}
            isDisabled={!isStudentsFieldActive}
            isMulti
            optionType={SELECT_OPTIONS_TYPE.MULTI}
          />
        </div>
      </div>
    </form>
  );
};

export default AddGroupForm;
