/* eslint-disable camelcase */
import { IFormData } from 'components/TestResults/GeneralInfoTestResult/interface';

export const updateFormaData = (data: IFormData) => {
  const {
    accuracy,
    comment,
    communicativeness,
    essay,
    fluency,
    holding,
    listening,
    paraphrase,
    reading,
    recommended_english_level_id,
    speaking_english_level_id,
    speaking_grammar,
    test_result_id,
    vocabulary,
    writing,
    writing_english_level_id,
    writing_grammar
  } = data;

  return {
    accuracy,
    comment,
    communicativeness: communicativeness || null,
    essay,
    fluency,
    holding,
    listening,
    paraphrase,
    reading,
    recommendedEnglishLevelId: recommended_english_level_id,
    speakingEnglishLevelId: speaking_english_level_id,
    speakingGrammar: speaking_grammar,
    testResultId: test_result_id,
    vocabulary,
    writing,
    writingEnglishLevelId: writing_english_level_id,
    writingGrammar: writing_grammar
  };
};
