import React from 'react';

import { IDetailInfoProps } from './interfaces';

import GeneralInformation from './GeneralInformation';
import GroupData from './GroupData';

const DetailInfo: React.FC<IDetailInfoProps> = (props: IDetailInfoProps) => {
  const {
    groupId, groupName, timeTables, students, teacher, emptySeats
  } = props;

  return (
    <div className="panel-details">
      <div className="group">
        <div className="group-card">
          <GeneralInformation timeTables={timeTables} />
          <GroupData
            students={students}
            teacher={teacher}
            emptySeats={emptySeats}
            groupId={groupId}
            groupName={groupName}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailInfo;
