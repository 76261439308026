import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import withModal from 'hocs/WithModal';
import userPicDefault from 'assets/img/face-01.png';

import { ISelectModal } from './interfaces';

type CheckBoxItemProps = {
  checked: boolean;
  onChange: (value: string) => void;
  label: string;
  icon: string;
  value: string;
}

const CheckBoxItem: React.FC<CheckBoxItemProps> = ({
  checked, onChange, label, icon, value
}) => {
  const onSelectItem = (e: any) => {
    e.stopPropagation();

    onChange(value);
  };

  return (
    <div className="dropdown-item is-reverse" onClick={onSelectItem}>
      <div className="checkbox-check-icon">
        <input
          type="checkbox"
          className="checkbox-input visually-hidden"
          onClick={onSelectItem}
          checked={checked}
        />
        <div className="checkbox-mark" />
      </div>
      <div className="dropdown-item-icon">
        <img className="dropdown-item-icon-img" src={icon || userPicDefault} alt="option-icon" />
      </div>
      <div className="dropdown-item-text">{label}</div>
    </div>
  );
};

const MultiplyMobileSelectModal: React.FC<ISelectModal> = (props: ISelectModal) => {
  const {
    title,
    items,
    onSubmit,
    toggleModal,
    selectedItems,
    applyText = 'Add',
    cancelText = 'Cancel'
  } = props;
  const [selected, setSelected] = useState([...selectedItems]);

  const selectItem = (value: string) => {
    const isItemExist = selected.includes(value);
    let updatedList = [...selected];

    if (!isItemExist) {
      updatedList.push(value);
      setSelected(updatedList);
      return;
    }

    updatedList = selected.filter(selectedItem => selectedItem !== value);
    setSelected(updatedList);
  };

  const selectItems = items.map(({ value, label, icon }) => (
    <CheckBoxItem
      key={value}
      value={String(value)}
      label={label}
      icon={icon}
      onChange={selectItem}
      checked={selected.includes(String(value))}
    />
  ));

  const onApply = () => {
    onSubmit(selected);
    toggleModal();
  };

  const onCancel = () => {
    toggleModal();
  };

  return (
    <div className="multiply-mobile-select">
      <div className="title">{title}</div>
      <div>
        <PerfectScrollbar
          className="perfect-scrollbar flex-grow-1"
        >
          {selectItems}
        </PerfectScrollbar>
      </div>
      <div className="modal-actions">
        <button
          className="btn-default"
          type="button"
          onClick={onApply}
        >
          {applyText}
        </button>
        <button
          className="btn-default"
          type="button"
          onClick={onCancel}
        >
          {cancelText}
        </button>
      </div>
    </div>
  );
};

export default withModal(MultiplyMobileSelectModal);
