import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { addStudentStatus, addStudentToGroup } from 'api/students';
import WithPermissions from 'hocs/WithPermissions';
import { IPermissions } from 'types';
import { ADMIN, SUPER_ADMIN } from 'constants/roles';
import {
  GROUP_LEAD_LABEL,
  ADMIN_LABEL,
  STATUS_WAITING
} from 'components/Students/StudentsTable/StudentInfo/Actions/constants';
import { IActionsProps } from 'components/Students/StudentsTable/StudentInfo/Actions/interface';
import { SelectData } from 'types/filters';
import ReactSelect from 'components/common/FormFields/Selects';

const SelectGroup: React.FC<IActionsProps & IPermissions> = observer((props: IActionsProps & IPermissions) => {
  const {
    permissions, userId, statusId, recommendedEnglishLevelId, teacherTypeId, studyGroupId
  } = props;
  const { students } = useContext(RootStore);

  const {
    getTeacherTypes,
    removeTeacherTypesData,
    teacherTypes,
    getAvailableStudyGroupsList,
    removeAvailableStudyGroupsList,
    availableStudyGroups,
    getStudentsList
  } = students;

  const placeholder = permissions([ADMIN, SUPER_ADMIN]) ? ADMIN_LABEL : GROUP_LEAD_LABEL;
  const options = permissions([ADMIN, SUPER_ADMIN]) ? availableStudyGroups : teacherTypes;

  const isRenderDeleteForAdmin = studyGroupId && statusId !== STATUS_WAITING;
  const isRenderSelectForAdmin = !isRenderDeleteForAdmin && teacherTypeId && recommendedEnglishLevelId;

  const isRenderSelectForLead = !teacherTypeId;

  const isRenderSelect = permissions([ADMIN, SUPER_ADMIN]) ? isRenderSelectForAdmin : isRenderSelectForLead;

  const onMenuOpen = async () => {
    if (permissions([ADMIN, SUPER_ADMIN])) {
      await getAvailableStudyGroupsList(userId);
    } else {
      await getTeacherTypes(recommendedEnglishLevelId);
    }
  };

  const onMenuClose = async () => {
    if (permissions([ADMIN, SUPER_ADMIN])) {
      await removeAvailableStudyGroupsList();
    } else {
      await removeTeacherTypesData();
    }
  };

  const studentActions = async (selectedValue: SelectData) => {
    if (permissions([ADMIN, SUPER_ADMIN])) {
      await addStudentToGroup({ student_user_id: userId, study_group_id: selectedValue.value });
    } else {
      await addStudentStatus({ user_id: userId, lesson_type: selectedValue.value });
    }

    await getStudentsList();
  };

  if (!isRenderSelect) {
    return null;
  }

  return (
    <ReactSelect
      values={options}
      updateData={studentActions}
      placeholder={placeholder}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
});

export default WithPermissions(SelectGroup);
