import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import RootStore from 'store';
import { successNotification } from 'components/common/Notifications/helper';

import { createAdmin } from 'api/admins';

import { IModalProps } from 'components/Modals/interfaces';

import FormAddAdmin from 'components/Modals/Admin/AddNew/Form';
import Container from 'components/Modals/ModalContainer';

const AddNewAdmin: React.FC<IModalProps> = observer((props: IModalProps) => {
  const { isOpen } = props;

  const { admins } = useContext(RootStore);
  const { getAdminList, updateModalAddNewAdminState } = admins;

  const [formData, setFormData] = useState(null);

  const [isDisabled, setButtonState] = useState(false);

  const btnClassName = classNames('btn-default', { 'btn-disabled': isDisabled });

  const closeModal = () => {
    updateModalAddNewAdminState(false);
  };

  const submitForm = async () => {
    const { errors } = await createAdmin(formData);

    if (!errors) {
      updateModalAddNewAdminState(false);
      getAdminList();
      successNotification('Admin was successfully added');
    }
  };

  const header = (
    <>
      <div className="modal-title">Add New Admin</div>
      <button className="modal-close" type="button" onClick={closeModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = <FormAddAdmin setFormData={setFormData} setButtonState={setButtonState} />;

  const footer = (
    <>
      <button
        className={btnClassName}
        disabled={isDisabled}
        type="button"
        onClick={submitForm}
      >
        send request
      </button>
    </>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      toggleModal={() => { updateModalAddNewAdminState(false); }}
    />
  );
});

export default AddNewAdmin;
