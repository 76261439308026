import {
  getTeachersList,
  getDepartments,
  getDepartmentGroups,
  getStudyGroupNames
} from 'api/filtersData';

import { IUser } from 'components/Authentication/interfaces';
import { SelectData, ISelectedFiltersData } from 'types/filters';

import { DEFAULT_FILTER_DATA } from 'components/Calendar/constants/filterSetting';
import * as filter from 'constants/select';
import { GROUP_LEAD, TEACHER, ADMIN, SUPER_ADMIN } from 'constants/roles';

import {
  normalizeFilterData,
  normalizeFilterByTeachers,
  normalizeDepartment,
  normalizeDepartmentGroups
} from 'helpers/select';

export const getFiltersForAdmin = async () => {
  try {
    const filterData = DEFAULT_FILTER_DATA;

    const teacherList = await getTeachersList();
    const listStudyGroup = await getStudyGroupNames();

    const normalizedTeacherList = await normalizeFilterByTeachers(teacherList.data.data);
    const normalizedListStudyGroup = await normalizeFilterData(listStudyGroup.data.data.data_page);

    filterData.teachers = [filter.ALL_TEACHERS, ...normalizedTeacherList];
    filterData.studyGroups = [filter.ALL_GROUPS, ...normalizedListStudyGroup];

    return filterData;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getFiltersForSuperAdmin = async (selectedDepartment: SelectData) => {
  try {
    const filterData = DEFAULT_FILTER_DATA;

    const departmentId = selectedDepartment ? selectedDepartment.value : null;

    const teacherList = await getTeachersList();
    const normalizedTeacherList = await normalizeFilterByTeachers(teacherList.data.data);
    filterData.teachers = [filter.ALL_TEACHERS, ...normalizedTeacherList];

    const listStudyGroup = await getStudyGroupNames();
    const normalizedListStudyGroup = await normalizeFilterData(listStudyGroup.data.data.data_page);
    filterData.studyGroups = [filter.ALL_GROUPS, ...normalizedListStudyGroup];

    const departments = await getDepartments();
    const normalizedDepartments = await normalizeDepartment(departments.data.data);
    filterData.departments = [filter.ALL_DEPARTMENTS, ...normalizedDepartments];

    const departmentGroups = departmentId
      ? await getDepartmentGroups({ department_id: departmentId })
      : await getDepartmentGroups();

    const normalizedDepartmentGroups = await normalizeDepartmentGroups(departmentGroups.data.data);
    filterData.departmentsGroups = [filter.ALL_DEPARTMENTS_GROUPS, ...normalizedDepartmentGroups];

    return filterData;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getFiltersForGroupLead = async (user: IUser) => {
  try {
    const { departmentId } = user;

    const filterData = DEFAULT_FILTER_DATA;

    const departmentGroups = await getDepartmentGroups({ department_id: departmentId });

    filterData.departmentsGroups = await normalizeDepartmentGroups(departmentGroups.data.data);

    return filterData;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getFiltersForTeacher = async (user: IUser) => {
  try {
    const { id } = user;

    const filterData = DEFAULT_FILTER_DATA;

    const listStudyGroup = await getStudyGroupNames({ teacher_user_id: id });

    filterData.studyGroups = await normalizeFilterData(listStudyGroup.data.data.data_page);

    return filterData;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getFiltersDataByRole = (user: IUser, selectedDepartment: SelectData) => {
  const { role } = user;

  switch (role) {
    case ADMIN: {
      const adminFilters = getFiltersForAdmin();

      return adminFilters;
    }
    case SUPER_ADMIN: {
      const superAdminFilters = getFiltersForSuperAdmin(selectedDepartment);

      return superAdminFilters;
    }
    case GROUP_LEAD: {
      const leadFilters = getFiltersForGroupLead(user);

      return leadFilters;
    }
    case TEACHER: {
      const teacherFilters = getFiltersForTeacher(user);

      return teacherFilters;
    }
    default:
      return DEFAULT_FILTER_DATA;
  }
};

export const getAdditionalParams = (user: IUser, filters: ISelectedFiltersData) => {
  const { role, id } = user;
  const {
    selectedTeachers,
    selectedStudyGroup,
    selectedDepartment,
    selectedDepartmentGroup
  } = filters;

  const isSelectedTeachers = selectedTeachers && selectedTeachers.value;
  const teacherId = isSelectedTeachers ? { teacher_user_id: selectedTeachers.value } : {};

  const isSelectedStudyGroup = selectedStudyGroup && selectedStudyGroup.value;
  const studyGroupId = isSelectedStudyGroup ? { study_group_id: selectedStudyGroup.value } : {};

  const isSelectedDepartment = selectedDepartment && selectedDepartment.value;
  const departmentId = isSelectedDepartment ? { department_id: selectedDepartment.value } : {};

  const isSelectedDepartmentGroup = selectedDepartmentGroup && selectedDepartmentGroup.value;
  const departmentGroupId = isSelectedDepartmentGroup
    ? { department_group_id: selectedDepartmentGroup.value }
    : {};

  switch (role) {
    case ADMIN: {
      return {
        ...studyGroupId,
        ...teacherId
      };
    }
    case SUPER_ADMIN: {
      return {
        ...studyGroupId,
        ...teacherId,
        ...departmentId,
        ...departmentGroupId
      };
    }
    case GROUP_LEAD: {
      return {
        ...departmentGroupId
      };
    }
    case TEACHER: {
      return {
        ...studyGroupId,
        teacher_user_id: id
      };
    }
    default:
      return {};
  }
};
