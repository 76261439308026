import React, { useState, useContext } from 'react';
import classNames from 'classnames';

import RootStore from 'store';

import ButtonsGroup from 'components/common/BtnsGroup';

import Container from '../../ModalContainer';
import ChildTest from '../components/ChildTest';
import Lesson from '../components/Lesson';
import { eventsType, buttonsGroupData } from './constants';
import { isSelectedTab, validLesson } from './helpers';
import { IAddEvent } from './interface';
import MainEvent from '../components/MainEvent';

const AddEvent: React.FC<IAddEvent> = ({ isOpen, updateModalState }: IAddEvent) => {
  const {
    addEventStore: { addEvent }
  } = useContext(RootStore);

  const [typeButtonGroup, setTypeButtonGroup] = useState(eventsType.LESSON);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const btnClassName = classNames('btn-default', { 'btn-disabled': !isFormValid });

  const closeModal = () => {
    updateModalState(false);
  };

  const setData = (data: any) => {
    setFormData(data);
  };

  const createEvent = (): void => {
    addEvent(typeButtonGroup, formData, closeModal);
  };

  const header = (
    <>
      <div className="modal-title">Create New Event</div>
      <button className="modal-close" type="button" onClick={closeModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = (
    <>
      <div className="container modal-container">
        <div className="form-group">
          <ButtonsGroup
            data={buttonsGroupData}
            updateData={setTypeButtonGroup}
            selectedValue={typeButtonGroup}
          />
        </div>
        {isSelectedTab(typeButtonGroup, eventsType.LESSON) && (
          <Lesson
            setFormData={setData}
            setIsFormValid={setIsFormValid}
            validate={validLesson}
          />
        )}
        {isSelectedTab(typeButtonGroup, eventsType.MAIN) && (
          <MainEvent setIsFormValid={setIsFormValid} setFormData={setData} />
        )}
        {isSelectedTab(typeButtonGroup, eventsType.WRITING) && (
          <ChildTest
            setFormData={setData}
            setIsFormValid={setIsFormValid}
            childEventType={eventsType.WRITING}
          />
        )}
        {isSelectedTab(typeButtonGroup, eventsType.SPEAKING) && (
          <ChildTest setFormData={setData} setIsFormValid={setIsFormValid} />
        )}
      </div>
    </>
  );

  const footer = (
    <>
      <button
        className={btnClassName}
        type="button"
        onClick={createEvent}
        disabled={!isFormValid}
      >
        Save
      </button>
    </>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      additionalClassName="add-event"
      toggleModal={closeModal}
    />
  );
};

export default AddEvent;
