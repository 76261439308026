import React from 'react';

import ProgressBar from 'components/common/ProgressBar';

import { initialValueNames } from 'components/Modals/AddTestResult/FormAddTestResult/constants';
import { IFormikProps } from 'components/Modals/AddTestResult/FormAddTestResult/FormComponentAddTestResult/interface';

const AddSpeakingTestResult: React.FC<IFormikProps> = (props: IFormikProps) => {
  const { setFieldValue } = props;

  return (
    <div className="modal-content">
      <h2 className="modal-subtitle">Speaking</h2>
      <div className="row modal-row">
        <div className="col-xs-12 col-sm-6 modal-col">
          <ProgressBar
            name={initialValueNames.FLUENCY}
            setFieldValue={setFieldValue}
            progressClass="progress-listen"
            progressValue={0}
            progressLabel="Fluency"
            disabled={false}
          />
          <ProgressBar
            name={initialValueNames.ACCURACY}
            setFieldValue={setFieldValue}
            progressClass="progress-write"
            progressValue={0}
            progressLabel="Accuracy"
            disabled={false}
          />
          <ProgressBar
            name={initialValueNames.VOCABULARY}
            setFieldValue={setFieldValue}
            progressClass="progress-read"
            progressValue={0}
            progressLabel="Vocabulary"
            disabled={false}
          />
        </div>
        <div className="col-xs-12 col-sm-6 modal-col">
          <ProgressBar
            name={initialValueNames.SPEAKING_GRAMMAR}
            setFieldValue={setFieldValue}
            progressClass="progress-read"
            progressValue={0}
            progressLabel="Grammar"
            disabled={false}
          />
          <ProgressBar
            name={initialValueNames.COMMUNICATIVENESS}
            setFieldValue={setFieldValue}
            progressClass="progress-red"
            progressValue={0}
            progressLabel="Communicativeness"
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AddSpeakingTestResult;
