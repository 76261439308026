import React, { useState } from 'react';
import { TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';

import { IFormTimePickerProps, TimePickerView } from './interface';

const FormTimePicker: React.FC<IFormTimePickerProps> = (props: IFormTimePickerProps) => {
  const {
    onChange = () => {},
    onBlur = () => {},
    value,
    views = ['hours', 'minutes'],
    isDisabled = false,
    placeholder = 'Time',
    ampm = false,
    field,
    form
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDatePicker = (open: boolean) => setIsOpen(open);

  const { errors, touched } = form;
  // @ts-ignore
  const error = errors[field.name];
  const fieldHasError = error && touched[field.name] && !isOpen;
  const classError = classNames('form-field', { 'has-error': fieldHasError });

  return (
    <div className={classError}>
      <TimePicker
        onChange={onChange}
        onBlur={onBlur}
        onOpen={() => toggleDatePicker(true)}
        onClose={() => toggleDatePicker(false)}
        open={isOpen}
        views={views as TimePickerView[]}
        placeholder={placeholder}
        disabled={isDisabled}
        value={value}
        ampm={ampm}
      />
      {fieldHasError && <span className="form-group-error">{error}</span>}
    </div>
  );
};

export default FormTimePicker;
