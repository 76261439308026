export const MINUTES_IN_HOUR = 60;

export const format = {
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  MONTH_YEAR: 'MM-YYYY',
  MONTH_STR_FULL_YEAR: 'MMMM YYYY',
  MONTH_DAY: 'M_D',
  DAY_OF_WEEK: 'ddd',
  DAY: 'DD',
  TIME: 'HH:mm'
};
