import React, { useState } from 'react';
import { Field } from 'formik';

import { formFields } from 'components/common/FormComponent/formFields/index';

import { initialValueNames } from 'components/Modals/AddTestResult/FormAddTestResult/constants';
import { IFormikProps } from 'components/Modals/AddTestResult/FormAddTestResult/FormComponentAddTestResult/interface';
import { RecommendedLevelProps } from './interface';

const AddRecommendedLevelTestResult: React.FC<RecommendedLevelProps&IFormikProps> = (
  props: RecommendedLevelProps&IFormikProps
) => {
  const { testLevelsList, setFieldValue, setFieldTouched } = props;

  const [selectedSpeakingEnglishLevel, changeSpeakingEnglishLevel] = useState(null);
  const [selectedWritingEnglishLevel, changeWritingEnglishLevel] = useState(null);
  const [selectedRecommendedEnglishLevel, changeRecommendedEnglishLevel] = useState(null);

  return (
    <>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field
            name={initialValueNames.SPEAKING_ENGLISH_LEVEL_ID}
            component={formFields.FormSelect}
            options={testLevelsList}
            value={selectedSpeakingEnglishLevel}
            placeholder="Speaking level"
            onBlur={() => setFieldTouched(initialValueNames.SPEAKING_ENGLISH_LEVEL_ID)}
            onChange={(data: any) => {
              changeSpeakingEnglishLevel(data);
              setFieldValue(initialValueNames.SPEAKING_ENGLISH_LEVEL_ID, data.value);
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 modal-col">
          <Field
            name={initialValueNames.WRITING_ENGLISH_LEVEL_ID}
            component={formFields.FormSelect}
            options={testLevelsList}
            value={selectedWritingEnglishLevel}
            placeholder="Writing level"
            onBlur={() => setFieldTouched(initialValueNames.WRITING_ENGLISH_LEVEL_ID)}
            onChange={(data: any) => {
              changeWritingEnglishLevel(data);
              setFieldValue(initialValueNames.WRITING_ENGLISH_LEVEL_ID, data.value);
            }}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-12 modal-col">
          <Field
            name={initialValueNames.RECOMMENDED_ENGLISH_LEVEL_ID}
            component={formFields.FormSelect}
            options={testLevelsList}
            value={selectedRecommendedEnglishLevel}
            placeholder="Recommended level"
            onBlur={() => setFieldTouched(initialValueNames.RECOMMENDED_ENGLISH_LEVEL_ID)}
            onChange={(data: any) => {
              changeRecommendedEnglishLevel(data);
              setFieldValue(initialValueNames.RECOMMENDED_ENGLISH_LEVEL_ID, data.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddRecommendedLevelTestResult;
