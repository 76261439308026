import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import RootStore from 'store';

import FormAddTestResult from 'components/Modals/AddTestResult/FormAddTestResult';
import Container from 'components/Modals/ModalContainer';
import EmptyState from 'components/common/EmptyState';
import { successNotification } from 'components/common/Notifications/helper';

import { postTestResult } from 'api/testResult';

import { getIsHasValidationErrors } from './helpers';
import { IAddTestResultProps } from './interface';

const AddTestResults = observer((props: IAddTestResultProps) => {
  const { isOpen, toggleModal } = props;

  const { modalsAddTestResult } = useContext(RootStore);
  const {
    studentsList,
    getSelectsData,
    testNamesList,
    testLevelsList,
    getStudentsList,
    getNextTestResult
  } = modalsAddTestResult;

  const testNamesListLength = testNamesList.length;
  const studentsListLength = studentsList.length;

  const [formData, setFormData] = useState(null);

  const hasValidationErrors = getIsHasValidationErrors(formData);

  const [selectedTest, changeTest] = useState(null);
  const selectedTestValue = selectedTest ? selectedTest.value : null;

  const [selectedStudent, changeStudent] = useState(studentsListLength ? studentsList[0] : null);

  const btnClassName = classNames('btn-default', { 'btn-disabled': hasValidationErrors });

  useEffect(() => {
    if (testNamesListLength) {
      changeTest(testNamesList[0]);
    } else {
      changeTest(null);
    }
  }, [testNamesListLength]);

  useEffect(() => {
    if (selectedTestValue) {
      getStudentsList(selectedTestValue);
    }
  }, [getStudentsList, selectedTestValue]);

  useEffect(() => {
    if (studentsListLength) {
      changeStudent(studentsList[0]);
    } else {
      changeStudent(null);
    }
  }, [studentsList, studentsListLength]);

  const closeModal = () => {
    toggleModal(false);
  };

  const header = (
    <>
      <div className="modal-title">Add Test Results</div>
      <button className="modal-close" type="button" onClick={closeModal}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const formProps = {
    testNamesList,
    changeTest,
    selectedTest,
    studentsList,
    changeStudent,
    selectedStudent,
    testLevelsList
  };

  const bindSubmitForm = (data: any) => {
    setFormData(data);
  };

  const body = selectedTestValue ? (
    <FormAddTestResult
      key={`${selectedTest.value}${selectedStudent.value}`}
      bindSubmitForm={bindSubmitForm}
      testId={selectedTest.value}
      studentId={selectedStudent.value}
      {...formProps}
    />
  ) : (
    <div className="container modal-container">
      <EmptyState
        icon="sentiment_dissatisfied"
        title="There no students without test results!"
        additionalClassName="has-spacings"
      />
    </div>
  );

  const submit = async () => {
    try {
      await postTestResult(formData);

      const isLastTest = !(testNamesListLength - 1);
      const isLastStudent = !(studentsListLength - 1);

      const testResultData = {
        selectedTest: selectedTest.value,
        selectedStudent: selectedStudent.value
      };

      if (isLastTest && isLastStudent) {
        closeModal();
        getSelectsData();
        successNotification('Test results was successfully added');

        return null;
      }
      await getNextTestResult(isLastStudent, testResultData);

      return null;
    } catch (error) {
      return error;
    }
  };

  const footer = selectedTestValue ? (
    <button className={btnClassName} disabled={hasValidationErrors} type="button" onClick={submit}>
      Save
    </button>
  ) : (
    <button className="btn-border" type="button" onClick={closeModal}>
      Back
    </button>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      toggleModal={() => toggleModal(false)}
    />
  );
});

export default AddTestResults;
