import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { STUDENT } from 'constants/roles';

import FormComponent from '../../common/FormComponent';
import StudentProfileForm from './StudentProfileForm';
import validate from './validate';
import { useStateModal } from '../../Modals/helper';
import { IUserUpdateData } from '../../Authentication/interfaces';

function StudentProfileModal() {
  const {
    authenticationStore: { user, userDataInitialized, updateProfile, logoutRequest }
  } = useContext(RootStore);
  const { role, phone, skype } = user;

  const [isOpen, , setIsModalOpen]: any = useStateModal(false);

  const studentAccountValidation = () => {
    const isRequiredFieldsNotSpecified = !skype || !phone;

    if (role === STUDENT && isRequiredFieldsNotSpecified) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    studentAccountValidation();
  }, [user]);

  if (!userDataInitialized) {
    return null;
  }

  const updateStudentProfile = async (data: IUserUpdateData) => {
    await updateProfile(data);
    setIsModalOpen(false);
  };

  return (
    <FormComponent
      Component={StudentProfileForm}
      initialValues={{ phone, skype }}
      onSubmit={updateStudentProfile}
      validate={validate}
      otherProps={{ isOpen, logoutRequest }}
    />
  );
}

export default observer(StudentProfileModal);
