import React from 'react';

import * as apiDepartment from 'api/department';

import WithModal from 'hocs/WithModal';
import FormComponent from 'components/common/FormComponent';

import * as interfaces from './interface';
import { checkValidityGroupData } from './validate';
import { getEditInitialValues } from './helpers';
import { DEFAULT_INITIAL_VALUES } from './constant';

import FormCreateDepartmentGroup from './FormCreateDepartmentGroup';

const ModalDepartmentGroup: React.FC<interfaces.IModalDepartmentGroupProps> = (
  props: interfaces.IModalDepartmentGroupProps
) => {
  const { toggleModal, modalData = null } = props;

  const initialValues: interfaces.IValidGroupRequestParams = modalData
    ? getEditInitialValues(modalData)
    : DEFAULT_INITIAL_VALUES;

  const closeModal = () => {
    toggleModal(false);
  };

  const onSubmit = async (params: interfaces.IValidGroupRequestParams) => {
    try {
      if (modalData) {
        await apiDepartment.editDepartmentGroup(params);
      } else {
        await apiDepartment.createDepartmentGroup(params);
      }

      return closeModal();
    } catch (error) {
      return error;
    }
  };

  const validate = (values: interfaces.IValidGroupRequestParams) => checkValidityGroupData(values);

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-title">Create Group</div>
        <button className="modal-close" type="button" onClick={closeModal}>
          <i className="material-icons">clear</i>
        </button>
      </div>
      <FormComponent
        Component={FormCreateDepartmentGroup}
        otherProps={modalData}
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      />
    </div>
  );
};

export default WithModal(ModalDepartmentGroup);
