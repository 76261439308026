import React from 'react';

import { ITimeTablesData } from 'components/AddToGroup/GroupList/GroupItem/interfaces';

import ClassInfo from './ClassInfo';

const GeneralInformation: React.FC<ITimeTablesData> = (props: ITimeTablesData) => {
  const { timeTables } = props;

  return (
    <div className="d-flex flex-column">
      {timeTables.map((data: any) => {
        const { groupId, dayOfWeek } = data;
        const key = dayOfWeek + groupId;

        return <ClassInfo {...data} key={key} />;
      })}
    </div>
  );
};

export default GeneralInformation;
