import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { addStudentStatus, deleteStudentFromGroup } from 'api/students';

import { REMOVE_FROM_GROUP } from 'components/Students/StudentsTable/StudentInfo/Actions/constants';
import { IModalProps } from 'components/Modals/interfaces';

import Container from 'components/Modals/ModalContainer';

function StudentLevelError(props: IModalProps) {
  const { isOpen } = props;

  const { students } = useContext(RootStore);

  const { getStudentsList, closeModalErrorLevel, modalData } = students;
  const { userId, recommendedEnglishLevelId } = modalData;

  const selectRecommendedLevel = async () => {
    await deleteStudentFromGroup({
      student_user_id: userId,
      remove: REMOVE_FROM_GROUP
    });

    await addStudentStatus({ recommended_english_level_id: recommendedEnglishLevelId, user_id: userId });

    getStudentsList();

    closeModalErrorLevel();
  };

  const header = <div className="modal-title">Error</div>;

  const body = (
    <div className="container modal-container">
      <p>Are you sure you have chosen that level? </p>
      <p>The selected level is not suitable for a native speaker. </p>
      <p>Press “ok” to confirm the level, or press “cancel” to select another level</p>
    </div>
  );

  const footer = (
    <div className="inputs-group">
      <div className="inputs-group-item">
        <button className="btn-border" type="button" onClick={closeModalErrorLevel}>
          cancel
        </button>
      </div>
      <div className="inputs-group-item">
        <button className="btn-danger" type="button" onClick={selectRecommendedLevel}>
          ok
        </button>
      </div>
    </div>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      toggleModal={closeModalErrorLevel}
    />
  );
}

export default observer(StudentLevelError);
