import * as INTERFACES from 'components/Departments/interfaces';
import { IValidGroupRequestParams } from 'components/Departments/ModalDepartmentGroup/interface';

import request from './request';
import api from './index';

export const removeStudentFromDepartmentGroup = (
  params: INTERFACES.IRemoveStudentFromDepartmentGroupParams
): Promise<any> => request({
  url: api.USER_DEPARTMENT_GROUP,
  method: 'DELETE',
  params
});

export const assignStudentToDepartmentGroup = (
  data: INTERFACES.IAssignStudentToDepartmentGroupParams
): Promise<any> => request({
  url: api.USER_DEPARTMENT,
  method: 'PATCH',
  data
});

export const editDepartmentGroup = (
  data: IValidGroupRequestParams
): Promise<any> => request({
  url: api.DEPARTMENT_GROUP,
  method: 'PUT',
  data
});

export const createDepartmentGroup = (
  data: IValidGroupRequestParams
): Promise<any> => request({
  url: api.DEPARTMENT_GROUP,
  method: 'POST',
  data
});
