import React from 'react';

import { IDetailInfoProps } from './interface';

import GroupLeads from './GroupLeads';
import Students from './Students';

const DetailInfo: React.FC<IDetailInfoProps> = (props: IDetailInfoProps) => {
  const { departmentId, departmentGroupId, students, leads } = props;

  return (
    <>
      <GroupLeads leads={leads} />
      <Students
        students={students}
        departmentId={departmentId}
        departmentGroupId={departmentGroupId}
      />
    </>
  );
};

export default DetailInfo;
