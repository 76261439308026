import { GROUP_LEAD } from 'constants/roles';

import { getLeadTableValues, getAdminTableValues } from './constants';

export const getDefaultSortedPanel = (permissions: (arrayRoles: string[]) => boolean, isMobile = false) => {
  if (permissions([GROUP_LEAD])) {
    return getLeadTableValues(isMobile);
  }

  return getAdminTableValues(isMobile);
};
