/* eslint-disable camelcase */
export const normalizeStudentsList = requestData => requestData.map((student) => {
  const { student_user_id, student_user_name, student_user_surname, student_user_photo } = student;

  return {
    label: `${student_user_surname} ${student_user_name}`,
    value: student_user_id,
    photo: student_user_photo
  };
});

export const normalizeListEnglishLevels = requestData => requestData.map((englishLevel) => {
  const { id, name } = englishLevel;

  return { label: name, value: id };
});
