import { errorMassage } from 'constants/forms';
import { phone } from 'services/patterns';

export const validAddTeacher = (values: any) => {
  const errors: any = {};

  if (!values.password) {
    errors.password = errorMassage.default;
  }

  if (!values.name) {
    errors.name = errorMassage.default;
  }

  if (!values.surname) {
    errors.surname = errorMassage.default;
  }

  if (!values.username) {
    errors.username = errorMassage.default;
  }

  if (!values.email) {
    errors.email = errorMassage.default;
  }

  if (!values.skype) {
    errors.skype = errorMassage.default;
  }

  if (!values.phone_number) {
    errors.phone_number = errorMassage.default;
  }

  if (values.phone_number) {
    const isPhoneHasInvalidFormat = !phone.test(values.phone_number);

    if (isPhoneHasInvalidFormat) {
      errors.phone_number = errorMassage.phone;
    }
  }

  if (!values.type_id) {
    errors.type_id = errorMassage.default;
  }

  return errors;
};
