import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { isEqual } from 'lodash';
import cn from 'classnames';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import { PROFILE_FORM } from 'constants/forms';
import withModal from 'hocs/WithModal';

function StudentProfileForm(props: any) {
  const [editMode, setEditMode] = useState(false);
  const {
    handleSubmit, initialValues, validateForm, errors, dirty, logoutRequest
  } = props;

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  const isFormValid = isEqual(errors, {});

  const toggleMode = () => {
    setEditMode(!editMode);
  };

  const submit = (data: any) => {
    toggleMode();
    handleSubmit(data);
  };

  const submitBtnClassName = cn('btn-default', {
    'btn-disabled': !isFormValid || !dirty
  });

  const logout = () => {
    logoutRequest();
  };

  const buttonGroup = (
    <div className="modal-actions">
      <button className={submitBtnClassName} type="submit">Save</button>
      <button className="btn-default" type="button" onClick={logout}>Logout</button>
    </div>
  );

  return (
    <form autoComplete="off" className="student-profile-form" onSubmit={submit}>
      <div className="modal-header">You should provide your contact information!</div>
      <>
        <Field
          name={PROFILE_FORM.phone}
          label="Phone"
          type="tel"
          component={FormInput}
        />
        <Field
          name={PROFILE_FORM.skype}
          label="Skype"
          component={FormInput}
        />
      </>
      {buttonGroup}
    </form>
  );
}

export default withModal(StudentProfileForm);
