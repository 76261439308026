import { TIME_LINE_DATE } from 'components/Calendar/constants/eventSetting';
import { getTimeAsNumber } from 'helpers/calendar';

import { TIME_COEFFICIENT, MINUTES_IN_HOUR } from './constant';

const getPositionTopByHour = (hour: number): number => {
  let coefficient: number;

  Object.keys(TIME_COEFFICIENT).forEach((key) => {
    if (key === String(hour)) {
      coefficient = TIME_COEFFICIENT[key];
    }
  });

  return coefficient;
};

export const getWorkTime = () => TIME_LINE_DATE.length;

export const getUpdatePosition = (time: string, height: number) => {
  const timeData = getTimeAsNumber(time, ':');
  const hourCoefficient = getPositionTopByHour(timeData.hour);

  const workTime = getWorkTime();
  const oneElementHeight = height / workTime;
  const minutesCoefficient = oneElementHeight / MINUTES_IN_HOUR;

  const positionByHour = hourCoefficient * oneElementHeight;
  const positionByMinutes = timeData.minutes * minutesCoefficient;

  return positionByHour + positionByMinutes;
};
