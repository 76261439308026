import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notifications: React.FC = () => (
  <ToastContainer
    position="top-right"
    autoClose={6000}
    hideProgressBar
    closeOnClick
    newestOnTop
  />
);

export default Notifications;
