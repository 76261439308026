import { observable, action, computed } from 'mobx';

import * as studentsApi from 'api/students';
import * as filtersApi from 'api/filtersData';

import * as defaultStoreData from 'components/Students/constants';
import { ADMIN } from 'constants/roles';
import { ALL_GROUPS, ALL_STATUSES, ALL_DEPARTMENTS_GROUPS } from 'constants/select';
import { NO_AVAILABLE_RESULTS } from 'components/common/FormFields/Selects/constant';

import { normalizeFilterData, normalizeDepartmentGroups } from 'helpers/select';
import { getStringifyData } from 'helpers';
import { getSortingStatus } from 'components/common/ColumnValueInTable/helpers';
import { getStatusIds, checkAvailableTeachersTypes } from 'components/Students/helpers';
import { normalizeStudentsList, normalizeStudentsListOptions, prepareStudentsUserId } from 'components/Students/utils';

import { IAuthenticationStore } from 'store/interfaces';
import { ISortingData } from 'components/common/ColumnValueInTable/interface';
import * as type from 'components/Students/interface';
import { SelectData } from 'types/filters';
import * as SEARCH_INTERFACES from '../../types';

export default class StudentsStore {
  constructor(rootStore: any) {
    this.authenticationData = rootStore.authenticationStore;
  }

  @observable authenticationData: IAuthenticationStore = null;

  @observable studentsList: type.IStudentData[] = [];

  @observable paginationData = defaultStoreData.DEFAULT_PAGE_DATA;

  @observable countPages: number = defaultStoreData.DEFAULT_CURRENT_PAGE;

  @observable sortedData = defaultStoreData.DEFAULT_SORT_DATA;

  @observable filters = defaultStoreData.DEFAULT_FILTERS;

  @observable filterByGroup: SelectData = ALL_GROUPS;

  @observable filterByDepartmentGroup: SelectData = ALL_DEPARTMENTS_GROUPS;

  @observable filterByStatus: SelectData = ALL_STATUSES;

  @observable allStatusesValue: string = null;

  @observable teacherTypes: SelectData[] = [];

  @observable availableStudyGroups: SelectData[] = [];

  @observable englishLevels: SelectData[] = [];

  @observable isOpenModalErrorLevel = false;

  @observable isOpenModalDeleteStudentFromGroup = false;

  @observable modalData: type.IModalData = null;

  @observable isLoadingStudents = false;

  @observable isLoadingStudentsStatuses = false;

  @observable searchOptions: SEARCH_INTERFACES.ISelectItem[] = [];

  @observable selectedStudent: SEARCH_INTERFACES.ISelectItem = null;

  @observable searchString = '';

  @computed get isLoading() {
    return this.isLoadingStudentsStatuses
      || this.isLoadingStudents;
  }

  @action setSelectedStudent = (selectedStudent: SEARCH_INTERFACES.ISelectItem): void => {
    this.selectedStudent = selectedStudent;
    this.getStudentsList();
  };

  @action searchRequest = async (searchString: string): Promise<void> => {
    try {
      const {
        user: { departmentId }
      } = this.authenticationData;

      const statusIds = getStatusIds(this.filterByStatus, this.allStatusesValue);

      const params: type.IGetStudentsParams = {};

      if (statusIds) {
        params.status_ids = statusIds;
      }

      if (searchString) {
        params.search_string = searchString;
      }

      if (departmentId) {
        params.department_id = departmentId;
      }

      const {
        data: { data }
      } = await studentsApi.getStudentsList(params);

      this.searchOptions = normalizeStudentsListOptions(normalizeStudentsList(data.data_page));
    } catch (error) {
      console.error(error);
    } finally {
      this.searchString = '';
    }
  };

  @action getStudentsList = async (): Promise<void> => {
    try {
      this.isLoadingStudents = true;
      const {
        user: { role, departmentId }
      } = this.authenticationData;
      const isAdmin = role === ADMIN;

      if (!this.filters.studentsStatuses.length) {
        await this.getStudentsStatuses(isAdmin);
      }

      const statusIds = getStatusIds(this.filterByStatus, this.allStatusesValue);

      const params: type.IGetStudentsParams = {
        ...this.paginationData,
        ...this.sortedData
      };

      if (this.filterByGroup.value) {
        params.study_group_id = this.filterByGroup.value;
      }

      if (departmentId) {
        params.department_id = departmentId;
      }

      if (this.filterByDepartmentGroup.value) {
        params.department_group_id = this.filterByDepartmentGroup.value;
      }

      if (statusIds) {
        params.status_ids = statusIds;
      }

      if (this.selectedStudent) {
        params.students_user_id = prepareStudentsUserId(this.selectedStudent);
      }

      const {
        data: { data }
      } = await studentsApi.getStudentsList(params);

      this.countPages = data.count_of_pages;
      this.studentsList = normalizeStudentsList(data.data_page);
    } catch (error) {
      this.countPages = defaultStoreData.DEFAULT_CURRENT_PAGE;
      this.studentsList = [];
      console.error(error);
    } finally {
      this.isLoadingStudents = false;
    }
  };

  @action changSelectedPage = (selectedPage?: number) => {
    if (selectedPage) {
      this.paginationData = {
        ...this.paginationData,
        current_page: selectedPage + 1
      };
    } else {
      this.paginationData = {
        ...this.paginationData,
        current_page: defaultStoreData.DEFAULT_CURRENT_PAGE
      };
    }
  };

  @action changeSorted = (updatedSortedData: ISortingData[]) => {
    const { value, sortingArrow } = updatedSortedData.find((sortedValue) => sortedValue.isActive);

    const sortingState = getSortingStatus(sortingArrow);

    this.sortedData = {
      sort_key: value,
      sort_order: sortingState
    };
  };

  @action getStudyGroup = async () => {
    const {
      data: { data }
    } = await filtersApi.getStudyGroupNames();

    const normalizedFilterStudyGroups = normalizeFilterData(data.data_page);

    this.filters = {
      ...this.filters,
      groups: [ALL_GROUPS, ...normalizedFilterStudyGroups]
    };
  };

  @action changeFilterGroup = (group: SelectData) => {
    this.filterByGroup = group;

    this.changSelectedPage();
  };

  @action getDepartmentGroups = async (params: type.IGetDepartmentGroupsParams) => {
    const {
      data: { data }
    } = await filtersApi.getDepartmentGroups(params);

    const normalizedDepartmentGroups = normalizeDepartmentGroups(data);

    this.filters = {
      ...this.filters,
      departmentGroups: [ALL_DEPARTMENTS_GROUPS, ...normalizedDepartmentGroups]
    };
  };

  @action changeFilterDepartmentGroup = (departmentGroup: SelectData) => {
    this.filterByDepartmentGroup = departmentGroup;

    this.changSelectedPage();
  };

  @action getStudentsStatuses = async (isAdmin: boolean): Promise<void> => {
    try {
      this.isLoadingStudentsStatuses = true;
      const {
        data: { data }
      } = await filtersApi.getStudentsStatuses();

      const normalizedStudentsStatuses = normalizeFilterData(data);

      if (isAdmin) {
        const allStatuses = normalizedStudentsStatuses.map((status) => status.value);
        this.allStatusesValue = getStringifyData(allStatuses);
      }

      this.filters = {
        ...this.filters,
        studentsStatuses: [ALL_STATUSES, ...normalizedStudentsStatuses]
      };
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingStudentsStatuses = false;
    }
  };

  @action changeFilterStudentsStatus = (status: SelectData) => {
    this.filterByStatus = status;

    this.changSelectedPage();
  };

  @action getTeacherTypes = async (recommendedEnglishLevelId: number) => {
    const {
      data: { data }
    } = await filtersApi.getTeacherTypes();

    const normalizedTeachersTypes = await normalizeFilterData(data);

    this.teacherTypes = await checkAvailableTeachersTypes(
      normalizedTeachersTypes,
      recommendedEnglishLevelId
    );
  };

  @action removeTeacherTypesData = async () => {
    this.teacherTypes = [];
  };

  @action getAvailableStudyGroupsList = async (userId: number) => {
    const {
      data: { data }
    } = await studentsApi.getAvailableStudyGroups({ student_user_id: userId });

    if (!data.length) {
      this.availableStudyGroups = NO_AVAILABLE_RESULTS;
    } else {
      this.availableStudyGroups = normalizeFilterData(data);
    }
  };

  @action removeAvailableStudyGroupsList = async () => {
    this.availableStudyGroups = [];
  };

  @action getEnglishLevels = async () => {
    const {
      data: { data }
    } = await filtersApi.getTestLevels();

    this.englishLevels = normalizeFilterData(data);
  };

  @action openModalErrorLevel = (studentId: number) => {
    this.isOpenModalErrorLevel = true;

    this.modalData = this.studentsList.find((student) => student.userId === studentId);
  };

  @action closeModalErrorLevel = () => {
    this.isOpenModalErrorLevel = false;

    this.modalData = null;
  };

  @action deleteStudentFromGroup = async (params: type.IDeleteStudentFromGroupParams) => {
    try {
      const request = await studentsApi.deleteStudentFromGroup(params);

      this.closeModalDeleteStudentFromGroup();
      this.getStudentsList();

      return request;
    } catch (error) {
      return error;
    }
  };

  @action openModalDeleteStudentFromGroup = (studentId: number, isAdmin: boolean) => {
    this.isOpenModalDeleteStudentFromGroup = true;

    const selectedStudent = this.studentsList.find((student) => student.userId === studentId);

    this.modalData = { ...selectedStudent, isAdmin };
  };

  @action closeModalDeleteStudentFromGroup = () => {
    this.isOpenModalDeleteStudentFromGroup = false;

    this.modalData = null;
  };
}
