import { observable, action, computed } from 'mobx';

import { getEvents, addStudentToTest, deleteStudentFromTest } from 'api/testSchedule';
import { EventsRequestParamType } from 'components/Calendar/types/storeTypes';

import { getMonth } from 'helpers/actionsWithDates';
import { getSelectedDay } from 'components/TestSchedule/components/TestCalendar/helpers';
import { IRootStore } from 'store/interfaces';

import { getSortedEvents, getStudents, normalizeAllEventData } from './helpers';
import { MonthEventsType, StudentsListType } from './interfaces';

export default class TestSchedule {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @observable monthEvents: MonthEventsType[] = [];

  @observable eventStudents: StudentsListType[] = [];

  @observable availableStudents: StudentsListType[] = [];

  @observable selectedDay: string = null;

  @observable selectedMonth: string = getMonth();

  @observable students: StudentsListType[] = [];

  @computed get notAssignedStudents() {
    return this.availableStudents.reduce((result, student) => {
      const isUserAssigned = this.eventStudents && this.eventStudents.some((item) => item.userId === student.userId);

      if (isUserAssigned) {
        return result;
      }

      result.push(student);

      return result;
    }, []);
  }

  @action setSelectedDay = (date: string) => {
    this.selectedDay = date;
  };

  @action setSelectedMonth = (date: string) => {
    this.selectedMonth = date;
  };

  @action getMonthEvents = async (params?: EventsRequestParamType) => {
    this.monthEvents = [];

    const { data } = await getEvents(params);

    const normalizedEventData = normalizeAllEventData(data);

    const events = getSortedEvents(normalizedEventData);

    this.monthEvents = events;
    this.selectedDay = getSelectedDay(this.selectedMonth, events);
    this.eventStudents = getStudents(events);
  };

  @action setAvailableStudents = (students: StudentsListType[]) => {
    this.availableStudents = students;
  };

  @action addStudentToTest = async ({ student, ...data }: any) => {
    await addStudentToTest(data);

    this.monthEvents = this.monthEvents.map((event) => {
      if (event.id === data.event_id) {
        return {
          ...event,
          students: [...event.students, student]
        };
      }
      return event;
    });

    this.eventStudents = getStudents(this.monthEvents);
  };

  @action deleteStudentFromTest = async ({ student, ...data }: any) => {
    await deleteStudentFromTest(data);

    this.monthEvents = this.monthEvents.map((event) => {
      if (event.id === data.event_id) {
        return {
          ...event,
          students: event.students.filter((item) => item.userId !== student.userId)
        };
      }

      return event;
    });

    this.eventStudents = getStudents(this.monthEvents);
  };
}
