import { userName, userPassword } from 'services/validation';
import { IEmailValue } from './interfaces';

export const validate = (values: IEmailValue) => ({
  ...userName(values, true),
  ...userPassword(values, true)
});

export const serializeUser = (data: any) => {
  const {
    role,
    id,
    name,
    surname,
    department_id: departmentId,
    department_group_id: departmentGroupId,
    department_group_name: departmentGroupName,
    photo,
    username,
    email,
    skype,
    phone_number: phone,
    lesson_type: type
  } = data;

  return {
    id,
    role,
    name,
    surname,
    departmentId,
    departmentGroupId,
    departmentGroupName,
    photo,
    username,
    email,
    skype,
    phone,
    type
  };
};
