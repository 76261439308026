/* eslint-disable lines-between-class-members */
import { createContext } from 'react';

import AuthenticationStore from 'components/Authentication/store';
import CalendarStore from 'components/Calendar/store';
import TestResults from 'components/TestResults/store';
import ModalsAddTestResult from 'components/Modals/AddTestResult/store';
import AddEventStore from 'components/Modals/Event/AddEvent/store';
import EditEventStore from 'components/Modals/Event/EditEvent/store';
import AttendanceLog from 'components/AttendanceLog/store';
import TestSchedule from 'components/TestSchedule/store';
import SidebarStore from 'components/Aside/store';
import TeachersStore from 'components/Teachers/store';
import StudentsStore from 'components/Students/store';
import AdminsStore from 'components/Admins/store';
import Groups from 'components/Groups/store';
import AddToGroup from 'components/AddToGroup/store';
import DepartmentsStore from 'components/Departments/store';

import ServiceStore from './ServiceStore';

class RootStore {
  authenticationStore: AuthenticationStore;
  calendarStore: CalendarStore;
  addEventStore: AddEventStore;
  editEventStore: EditEventStore;
  testResults: TestResults;
  modalsAddTestResult: ModalsAddTestResult;
  attendanceLog: AttendanceLog;
  testSchedule: TestSchedule;
  sidebar: SidebarStore;
  teachers: TeachersStore;
  students: StudentsStore;
  admins: AdminsStore;
  groups: Groups;
  addToGroupStore: AddToGroup;
  departmentsStore: DepartmentsStore;
  serviceStore: ServiceStore;

  constructor() {
    this.authenticationStore = new AuthenticationStore();
    this.calendarStore = new CalendarStore();
    this.addEventStore = new AddEventStore(this);
    this.editEventStore = new EditEventStore(this);
    this.testResults = new TestResults();
    this.modalsAddTestResult = new ModalsAddTestResult();
    this.attendanceLog = new AttendanceLog(this);
    this.testSchedule = new TestSchedule(this);
    this.sidebar = new SidebarStore();
    this.teachers = new TeachersStore();
    this.students = new StudentsStore(this);
    this.admins = new AdminsStore();
    this.groups = new Groups();
    this.addToGroupStore = new AddToGroup(this);
    this.departmentsStore = new DepartmentsStore(this);
    this.serviceStore = new ServiceStore();
  }
}

export default createContext(new RootStore());
