import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import Logo from 'components/common/Logo';
import classNames from 'classnames';
import NavigationMenu from './NavigationMenu';

const Aside: React.FC = observer(() => {
  const {
    sidebar: { isShowSidebar, toggleSidebar }
  } = useContext(RootStore);

  const sidebarClassName = classNames('side', { 'is-open': isShowSidebar });

  return (
    <>
      <aside className={sidebarClassName}>
        <div className="side-logo">
          <Logo />
        </div>
        <NavigationMenu />
      </aside>
      <div className="side-overlay" role="presentation" onClick={() => toggleSidebar()}>
        <button type="button" className="close-menu">
          <div className="close-icon">
            <div className="close-strips" />
            <div className="close-strips" />
            <div className="close-strips" />
          </div>
        </button>
      </div>
    </>
  );
});

export default Aside;
