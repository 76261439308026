import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import Header from 'components/common/Header';
import Filters from 'components/Students/Filters';
import StudentsTable from 'components/Students/StudentsTable';
import Pagination from 'components/common/Pagination';
import StudentLevelError from 'components/Modals/Student/StudentLevelError';
import DeleteStudentFromGroup from 'components/Modals/Student/DeleteStudentFromGroup';
import Search from 'components/common/FormFields/Search';
import { useStateModal } from '../Modals/helper';

function Students() {
  const { students, serviceStore: { isMobile } } = useContext(RootStore);
  const {
    getStudentsList,
    changSelectedPage,
    paginationData,
    countPages,
    sortedData,
    filterByGroup,
    filterByDepartmentGroup,
    filterByStatus,
    modalData,
    isOpenModalErrorLevel,
    isOpenModalDeleteStudentFromGroup,
    closeModalDeleteStudentFromGroup,
    deleteStudentFromGroup,
    setSelectedStudent,
    searchOptions,
    searchRequest,
    selectedStudent
  } = students;

  const [isMobileFiltersOpen, toggleIsMobileFiltersOpen]: any = useStateModal(false);

  useEffect(() => {
    if (isMobile) {
      getStudentsList();
    }
  }, [paginationData, sortedData]);

  useEffect(() => {
    if (isMobile) { return; }
    getStudentsList();
  }, [paginationData, sortedData, filterByGroup, filterByDepartmentGroup, filterByStatus]);

  const isRenderPagination = countPages > 1;

  return (
    <>
      <Header pageTitle="Students" onFiltersClick={toggleIsMobileFiltersOpen}>
        <div className="title-content">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Search
                value={selectedStudent}
                options={searchOptions}
                searchRequest={searchRequest}
                updateSelectedItem={setSelectedStudent}
              />
            </div>
            <div className="inputs-group-item">
              <Filters
                isMobileFiltersOpen={isMobileFiltersOpen}
                toggleIsMobileFiltersOpen={toggleIsMobileFiltersOpen}
              />
            </div>
          </div>
        </div>
      </Header>
      <div className="page-content">
        <StudentsTable />
      </div>
      {isRenderPagination && (
        <Pagination
          countPages={countPages}
          selectedPage={paginationData.current_page}
          updateSelectedPage={changSelectedPage}
          paginationClassName="pagination-holder"
        />
      )}
      {isOpenModalErrorLevel && <StudentLevelError isOpen={isOpenModalErrorLevel} />}
      {isOpenModalDeleteStudentFromGroup && (
        <DeleteStudentFromGroup
          isOpen={isOpenModalDeleteStudentFromGroup}
          closeModal={closeModalDeleteStudentFromGroup}
          studentData={modalData}
          deleteStudentFromGroup={deleteStudentFromGroup}
        />
      )}
    </>
  );
}

export default observer(Students);
