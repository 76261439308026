import React from 'react';
import classNames from 'classnames';

import { IFormInputProps } from './interface';

export default ({
  field,
  form: { touched, errors, submitCount },
  inputType = 'text',
  ...props
}: IFormInputProps) => {
  const { label } = props;

  // @ts-ignore
  const error = errors[field.name];

  // @ts-ignore
  const fieldTouchedHasError = (touched[field.name] && error) || (submitCount && error);
  const classError = classNames('form-field', { 'has-error': fieldTouchedHasError });

  return (
    <div className="form-group">
      <div className={classError}>
        {label && (
          <label htmlFor="" className="input-label">
            {label}
          </label>
        )}
        <input className="input" type={inputType} {...field} {...props} />
      </div>
      {Boolean(fieldTouchedHasError) && <span className="form-group-error">{error}</span>}
    </div>
  );
};
