import { EXPAND_MORE, EXPAND_LESS } from 'components/common/ColumnValueInTable/constants';
import { ISortingData } from 'components/common/ColumnValueInTable/interface';

export const getTableValues = (isMobile: boolean): ISortingData[] => [
  {
    title: 'Name',
    value: 'surname',
    sortingArrow: EXPAND_MORE,
    itemWidth: isMobile ? 'w-50' : 'w-15',
    isActive: true
  },
  {
    title: 'Email',
    value: 'email',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  },
  {
    title: 'Status',
    value: 'status_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: isMobile ? 'w-50' : 'w-10',
    isActive: false
  },
  {
    title: 'Group',
    value: 'study_group_name',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-10 m-hidden',
    isActive: false
  }
];

export const getLeadTableValues = (isMobile = false) : ISortingData[] => [
  ...getTableValues(isMobile),
  {
    title: 'Speaking level',
    value: 'speaking_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  },
  {
    title: 'Reading level',
    value: 'writing_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  },
  {
    title: 'Recommended level',
    value: 'recommended_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  }
];

export const getAdminTableValues = (isMobile = false) : ISortingData[] => [
  ...getTableValues(isMobile),
  {
    title: 'Recommended level',
    value: 'recommended_english_level_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  },
  {
    title: 'Department / Group',
    value: 'department_id',
    sortingArrow: EXPAND_LESS,
    itemWidth: 'w-15 m-hidden',
    isActive: false
  }
];
