import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';

import RootStore from 'store';

import { DRAG_TYPE } from 'components/Departments/constant';
import { IStudentData } from 'components/Students/interface';

import User from 'components/common/User';

const Student = (props: IStudentData) => {
  const { userId, fullName, departmentGroupName, photo } = props;

  const { departmentsStore } = useContext(RootStore);
  const { setRemoveStudentModalState } = departmentsStore;

  const [, drag] = useDrag({
    item: { type: DRAG_TYPE.student, userData: { userId } },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const deleteUserFromGroup = () => {
    const params = {
      userId,
      fullName,
      studyGroupName: departmentGroupName
    };

    setRemoveStudentModalState(params);
  };

  return (
    <div ref={drag} className="card is-draggable">
      <div className="panel-summary">
        <div className="panel-header">
          <div className="panel-drag-icon" />
          <div className="panel-item w-40">
            <User hasRole={false} userIcon={photo} userName={fullName} />
          </div>
          <div className="panel-item panel-navigation">
            <button className="panel-action dangerous" type="button" onClick={deleteUserFromGroup}>
              <i className="material-icons">delete</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
