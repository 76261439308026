import React from 'react';
import { useDrag } from 'react-dnd';

import User from 'components/common/User';

import { IDragItemProps } from './interface';

export default function DragItem(props: IDragItemProps) {
  const { data, transmittedData, dragType, deleteUserFromGroup } = props;
  const { fullName, recommendedEnglishLevelName } = data;

  const [, drag] = useDrag({
    item: { type: dragType, userData: transmittedData },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag} className="card is-draggable">
      <div className="panel-summary">
        <div className="panel-header">
          <div className="panel-drag-icon" />
          <div className="panel-item w-40">
            <User hasRole={false} userIcon="" userName={fullName} />
          </div>
          {recommendedEnglishLevelName && (
            <div className="panel-item">
              {recommendedEnglishLevelName}
            </div>
          )}
          {deleteUserFromGroup && (
            <div className="panel-item panel-navigation">
              <button
                className="panel-action dangerous"
                type="button"
                onClick={deleteUserFromGroup}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
