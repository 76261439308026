/* eslint camelcase: 0 */
import { ENGLISH_LEVEL_ID, STUDY_GROUP_TITLE, TEACHER_USER_ID } from './constants';

export const validate = (values: any): any => {
  const { study_group_title, teacher_user_id, english_level_id } = values;
  const errors: any = {};

  if (!study_group_title) {
    errors[STUDY_GROUP_TITLE] = 'Title is required';
  }

  if (!teacher_user_id) {
    errors[TEACHER_USER_ID] = 'Select a teacher';
  }

  if (ENGLISH_LEVEL_ID in values && !english_level_id) {
    errors[ENGLISH_LEVEL_ID] = 'Select a level';
  }

  return errors;
};
