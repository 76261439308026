import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';

import { getBackend, getBackendOptions } from 'components/common/DrugAndDrop/service';
import RootStore from 'store';
import { removeStudentFromDepartmentGroup } from 'api/department';
import DeleteStudentFromGroup from 'components/Modals/Student/DeleteStudentFromGroup';
import Header from 'components/common/Header';

import StudentList from './StudentList';
import GroupList from './GroupList';
import Filters from './Filters';
import { useStateModal } from '../Modals/helper';
import ModalDepartmentGroup from './ModalDepartmentGroup';

const Departments: React.FC = () => {
  const { departmentsStore, serviceStore: { isMobile } } = useContext(RootStore);
  const [isMobileFiltersOpen, toggleMobileFilters]: any = useStateModal(false);
  const [isAddNewModalOpen, toggleAddNew]: any = useStateModal(false);

  const {
    getDepartments,
    modalsData: { removeStudentModal },
    setRemoveStudentModalState
  } = departmentsStore;

  const { state, studentData } = removeStudentModal;

  useEffect(() => {
    getDepartments();
  }, []);

  const deleteStudentFromGroup = async () => {
    await removeStudentFromDepartmentGroup({
      user_id: studentData.userId
    });

    await getDepartments();

    setRemoveStudentModalState();
  };

  const closeModalDeleteStudent = () => {
    setRemoveStudentModalState();
  };

  const toggleAddNewGroupModal = () => {
    toggleAddNew();
    getDepartments();
  };

  return (
    <>
      <Header
        pageTitle="Manage departments"
        onFiltersClick={toggleMobileFilters}
        onAddClick={toggleAddNew}
      >
        <div className="title-content">
          <Filters
            isMobileFiltersOpen={isMobileFiltersOpen}
            toggleMobileFilters={toggleMobileFilters}
            toggleAddNew={toggleAddNew}
          />
        </div>
      </Header>
      <div className="page-content">
        <div className="groups">
          <DndProvider backend={getBackend(isMobile)} options={getBackendOptions(isMobile)}>
            <div className="page-col d-flex flex-column">
              <StudentList />
            </div>
            <div className="page-col is-stretched d-flex flex-column">
              <GroupList />
            </div>
          </DndProvider>
        </div>
      </div>
      {state && (
        <DeleteStudentFromGroup
          isOpen={state}
          closeModal={closeModalDeleteStudent}
          studentData={studentData}
          deleteStudentFromGroup={deleteStudentFromGroup}
        />
      )}
      <ModalDepartmentGroup isOpen={isAddNewModalOpen} toggleModal={toggleAddNewGroupModal} />
    </>
  );
};

export default observer(Departments);
