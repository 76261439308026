import React, { useContext, useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import RootStore from 'store';

import { ISelectItem } from 'types';

import { PLACEHOLDERS } from 'constants/forms';
import { DEFAULT_OPTION } from 'components/common/FormFields/Selects/constant';
import { formFields } from 'components/common/FormComponent/formFields';

import { IModalDepartmentGroupData, IValidGroupRequestParams } from '../interface';
import { FIELDS_NAMES } from './constants';
import * as helpers from './helpers';

const FormDepartmentGroup: React.FC<
  IModalDepartmentGroupData & FormikProps<IValidGroupRequestParams>
> = (props: IModalDepartmentGroupData & FormikProps<IValidGroupRequestParams>) => {
  const {
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isValid,
    submitForm,
    departmentId,
    leadUserId
  } = props;

  const { departmentsStore } = useContext(RootStore);
  const {
    filter: { departments }
  } = departmentsStore;

  const [selectedDepartment, setDepartment] = useState(
    helpers.getDefaultDepartment(departments.options, departmentId)
  );

  const [isDisabledLeadSelect, setDisabledLeadSelect] = useState(!departmentId);

  const [students, setStudents] = useState(DEFAULT_OPTION);
  const [leads, setLead] = useState(null);

  const btnClassName = classNames('btn-default', { 'btn-disabled': !isValid });

  const setDepartmentStudents = async (selectedDepartmentId: number) => {
    const departmentStudents = await helpers.getDepartmentStudents(selectedDepartmentId);

    setStudents(departmentStudents);

    if (leadUserId) {
      const departmentLeads = departmentStudents.find(({ value }) => value === leadUserId);

      setLead(departmentLeads);
    }

    setDisabledLeadSelect(false);
  };

  useEffect(() => {
    if (selectedDepartment) {
      setDepartmentStudents(selectedDepartment.value);
    }
  }, [selectedDepartment]);

  const onChangeDepartment = async (data: ISelectItem) => {
    setDepartment(data);

    await setDepartmentStudents(data.value);

    setFieldValue(FIELDS_NAMES.departmentId, data.value);
  };

  const onBlurDepartment = () => setFieldTouched(FIELDS_NAMES.departmentId);

  const onSelectLeads = (data: ISelectItem) => {
    setLead(data);
    setFieldValue(FIELDS_NAMES.leadId, data.value);
  };

  const onBlurLeads = () => setFieldTouched(FIELDS_NAMES.leadId);

  return (
    <form onSubmit={handleSubmit} className="container modal-container">
      <Field
        name={FIELDS_NAMES.departmentGroupName}
        placeholder={PLACEHOLDERS.groupName}
        component={formFields.FormInput}
      />
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-12 modal-col">
          <Field
            name={FIELDS_NAMES.departmentId}
            component={formFields.FormSelect}
            options={departments.options}
            value={selectedDepartment}
            placeholder={PLACEHOLDERS.department}
            onBlur={onBlurDepartment}
            onChange={onChangeDepartment}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 col-sm-12 modal-col">
          <Field
            name={FIELDS_NAMES.leadId}
            component={formFields.FormSelect}
            options={students}
            value={leads}
            placeholder={PLACEHOLDERS.lead}
            isDisabled={isDisabledLeadSelect}
            onBlur={onBlurLeads}
            onChange={onSelectLeads}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" disabled={!isValid} className={btnClassName} onClick={submitForm}>
          send request
        </button>
      </div>
    </form>
  );
};

export default observer(FormDepartmentGroup);
