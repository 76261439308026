import React from 'react';
import { ClipLoader } from 'react-spinners';

interface ISpinnerProps {
  isLoading: boolean;
}

export default function Spinner({ isLoading }: ISpinnerProps) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="spinner">
      <div className="spinner-inner">
        <ClipLoader
          sizeUnit="px"
          size={100}
          loading={isLoading}
          color="#0589ff"
        />
      </div>
    </div>
  );
}
