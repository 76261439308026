import {
  EXPAND_LESS, UP_ARROW, DOWN_ARROW, EXPAND_MORE
} from './constants';
import { ISortingArrowType, ISortingData } from './interface';

export const getSortingStatus = (sortingArrow: ISortingArrowType) => {
  if (sortingArrow === EXPAND_LESS) {
    return UP_ARROW;
  }

  return DOWN_ARROW;
};

export const getUpdatedSortedData = (sortedData: ISortingData[], titleSort: string) => sortedData.map((sortValue) => {
  const { title, sortingArrow } = sortValue;

  if (titleSort === title) {
    return {
      ...sortValue,
      sortingArrow: sortingArrow === EXPAND_MORE ? EXPAND_LESS : EXPAND_MORE,
      isActive: true
    };
  }

  return {
    ...sortValue,
    sortingArrow: EXPAND_LESS,
    isActive: false
  };
});
