import React, { useContext, useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { initialAddTeacherKey, initialAddTeacher } from 'components/Modals/AddNewTeacher/FormAddTestResult/constants';
import { PLACEHOLDERS } from 'constants/forms';

import { IPostTeacherParams } from 'components/Teachers/interface';
import { ISetIsFormValid } from 'components/Modals/AddNewTeacher/FormAddTestResult/interface';
import { ISelectItem } from 'types/index';

import { formFields } from 'components/common/FormComponent/formFields/index';

const FormComponentAddTestResult: React.FC<ISetIsFormValid & FormikProps<IPostTeacherParams>> = observer(
  (props: ISetIsFormValid & FormikProps<IPostTeacherParams>) => {
    const {
      handleSubmit, setFieldTouched, setFieldValue, isValid, setIsFormValid, resetForm
    } = props;

    const { teachers } = useContext(RootStore);
    const { filters, isOpenModalAddNewTeacher } = teachers;

    const [selectedType, changeType] = useState(null);

    useEffect(() => {
      setIsFormValid(isValid);
    }, [isValid]);

    useEffect(() => {
      resetForm(initialAddTeacher);
    }, [isOpenModalAddNewTeacher]);

    const selectTeacherType = (data: ISelectItem) => {
      changeType(data);
      setFieldValue(initialAddTeacherKey.TYPE_ID, data.value);
    };

    return (
      <form onSubmit={handleSubmit} className="container modal-container">
        <Field name={initialAddTeacherKey.NAME} placeholder={PLACEHOLDERS.name} component={formFields.FormInput} />
        <Field
          name={initialAddTeacherKey.SURNAME}
          placeholder={PLACEHOLDERS.surname}
          component={formFields.FormInput}
        />
        <Field
          name={initialAddTeacherKey.USERNAME}
          placeholder={PLACEHOLDERS.username}
          component={formFields.FormInput}
        />
        <Field name={initialAddTeacherKey.EMAIL} placeholder={PLACEHOLDERS.email} component={formFields.FormInput} />
        <Field name={initialAddTeacherKey.SKYPE} placeholder={PLACEHOLDERS.skype} component={formFields.FormInput} />
        <Field
          name={initialAddTeacherKey.PHONE_NUMBER}
          placeholder={PLACEHOLDERS.phone}
          component={formFields.FormInput}
        />
        <div className="row modal-row form-group">
          <div className="col-xs-12 col-sm-12 modal-col">
            <Field
              name={initialAddTeacherKey.TYPE_ID}
              component={formFields.FormSelect}
              options={filters.teachersTypes}
              value={selectedType}
              placeholder={PLACEHOLDERS.lessonType}
              onBlur={() => setFieldTouched(initialAddTeacherKey.TYPE_ID)}
              onChange={(data: ISelectItem) => selectTeacherType(data)}
            />
          </div>
        </div>
        <Field
          name={initialAddTeacherKey.PASSWORD}
          placeholder={PLACEHOLDERS.password}
          component={formFields.FormInput}
        />
      </form>
    );
  }
);

export default FormComponentAddTestResult;
