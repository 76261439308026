import React from 'react';

import ProgressBar from 'components/common/ProgressBar';

interface IProps {
  disabled: boolean;
  label: string;
  name: string;
  prevValue?: number | null;
  progressClass: string;
  value: number;
}

const ProgressBarItem = (props: IProps): JSX.Element => {
  const {
    label, progressClass, value, prevValue, disabled, name
  } = props;

  return (
    <div className="progress-group">
      <ProgressBar
        name={name}
        progressClass={progressClass}
        progressValue={value}
        progressLabel={label}
        disabled={disabled}
        key={name}
      />
      {prevValue && <ProgressBar progressClass="progress-default" progressValue={prevValue} />}
    </div>
  );
};

export default ProgressBarItem;
