import { STUDENT, GROUP_LEAD } from 'constants/roles';
import {
  FILTER_PANEL_DATA_FOR_STUDENT,
  FILTER_PANEL_DATA_FOR_ADMIN,
  FILTER_PANEL_DATA_FOR_GROUP_LEAD
} from 'components/TestResults/SortPanel/constants';

export const getFilterPanelView = (role: string) => {
  switch (role) {
    case STUDENT:
      return FILTER_PANEL_DATA_FOR_STUDENT;
    case GROUP_LEAD:
      return FILTER_PANEL_DATA_FOR_GROUP_LEAD;
    default:
      return FILTER_PANEL_DATA_FOR_ADMIN;
  }
};
