export const getTrackStyle = (color: string) => {
  switch (color) {
    case 'progress-listen':
      return '#0589ff';
    case 'progress-read':
      return '#4fc07c';
    case 'progress-write':
      return '#fbc402';
    case 'progress-red':
      return '#fe6276';
    case 'progress-default':
      return '#c2c4d4';
    default:
      return null;
  }
};
