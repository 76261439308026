/* eslint camelcase: 0 */
import { SelectData } from 'types/filters';

import { getStudentsList } from 'api/students';

export const normalizeStudentData = (students: any[]) => students.map((student) => {
  const { name, surname, user_id } = student;

  return {
    value: user_id,
    label: `${surname} ${name}`
  };
});

export const getDefaultDepartment = (departments: SelectData[], departmentId: number) => {
  if (!departmentId) {
    return null;
  }

  return departments.find(({ value }) => value === departmentId);
};

export const getDepartmentStudents = async (selectedDepartmentId: number) => {
  const params = { department_id: selectedDepartmentId };

  const { data } = await getStudentsList(params);

  return normalizeStudentData(data.data.data_page);
};
