import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import FormComponent from 'components/common/FormComponent';

import ChildTestForm from './ChildTestForm';
import { IChildTestProps } from './interface';
import { IEventData } from '../../AddEvent/interface';
import { validChildEvent } from '../../AddEvent/helpers';
import { getInitial } from './helpers';

const ChildTest: React.FC<IChildTestProps> = observer(
  ({
    setFormData, setIsFormValid, childEventType, initialData
  }: IChildTestProps) => {
    const { addEventStore } = useContext(RootStore);
    const {
      testLevels,
      mainEventList,
      teachers,
      getCommonTeachers,
      getWritingLevels,
      getMainEventList
    } = addEventStore;

    useEffect(() => {
      if (!testLevels.length) {
        getWritingLevels();
      }

      if (!mainEventList.length) {
        getMainEventList();
      }

      if (!teachers.length) {
        getCommonTeachers();
      }
    }, [testLevels, mainEventList, teachers]);

    const bindSubmitForm = (data: IEventData) => {
      setFormData(data);
    };

    return (
      <FormComponent
        Component={ChildTestForm}
        bindSubmitForm={bindSubmitForm}
        otherProps={{
          testLevels,
          teachers,
          mainEventList,
          childEventType,
          setIsFormValid
        }}
        initialValues={getInitial(initialData)}
        validate={validChildEvent}
      />
    );
  }
);

export default ChildTest;
