import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RootStore from 'store';
import FormComponent from 'components/common/FormComponent';

import { compose } from 'recompose';
import { IRestorePassword, IRouterProps } from '../interfaces';
import RestorePasswordForm from './RestorePasswordForm';
import { confirmationPassword } from '../../../services/validation';

const RestorePassword: React.FC<IRouterProps> = (props: IRouterProps) => {
  const { authenticationStore } = useContext(RootStore);
  const { changePassword } = authenticationStore;
  const { match } = props;

  const submit = ({ password }: IRestorePassword): void => {
    const { token } = match.params;

    const requestData = {
      token,
      password
    };

    changePassword(requestData);
  };

  return (
    <FormComponent
      Component={RestorePasswordForm}
      onSubmit={submit}
      initialValues={{ password: '', confirmPassword: '' }}
      validate={confirmationPassword}
    />
  );
};

export default compose(
  withRouter,
  observer,
)(RestorePassword);
