import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import {
  ADMIN, SUPER_ADMIN, GROUP_LEAD, TEACHER
} from 'constants/roles';
import ReactSelect from 'components/common/FormFields/Selects';
import FiltersContainer from 'components/common/FiltersContainer';
import { IFiltersProps } from './types';

const TestResultsFilters: React.FC<IFiltersProps> = (props: IFiltersProps) => {
  const { testResults: { filter } } = useContext(RootStore);
  const { testNames, departmentGroups, studyGroupNames } = filter;
  const {
    selectedDepartmentGroup,
    toggleIsMobileFiltersOpen,
    changeDepartmentGroup,
    selectedStudyGroup,
    changeStudyGroup,
    selectedTest,
    permissions,
    changeTest,
    onApply,
    isOpen
  } = props;

  return (
    <FiltersContainer onApply={onApply} isOpen={isOpen} toggleModal={toggleIsMobileFiltersOpen}>
      <div className="title-content">
        <div className="inputs-group">
          {permissions([ADMIN, SUPER_ADMIN, TEACHER, GROUP_LEAD]) && (
            <div className="inputs-group-item">{/* <Search /> */}</div>
          )}
          {permissions([ADMIN, SUPER_ADMIN, TEACHER]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-medium"
                values={studyGroupNames}
                selectedValue={selectedStudyGroup}
                updateData={changeStudyGroup}
              />
            </div>
          )}
          {permissions([GROUP_LEAD]) && (
            <div className="inputs-group-item">
              <ReactSelect
                additionalClassName="is-big"
                values={departmentGroups}
                selectedValue={selectedDepartmentGroup}
                updateData={changeDepartmentGroup}
              />
            </div>
          )}
          {selectedTest && (
            <div className="inputs-group-item">
              <ReactSelect values={testNames} selectedValue={selectedTest} updateData={changeTest} />
            </div>
          )}
        </div>
      </div>
    </FiltersContainer>
  );
};

export default observer(TestResultsFilters);
