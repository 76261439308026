import { observable, action } from 'mobx';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

export default class SidebarStore {
  @observable isShowSidebar: boolean = false;

  @action toggleSidebar = (): void => {
    this.isShowSidebar = !this.isShowSidebar;

    if (this.isShowSidebar) {
      disableBodyScroll(document.body);
    } else {
      clearAllBodyScrollLocks();
    }
  };
}
