import { IFiltersData } from 'components/Teachers/interface';

export const DEFAULT_FILTERS: IFiltersData = {
  teachersTypes: []
};

export const ACTIVE_TEACHERS = 1;

export const DEFAULT_SORT_DATA = {
  sort_key: 'surname',
  sort_order: 4
};
