import React from 'react';

import FormComponent from 'components/common/FormComponent';
import { IPostTeacherParams } from 'components/Teachers/interface';
import FormComponentAddTeacher from './FormComponentAddTeacher';

import { validAddTeacher } from './validate';
import { initialAddTeacher } from './constants';
import { IAddNewTeacherProps } from './interface';

export default (props: IAddNewTeacherProps) => {
  const { setFormData, setIsFormValid } = props;

  const bindSubmitForm = (data: IPostTeacherParams) => {
    setFormData(data);
  };

  return (
    <FormComponent
      Component={FormComponentAddTeacher}
      otherProps={{
        setIsFormValid
      }}
      bindSubmitForm={bindSubmitForm}
      validate={(values: any) => validAddTeacher(values)}
      initialValues={initialAddTeacher}
    />
  );
};
