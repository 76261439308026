import React, { useContext } from 'react';

import RootStore from 'store';
import withModal from 'hocs/WithModal';
import { INormalAdminData, IPatchAdminParams } from 'components/Admins/interface';
import FormComponent from 'components/common/FormComponent';

import FormComponentEditAdminInfo from './FormComponentEditAdminInfo';
import { IModalProps } from '../../../Modals/interfaces';

const EditAdminInfo: React.FC<INormalAdminData & IModalProps> = (props: INormalAdminData & IModalProps) => {
  const { userId, email, toggleModal } = props;

  const { admins } = useContext(RootStore);
  const { editAdminInfo } = admins;

  const submitForm = async (data: IPatchAdminParams) => {
    await editAdminInfo(data);
    toggleModal();
  };

  return (
    <FormComponent
      Component={FormComponentEditAdminInfo}
      otherProps={props}
      initialValues={{
        admin_user_id: userId,
        email
      }}
      onSubmit={(data: IPatchAdminParams) => submitForm(data)}
    />
  );
};

export default withModal(EditAdminInfo);
