import { SelectData } from 'types/filters';

export const ALL_TESTS: SelectData = { label: 'All tests', value: null };

export const ALL_STATUSES: SelectData = { label: 'All statuses', value: null };

export const ALL_TEACHER_TYPES: SelectData = { label: 'All types', value: null };

export const ALL_TEACHERS: SelectData = { label: 'All teachers', value: null };

export const ALL_GROUPS: SelectData = { label: 'All groups', value: null };

export const ALL_DEPARTMENTS: SelectData = { label: 'All departments', value: null };

export const ALL_DEPARTMENTS_GROUPS: SelectData = { label: 'All department groups', value: null };
