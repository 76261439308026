import React from 'react';
import { FormikProps } from 'formik';

import EditFormSpeakingTestResult from './EditFormSpeakingTestResult';
import EditFormWritingTestResult from './EditFormWritingTestResult';
import EditFormRecommended from './EditFormRecommended';

import { IEditTestResultProps, IFormikProps } from './interface';

const FormComponentEditTestResult: React.FC<IEditTestResultProps & FormikProps<IFormikProps>> = (
  props: IEditTestResultProps & FormikProps<IFormikProps>
) => {
  const { initialTestResultData, handleSubmit, setFieldValue } = props;

  return (
    <form onSubmit={handleSubmit} className="container modal-container">
      <div className="accordion-content">
        <EditFormSpeakingTestResult initialTestResultData={initialTestResultData} setFieldValue={setFieldValue} />

        <EditFormWritingTestResult initialTestResultData={initialTestResultData} setFieldValue={setFieldValue} />

        <EditFormRecommended initialTestResultData={initialTestResultData} setFieldValue={setFieldValue} />
      </div>
    </form>
  );
};

export default FormComponentEditTestResult;
