export const eventsType = {
  LESSON: 'lesson',
  MAIN: 'main_event',
  WRITING: 'writing',
  SPEAKING: 'speaking'
};

export const buttonsGroupData = [
  {
    name: eventsType.LESSON,
    id: 'lesson',
    value: 'Lesson'
  },
  {
    name: eventsType.MAIN,
    id: 'main-test',
    value: 'Test'
  },
  {
    name: eventsType.WRITING,
    id: 'writing-test',
    value: 'Writing'
  },
  {
    name: eventsType.SPEAKING,
    id: 'speaking-test',
    value: 'Speaking'
  }
];

export const eventFormFields = {
  GROUP: 'groupId',
  EVENT_PARENT: 'eventParent',
  EVENT_LEVEL: 'eventLevel',
  TEACHER: 'teacherUserId',
  DATE: 'date',
  BEGIN_DATE: 'beginDate',
  END_DATE: 'endDate',
  DAY: 'day',
  ROOM: 'room',
  BEGIN_TIME: 'beginTime',
  END_TIME: 'endTime',
  NAME: 'name'
};

export const days = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' }
];
