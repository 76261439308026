import React, { useContext } from 'react';
import classNames from 'classnames';

import RootStore from 'store';
import { IHeaderProps } from './interface';

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const {
    pageTitle, children, onAddClick, onFiltersClick
  } = props;
  const {
    sidebar: { isShowSidebar, toggleSidebar }
  } = useContext(RootStore);

  const headerClassName = classNames('header-nav', { 'side-is-open': isShowSidebar });

  const addHandler = () => { onAddClick(); };

  return (
    <header className="header">
      <nav className={headerClassName}>
        <button
          className="header-controls header-menu"
          type="button"
          onClick={() => toggleSidebar()}
        />
        <div className="header-btn-group">
          {onAddClick && (
            <button className="header-controls header-add" type="button" onClick={addHandler}>
              <i className="material-icons">add</i>
            </button>
          )}
          {onFiltersClick && (
            <button className="header-controls header-filter" type="button" onClick={onFiltersClick} />
          )}
        </div>
      </nav>
      {/* TODO add class "is-sticky" for scrolling */}
      <div className="header-title">
        <div className="header-title-marker" />
        {pageTitle}
      </div>
      <div className="header-content">
        {children}
      </div>
    </header>
  );
};

export default Header;
