import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { IDepartmentGroup } from 'components/Departments/interfaces';

import Header from './Header';
import DetailInfo from './DetailInfo';
import ModalDepartmentGroup from '../../ModalDepartmentGroup';

const GroupItem: React.FC<IDepartmentGroup> = (props: IDepartmentGroup) => {
  const {
    departmentId, departmentGroupId, departmentGroupName, students, leads
  } = props;

  const { departmentsStore } = useContext(RootStore);
  const { getDepartments } = departmentsStore;

  const [isOpenDetailInfo, setOpenDetailInfo] = useState(true);
  const [isOpenModal, setModalState] = useState(false);

  const toggleDetailInfo = () => {
    setOpenDetailInfo(!isOpenDetailInfo);
  };

  const toggleModal = (state: boolean) => {
    setModalState(state);

    getDepartments();
  };

  const [leadUserId] = leads.map(({ userId }) => userId);

  return (
    <div className="accordion-thumb">
      <Header
        groupName={departmentGroupName}
        isOpenDetailInfo={isOpenDetailInfo}
        toggleDetailInfo={toggleDetailInfo}
        setModalState={setModalState}
      />
      {isOpenDetailInfo && (
        <div className="panel-details">
          <div className="group">
            <DetailInfo
              departmentId={departmentId}
              departmentGroupId={departmentGroupId}
              departmentGroupName={departmentGroupName}
              students={students}
              leads={leads}
            />
          </div>
        </div>
      )}
      <ModalDepartmentGroup
        isOpen={isOpenModal}
        toggleModal={toggleModal}
        modalData={{
          departmentGroupId,
          departmentGroupName,
          departmentId,
          leadUserId
        }}
      />
    </div>
  );
};

export default observer(GroupItem);
