import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import RootStore from 'store';
import WithPermissions from 'hocs/WithPermissions';
import { IPermissions } from 'types';
import { ADMIN, SUPER_ADMIN } from 'constants/roles';
import {
  STATUS_WAITING
} from 'components/Students/StudentsTable/StudentInfo/Actions/constants';
import { IActionsProps } from 'components/Students/StudentsTable/StudentInfo/Actions/interface';
import EditStudentInfo from 'components/Students/StudentsTable/EditStudentInfo';

import { useStateModal } from '../../../../Modals/helper';
import SelectGroup from '../SelectGroup';

const Actions: React.FC<IActionsProps & IPermissions> = observer((props: IActionsProps & IPermissions) => {
  const {
    permissions, userId, statusId, recommendedEnglishLevelId, teacherTypeId, studyGroupId, student
  } = props;
  const [isEditModalOpen, toggleEditModal]: any = useStateModal(false);

  const { students, serviceStore: { isMobile } } = useContext(RootStore);

  const {
    openModalDeleteStudentFromGroup
  } = students;

  const isRenderDeleteForAdmin = studyGroupId && statusId !== STATUS_WAITING;
  const isRenderSelectForAdmin = !isRenderDeleteForAdmin && teacherTypeId && recommendedEnglishLevelId;

  const isRenderSelectForLead = !teacherTypeId;

  const isRenderDelete = permissions([ADMIN, SUPER_ADMIN]) ? isRenderDeleteForAdmin : teacherTypeId;
  const isRenderSelect = permissions([ADMIN, SUPER_ADMIN]) ? isRenderSelectForAdmin : isRenderSelectForLead;

  const removeStudentFromGroup = () => {
    openModalDeleteStudentFromGroup(userId, permissions([ADMIN, SUPER_ADMIN]));
  };

  const editStudentInfo = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    toggleEditModal();
  };

  const panelNavigationClassName = cn('panel-navigation', {
    'is-hidden': !isMobile
  });

  return (
    <div className={panelNavigationClassName}>
      {permissions([ADMIN, SUPER_ADMIN]) && (
        <button className="accordion-button-edit" type="button" onClick={editStudentInfo}>
          <i className="material-icons">edit</i>
        </button>
      )}
      {isRenderDelete && (
        <button className="accordion-button-edit" type="button" onClick={removeStudentFromGroup}>
          <i className="material-icons">delete</i>
        </button>
      )}
      {isRenderSelect && !isMobile && (
        <SelectGroup {...props} />
      )}
      {isMobile && <i className="material-icons accordion-arrow">keyboard_arrow_down</i>}
      <EditStudentInfo {...student} isOpen={isEditModalOpen} toggleModal={toggleEditModal} />
    </div>
  );
});

export default WithPermissions(Actions);
