import React, { useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import classNames from 'classnames';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import Button from 'components/common/FormFields/Button';
import { IFormValues, IOtherProps } from '../interfaces';
import { FORGOT_PASSWORD_SUBTITLE } from '../constants';

const ForgotPasswordForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { handleSubmit, errors, handleReset, setIsLoginForm } = props;

  useEffect(() => {
    handleReset();
  }, []);

  const shouldDisableSubmit = Boolean(Object.keys(errors).length);
  const btnClassName = classNames('btn-default btn-wide', { 'btn-disabled': shouldDisableSubmit });

  return (
    <form onSubmit={handleSubmit} className="auth-form sign-in">
      <h1 className="auth-title">Password Recovery</h1>
      <span className="auth-subtitle">{FORGOT_PASSWORD_SUBTITLE}</span>
      <Field
        name="username"
        placeholder="username"
        component={FormInput}
      />
      <Button
        className={btnClassName}
        disabled={shouldDisableSubmit}
      >
        Send
      </Button>
      <span
        className="go-back-login"
        tabIndex={0}
        role="button"
        onClick={() => setIsLoginForm(true)}
      >
        Back to Login
      </span>
    </form>
  );
};

export default ForgotPasswordForm;
