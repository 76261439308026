import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/img/logo.png';

export default () => (
  <Link to="/" className="logo">
    <img className="logo-img" src={logo} alt="logo" />
  </Link>
);
