import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { isEqual } from 'lodash';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import WithPermissions from 'hocs/WithPermissions';
import defaultAvatar from 'assets/img/face-01.png';
import { errorMassage, PROFILE_FORM } from 'constants/forms';
import CropImageUploader from 'components/common/CropImageUploader';
import {
  ADMIN, GROUP_LEAD, STUDENT, SUPER_ADMIN, TEACHER
} from 'constants/roles';
import { phone as phonePattern } from 'services/patterns';

import RootStore from '../../store';
import { formFields } from '../common/FormComponent/formFields';
import { SelectData } from '../../types/filters';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { useStateModal } from '../Modals/helper';

import './styles.scss';

function ProfileForm(props: any) {
  const {
    authenticationStore: { deletePhoto },
    teachers: { teachersTypes }
  } = useContext(RootStore);
  const [editMode, setEditMode] = useState(false);
  const [isConfirmDeleteOpen, toggleConfirmDeleteState, setConfirmDeleteState]: any = useStateModal(false);
  const {
    values,
    errors,
    permissions,
    handleReset,
    submitCount,
    handleSubmit,
    validateForm,
    setFieldValue,
    initialValues
  } = props;

  const [photo, setPhoto] = useState(initialValues.photo || defaultAvatar);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  const isTeacher = permissions([TEACHER]);
  const isAdmin = permissions([ADMIN]);
  const isLdap = permissions([GROUP_LEAD, STUDENT, SUPER_ADMIN]);
  const isSubmitted = !!submitCount;

  const required = (field: string) => (value: any) => (value ? undefined : `${field} is required`);

  const phoneValidate = (value: any) => {
    if (!value) {
      return 'Phone is required';
    }
    const isPhoneHasInvalidFormat = !phonePattern.test(values.phone);

    return isPhoneHasInvalidFormat ? errorMassage.phone : undefined;
  };

  const file = (
    <div className="avatar">
      {editMode && (
        <>
          <div className="avatar-control">
            <i className="material-icons">
              create
            </i>
            <CropImageUploader
              onSelect={(image) => {
                setPhoto(URL.createObjectURL(image));
                setFieldValue('photo', image);
              }}
            />
          </div>
          {initialValues.photo && (
          <div
            className="avatar-control delete"
            onClick={() => { setConfirmDeleteState(true); }}
          >
            <i className="material-icons">
              delete_outline
            </i>
          </div>
          )}
        </>
      )}
      <div className="avatar-media">
        <img className="avatar-img" src={photo} alt="avatar" />
      </div>
      {!editMode && (
        <div className="avatar-control default">
          <i className="material-icons">
            camera_alt
          </i>
        </div>
      )}
    </div>
  );
  const passwordChange = editMode && (
    <div className="profile-section">
      <div className="profile-title">Password</div>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-6">
          <Field
            name={PROFILE_FORM.password}
            label="Password"
            type="password"
            component={FormInput}
            autoComplete="new-password"
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Field
            name={PROFILE_FORM.confirmPassword}
            label="Confirm Password"
            type="password"
            component={FormInput}
            autoComplete="new-password"
            isSubmitted={isSubmitted}
          />
        </div>
      </div>
    </div>
  );

  const nameSection = (
    <>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-6">
          <Field
            name={PROFILE_FORM.name}
            label="Name"
            component={FormInput}
            validate={required('Name')}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Field
            name={PROFILE_FORM.surname}
            label="Surname"
            component={FormInput}
            validate={required('Surname')}
          />
        </div>
      </div>
    </>
  );

  const renderTeacherType = () => (editMode ? (
    <Field
      name={PROFILE_FORM.type}
      component={formFields.FormSelect}
      options={teachersTypes}
      value={values.type}
      onChange={(data: SelectData) => {
        setFieldValue(PROFILE_FORM.type, data);
      }}
    />
  ) : (
    <Field
      label="Type"
      component={FormInput}
      value={values.type.label}
    />
  ));

  const generalInfo = (
    <div className="profile-section">
      <div className="profile-title">General info</div>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-12">
          <Field
            name={PROFILE_FORM.username}
            label="User Name"
            component={FormInput}
            validate={required('User Name')}
          />
          {isTeacher && renderTeacherType()}
          {nameSection}
        </div>
      </div>
    </div>
  );

  const phone = (
    <Field
      name={PROFILE_FORM.phone}
      label="Phone"
      type="tel"
      component={FormInput}
      validate={phoneValidate}
    />
  );

  const skype = (
    <Field
      name={PROFILE_FORM.skype}
      label="Skype"
      component={FormInput}
      validate={required('Skype')}
    />
  );

  const contactsSection = (
    <div className="profile-section">
      <div className="profile-title">Contacts</div>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-6">
          {phone}
        </div>
        <div className="col-xs-12 col-sm-6">
          {skype}
        </div>
      </div>
      {isTeacher
      && (
        <div className="row form-group">
          <div className="col-xs-12 col-sm-12">
            <Field
              name={PROFILE_FORM.email}
              label="Email"
              component={FormInput}
              validate={required('email')}
            />
          </div>
        </div>
      )
      }
    </div>
  );

  const adminContent = isAdmin && (
    <>
      {generalInfo}
      {passwordChange}
    </>
  );

  const teacherContent = isTeacher && (
    <>
      <div className="row form-group">
        <div className="col-xs-12 col-sm-6">
          {generalInfo}
        </div>
        <div className="col-xs-12 col-sm-6">
          {contactsSection}
        </div>
        <div className="col-xs-12">
          {passwordChange}
        </div>
      </div>
    </>
  );

  const ldapUser = isLdap && (
    <>
      {contactsSection}
    </>
  );

  const formClassName = classNames('profile-form', {
    'edit-mode': editMode
  });

  const toggleMode = () => {
    setEditMode(!editMode);
  };

  const isFormValid = isEqual({}, errors);

  const submit = (data: any) => {
    handleSubmit(data);

    if (isFormValid) {
      toggleMode();
    }
  };

  const cancelHandler = () => {
    toggleMode();
    handleReset();
  };

  const isSubmitBtnDisabled = submitCount && !isFormValid;

  const btnClassName = classNames('btn-default', {
    'btn-disabled': isSubmitBtnDisabled
  });

  const buttonGroup = (
    <div className="d-flex justify-content-end">
      {!editMode && <button className="btn-default" type="button" onClick={toggleMode}>Edit</button>}
      {editMode
      && (
        <div className="inputs-group">
          <div className="inputs-group-item">
            <button className="btn-border" type="button" onClick={cancelHandler}>Cancel</button>
          </div>
          <div className="inputs-group-item">
            <button className={btnClassName} type="submit" disabled={isSubmitBtnDisabled}>Save</button>
          </div>
        </div>
      )}
    </div>
  );

  const deletePhotoRequest = async () => {
    await deletePhoto();
    setPhoto(defaultAvatar);
    toggleConfirmDeleteState();
  };

  return (
    <div className="card">
      <ConfirmationModal
        isOpen={isConfirmDeleteOpen}
        title="Delete photo"
        toggleModal={toggleConfirmDeleteState}
        onSubmit={deletePhotoRequest}
      />
      <form autoComplete="off" className={formClassName} onSubmit={submit}>
        <div className="d-flex">
          <div className="flex-grow-0">
            {file}
          </div>
          <div className="flex-grow-1">
            {adminContent}
            {teacherContent}
            {ldapUser}
            {buttonGroup}
          </div>
        </div>
      </form>
    </div>
  );
}

export default compose(
  WithPermissions,
  observer,
)(ProfileForm);
