export const initialValueNames = {
  EVENT_PARENT_ID: 'event_parent_id',
  EVENT_LEVEL_ID: 'event_level_id',
  USER_ID: 'user_id',
  HOLDING: 'holding',
  COMMENT: 'comment',
  RECOMMENDED_ENGLISH_LEVEL_ID: 'recommended_english_level_id',
  SPEAKING_ENGLISH_LEVEL_ID: 'speaking_english_level_id',
  FLUENCY: 'fluency',
  ACCURACY: 'accuracy',
  VOCABULARY: 'vocabulary',
  SPEAKING_GRAMMAR: 'speaking_grammar',
  COMMUNICATIVENESS: 'communicativeness',
  WRITING_ENGLISH_LEVEL_ID: 'writing_english_level_id',
  LISTENING: 'listening',
  WRITING_GRAMMAR: 'writing_grammar',
  WRITING: 'writing',
  READING: 'reading',
  PARAPHRASE: 'paraphrase',
  ESSAY: 'essay'
};
