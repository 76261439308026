import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import FormComponent from 'components/common/FormComponent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ProfileForm from './ProfileForm';
import validate from './validate';
import { removeNullFields } from '../../services/helper';
import Header from '../common/Header';

function Profile() {
  const {
    authenticationStore: { user, userDataInitialized, updateProfile },
    teachers: { getTeachersTypes, teachersTypes }
  } = useContext(RootStore);

  useEffect(() => {
    getTeachersTypes();
  }, []);

  const processedUser = removeNullFields(user);

  if (!userDataInitialized) {
    return null;
  }

  if (!teachersTypes.length) { return null; }

  const getTeacherTypeValue = (type: string) => teachersTypes.find(item => item.label === type);

  const initialValues = { ...processedUser, type: getTeacherTypeValue(processedUser.type) };

  return (
    <>
      <Header pageTitle="Profile" />
      <div className="page-content">
        <PerfectScrollbar className="perfect-scrollbar">
          <FormComponent
            Component={ProfileForm}
            initialValues={initialValues}
            onSubmit={updateProfile}
            validate={validate}
          />
        </PerfectScrollbar>
      </div>
    </>
  );
}

export default observer(Profile);
