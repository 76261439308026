import React, { useState } from 'react';

import { INormalStudyGroupType } from 'components/AddToGroup/interfaces';

import { MAX_STUDENT_IN_GROUP } from './constant';
import { IGeneralGroupInformation } from './interfaces';

import Header from './Header';
import DetailInfo from './DetailInfo';

const GroupItem: React.FC<INormalStudyGroupType> = (props: INormalStudyGroupType) => {
  const { id, name, students, teacher } = props;

  const [isOpenDetailInfo, setOpenDetailInfo] = useState(true);

  const toggleDetailInfo = () => {
    setOpenDetailInfo(!isOpenDetailInfo);
  };

  const studentsInGroup = students.length;
  const emptySeats = MAX_STUDENT_IN_GROUP - studentsInGroup;

  // TODO: remove when this params add to group data
  const timeTables: IGeneralGroupInformation[] = [
    {
      groupId: id,
      dayOfWeek: 'Wed',
      timeStart: '11:00',
      timeEnd: '12:00',
      room: '504'
    },
    {
      groupId: id,
      dayOfWeek: 'Mon',
      timeStart: '11:00',
      timeEnd: '12:00',
      room: '503d'
    }
  ];

  return (
    <div className="accordion-thumb">
      <Header
        groupName={name}
        emptySeats={emptySeats}
        isOpenDetailInfo={isOpenDetailInfo}
        toggleDetailInfo={toggleDetailInfo}
      />
      {isOpenDetailInfo && (
        <DetailInfo
          groupId={id}
          groupName={name}
          timeTables={timeTables}
          students={students}
          teacher={teacher}
          emptySeats={emptySeats}
        />
      )}
    </div>
  );
};

export default GroupItem;
