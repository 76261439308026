import React from 'react';
import { NavLink } from 'react-router-dom';
import { INavigationItem } from './interfaces';

export const renderNavLinkElement = (navigationItem: INavigationItem, index?: number): JSX.Element => (
  <li key={index} className="nav-list-item">
    <NavLink to={navigationItem.route} className="nav-list-item-link" activeClassName="is-active">
      <i className="nav-list-item-icon material-icons">{navigationItem.iconName}</i>
      {navigationItem.name}
    </NavLink>
  </li>
);

export const renderNavigationList = (items: INavigationItem[]): JSX.Element => (
  <ul className="nav-list">
    {
      items.map((item: INavigationItem, index: number): JSX.Element => renderNavLinkElement(item, index))
    }
  </ul>
);
