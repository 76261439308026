import React from 'react';

import { OPTIONS } from 'components/AttendanceLog/AttendanceLogUserRow/constants';

import User from 'components/common/User';
import AttendanceLogEventCell from '../AttendanceLogEventCell';
import { setEventStatus } from '../utils';
import { IMonthCountDay } from '../interfaces';
import { IAttendanceLogUserRowProps } from './interfaces';

const AttendanceLogUserRow: React.FC<IAttendanceLogUserRowProps> = (
  props: IAttendanceLogUserRowProps
) => {
  const {
    canUserEdit, monthDays, attendances, updateAttendance
  } = props;

  const {
    studentUserName, studentUserSurname, studentUserPhoto, userEvents
  } = attendances;

  return (
    <tr>
      <td className="fixed-cell attendance-cell">
        <User
          hasRole={false}
          userIcon={studentUserPhoto}
          userName={`${studentUserName} ${studentUserSurname}`}
          userRole="name"
        />
      </td>
      {monthDays.map(
        (day: IMonthCountDay): JSX.Element => {
          const { status, eventStudentId } = setEventStatus(day, userEvents);

          return (
            <td className="attendance-cell" key={`AttendanceLogUserRow-${day.day}-${day.date}`}>
              <AttendanceLogEventCell
                status={status}
                options={OPTIONS}
                eventStudentId={eventStudentId}
                updateAttendance={updateAttendance}
                canUserEdit={canUserEdit}
              />
            </td>
          );
        }
      )}
    </tr>
  );
};

export default AttendanceLogUserRow;
