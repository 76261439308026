import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';

import Pagination from 'components/common/Pagination';
import Header from 'components/common/Header';
import WithPermissions from 'hocs/WithPermissions';
import { ADMIN, SUPER_ADMIN } from 'constants/roles';
import GroupRow from './GroupRow';
import SortPanel from './SortPanel';
import AddGroupModal from './Modals/AddGroup';
import * as INTERFACES from './interfaces';
import { useStateModal } from '../Modals/helper';

const Groups: React.FC<INTERFACES.IGroupsParams> = observer((props: INTERFACES.IGroupsParams) => {
  const { permissions } = props;
  const { groups, serviceStore: { isMobile } } = useContext(RootStore);
  const {
    getStudyGroups,
    getTeachersList,
    getStudents,
    getTeacherTypes,
    updateSortParams,
    selectTeacherType,
    setSelectedTeacher,
    selectedTeacherType,
    removeStudentFromStudyGroup,
    SortParams,
    GroupsData,
    TeachersList,
    TeacherTypes,
    SelectedTeacher,
    NewGroupTitle,
    setNewGroupTitle,
    getEnglishLevels,
    LevelsList,
    setLevel,
    SelectedLevel,
    onStudentsInputChange,
    SelectedStudents,
    StudentsList,
    StudentsSearchString,
    setStudentsSearchString,
    setSelectedStudent,
    resetStudentsList,
    createStudyGroup,
    deleteStudyGroup,
    setEditingStudyGroup,
    EditingStudyGroup,
    setEditingStudyGroupTitle,
    setEditingStudyGroupTeacher,
    updateStudyGroup,
    PaginationParams,
    setCurrentPage
  } = groups;

  const isAdmin = permissions([ADMIN, SUPER_ADMIN]);
  const { currentPage, countOfPages } = PaginationParams;
  const selectedPage: number = currentPage + 1;
  const isPaginationShown: boolean = countOfPages > 1;

  const [isAddGroupModalOpen, toggleAddGroupModal, setIsAddGroupModalOpen]:any = useStateModal(false);

  useEffect(() => {
    (async () => {
      await getStudyGroups();
    })();
  }, [getStudyGroups, SortParams, currentPage]);

  return (
    <>
      <Header pageTitle="Groups" onAddClick={toggleAddGroupModal} />
      <div className="page-content">
        <PerfectScrollbar className="perfect-scrollbar">
          <SortPanel
            updateSortParams={updateSortParams}
            openAddGroupModal={() => setIsAddGroupModalOpen(true)}
            isAdmin={isAdmin}
          />
          {GroupsData && (
            <div className="accordion">
              {
                GroupsData.map((group: INTERFACES.IGroupsData) => (
                  <GroupRow
                    key={`GroupRow-${group.id}`}
                    group={group}
                    deleteStudent={removeStudentFromStudyGroup}
                    deleteStudyGroup={deleteStudyGroup}
                    setEditingStudyGroup={setEditingStudyGroup}
                    editingStudyGroup={EditingStudyGroup}
                    setEditingStudyGroupTitle={setEditingStudyGroupTitle}
                    setEditingStudyGroupTeacher={setEditingStudyGroupTeacher}
                    updateStudyGroup={updateStudyGroup}
                    getTeachersList={getTeachersList}
                    teachersList={TeachersList}
                    isAdmin={isAdmin}
                    isMobile={isMobile}
                  />
                ))}
            </div>
          )}
          <AddGroupModal
            isOpen={isAddGroupModalOpen}
            closeModal={() => setIsAddGroupModalOpen(false)}
            getTeacherTypes={getTeacherTypes}
            teacherTypes={TeacherTypes}
            selectedTeacherType={selectedTeacherType}
            selectTeacherType={selectTeacherType}
            teachersList={TeachersList}
            getTeachersList={getTeachersList}
            getStudents={getStudents}
            selectedTeacher={SelectedTeacher}
            setSelectedTeacher={setSelectedTeacher}
            newGroupTitle={NewGroupTitle}
            setNewGroupTitle={setNewGroupTitle}
            getEnglishLevels={getEnglishLevels}
            levelsList={LevelsList}
            setLevel={setLevel}
            selectedLevel={SelectedLevel}
            onStudentsInputChange={onStudentsInputChange}
            selectedStudents={SelectedStudents}
            studentsList={StudentsList}
            studentsSearchString={StudentsSearchString}
            setStudentsSearchString={setStudentsSearchString}
            setSelectedStudent={setSelectedStudent}
            resetStudentsList={resetStudentsList}
            createStudyGroup={createStudyGroup}
          />
        </PerfectScrollbar>
        {isPaginationShown && (
          <Pagination
            selectedPage={selectedPage}
            countPages={countOfPages}
            updateSelectedPage={setCurrentPage}
            paginationClassName="pagination-holder"
          />
        )}
      </div>
    </>
  );
});

export default WithPermissions(Groups);
