import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import RootStore from 'store';

export interface IWithPermissionsProps {
  children?: React.ReactNode;
  permissions?: (arrayRoles: string[]) => boolean;
}

const withPermissions = <T extends IWithPermissionsProps = IWithPermissionsProps>(
  Component: React.ComponentType<T>
) => {
  const WithPermissions: React.FC<T> = observer((props: any) => {
    const { authenticationStore } = useContext(RootStore);
    const {
      user: { role },
      loadUserRoleFromLocalStorage
    } = authenticationStore;

    useEffect(() => {
      if (!role) {
        loadUserRoleFromLocalStorage();
      }
    }, [loadUserRoleFromLocalStorage, role]);

    const permissions = (arrayValidRoles: string[]) => arrayValidRoles.some((elem) => elem === role);

    return role && <Component permissions={permissions} {...props} />;
  });

  return WithPermissions;
};

export default withPermissions;
