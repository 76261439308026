import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { getUpdatedSortedData } from 'components/Teachers/TeachersTable/TableHeader/helper';
import { getTableValues } from 'components/Teachers/TeachersTable/TableHeader/constant';

import ColumnValueInTable from 'components/common/ColumnValueInTable';

const TableHeader: React.FC = observer(() => {
  const { teachers, serviceStore: { isMobile } } = useContext(RootStore);
  const { changeSorted, updateModalAddNewTeacherState } = teachers;

  const [sortedPanel, updateSortedPanel] = useState(getTableValues(isMobile));

  const selectedFilter = (titleSort: string) => {
    const updatedSortData = getUpdatedSortedData(sortedPanel, titleSort);

    updateSortedPanel(updatedSortData);
    changeSorted(updatedSortData);
  };

  const openModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    updateModalAddNewTeacherState(true);
  };

  return (
    <div className="filter-panel">
      {sortedPanel.map((value) => (
        <ColumnValueInTable {...value} selectedFilter={selectedFilter} key={value.title} />
      ))}

      <div className="d-flex flex-grow-1 justify-content-end">
        <div className="filter-button-group">
          {!isMobile && (
            <button className="btn-default" onClick={openModal} type="button">
              + Add New
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default TableHeader;
