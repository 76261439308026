import React, { useEffect, useRef } from 'react';

import { TIME_LINE_DATE } from 'components/Calendar/constants/eventSetting';
import { TableBodyType } from '../../interface';

import Event from './Event';

const TableBody = (props: TableBodyType) => {
  const { scrollY, dayEvents, height, getHeight } = props;

  const ref = useRef(null);

  useEffect(() => {
    const heightTableBody = ref.current.clientHeight;

    getHeight(heightTableBody);
  });

  return (
    <tbody className="timeline-tbody" ref={ref}>
      {TIME_LINE_DATE.map((timeData, indexTime) => (
        <tr key={`${timeData.time + indexTime}`}>
          <Event timeEvent={timeData.time} scrollY={scrollY} />
          {dayEvents.map((event, indexEvent) => (
            <Event
              dayEvent={event}
              height={height}
              indexTime={indexTime}
              indexEvent={indexEvent}
              key={event.id}
            />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
