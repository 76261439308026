import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';

import Header from 'components/common/Header';
import AdminsTable from 'components/Admins/AdminsTable';
import DeleteAdmin from 'components/Modals/Admin/Delete';
import AddNewAdmin from 'components/Modals/Admin/AddNew';

const Admins: React.FC = observer(() => {
  const { admins } = useContext(RootStore);
  const { getAdminList, isOpenModalDeleteAdmin, isOpenModalAddNewAdmin, updateModalAddNewAdminState } = admins;

  useEffect(() => {
    getAdminList();
  }, []);

  return (
    <>
      <Header pageTitle="Admins" onAddClick={() => { updateModalAddNewAdminState(true); }} />
      <div className="page-content">
        <PerfectScrollbar className="perfect-scrollbar">
          <AdminsTable />
          {isOpenModalDeleteAdmin && <DeleteAdmin isOpen={isOpenModalDeleteAdmin} />}
          {isOpenModalAddNewAdmin && <AddNewAdmin isOpen={isOpenModalAddNewAdmin} />}
        </PerfectScrollbar>
      </div>
    </>
  );
});

export default Admins;
