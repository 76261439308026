import React from 'react';
import { Field } from 'formik';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import FormTextarea from 'components/common/FormComponent/formFields/FormTextarea';

import { initialValueNames } from 'components/Modals/AddTestResult/FormAddTestResult/constants';
import { PLACEHOLDER_COMMITS } from '../../constants';

const AddCommitsTestResult = () => {
  const { input, textarea } = PLACEHOLDER_COMMITS;

  return (
    <div className="modal-content">
      <Field
        name={initialValueNames.HOLDING}
        placeholder={input}
        component={FormInput}
        label="What is holding your back"
      />
      <Field
        name={initialValueNames.COMMENT}
        placeholder={textarea}
        component={FormTextarea}
        label="Comment"
        id="comment"
        rows={5}
      />
    </div>
  );
};

export default AddCommitsTestResult;
