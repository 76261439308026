import React from 'react';

import { EventType } from 'components/Calendar/EventsTable/interface';
import { getHeightTimelineCellContent } from '../helper';

import EventCard from './EventCard';

const Event = (props: EventType) => {
  const {
    scrollY, dayEvent, height, indexTime, indexEvent, timeEvent
  } = props;

  if (timeEvent) {
    return (
      <td className="fixed-cell">
        <div className="timeline-cell-content" style={{ bottom: scrollY }}>
          {`${timeEvent}:00`}
        </div>
      </td>
    );
  }

  if (dayEvent) {
    const { eventsGroup } = dayEvent;
    const { roomNumber } = eventsGroup[indexTime];

    return (
      <td className="timeline-cell">
        <div className="timeline-cell-content">
          {roomNumber && (
            <EventCard
              heightTimelineCellContent={getHeightTimelineCellContent(height)}
              index={indexEvent}
            />
          )}
        </div>
      </td>
    );
  }

  return null;
};

export default Event;
