import { isEqual } from 'lodash';
import moment from 'moment';

import { toDate } from 'services/date';

import { eventFormFields } from '../../AddEvent/constants';
import { ILessonInitialValues } from './interface';

export const getInitial = (initialData: ILessonInitialValues = {}) => {
  const {
    time, group, roomNumber, endTime, studyGroupId, date
  } = initialData;

  if (isEqual(initialData, {})) {
    return {};
  }

  const weekDay = moment(date).format('dddd');

  return {
    [eventFormFields.GROUP]: { value: studyGroupId, label: group },
    [eventFormFields.ROOM]: roomNumber,
    [eventFormFields.BEGIN_DATE]: toDate(date),
    [eventFormFields.DAY]: { label: weekDay, value: weekDay },
    [eventFormFields.BEGIN_TIME]: toDate(`${date} ${time}`),
    [eventFormFields.END_TIME]: toDate(`${date} ${endTime}`)
  };
};

