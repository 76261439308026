import { ILessonData } from 'components/Modals/Event/AddEvent/interface';
import { IDeleteEventParams } from 'types';
import api from './index';
import request from './request';

export const setMainEventTest = (data: any): Promise<any> => request({
  url: api.CREATE_TEST_NAME,
  method: 'POST',
  data
});

export const setEventTest = (data: any): Promise<any> => request({
  url: api.EVENT_TEST,
  method: 'POST',
  data
});

export const setLesson = (data: ILessonData[]): Promise<any> => request({
  url: api.LESSON,
  method: 'POST',
  data
});

export const getWritingLevels = (): Promise<any> => request({
  url: api.TEST_LEVELS,
  method: 'GET'
});

export const getEventTestTypes = (): Promise<any> => request({
  url: api.EVENT_TEST_TYPES,
  method: 'GET'
});

export const deleteEvent = (params: IDeleteEventParams): Promise<any> => request({
  url: api.DELETE_EVENT,
  method: 'DELETE',
  params
});

export const editEventLesson = (data: any): Promise<any> => request({
  url: api.LESSON,
  method: 'PATCH',
  data
});

export const editEventTest = (data: any): Promise<any> => request({
  url: api.EVENT_TEST,
  method: 'PATCH',
  data
});

export const cancelEvent = (data: any): Promise<any> => request({
  url: api.CANCEL_EVENT,
  method: 'PUT',
  data
});
