import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import RootStore from 'store';
import { loadFromLocalStorage } from 'services/localStorage';
import routes from 'constants/routes';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/localStorage';
import { setNavigationItemsList } from 'components/Aside/NavigationMenu/services';
import { COMMON_ROUTES } from 'components/Aside/NavigationMenu/navigationItemsList';

export default (Component: React.ComponentType<RouteComponentProps>) => {
  const AuthGuard: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const { location: { pathname }, history } = props;
    const [userWasAuthenticated, setUserWasAuthenticated] = useState(false);
    const { authenticationStore } = useContext(RootStore);
    const { isAuthenticated, setTokenFromStorage, user: { role } } = authenticationStore;

    useEffect(() => {
      if (!role) {
        return;
      }

      const availableNavDate = setNavigationItemsList(role).map(item => item.route);
      const isRouteProhibited = !availableNavDate.includes(pathname);
      const isNotCommonRoute = COMMON_ROUTES.includes(pathname);

      if (isRouteProhibited && !isNotCommonRoute) {
        history.replace(routes.FORBIDDEN);
      }
    }, [pathname, role]);

    useEffect(() => {
      const accessToken = loadFromLocalStorage(ACCESS_TOKEN);
      const refreshToken = loadFromLocalStorage(REFRESH_TOKEN);

      if (accessToken) {
        setTokenFromStorage({
          accessToken,
          refreshToken
        });

        setUserWasAuthenticated(accessToken);
        return;
      }

      history.push(routes.LOGIN_ROUTE);
    }, [setTokenFromStorage]);

    const isUserAuthenticated = isAuthenticated || userWasAuthenticated;

    return isUserAuthenticated ? <Component {...props} /> : null;
  };

  return compose(withRouter, observer)(AuthGuard);
};
