import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import RootStore from 'store';
import { successNotification } from 'components/common/Notifications/helper';

import { addTeacher } from 'api/teachers';

import FormAddTestResult from 'components/Modals/AddNewTeacher/FormAddTestResult/index';
import Container from '../ModalContainer';

const AddNewTeacher = observer(() => {
  const { teachers } = useContext(RootStore);
  const { isOpenModalAddNewTeacher, updateModalAddNewTeacherState, getTeachersList } = teachers;

  const [formData, setFormData] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);

  const header = (
    <>
      <div className="modal-title">Add New Teacher</div>
      <button className="modal-close" type="button" onClick={() => updateModalAddNewTeacherState(false)}>
        <i className="material-icons">clear</i>
      </button>
    </>
  );

  const body = <FormAddTestResult setFormData={setFormData} setIsFormValid={setIsFormValid} />;

  const btnClassName = classNames('btn-default', { 'btn-disabled': !isFormValid });

  const submitForm = async () => {
    try {
      await addTeacher(formData);

      updateModalAddNewTeacherState(false);
      getTeachersList();
      successNotification('Teacher was successfully added');

      return null;
    } catch (e) {
      return e;
    }
  };

  const footer = (
    <>
      <button
        className={btnClassName}
        type="button"
        onClick={submitForm}
        disabled={!isFormValid}
      >
        save
      </button>
    </>
  );

  return (
    <Container
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpenModalAddNewTeacher}
      toggleModal={() => { updateModalAddNewTeacherState(false); }}
    />
  );
});

export default AddNewTeacher;
