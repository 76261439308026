import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import routes from 'constants/routes';

import ErrorPage from './ErrorPage';
import './styles.scss';

const ServerError500: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { history } = props;
  const isFirstPage = history.length <= 2;

  const refreshPage = () => {
    if (isFirstPage) {
      history.replace(routes.INDEX);

      return;
    }
    history.goBack();
  };

  const actionBtn = (
    <span role="button" tabIndex={0} className="errorpage-link" onClick={refreshPage}>Refresh the page</span>
  );

  return (
    <ErrorPage code="500" actionBtn={actionBtn} />
  );
};

export default withRouter(ServerError500);
