import React from 'react';

import { IAttendanceLogEventCellProps } from 'components/AttendanceLog/AttendanceLogEventCell/StatusOption/interface';

import Status from 'components/common/Status';

const StatusOption: React.FC<IAttendanceLogEventCellProps> = (props: IAttendanceLogEventCellProps) => {
  const {
    setAttendance, label, status, value
  } = props;

  return (
    <div role="presentation" className="dropdown-item" onClick={() => setAttendance(value)}>
      <Status status={status} label={label} />
    </div>
  );
};

export default StatusOption;
