/* eslint camelcase: 0 */
import { INoNormalStudentData, IStudentData } from 'components/Students/interface';

export const normalizeStudentsList = (students: INoNormalStudentData[]): IStudentData[] => students.map((student) => {
  const {
    department_group_id,
    department_group_name,
    department_id,
    department_name,
    email,
    name,
    photo,
    speaking_english_level_id,
    speaking_english_level_name,
    status_id,
    status_name,
    study_group_id,
    study_group_name,
    lesson_type,
    teacher_type_name,
    surname,
    user_id,
    role_id,
    writing_english_level_id,
    writing_english_level_name,
    recommended_english_level_id,
    recommended_english_level_name,
    study_group_english_level_id,
    study_group_english_level_name
  } = student;

  return {
    departmentGroupId: department_group_id,
    departmentGroupName: department_group_name,
    departmentId: department_id,
    departmentName: department_name,
    email,
    name,
    photo,
    speakingEnglishLevelId: speaking_english_level_id,
    speakingEnglishLevelName: speaking_english_level_name,
    statusId: status_id,
    statusName: status_name,
    studyGroupId: study_group_id,
    studyGroupName: study_group_name || 'None',
    teacherTypeId: lesson_type,
    teacherTypeName: teacher_type_name,
    surname,
    userId: user_id,
    roleId: role_id,
    writingEnglishLevelId: writing_english_level_id,
    writingEnglishLevelName: writing_english_level_name,
    recommendedEnglishLevelId: recommended_english_level_id,
    recommendedEnglishLevelName: recommended_english_level_name,
    studyGroupEnglishLevelId: study_group_english_level_id,
    studyGroupEnglishLevelName: study_group_english_level_name,
    fullName: `${surname} ${name}`,
    isEdit: false,
    studentUserName: name,
    studentUserSurname: surname
  };
});

export const normalizeStudentsListOptions = (students: any[]): any[] => students.map(student => {
  const { fullName, userId } = student;

  return {
    value: userId,
    label: fullName
  };
});

export const prepareStudentsUserId = (selectedStudents: any): string => {
  const indexes: number[] = selectedStudents.map((selectedStudent: any) => {
    const { value } = selectedStudent;

    return value;
  });

  return JSON.stringify(indexes);
};

