import React, { useContext } from 'react';
import cn from 'classnames';

import userPicDefault from 'assets/img/face-01.png';
import history from 'services/history';
import routes from 'constants/routes';
import RootStore from 'store';

interface IUser {
  hasRole?: boolean;
  userName?: string;
  userRole?: string;
  userIcon?: string;
  userProfile?: boolean;
  additionalClassName?: string;
}

function User(props: IUser) {
  const { authenticationStore: { user: { photo } } } = useContext(RootStore);

  const {
    hasRole, userName, userRole, userIcon, userProfile, additionalClassName
  } = props;
  const showRole = hasRole ? 'show-role' : '';

  function goToEditProfile() {
    if (!userProfile) { return; }

    history.push(routes.PROFILE);
  }

  const profilePhoto = userProfile ? photo : null;
  const userAvatar = userIcon || profilePhoto || userPicDefault;

  const userClassName = cn(
    `user ${showRole}`,
    additionalClassName,
    { 'with-link': userProfile }
  );

  return (
    <div
      tabIndex={0}
      role="button"
      className={userClassName}
      onClick={goToEditProfile}
    >
      <div className="user-icon">
        <img className="user-icon-img" src={userAvatar} alt="user-pic" />
      </div>
      <div className="user-info">
        <span className="user-name">{userName}</span>
        <span className="user-role">{userRole}</span>
      </div>
    </div>
  );
}

export default User;
