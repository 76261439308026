import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import WithPermissions from 'hocs/WithPermissions';
import { IPermissions } from 'types';

import { ADMIN, SUPER_ADMIN, TEACHER } from 'constants/roles';
import { OPTIONS, ATTEND, MISSED } from 'components/Calendar/EventDetails/AttendanceStudent/constants';
import { IAttendanceStudentProps } from 'components/Calendar/EventDetails/AttendanceStudent/interface';
import { IPatchAttendanceParams } from 'components/Calendar/interface';

import User from 'components/common/User';
import Status from 'components/common/Status';
import AttendanceLogEventCell from 'components/AttendanceLog/AttendanceLogEventCell';

const AttendanceStudent: React.FC<IAttendanceStudentProps & IPermissions> = observer(
  (props: IAttendanceStudentProps & IPermissions) => {
    const {
      attendance,
      eventStudentId,
      isFirstStudent,
      studentUserName,
      studentUserPhoto,
      studentUserSurname,
      permissions
    } = props;

    const { calendarStore } = useContext(RootStore);
    const { setStudentsAttendance } = calendarStore;

    const labelRow = isFirstStudent ? 'Students:' : '';
    const fullName = `${studentUserSurname} ${studentUserName}`;

    const { status, label } = attendance ? ATTEND : MISSED;

    const updateData = (data: IPatchAttendanceParams) => {
      setStudentsAttendance(data);
    };

    return (
      <>
        <tr>
          <td className="event-details-cell">
            <div className="event-details-property flex-grow-1">{labelRow}</div>
          </td>
          <td className="event-details-cell">
            <div className="d-flex">
              <div className="event-details-value flex-grow-1">
                <User hasRole={false} userIcon={studentUserPhoto} userName={fullName} />
              </div>
              <div className="event-details-value flex-grow-0">
                {permissions([ADMIN, SUPER_ADMIN, TEACHER]) ? (
                  <AttendanceLogEventCell
                    status={status}
                    label={label}
                    options={OPTIONS}
                    eventStudentId={eventStudentId}
                    updateAttendance={updateData}
                    canUserEdit
                  />
                ) : (
                  <div className="attendance-item">
                    <Status status={status} label={label} />
                  </div>
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
);

export default WithPermissions(AttendanceStudent);
