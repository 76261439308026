import { action, computed, observable } from 'mobx';
import { debounce } from 'lodash';

import * as API from 'api/groups';
import { getTeacherTypes, getStudyGroupNames, getTestLevels } from 'api/filtersData';
import { getStudentsList } from 'api/students';
import { getTeacherList } from 'api/teachers';
import { ISortingData } from 'components/common/ColumnValueInTable/interface';
import { getSortingStatus } from 'components/common/ColumnValueInTable/helpers';
import * as ATTENDANCE_INTERFACES from 'components/AttendanceLog/interfaces';
import { normalizeStudentsList } from 'components/AttendanceLog/utils';
import * as CONSTANTS from 'components/AttendanceLog/constants';
import { ACTIVE_TEACHERS } from 'components/Teachers/constants';
import { successNotification } from 'components/common/Notifications/helper';

import {
  DEFAULT_SORT_PARAMS, NATIVE_TYPE_ID, LOADING_SELECT_STATE, REMOVE_STUDENT_PARAM_FOR_ADMIN
} from './constants';
import * as UTILS from './utils';
import * as INTERFACES from './interfaces';

export default class GroupsStore {
  @observable error: any = null;

  @observable groups: INTERFACES.IGroupsData[] = [];

  @observable pagination: ATTENDANCE_INTERFACES.IPagination = {
    currentPage: CONSTANTS.DEFAULT_ATTENDANCES_PAGE,
    maxPerPage: CONSTANTS.MAX_ATTENDANCES_PER_PAGE,
    countOfPages: null
  };

  @observable sortParams: INTERFACES.ISortParams = DEFAULT_SORT_PARAMS;

  @observable teacherTypes: INTERFACES.ITeacherType[] = [];

  @observable selectedTeacherType = {} as (INTERFACES.ITeacherType | any);

  @observable teachersList: INTERFACES.ISelectValues[] = [];

  @observable selectedTeacher: INTERFACES.ISelectedTeacher = null;

  @observable newGroupTitle: string = '';

  @observable levelsList: INTERFACES.ISelectValues[] = [];

  @observable selectedLevel: INTERFACES.ISelectValues = null;

  @observable studentsList: INTERFACES.ISelectValues[] = [];

  @observable selectedStudents: INTERFACES.ISelectValues[] = [];

  @observable studentsSearchString: string = '';

  @observable editingStudyGroup: INTERFACES.IEditingGroupData = null;

  @observable isLoadingTeacherTypes = false;

  @observable isLoadingStudyGroups = false;

  @computed get isLoading() {
    return this.isLoadingTeacherTypes
      || this.isLoadingStudyGroups;
  }

  @action updateStudyGroup = async (): Promise<void> => {
    try {
      const { id: editingStudyGroupId, name, teacher } = this.editingStudyGroup;
      const { value } = teacher;

      await API.updateStudyGroup({
        study_group_id: editingStudyGroupId,
        study_group_title: name,
        teacher_user_id: value
      });

      await this.getStudyGroups();

      successNotification('Groups was updated');
    } catch (error) {
      this.error = error;
    }
  };

  @action setEditingStudyGroupTeacher = (teacher: INTERFACES.ISelectValues): void => {
    const updatedEditingStudyGroup: INTERFACES.IEditingGroupData = { ...this.editingStudyGroup };

    updatedEditingStudyGroup.teacher = teacher;

    this.editingStudyGroup = updatedEditingStudyGroup;
  };

  @action setEditingStudyGroupTitle = (title: string): void => {
    const updatedEditingStudyGroup: INTERFACES.IEditingGroupData = { ...this.editingStudyGroup };

    updatedEditingStudyGroup.name = title;

    this.editingStudyGroup = updatedEditingStudyGroup;
  };

  @action setEditingStudyGroup = (studyGroup: any): void => {
    const { teacher: { type } } = studyGroup;
    this.editingStudyGroup = {
      ...studyGroup,
      teacher: UTILS.teacherToSelect(studyGroup.teacher)
    };

    this.selectedTeacherType = UTILS.defineTeacherType(this.teacherTypes, type);
  };

  @action getStudyGroups = async (): Promise<void> => {
    try {
      this.isLoadingTeacherTypes = true;

      const { value, sortOrder } = this.sortParams;
      const { maxPerPage, currentPage } = this.pagination;

      const { data: { data } } = await getStudyGroupNames({
        sort_key: value,
        sort_order: sortOrder,
        max_per_page: maxPerPage,
        current_page: currentPage + 1
      });
      const { count_of_pages: countOfPages, data_page: groups } = data;

      this.setStudyGroups(UTILS.normalizeGroupsData(groups));
      this.setCountOfPages(countOfPages);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingTeacherTypes = false;
    }
  };

  @action setStudyGroups = (groups: INTERFACES.IGroupsData[]): void => {
    this.groups = groups;
  };

  @action setCountOfPages = (countOfPages: number): void => {
    this.pagination = {
      ...this.pagination,
      countOfPages
    };
  };

  @action setCurrentPage = (pageNumber: number): void => {
    this.pagination = {
      ...this.pagination,
      currentPage: pageNumber
    };
  };

  @action setPagination = (paginationParams: ATTENDANCE_INTERFACES.IPagination): void => {
    this.pagination = {
      ...this.pagination,
      ...paginationParams
    };
  };

  @action removeStudentFromStudyGroup = async (studentId: number): Promise<void> => {
    try {
      await API.removeStudentFromStudyGroup({
        student_user_id: studentId,
        remove: REMOVE_STUDENT_PARAM_FOR_ADMIN
      });
      await this.getStudyGroups();
      successNotification('Student was removed from group');
    } catch (error) {
      this.error = error;
    }
  };

  @action updateSortParams = (updatedSortedData: ISortingData[]) => {
    const { value, sortingArrow } = updatedSortedData.find((sortedValue) => sortedValue.isActive);

    const sortOrder = getSortingStatus(sortingArrow);

    this.sortParams = {
      ...this.sortParams,
      value,
      sortOrder
    };
  };

  @action getTeacherTypes = async (): Promise<void> => {
    try {
      this.isLoadingTeacherTypes = true;

      const { data: { data } } = await getTeacherTypes();
      const normalizedTeacherTypes: INTERFACES.ITeacherType[] = UTILS.normalizeTeacherTypes(data);

      this.teacherTypes = normalizedTeacherTypes;

      const [defaultTeacherType] = normalizedTeacherTypes;

      this.selectedTeacherType = defaultTeacherType;
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoadingTeacherTypes = false;
    }
  };

  @action selectTeacherType = (teacherType: string): void => {
    this.selectedTeacherType = UTILS.defineTeacherType(this.teacherTypes, teacherType);
    this.newGroupTitle = '';
    this.selectedTeacher = null;
    this.selectedLevel = null;
    this.selectedStudents = [];
  };

  @action getTeachersList = async (): Promise<void> => {
    try {
      this.setLoadingStateTeacher();

      const { id } = this.selectedTeacherType;

      const { data: { data } } = await getTeacherList({
        type_id: id,
        active: ACTIVE_TEACHERS
      });

      this.teachersList = UTILS.normalizeTeachersData(data);
    } catch (error) {
      this.error = error;
    }
  };

  @action setLoadingStateTeacher = (): void => {
    this.teachersList = LOADING_SELECT_STATE;
  };

  @action setSelectedTeacher = (teacher: INTERFACES.ISelectedTeacher) => {
    this.selectedTeacher = teacher;
  };

  @action getStudents = async (searchString: string): Promise<void> => {
    try {
      this.studentsList = LOADING_SELECT_STATE;

      const { id } = this.selectedTeacherType;
      const isNativeSpeaker = id === NATIVE_TYPE_ID;
      const params: any = {
        search_string: searchString
      };

      if (isNativeSpeaker) {
        params.type_id = 2;
        params.status_ids = JSON.stringify([1]);
      } else {
        const { value } = this.selectedLevel;

        params.type_id = 1;
        params.status_ids = JSON.stringify([1]);
        params.recommended_english_level_id = value;
      }

      const { data: { data } } = await getStudentsList(params);
      // eslint-disable-next-line camelcase
      const { data_page } = data;

      this.studentsList = normalizeStudentsList(data_page);
    } catch (error) {
      this.error = error;
    }
  };

  @action getStudentsDelayed = debounce(this.getStudents, 500);

  @action onStudentsInputChange = (value: string): void => {
    this.setStudentsSearchString(value);

    if (value) {
      this.getStudentsDelayed(value);
    }
  };

  @action setSelectedStudent = (selectedStudents: INTERFACES.ISelectValues[]): void => {
    this.selectedStudents = selectedStudents;
    this.studentsList = [];
  };

  @action resetStudentsList = (): void => {
    this.studentsList = [];
  };

  @action setStudentsSearchString = (value: string): void => {
    this.studentsSearchString = value;
  };

  @action setNewGroupTitle = (title: string): void => {
    this.newGroupTitle = title;
  };

  @action getEnglishLevels = async (): Promise<void> => {
    try {
      this.levelsList = LOADING_SELECT_STATE;

      const { data: { data } } = await getTestLevels();

      this.levelsList = UTILS.normalizeLevels(data);
    } catch (error) {
      this.error = error;
    }
  };

  @action setLevel = (level: INTERFACES.ISelectValues) => {
    this.selectedLevel = level;
  };

  @action createStudyGroup = async (): Promise<void> => {
    try {
      const newGroupData: any = {
        study_group_title: this.newGroupTitle,
        teacher_user_id: this.selectedTeacher.value
      };

      if (this.selectedLevel && this.selectedLevel.value) {
        newGroupData.english_level_id = this.selectedLevel.value;
      }

      if (this.selectedStudents.length) {
        newGroupData.student_users_id = JSON.stringify(this.selectedStudents.map(student => student.value));
      }

      await API.createStudyGroup(newGroupData);
      await this.getStudyGroups();

      successNotification('Study group was created');
    } catch (error) {
      this.error = error;
      console.error(error);
    }
  };

  @action deleteStudyGroup = async (id: number): Promise<void> => {
    try {
      await API.deleteStudyGroup({
        study_group_id: id
      });

      await this.getStudyGroups();

      successNotification('Study group was deleted');
    } catch (error) {
      this.error = error;
    }
  };

  @computed get TeacherTypes(): INTERFACES.ITeacherType[] {
    return this.teacherTypes;
  }

  @computed get SelectedTeacherType(): INTERFACES.ITeacherType {
    return this.selectedTeacherType;
  }

  @computed get GroupsData(): INTERFACES.IGroupsData[] {
    return this.groups;
  }

  @computed get TeachersList(): INTERFACES.ISelectValues[] {
    return this.teachersList;
  }

  @computed get SortParams(): INTERFACES.ISortParams {
    return this.sortParams;
  }

  @computed get SelectedTeacher(): INTERFACES.ISelectValues {
    return this.selectedTeacher;
  }

  @computed get NewGroupTitle(): string {
    return this.newGroupTitle;
  }

  @computed get LevelsList(): INTERFACES.ISelectValues[] {
    return this.levelsList;
  }

  @computed get SelectedLevel(): INTERFACES.ISelectValues {
    return this.selectedLevel;
  }

  @computed get SelectedStudents(): INTERFACES.ISelectValues[] {
    return this.selectedStudents;
  }

  @computed get StudentsList(): INTERFACES.ISelectValues[] {
    return this.studentsList;
  }

  @computed get StudentsSearchString(): string {
    return this.studentsSearchString;
  }

  @computed get EditingStudyGroup(): INTERFACES.IEditingGroupData {
    return this.editingStudyGroup;
  }

  @computed get PaginationParams(): ATTENDANCE_INTERFACES.IPagination {
    return this.pagination;
  }
}
