import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import ProgressBarItem from '../ProgressBarItem';

interface Props {
  indexTestResult: number;
  isEdit: boolean;
}

const SpeakingResults = observer(
  (props: Props): JSX.Element => {
    const { indexTestResult, isEdit } = props;
    const { testResults } = useContext(RootStore);
    const { listTestResults, previousTestResults } = testResults;

    const isDisable = !isEdit;

    const {
      fluency, accuracy, vocabulary, speakingGrammar, communicativeness
    } = listTestResults[indexTestResult];

    const speakingResults1 = [
      {
        progressClass: 'progress-listen',
        value: fluency || 0,
        prevValue: previousTestResults ? previousTestResults.fluency : null,
        label: 'Fluency',
        name: 'fluency',
        disabled: isDisable
      },
      {
        progressClass: 'progress-write',
        value: accuracy || 0,
        prevValue: previousTestResults ? previousTestResults.accuracy : null,
        label: 'Accuracy',
        name: 'accuracy',
        disabled: isDisable
      },
      {
        progressClass: 'progress-read',
        value: vocabulary || 0,
        prevValue: previousTestResults ? previousTestResults.vocabulary : null,
        label: 'Vocabulary',
        name: 'vocabulary',
        disabled: isDisable
      }
    ];

    const speakingResults2 = [
      {
        progressClass: 'progress-read',
        value: speakingGrammar || 0,
        prevValue: previousTestResults ? previousTestResults.speakingGrammar : null,
        label: 'Grammar',
        name: 'grammar',
        disabled: isDisable
      },
      {
        progressClass: 'progress-red',
        value: communicativeness || 0,
        prevValue: previousTestResults ? previousTestResults.communicativeness : null,
        label: 'Communicativeness',
        name: 'communicativeness',
        disabled: isDisable
      }
    ];

    return (
      <>
        <div className="panel-item w-20">
          {speakingResults1.map((speakingResult) => {
            const { label } = speakingResult;

            return <ProgressBarItem {...speakingResult} key={label} />;
          })}
        </div>
        <div className="panel-item w-20">
          {speakingResults2.map((speakingResult) => {
            const { label } = speakingResult;

            return <ProgressBarItem {...speakingResult} key={label} />;
          })}
        </div>
      </>
    );
  }
);

export default SpeakingResults;
