import React from 'react';

import User from 'components/common/User';
import * as INTERFACES from '../interfaces';
import * as CONSTANTS from '../constants';

const StudentsIconsProps: React.FC<INTERFACES.IStudentsIconsProps> = (props: INTERFACES.IStudentsIconsProps) => {
  const { students } = props;
  const studentsLength: number = students.length;
  const studentsToRender: INTERFACES.IGroupsStudentData[] = [...students];
  studentsToRender.length = CONSTANTS.USER_ICONS_TO_RENDER;
  const restStudents: number = studentsLength - CONSTANTS.USER_ICONS_TO_RENDER;
  const isRestStudentsShown:boolean = restStudents > 0;

  return (
    <>
      <div className="user-items">
        {studentsToRender.map((student: any) => {
          const {
            id,
            studentUserPhoto
          } = student;

          return (
            <User
              hasRole={false}
              userIcon={studentUserPhoto}
              key={`UserIcon-${id}`}
            />
          );
        })}
      </div>
      {isRestStudentsShown && <span className="user-sum">{`+${restStudents}`}</span>}
    </>
  );
};

export default StudentsIconsProps;
