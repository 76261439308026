import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment';

import * as API from 'api/attendanceLog';
import { getStudyGroupNames } from 'api/filtersData';
import { normalizeFilterData } from 'helpers/select';
import { ALL_GROUPS } from 'constants/select';
import * as SEARCH_INTERFACES from 'types/index';
import * as UTILS from './utils';
import * as INTERFACES from './interfaces';
import * as CONSTANTS from './constants';
import { IRootStore } from '../../store/interfaces';

export default class AttendanceLogStore {
  rootStore: IRootStore;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @observable attendances: INTERFACES.IAttendanceLog[] = [];

  @observable selectedDate: Moment = null;

  @observable pagination: INTERFACES.IPagination = {
    currentPage: CONSTANTS.DEFAULT_ATTENDANCES_PAGE,
    maxPerPage: CONSTANTS.MAX_ATTENDANCES_PER_PAGE,
    countOfPages: null
  };

  @observable currentDay: string = null;

  @observable monthDays: INTERFACES.IMonthCountDay[] = null;

  @observable eventType: INTERFACES.IEventType = CONSTANTS.EVENT_TYPES[0];

  @observable studyGroups: SEARCH_INTERFACES.ISelectItem[] = [];

  @observable selectedGroup: SEARCH_INTERFACES.ISelectItem = null;

  @observable searchString: string = '';

  @observable selectedStudent: SEARCH_INTERFACES.ISelectItem = null;

  @observable searchOptions: SEARCH_INTERFACES.ISelectItem[] = [];

  @observable isAttendanceLogLoading = false;

  @observable isLoadingStudyGroups = false;

  @observable isLoadingStudentsList = false;

  @computed get isLoading() {
    return this.isAttendanceLogLoading
      || this.isLoadingStudentsList
      || this.isLoadingStudyGroups;
  }

  @computed get getUnit() {
    return this.rootStore.serviceStore.isMobile ? 'd' : 'M';
  }

  @action setEventType = (eventName: string): void => {
    CONSTANTS.EVENT_TYPES.forEach(
      (event: INTERFACES.IEventType): void => {
        const { value } = event;

        if (value === eventName) {
          this.eventType = event;
        }
      }
    );
  };

  @computed get getEventType(): INTERFACES.IEventType {
    return this.eventType;
  }

  @action setSelectedStudent = (selectedStudent: SEARCH_INTERFACES.ISelectItem): void => {
    this.selectedStudent = selectedStudent;
    this.searchOptions = [];
  };

  @computed get SelectedStudent(): SEARCH_INTERFACES.ISelectItem {
    return this.selectedStudent;
  }

  @computed get SearchOptions(): SEARCH_INTERFACES.ISelectItem[] {
    return this.searchOptions;
  }

  @action setCurrentPage = (pageNumber: number): void => {
    this.pagination = {
      ...this.pagination,
      currentPage: pageNumber
    };
  };

  @action setPagesCount = (count: number): void => {
    this.pagination = {
      ...this.pagination,
      countOfPages: count
    };
  };

  @action setPagination = (paginationParams: INTERFACES.IPagination): void => {
    this.pagination = {
      ...this.pagination,
      ...paginationParams
    };
  };

  @computed get PaginationParams(): INTERFACES.IPagination {
    return this.pagination;
  }

  @computed get DateParams(): INTERFACES.IDateParams {
    const date: Moment = moment(this.selectedDate);

    const monthName: string = date.format('MMMM');
    const month: string = date.format('MM');
    const year: string = date.format('YYYY');
    const daysCount: number = date.daysInMonth();

    return {
      month,
      year,
      monthName,
      daysCount
    };
  }

  @action setMonthCountDays = (): void => {
    const days: INTERFACES.IMonthCountDay[] = [];
    const countOfDays: number = moment(this.selectedDate).daysInMonth();
    const monthStart: Moment = moment(this.selectedDate).startOf('month');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < countOfDays; i++) {
      const nextDay: Moment = moment(monthStart).add(i, 'days');
      const day: string = moment(nextDay).format('ddd');
      const date: string = moment(nextDay).format('DD');

      days.push({
        day,
        date
      });
    }

    this.monthDays = days;
  };

  @action setCurrentDateMobile = (date: Moment) => {
    if (!this.rootStore.serviceStore.isMobile) { return; }

    this.currentDay = date.format('DD');
  };

  @action incrementDate = (): void => {
    this.selectedDate = moment(this.selectedDate).add(1, this.getUnit);
    this.setMonthCountDays();
    this.setCurrentDateMobile(this.selectedDate);
  };

  @action decrementDate = (): void => {
    this.selectedDate = moment(this.selectedDate).subtract(1, this.getUnit);
    this.setMonthCountDays();
    this.setCurrentDateMobile(this.selectedDate);
  };

  @action setCurrentDate = (): void => {
    const currentDate: Moment = moment(new Date(), 'YYYY/MM/DD');
    const day: string = currentDate.format('DD');

    this.selectedDate = currentDate;
    this.currentDay = day;
    this.setMonthCountDays();
  };

  @computed get MonthDays(): INTERFACES.IMonthCountDay[] {
    return this.monthDays;
  }

  @computed get AttendanceLogEvents(): INTERFACES.IAttendanceLog[] {
    return this.attendances;
  }

  @action setAttendances = (attendances: any): void => {
    this.attendances = [...attendances];
  };

  @action getAttendanceLog = async (params: any): Promise<void> => {
    try {
      this.isAttendanceLogLoading = true;

      const { maxPerPage, currentPage } = this.pagination;

      const getAttendanceParams = {
        ...params,
        month_year: this.GetMonthYear,
        max_per_page: maxPerPage,
        current_page: currentPage + 1,
        event_type_id: this.eventType.id
      };

      if (this.selectedGroup) {
        getAttendanceParams.study_group_id = this.selectedGroup.value;
      }

      if (this.selectedStudent) {
        getAttendanceParams.students_user_id = UTILS.prepareStudentsUserId(this.selectedStudent);
      }

      const { data } = await API.getAttendance(getAttendanceParams);

      const {
        data: { count_of_pages: countOfPages, data_page: pageData }
      } = data;

      const normalizedData: INTERFACES.IAttendanceLog = UTILS.normalizeAttendanceLog(pageData);

      this.setAttendances(normalizedData);

      this.setPagesCount(countOfPages);
      this.setMonthCountDays();
    } catch (error) {
      console.error(error);
    } finally {
      this.isAttendanceLogLoading = false;
    }
  };

  @action updateAttendance = async (params: any): Promise<void> => {
    try {
      const { UserData } = this.rootStore.authenticationStore;
      const { id } = UserData;

      this.isAttendanceLogLoading = true;

      await API.updateStudentAttendance(params);
      this.getAttendanceLog({ user_id: id });
    } catch (e) {
      console.error(e);
    } finally {
      this.isAttendanceLogLoading = false;
    }
  };

  @action getStudyGroupNames = async (): Promise<void> => {
    try {
      this.isLoadingStudyGroups = true;
      const {
        data: { data }
      } = await getStudyGroupNames();

      this.studyGroups = [ALL_GROUPS, ...normalizeFilterData(data.data_page)];
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingStudyGroups = false;
    }
  };

  @computed get StudyGroups(): SEARCH_INTERFACES.ISelectItem[] {
    return this.studyGroups;
  }

  @computed get SelectedStudyGroup(): SEARCH_INTERFACES.ISelectItem {
    return this.selectedGroup;
  }

  @action selectStudyGroup = (group: SEARCH_INTERFACES.ISelectItem): void => {
    this.selectedGroup = { ...group };
  };

  @action getListOfStudents = async (params: any): Promise<void> => {
    try {
      this.isLoadingStudentsList = true;
      const getAttendanceLogStudentsParams = {
        ...params,
        month_year: this.GetMonthYear,
        event_type_id: this.eventType.id
      };

      if (this.selectedGroup) {
        getAttendanceLogStudentsParams.study_group_id = this.selectedGroup.value;
      }

      const {
        data: { data }
      } = await API.getAttendanceLogStudents(getAttendanceLogStudentsParams);

      this.setSearchOptions(UTILS.normalizeStudentsList(data));
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingStudentsList = false;
    }
  };

  @action setSearchOptions = (options: SEARCH_INTERFACES.ISelectItem[]): void => {
    this.searchOptions = options;
  };

  @computed get GetMonthYear(): string {
    if (this.selectedDate) {
      const date: Moment = moment(this.selectedDate);
      const month: string = date.format('MM');
      const year: string = date.format('YYYY');

      return `${month}-${year}`;
    }
    return null;
  }
}
