import React from 'react';

interface Props {
  label: string;
  prevValue?: number | string | null;
  value: string;
}

const TotalResult = (props: Props): JSX.Element => {
  const { label, value, prevValue } = props;

  return (
    <div className="accordion-info">
      <span className="accordion-subtitle">{label}</span>
      <span className="accordion-result">{value}</span>
      {prevValue && <span className="accordion-compare">{`/ ${prevValue}`}</span>}
    </div>
  );
};

export default TotalResult;
