import { SelectData } from 'types/filters';

export const getTeachersListRequestParams = (teacherType: SelectData) => {
  if (teacherType) {
    const { value } = teacherType;

    return { type_id: value };
  }

  return {};
};
