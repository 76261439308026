import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import RootStore from 'store';

import {
  convertTimeToPixels, getLessonDuration, getHeightEventCard, getDayEvent
} from '../helper';

import { EventCardType } from './interface';

import StudentsImg from './StudentsImg';

const EventCard = observer((props: EventCardType) => {
  const { index, heightTimelineCellContent } = props;

  const { calendarStore } = useContext(RootStore);
  const { getDetailEvents, dayEvents } = calendarStore;

  const dayEvent = getDayEvent(dayEvents[index].eventsGroup);

  const {
    id, roomNumber, time, type, isActive, endTime, students, canceled
  } = dayEvent;

  const lessonDurationString = `${time} - ${endTime}`;

  const classEventCard = classNames('event-card', type, {
    'is-active': isActive,
    canceled
  });

  const position = convertTimeToPixels(time, heightTimelineCellContent);
  const lessonDuration = getLessonDuration(endTime, time);
  const heightEventCard = getHeightEventCard(heightTimelineCellContent, lessonDuration);

  return (
    <>
      <div
        className={classEventCard}
        onClick={() => getDetailEvents(id, index)}
        style={{ height: heightEventCard, top: position }}
        role="presentation"
        key={id}
      >
        <div className="event-card-content">
          <div className="event-card-title">
            {type}
            <span className="event-card-room">{roomNumber}</span>
          </div>
          <div className="event-card-speaking-time">{lessonDurationString}</div>
        </div>
        <div className="event-card-content">
          <div className="event-card-room">{roomNumber}</div>
          <StudentsImg students={students} />
        </div>
      </div>
    </>
  );
});

export default EventCard;
