import React, { useState, useEffect } from 'react';

import { getNowTime } from 'helpers/actionsWithDates';
import { getUpdatePosition } from './helpers';
import { START_POSITION } from './constant';

import { TimelineType } from './interface';

const Timeline = (props: TimelineType) => {
  const { scroll, height } = props;
  const { scrollY } = scroll;

  const [time, setTime] = useState(getNowTime);

  const updateTime = () => setTime(getNowTime);

  useEffect(() => {
    setInterval(updateTime, 15000);
  });

  const [position, setPosition] = useState(START_POSITION);

  useEffect(() => {
    const updatePosition = getUpdatePosition(time, height);
    const newPosition = START_POSITION + updatePosition - scrollY;

    setPosition(newPosition);
  }, [time, scrollY, height, position]);

  return (
    <div className="timeline-pointer" style={{ top: position || 0 }}>
      <div className="timeline-pointer-time">{time}</div>
      <div className="timeline-pointer-line" />
    </div>
  );
};

export default Timeline;
