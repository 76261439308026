import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

import RootStore from 'store';
import { StudentsListType } from 'types/calendarTypes';
import Label from 'components/Calendar/Label/index';
import Value from 'components/Calendar/EventDetails/Value';
import AttendanceStudent from 'components/Calendar/EventDetails/AttendanceStudent';
import { useStateModal } from 'components/Modals/helper';

import * as roles from '../../../constants/roles';
import { ATTENDED_STATUS, ABSENT_STATUS } from './constants';
import { IEventDetailProps } from './interface';
import EventMenu from '../EventMenu';
import ConfirmationModal from '../../Modals/ConfirmationModal';

const EventDetails: React.FC<IEventDetailProps> = ({ permissions }: IEventDetailProps) => {
  const {
    calendarStore: { detailEvent, deleteEvent, cancelEvent },
    authenticationStore: { user }
  } = useContext(RootStore);
  const [isCancelModalOpen, cancelModalToggle]: any = useStateModal();

  const {
    id, time, group, roomNumber, teacher, type, endTime, attendance, students, date, canceled
  } = detailEvent;

  const lessonDuration = `${time} - ${endTime}`;

  const attendanceStatus = attendance ? ATTENDED_STATUS : ABSENT_STATUS;

  const handleDeleteEvent = () => {
    deleteEvent(id, user.id);
  };

  const isFutureEvent = moment(date).isSameOrAfter(moment().startOf('day'));
  const isAllowEdit = permissions([roles.SUPER_ADMIN, roles.ADMIN]) && isFutureEvent;

  const studentsList = students.map((student: StudentsListType, index: number) => {
    const isFirstStudent = index === 0;

    return (
      <AttendanceStudent
        {...student}
        isFirstStudent={isFirstStudent}
        key={`${student.studentUserId}-${student.eventStudentId}`}
      />
    );
  });

  return (
    <PerfectScrollbar className="perfect-scrollbar">
      <div className="event-details">
        <div className="event-details-header">
          <div className="event-details-title">{type}</div>
          { canceled && (
            <div className="event-details-title">Canceled</div>
          )}
          <div className="event-details-label">
            {permissions([roles.STUDENT]) && <Label status={attendanceStatus} />}
            { isAllowEdit && (
              <EventMenu deleteEvent={handleDeleteEvent} openCancelEventConfirmation={cancelModalToggle} />
            )}
          </div>
        </div>
        <div className="event-details-body">
          <table className="event-details-table">
            <colgroup>
              <col width="25%" />
              <col width="75%" />
            </colgroup>
            <tbody>
              <Value label="Group" value={group} />
              <Value label="Room" value={roomNumber} />
              <Value label="Time" value={lessonDuration} />
              <Value label="Teacher" value={teacher} />
              {studentsList}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isCancelModalOpen}
        title="Cancel event"
        toggleModal={cancelModalToggle}
        onSubmit={() => cancelEvent(id, cancelModalToggle)}
      />
    </PerfectScrollbar>
  );
};

export default observer(EventDetails);
