import { useState } from 'react';

export const useStateModal = (initial = false) => {
  const [isOpen, setIsOpen] = useState(initial);

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  return [isOpen, toggle, setIsOpen];
};
