import { format } from 'constants/date';
import { TIME_LINE_DATE } from 'components/Calendar/constants/eventSetting';

import { getTodayDate, updateFormatDate } from 'helpers/actionsWithDates';

import { MonthEventsType } from 'types/calendarTypes';

const getNearestEvent = (date: MonthEventsType[], getDayEvents: (date: string) => void) => {
  const isDraw = date.some((events) => {
    const todayDate = getTodayDate();
    const newFormatTodayDate = updateFormatDate(todayDate, format.DAY);

    const eventDate = updateFormatDate(events.date, format.DAY);

    if (eventDate >= newFormatTodayDate) {
      getDayEvents(events.date);
      return true;
    }

    return false;
  });

  return isDraw;
};

const getDateLastEvent = (monthEvents: MonthEventsType[]): string => {
  const indexLastEvent = monthEvents.length - 1;
  const lastEvent = monthEvents[indexLastEvent];

  return lastEvent.date;
};

export const getEvent = (date: MonthEventsType[], getDayEvents: (date: string) => void): void => {
  const isDraw = getNearestEvent(date, getDayEvents);

  if (isDraw !== true && !!date.length) {
    const dateLastEvent = getDateLastEvent(date);

    getDayEvents(dateLastEvent);
  }
};

export const checkTimeEvent = (timeEventStart: string) => TIME_LINE_DATE.some(
  ({ time }) => {
    const [hour] = timeEventStart.split(':', 1);

    return time === hour;
  }
);

export const sortMonthEvents = (monthEvents: MonthEventsType[]) => monthEvents.sort(
  (el1, el2) => {
    const date1 = updateFormatDate(el1.date, format.DAY);
    const date2 = updateFormatDate(el2.date, format.DAY);

    if (date1 > date2) {
      return 1;
    }
    if (date1 < date2) {
      return -1;
    }

    return 0;
  }
);

export const validateMonthEvents = (monthEvents: MonthEventsType[]) => {
  const validatedMonthEvents = monthEvents.filter(({ time }) => checkTimeEvent(time));

  return sortMonthEvents(validatedMonthEvents);
};

export const setMonthEventsAttendance = (
  monthEvents: MonthEventsType[],
  detailEvent: MonthEventsType
) => monthEvents.map((monthEvent) => {
  if (monthEvent.id === detailEvent.id) {
    return {
      ...monthEvent,
      students: detailEvent.students
    };
  }

  return monthEvent;
});
