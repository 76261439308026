import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import { getTableValues } from 'components/Admins/AdminsTable/TableHeader/constants';

import ColumnValueInTable from 'components/common/ColumnValueInTable';

const TableHeader: React.FC = observer(() => {
  const { admins, serviceStore: { isMobile } } = useContext(RootStore);
  const { updateModalAddNewAdminState } = admins;

  const openModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    updateModalAddNewAdminState(true);
  };

  const tableValues = getTableValues(isMobile);

  return (
    <div className="filter-panel">
      {tableValues.map((value) => (
        <ColumnValueInTable {...value} key={value.title} />
      ))}

      <div className="d-flex flex-grow-1 justify-content-end">
        <div className="filter-button-group">
          {!isMobile && (
            <button className="btn-default" onClick={openModal} type="button">
              + Add New
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default TableHeader;
