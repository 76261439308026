import request from './request';
import api from './index';

export const getTestNames = (params) => request({
  url: api.GET_TEST_NAMES,
  method: 'GET',
  params
});

export const getStudyGroupNames = (params = {}) => request({
  url: api.STUDY_GROUPS,
  method: 'GET',
  params
});

export const getDepartmentGroup = (params) => request({
  url: api.DEPARTMENT_GROUP,
  method: 'GET',
  params
});

export const getStudentsByTestId = (params) => request({
  url: api.STUDENTS_BY_TEST_ID,
  method: 'GET',
  params
});

export const getTestLevels = () => request({
  url: api.ENGLISH_LEVELS,
  method: 'GET'
});

export const getTeachersList = (params = {}) => request({
  url: api.TEACHERS_LIST,
  method: 'GET',
  params
});

export const getDepartmentGroups = (params) => request({
  url: api.DEPARTMENT_GROUPS,
  method: 'GET',
  params
});

export const getDepartments = (params = {}) => request({
  url: api.DEPARTMENTS,
  method: 'GET',
  params
});

export const searchStudents = (params) => request({
  url: api.SEARCH_USERS,
  method: 'GET',
  params
});

export const getTeacherTypes = () => request({
  url: api.GET_TEACHER_TYPES,
  method: 'GET'
});

export const getStudentsStatuses = () => request({
  url: api.STUDENT_STATUS,
  method: 'GET'
});
