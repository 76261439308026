import api from 'api';
import request from 'api/request';

import {
  IGetTeacherParams,
  IPatchTeacherParams,
  IDeleteTeacherParams,
  IPostTeacherParams
} from 'components/Teachers/interface';

export const getTeacherList = (params: IGetTeacherParams): Promise<any> => request({
  url: api.TEACHERS_LIST,
  method: 'GET',
  params
});

export const patchTeacherInfo = (data: IPatchTeacherParams): Promise<any> => request({
  url: api.TEACHER,
  method: 'PATCH',
  data
});

export const deleteTeacher = (params: IDeleteTeacherParams): Promise<any> => request({
  url: api.TEACHER,
  method: 'DELETE',
  params
});

export const addTeacher = (data: IPostTeacherParams): Promise<any> => request({
  url: api.TEACHER,
  method: 'POST',
  data
});
