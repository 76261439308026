import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';
import * as ROLES from 'constants/roles';
import WithGradient from 'hocs/WithGradient';
import WithPermissions from 'hocs/WithPermissions';

import * as INTERFACES from '../interfaces';
import AttendanceLogCalendarCell from '../AttendenceLogCalenderCell';
import AttendanceLogUserRow from '../AttendanceLogUserRow';

const AttendanceLogTableDesktopView: React.FC<INTERFACES.IAttendanceLogProps> = (
  props: INTERFACES.IAttendanceLogProps
) => {
  const {
    left, right, bottom, top, toggleXAxis, toggleYAxis, permissions
  } = props;
  const { isReachedLeft, removeLeftGradient } = left;
  const { isReachedRight, removeRightGradient } = right;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { attendanceLog } = useContext(RootStore);

  const {
    AttendanceLogEvents,
    MonthDays,
    updateAttendance,
    currentDay
  } = attendanceLog;

  const isGroupFilterShown = !permissions([ROLES.STUDENT, ROLES.GROUP_LEAD]);
  const attendanceContentClassNameDefault = 'attendance-content has-horizontal-gradient has-vertical-gradient';
  const attendanceContentClassName = classNames(attendanceContentClassNameDefault, {
    'has-top-gradient': !isReachedTop,
    'has-bottom-gradient': !isReachedBottom,
    'has-left-gradient': !isReachedLeft,
    'has-right-gradient': !isReachedRight
  });

  return (
    <>
      {!!AttendanceLogEvents.length && (
        <div className={attendanceContentClassName}>
          <PerfectScrollbar
            className="perfect-scrollbar attendance-scrollable"
            onYReachStart={removeTopGradient}
            onYReachEnd={removeBottomGradient}
            onXReachStart={removeLeftGradient}
            onXReachEnd={removeRightGradient}
            onScrollX={toggleXAxis}
            onScrollY={toggleYAxis}
          >
            <table className="attendance-table">
              {!!AttendanceLogEvents.length && (
                <thead className="attendance-thead">
                  <tr>
                    <th className="fixed-cell attendance-cell-item" />
                    {MonthDays
                  && MonthDays.map((item: INTERFACES.IMonthCountDay) => (
                    <AttendanceLogCalendarCell
                      {...item}
                      currentDay={currentDay}
                      key={`AttendanceLogCalendarCell-${item.date}-${item.day}`}
                    />
                  ))}
                  </tr>
                </thead>
              )}
              <tbody className="attendance-tbody">
                {AttendanceLogEvents
              && AttendanceLogEvents.map(
                (
                  attendances: INTERFACES.IAttendanceLog,
                  index: number
                ): JSX.Element => (
                  <AttendanceLogUserRow
                    attendances={attendances}
                    monthDays={MonthDays}
                    key={`attendance-tbody-${MonthDays[index].date}`}
                    updateAttendance={updateAttendance}
                    canUserEdit={isGroupFilterShown}
                  />
                )
              )
              }
              </tbody>
            </table>
          </PerfectScrollbar>
        </div>
      )}
    </>
  );
};

export default compose(
  WithPermissions,
  WithGradient,
  observer
)(AttendanceLogTableDesktopView);
