import React from 'react';

import { GROUP_LEAD } from 'constants/roles';
import { IPermissions } from 'types';

import SelectGroup from './SelectGroup';
import { IStudentData } from '../../interface';

const StudentMobileDetails: React.FC<IStudentData & IPermissions> = (props: IStudentData & IPermissions) => {
  const {
    permissions,
    departmentGroupName,
    departmentName,
    email,
    recommendedEnglishLevelName,
    speakingEnglishLevelName,
    writingEnglishLevelName,
    studyGroupName
  } = props;

  return (
    <>
      <div className="panel-item">
        <div className="panel-label">Email</div>
        {email}
      </div>
      <div className="panel-item">
        <div className="panel-label">Group</div>
        {studyGroupName}
      </div>
      {permissions([GROUP_LEAD]) ? (
        <>
          {speakingEnglishLevelName && (
            <div className="panel-item">
              <div className="panel-label">Speaking Level</div>
              {speakingEnglishLevelName}
            </div>
          )}
          {writingEnglishLevelName && (
            <div className="panel-item">
              <div className="panel-label">Writing Level</div>
              {writingEnglishLevelName}
            </div>
          )}
          {recommendedEnglishLevelName && (
            <div className="panel-item">
              <div className="panel-label">Recommended English Level</div>
              {recommendedEnglishLevelName}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="panel-item">
            <div className="panel-label">Recommended English Level</div>
            {recommendedEnglishLevelName}
          </div>
          <div className="panel-item">
            <div className="panel-label">Department / Group</div>
            {`${departmentName} / ${departmentGroupName}`}
          </div>
          <div className="panel-item">
            <SelectGroup {...props} />
          </div>
        </>
      )}
    </>
  );
};

export default StudentMobileDetails;
