import React, { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { observer } from 'mobx-react';

import RootStore from 'store';

import TableHeader from 'components/Students/StudentsTable/TableHeader';
import StudentInfo from 'components/Students/StudentsTable/StudentInfo';
import EmptyState from 'components/common/EmptyState';

const StudentsTable: React.FC = observer(() => {
  const { students } = useContext(RootStore);
  const { studentsList } = students;

  return (
    <PerfectScrollbar className="perfect-scrollbar">
      <div className="panel-group">
        <TableHeader />
        {studentsList.length ? (
          <div className="accordion">
            {studentsList.map((student) => {
              const { userId } = student;

              return <StudentInfo {...student} key={userId} />;
            })}
          </div>
        ) : (
          <EmptyState icon="sentiment_dissatisfied" title="No students" additionalClassName="card has-spacings" />
        )}
      </div>
    </PerfectScrollbar>
  );
});

export default StudentsTable;
