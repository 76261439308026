import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import Teacher from 'components/Teachers/TeachersTable/Teacher';
import TableHeader from 'components/Teachers/TeachersTable/TableHeader';

const Teachers: React.FC = () => {
  const { teachers } = useContext(RootStore);
  const { teachersList } = teachers;

  return (
    <div className="panel-group">
      <TableHeader />
      <div className="accordion">
        {teachersList.map((teacher) => {
          const { userId } = teacher;

          return (
            <Teacher {...teacher} key={userId} />
          );
        })}
      </div>
    </div>
  );
};

export default observer(Teachers);
