import React from 'react';

import { IGeneralGroupInformation } from 'components/AddToGroup/GroupList/GroupItem/interfaces';

const ClassInfo: React.FC<IGeneralGroupInformation> = (props: IGeneralGroupInformation) => {
  const { dayOfWeek, timeStart, timeEnd, room } = props;

  const time = `${timeStart} - ${timeEnd}`;

  return (
    <div className="panel-time">
      <span className="panel-value">{dayOfWeek}</span>
      <div className="">
        <div className="panel-time-start">{time}</div>
        <div className="panel-time-end">{room}</div>
      </div>
    </div>
  );
};

export default ClassInfo;
