import React, { useState } from 'react';
import Slider from 'rc-slider';

import { getTrackStyle } from './helpers';
import { ProgressBarType } from './interface';

import 'rc-slider/assets/index.css';

const ProgressBar = (props: ProgressBarType) => {
  const {
    name, setFieldValue, progressValue, progressLabel, progressClass, disabled = true
  } = props;

  const [value, changeValue] = useState(progressValue);

  const trackStyle = { backgroundColor: getTrackStyle(progressClass) } as React.CSSProperties;
  const handleStyle = { display: disabled ? 'none' : null } as React.CSSProperties;

  return (
    <div className={`progress ${progressClass}`}>
      {progressLabel && (
        <div className="progress-label">
          <span className="progress-title">{progressLabel}</span>
          <span className="progress-sum">{`${value}%`}</span>
        </div>
      )}
      <Slider
        min={0}
        max={100}
        step={1}
        onChange={(updatedValue) => {
          changeValue(updatedValue);

          if (setFieldValue) {
            setFieldValue(name, updatedValue);
          }
        }}
        value={value}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        disabled={disabled}
      />
    </div>
  );
};

export default ProgressBar;
