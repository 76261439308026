import React, { useEffect } from 'react';
import { Field } from 'formik';

import { formFields } from 'components/common/FormComponent/formFields';
import { STUDY_GROUP_TITLE, TEACHER_USER_ID } from '../AddGroup/constants';
import * as INTERFACES from '../../interfaces';
import { IEditStudyGroupFormProps } from './interfaces';

const EditStudyGroupForm = (props: IEditStudyGroupFormProps) => {
  const {
    handleSubmit,
    teachersList,
    getTeachersList,
    setFieldValue,
    studyGroup,
    setEditingStudyGroupTitle,
    setEditingStudyGroupTeacher,
    setIsFormValid,
    isValid
  } = props;

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  const { name: studyGroupName, teacher } = studyGroup;

  const onStudyGroupTitleChange = (event: any): void => {
    const { value } = event.target;

    setEditingStudyGroupTitle(value);
    setFieldValue(STUDY_GROUP_TITLE, value);
  };

  const onTeacherUserIdChange = (value: INTERFACES.ISelectValues): void => {
    setEditingStudyGroupTeacher(value);
    setFieldValue(TEACHER_USER_ID, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={STUDY_GROUP_TITLE}
            placeholder="Study Group Title"
            component={formFields.FormInput}
            value={studyGroupName}
            onChange={onStudyGroupTitleChange}
          />
        </div>
      </div>
      <div className="row modal-row form-group">
        <div className="col-xs-12 modal-col">
          <Field
            name={TEACHER_USER_ID}
            placeholder="Teacher"
            component={formFields.FormSelect}
            options={teachersList}
            value={teacher}
            onChange={onTeacherUserIdChange}
            onMenuOpen={getTeachersList}
          />
        </div>
      </div>
    </form>
  );
};

export default EditStudyGroupForm;
