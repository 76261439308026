import React from 'react';

import Logo from 'components/common/Logo/index';
import LoginForm from './LoginForm';
import { AUTH_LABEL } from '../constants';

function Login() {
  return (
    <div className="auth">
      <div className="auth-content">
        <Logo />
        <LoginForm />
        <span className="auth-label">{AUTH_LABEL}</span>
      </div>
    </div>
  );
}

export default Login;
