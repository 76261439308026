import React from 'react';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

import { EventsType } from '../interface';

const Events = (props: EventsType) => {
  const {
    dayEvents, scroll, heightTableBody, getHeight
  } = props;
  const { scrollX, scrollY } = scroll;

  return (
    <table className="timeline-table">
      <TableHeader scrollX={scrollX} dayEvents={dayEvents} />
      <TableBody scrollY={scrollY} dayEvents={dayEvents} height={heightTableBody} getHeight={getHeight} />
    </table>
  );
};

export default Events;
