import React from 'react';

import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';

import { IGroupLeadProps } from './interface';

import GroupLead from './GroupLead';

const GroupLeads: React.FC<IGroupLeadProps> = (props: IGroupLeadProps) => {
  const { leads } = props;

  const leadsCard = leads.map((lead) => <GroupLead {...lead} key={lead.userId} />);

  return (
    <div className="panel-row">
      <span className="subtitle">Group Leads</span>
      {leadsCard}
      <EmptyRow />
    </div>
  );
};

export default GroupLeads;
