import React from 'react';

import ProgressBar from 'components/common/ProgressBar';

import { testLevel } from 'components/Modals/AddTestResult/FormAddTestResult/FormComponentAddTestResult/constants';

import { IEditTestResultProps } from '../interface';

const EditFormSpeakingTestResult: React.FC<IEditTestResultProps> = (props: IEditTestResultProps) => {
  const { initialTestResultData, setFieldValue } = props;

  const {
    essay, listening, paraphrase, reading, writing, writingGrammar, eventLevelId
  } = initialTestResultData;

  const isLowTest = eventLevelId === testLevel.LOW.value;

  return (
    <div className="panel-item accordion-separator w-25">
      <ProgressBar
        name="listening"
        setFieldValue={setFieldValue}
        progressClass="progress-listen"
        progressValue={listening}
        progressLabel="Listening"
        disabled={false}
      />
      <ProgressBar
        name="reading"
        setFieldValue={setFieldValue}
        progressClass="progress-red"
        progressValue={reading}
        progressLabel="Reading"
        disabled={false}
      />
      {isLowTest ? (
        <div className="col-xs-12 col-sm-6 modal-col">
          <ProgressBar
            name="writing"
            setFieldValue={setFieldValue}
            progressClass="progress-write"
            progressValue={writing}
            progressLabel="Writing"
            disabled={false}
          />
          <ProgressBar
            name="writing_grammar"
            setFieldValue={setFieldValue}
            progressClass="progress-read"
            progressValue={writingGrammar}
            progressLabel="Grammar"
            disabled={false}
          />
        </div>
      ) : (
        <div className="col-xs-12 col-sm-6 modal-col">
          <ProgressBar
            name="paraphrase"
            setFieldValue={setFieldValue}
            progressClass="progress-write"
            progressValue={paraphrase}
            progressLabel="Paraph"
            disabled={false}
          />
          <ProgressBar
            name="essay"
            setFieldValue={setFieldValue}
            progressClass="progress-read"
            progressValue={essay}
            progressLabel="Essay"
            disabled={false}
          />
        </div>
      )}
    </div>
  );
};

export default EditFormSpeakingTestResult;
