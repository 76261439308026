import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { IModalProps } from 'components/Modals/interfaces';

import usePortal from './usePortal';

const isReactSelectOptionClicked = (target: any) => target.className.includes('react-select__option');

const withModal = <T extends IModalProps>(Component: React.ComponentType<T & IModalProps>) => {
  const WithModal: React.FC<T> = (props: T) => {
    const { isOpen, toggleModal } = props;
    const overlayRef = useRef(null);

    const outsideClick = (e: any) => {
      try {
        if (!isOpen || !overlayRef.current || isReactSelectOptionClicked(e.target)) {
          return;
        }
        const isOutsideClick = overlayRef.current.contains(e.target);

        if (isOutsideClick) {
          toggleModal();
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (isOpen) {
        window.addEventListener('click', outsideClick);
      }

      return () => { window.removeEventListener('click', outsideClick); };
    });

    const target = usePortal('modal-root', isOpen);

    if (!isOpen) { return null; }

    const modalContainer = (
      <div className="modal-layout">
        <div className="modal-overlay" ref={overlayRef} />
        <Component {...props} />
      </div>
    );

    return createPortal(modalContainer, target);
  };

  return WithModal;
};

export default withModal;
