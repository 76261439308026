import { IEventType } from './interfaces';

export const DEFAULT_STATUS: string = 'default';
export const DEFAULT_LIGHT_STATUS: string = 'default-light';
export const ABSENT_STATUS: string = 'absent';
export const LESSON_STATUS: string = 'lesson';

export const MAX_ATTENDANCES_PER_PAGE: number = 10;
export const DEFAULT_ATTENDANCES_PAGE: number = 0;

export const EVENT_TYPES: IEventType[] = [
  {
    id: '1',
    value: 'Lesson',
    name: 'Lesson'
  },
  {
    id: '2',
    value: 'Writing',
    name: 'Writing'
  },
  {
    id: '3',
    value: 'Speaking',
    name: 'Speaking'
  }
];
