export default {
  INDEX: '/',
  LOGIN_ROUTE: '/login',
  RESTORE_PASSWORD: '/restore-password/:token',
  CALENDAR_ROUTE: '/calendar',
  ATTENDANCE_LOG_ROUTE: '/attendance',
  TEST_RESULTS_ROUTE: '/results',
  STUDENTS_ROUTE: '/students',
  TEST_SCHEDULE_ROUTE: '/schedule',
  ADD_TO_GROUP_ROUTE: '/add-to-group',
  GROUPS_ROUTE: '/groups',
  TEACHERS_ROUTE: '/teachers',
  ADMINS_ROUTE: '/admins',
  DEPARTMENTS_ROUTE: '/departments',
  SETTINGS_ROUTE: '/settings',
  PAGE_NOT_FOUND: '/page-not-found',
  SERVER_ERROR: '/server-error',
  FORBIDDEN: '/forbidden',
  PROFILE: '/profile'
};
