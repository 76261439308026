import request from './request';
import api from './index';

export const getListTestResults = params => request({
  url: api.RESULTS_LIST,
  method: 'GET',
  params
});

export const getDetailsTestResults = params => request({
  url: api.RESULTS_DETAILS,
  method: 'GET',
  params
});

export const postTestResult = data => request({
  url: api.TEST_RESULT,
  method: 'POST',
  data
});

export const putTestResult = data => request({
  url: api.TEST_RESULT,
  method: 'PATCH',
  data
});

export const deleteTestResult = params => request({
  url: api.TEST_RESULT,
  method: 'DELETE',
  params
});
