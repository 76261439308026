import React from 'react';

import { IGroupDataProps } from './interfaces';

import Teacher from './Teacher';
import Students from './Students';

const GroupData = (props: IGroupDataProps) => {
  const {
    groupId, groupName, students, teacher, emptySeats
  } = props;

  return (
    <div className="group-participants">
      <Teacher {...teacher} />
      <Students
        students={students}
        emptySeats={emptySeats}
        groupId={groupId}
        groupName={groupName}
      />
    </div>
  );
};

export default GroupData;
