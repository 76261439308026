import React, { useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';

import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import EmptyState from 'components/common/EmptyState';

import { IDepartmentGroup } from '../interfaces';

import GroupItem from './GroupItem';

const GroupList: React.FC<IGradientData> = (props: IGradientData) => {
  const { bottom, top, toggleGradient } = props;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { departmentsStore } = useContext(RootStore);
  const { departmentGroups } = departmentsStore;

  const testEventClassName = classNames('groups-list', {
    'has-bottom-gradient': !isReachedBottom,
    'has-top-gradient': !isReachedTop
  });

  const groupsData = departmentGroups.map((group: IDepartmentGroup) => (
    <GroupItem {...group} key={group.departmentGroupId} />
  ));

  const content = departmentGroups.length ? (
    <div className="groups-list-inner">
      {groupsData}
    </div>
  ) : (
    <EmptyState
      icon="sentiment_dissatisfied"
      title="No groups are existing"
      additionalClassName="card has-spacings"
    />
  );

  return (
    <>
      <div className="title">JS/UI</div>
      <div className={testEventClassName}>
        <PerfectScrollbar
          className="perfect-scrollbar"
          onYReachStart={removeTopGradient}
          onYReachEnd={removeBottomGradient}
          onScrollY={toggleGradient}
        >
          {content}
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default compose(
  WithGradient,
  observer
)(GroupList);
