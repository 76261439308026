import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { DatePickerView } from '@material-ui/pickers/DatePicker/DatePicker';
import classNames from 'classnames';

import { IFormDatePickerProps } from './interface';

const FormDatePicker: React.FC<IFormDatePickerProps> = (props: IFormDatePickerProps) => {
  const {
    onChange = () => {},
    onBlur = () => {},
    value,
    views = ['date'],
    isDisabled = false,
    minDate,
    maxDate,
    placeholder = 'Date',
    openTo = 'date',
    field,
    form
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDatePicker = (open: boolean) => setIsOpen(open);

  const { errors, touched } = form;
  // @ts-ignore
  const error = errors[field.name];
  const fieldHasError = error && touched[field.name] && !isOpen;
  const classError = classNames('form-field', { 'has-error': fieldHasError });

  return (
    <div className={classError}>
      <DatePicker
        className="has-error"
        onChange={onChange}
        onBlur={onBlur}
        onOpen={() => toggleDatePicker(true)}
        onClose={() => toggleDatePicker(false)}
        open={isOpen}
        views={views as DatePickerView[]}
        placeholder={placeholder}
        disabled={isDisabled}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        openTo={openTo as DatePickerView}
      />
      {fieldHasError && <span className="form-group-error">{error}</span>}
    </div>
  );
};

export default FormDatePicker;
