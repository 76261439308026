import TouchBackend from 'react-dnd-touch-backend';
import DesktopBackend from 'react-dnd-html5-backend';

export const getBackend = (isMobile: boolean) => (
  isMobile ? TouchBackend : DesktopBackend
);

// @ts-ignore
const hasNative = document && (document.elementsFromPoint || document.msElementsFromPoint);

function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
  return dropTargets.filter((target: any) => {
    const rect = target.getBoundingClientRect();
    return (
      x >= rect.left
      && x <= rect.right
      && y <= rect.bottom
      && y >= rect.top
    );
  });
}

const backendOptions = {
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
};

export const getBackendOptions = (isMobile: boolean) => (
  isMobile ? backendOptions : {}
);
