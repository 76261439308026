import React from 'react';

import { MAX_STUDENT_IN_GROUP } from '../constant';
import { IGroupItemProps } from './interface';

const GroupItem: React.FC<IGroupItemProps> = (props: IGroupItemProps) => {
  const { groupName, emptySeats, isOpenDetailInfo, toggleDetailInfo } = props;

  const freePlacesText = emptySeats
    ? `Max ${MAX_STUDENT_IN_GROUP} students / left ${emptySeats}`
    : 'Group Formed';

  return (
    <div className="panel-summary">
      <div className="panel-header">
        <div className="card-title">{groupName}</div>
        <div className="card-subinfo">{freePlacesText}</div>
        <div className="panel-navigation">
          <button className="btn-down" type="button" onClick={toggleDetailInfo}>
            {isOpenDetailInfo ? (
              <i className="material-icons accordion-arrow">keyboard_arrow_up</i>
            ) : (
              <i className="material-icons accordion-arrow">keyboard_arrow_down</i>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupItem;
