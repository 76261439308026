import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import RootStore from 'store';
import { INormalStudentType } from 'components/AddToGroup/interfaces';
import EmptyCard from 'components/common/DrugAndDrop/EmptyCard';
import EmptyRow from 'components/common/DrugAndDrop/EmptyRow';
import { DRAG_TYPE_STUDENT } from 'components/AddToGroup/constant';

import StudentsSelectionMobile from 'components/common/MobileUsersSelect/StudentsSelection';
import { IStudentsProps, IDropUserData } from './interfaces';
import UserCard from '../UserCard';

const Students: React.FC<IStudentsProps> = (props: IStudentsProps) => {
  const {
    students, emptySeats, groupId, groupName
  } = props;

  const { addToGroupStore, serviceStore: { isMobile } } = useContext(RootStore);
  const { addStudentToGroup, setModalData, students: storeStudents } = addToGroupStore;

  const dropStudentToGroup = (dropData: IDropUserData) => {
    const {
      userData: { userId }
    } = dropData;

    const params = {
      student_user_id: userId,
      study_group_id: groupId
    };

    addStudentToGroup(params);
  };

  const [{ isOver }, drop] = useDrop({
    accept: DRAG_TYPE_STUDENT,
    drop: dropStudentToGroup,
    collect: (monitor: any) => ({
      isOver: monitor.isOver()
    })
  });

  const scheduleCardClassNames = classNames('groups-details', 'panel-row', {
    'is-dragging': isOver
  });

  const studentsData = students.map((student: INormalStudentType) => {
    const { id, fullName, photo, recommendedEnglishLevelName } = student;

    return (
      <UserCard
        id={id}
        fullName={fullName}
        groupName={groupName}
        photo={photo}
        englishLevel={recommendedEnglishLevelName}
        deleteUser={setModalData}
        key={id}
      />
    );
  });

  const desktopContent = !students.length ? (
    <EmptyCard />
  ) : (
    <>
      {studentsData}
      {!!emptySeats && <EmptyRow />}
    </>
  );

  const mobileContent = (
    <>
      {studentsData}
      <StudentsSelectionMobile
        addStudent={dropStudentToGroup}
        students={storeStudents}
        canSelect={!!emptySeats && !!storeStudents.length}
      />
    </>
  );

  const content = isMobile ? mobileContent : desktopContent;

  return (
    <div ref={drop} className={scheduleCardClassNames}>
      <span className="subtitle">Students</span>
      {content}
    </div>
  );
};

export default observer(Students);
