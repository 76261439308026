import React, { useState, useContext } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import RootStore from 'store';

import { IGradientData } from 'hocs/WithGradient/interface';

import WithGradient from 'hocs/WithGradient';
import Timeline from 'components/Calendar/EventsTable/Timeline';
import Events from './Events';

import { SCROLL } from './constants';
import { scrollSetting } from './scrollSetting';

import 'react-perfect-scrollbar/dist/css/styles.css';

const EventsTable: React.FC<IGradientData> = (props: IGradientData) => {
  const {
    bottom, top, right, left, toggleGradient
  } = props;
  const { isReachedLeft, removeLeftGradient } = left;
  const { isReachedRight, removeRightGradient } = right;
  const { isReachedTop, removeTopGradient } = top;
  const { isReachedBottom, removeBottomGradient } = bottom;

  const { calendarStore } = useContext(RootStore);
  const { dayEvents } = calendarStore;

  const [scroll, setScroll] = useState(scrollSetting);
  const [heightTableBody, getHeightTableBody] = useState(0);

  const timelineClassName = classNames('timeline has-vertical-gradient has-horizontal-gradient', {
    'has-bottom-gradient': !isReachedBottom,
    'has-top-gradient': !isReachedTop,
    'has-left-gradient': !isReachedLeft,
    'has-right-gradient': !isReachedRight
  });

  const getScrollDate = (container: any, key: string) => {
    let scrollDirection = SCROLL.top;

    if (key === 'scrollX') {
      scrollDirection = SCROLL.left;
    }

    setScroll((prevCount) => {
      const newCount: any = { ...prevCount };
      newCount[key] = container[scrollDirection];

      return newCount;
    });
  };

  const onScrollX = (container: any) => {
    getScrollDate(container, SCROLL.axisX);
    toggleGradient();
  };

  const onScrollY = (container: any) => {
    getScrollDate(container, SCROLL.axisY);
    toggleGradient();
  };

  return (
    <div className={timelineClassName}>
      <Timeline scroll={scroll} height={heightTableBody} />
      <PerfectScrollbar
        className="perfect-scrollbar"
        onYReachStart={removeTopGradient}
        onYReachEnd={removeBottomGradient}
        onXReachStart={removeLeftGradient}
        onXReachEnd={removeRightGradient}
        onScrollX={onScrollX}
        onScrollY={onScrollY}
      >
        <Events
          dayEvents={dayEvents}
          scroll={scroll}
          heightTableBody={heightTableBody}
          getHeight={getHeightTableBody}
        />
      </PerfectScrollbar>
    </div>
  );
};

export default compose(
  WithGradient,
  observer
)(EventsTable);
