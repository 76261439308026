import React from 'react';
import classnames from 'classnames';

import { ISortingData } from './interface';

const ColumnValueInTable: React.FC<ISortingData> = (props: ISortingData) => {
  const {
    title, itemWidth, displayType, sortingArrow, selectedFilter, isActive
  } = props;

  const classFilterItem = classnames('filter-item', itemWidth, displayType);

  let content = (
    <div className="filter-button">
      <span>{title}</span>
    </div>
  );

  if (sortingArrow) {
    const classStatus = classnames('material-icons', { isActive });

    content = (
      <button className="filter-button" type="button" onClick={() => selectedFilter(title)}>
        <span>{title}</span>
        <i className={classStatus}>{sortingArrow}</i>
      </button>
    );
  }

  return <div className={classFilterItem}>{content}</div>;
};

export default ColumnValueInTable;
