import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { IFormSelectProps } from './interface';
import { renderOption } from '../../../FormFields/Selects/renderers';

export default (props: IFormSelectProps) => {
  const {
    options,
    updateData,
    placeholder = 'Select value',
    selectedValue,
    isDisabled = false,
    field,
    form,
    additionalClassName,
    isMulti = false,
    inputValue,
    onInputChange,
    optionType
  } = props;

  const { errors, touched } = form;
  // @ts-ignore
  const error = form ? errors[field.name] : null;
  const selectClassName = classNames('select', { 'has-error': error && touched[field.name] }, additionalClassName);

  const handleCloseMenuOnScroll = (e: Event) => {
    const target = e.target as HTMLElement;
    const scrollableElement = target.parentElement;

    return !(scrollableElement && (scrollableElement.classList.contains('react-select__menu')));
  };
  const onSelectChange = (data: any) => {
    updateData(data);

    if (form) {
      const { setFieldValue } = form;
      const { name } = field;

      setFieldValue(name, data.value);
    }
  };

  const renderSelectMenu = (data: any) => (
    <PerfectScrollbar className="perfect-scrollbar react-select__menu-list">{data.children}</PerfectScrollbar>
  );

  let selectProps: any = {
    classNamePrefix: 'react-select',
    styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) },
    menuPosition: 'fixed',
    menuPlacement: 'auto',
    menuPortalTarget: document.body,
    onChange: onSelectChange,
    className: selectClassName,
    selectProps: selectClassName,
    options,
    value: selectedValue,
    isDisabled,
    closeMenuOnScroll: handleCloseMenuOnScroll,
    placeholder,
    components: {
      MenuList: renderSelectMenu,
      Option: (optionProps: any) => renderOption(optionProps, optionType)
    },
    ...field,
    ...props
  };

  if (isMulti) {
    selectProps = {
      ...selectProps,
      isMulti,
      inputValue,
      onInputChange,
      closeMenuOnSelect: false,
      hideSelectedOptions: false
    };
  }

  return (
    <div className="form-field">
      <Select {...selectProps} />
      {error && touched[field.name] && <span className="form-group-error">{error}</span>}
    </div>
  );
};
