export const TIME_LINE_DATE = [
  {
    time: '08'
  },
  {
    time: '09'
  },
  {
    time: '10'
  },
  {
    time: '11'
  },
  {
    time: '12'
  },
  {
    time: '13'
  },
  {
    time: '14'
  },
  {
    time: '15'
  },
  {
    time: '16'
  },
  {
    time: '17'
  },
  {
    time: '18'
  },
  {
    time: '19'
  },
  {
    time: '20'
  }
];
