import { MonthEventsType } from './interfaces';
import { updateFormatDate } from '../../helpers/actionsWithDates';
import { format } from '../../constants/date';

export const isSelectedTab = (selectedTab: string, tab: string): boolean => selectedTab === tab;

/* eslint camelcase: 0 */

export const getSortedEvents = (data: MonthEventsType[]) => (
  data.sort((el1, el2) => {
    const date1 = updateFormatDate(el1.date, format.DAY);
    const date2 = updateFormatDate(el2.date, format.DAY);

    if (date1 > date2) {
      return 1;
    }
    if (date1 < date2) {
      return -1;
    }

    return 0;
  })
);

export const normalizeStudentEventData = (students: any) => students.map((student: any) => {
  const {
    student_user_id,
    student_user_name,
    student_user_photo,
    event_student_id,
    student_user_surname
  } = student;

  return {
    eventStudentId: event_student_id,
    userId: student_user_id,
    studentUserName: student_user_name,
    studentUserPhoto: student_user_photo,
    studentUserSurname: student_user_surname,
    fullName: `${student_user_surname} ${student_user_name}`
  };
});

export const normalizeEventData = (eventData: any) => {
  const {
    begin_time,
    date,
    end_time,
    event_id,
    room,
    teacher_user_name,
    teacher_user_surname,
    students,
    event_parent_id,
    event_parent_name,
    event_type_id,
    event_type_name,
    teacher_user_id,
    event_level_id,
    event_level_name
  } = eventData;

  return {
    eventLevelId: event_level_id,
    eventLevelName: event_level_name,
    id: event_id,
    type: event_type_name,
    date,
    time: begin_time,
    endTime: end_time,
    teacher: `${teacher_user_name} ${teacher_user_surname}`,
    roomNumber: room,
    eventParentId: event_parent_id,
    eventParentName: event_parent_name,
    eventTypeId: event_type_id,
    teacherUserId: teacher_user_id,
    students: normalizeStudentEventData(students)
  };
};

export const normalizeAllEventData = (requestData: any) => {
  const { data } = requestData;

  return data.map((eventData: any) => normalizeEventData(eventData));
};

export const getStudents = (events: MonthEventsType[]) => (
  events.reduce((result, item) => [...result, ...item.students], [])
);
