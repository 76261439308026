import { ITestLevelType } from './interface';

export const testLevel: ITestLevelType = {
  LOW: {
    name: 'LOW',
    value: 1
  },
  HIGH: {
    name: 'HIGH',
    value: 2
  }
};

export const buttonsGroupData = [
  {
    name: testLevel.LOW.name,
    id: testLevel.LOW.name,
    value: 'Low'
  },
  {
    name: testLevel.HIGH.name,
    id: testLevel.HIGH.name,
    value: 'High'
  }
];
