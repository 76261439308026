import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';

import TableHeader from 'components/Admins/AdminsTable/TableHeader';
import AdminInfo from 'components/Admins/AdminsTable/AdminInfo';

const AdminsTable: React.FC = observer(() => {
  const { admins } = useContext(RootStore);
  const { adminsList } = admins;

  return (
    <div className="panel-group">
      <TableHeader />
      {adminsList.map((admin) => {
        const { userId } = admin;

        return (
          <div className="panel-summary" key={userId}>
            <AdminInfo {...admin} />
          </div>
        );
      })}
    </div>
  );
});

export default AdminsTable;
