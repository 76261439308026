import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import RootStore from 'store';

import { STUDENT } from 'constants/roles';

import Checkbox from 'components/common/FormFields/Checkbox';

import SpeakingResults from './SpeakingResults';
import WritingResult from './WritingResults';
import TotalResult from './TotalResults';

import { IDetailsTestResultType } from './interface';

const DetailsTestResult: React.FC<IDetailsTestResultType> = observer(props => {
  const { previousTestResultId, indexTestResult, permissions } = props;

  const { testResults } = useContext(RootStore);
  const { getPreviousTestResults } = testResults;

  const [checkboxState, updateCheckboxState] = useState(false);

  const getPrevTestResult = async (updatedState: boolean) => {
    await updateCheckboxState(updatedState);

    getPreviousTestResults(previousTestResultId, updatedState);
  };

  return (
    <>
      <div className="accordion-content">
        <SpeakingResults indexTestResult={indexTestResult} isEdit={false} />
        <WritingResult indexTestResult={indexTestResult} isEdit={false} />
        <TotalResult indexTestResult={indexTestResult} />
      </div>
      <div className="accordion-footer">
        {permissions([STUDENT])
          && (previousTestResultId ? (
            <Checkbox
              label="Compare with previous results"
              isReverse={false}
              checkboxState={checkboxState}
              updateCheckboxState={getPrevTestResult}
            />
          ) : (
            'Your first test'
          ))}
      </div>
    </>
  );
});

export default DetailsTestResult;
