import React, { useContext } from 'react';
import { Field, FormikProps } from 'formik';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import FormInput from 'components/common/FormComponent/formFields/FormInput';
import Button from 'components/common/FormFields/Button';
import RootStore from 'store';

import { IFormValues, IOtherProps } from '../interfaces';
import { LOGIN_SUBTITLE } from '../constants';

const LoginFormComponent = observer((props: IOtherProps & FormikProps<IFormValues>) => {
  const { authenticationStore: { isLoading } } = useContext(RootStore);
  const { handleSubmit, errors, AuthError, setIsLoginForm } = props;
  const shouldDisableSubmit = Boolean(Object.keys(errors).length);
  const btnClassName = classNames('btn-default btn-wide', {
    'btn-disabled': shouldDisableSubmit || isLoading
  });

  return (
    <form onSubmit={handleSubmit} className="auth-form sign-in">
      <h1 className="auth-title">Sign In</h1>
      <span className="auth-subtitle">{LOGIN_SUBTITLE}</span>
      <Field
        name="username"
        placeholder="User Name"
        component={FormInput}
      />
      <div className="password-container">
        <Field
          name="password"
          inputType="password"
          placeholder="Password"
          component={FormInput}
        />
        <div
          tabIndex={0}
          role="button"
          className="input-link"
          onClick={() => setIsLoginForm(false)}
        >
          Forgot Password
        </div>
      </div>
      {AuthError.isAuthError && (<div className="auth-title form-group-error">{AuthError.authError}</div>)}
      <Button
        className={btnClassName}
        disabled={shouldDisableSubmit || isLoading}
      >
        Sign In
      </Button>
    </form>
  );
});

export default LoginFormComponent;
