import { observable, action, computed } from 'mobx';

import { getTeacherList, patchTeacherInfo } from 'api/teachers';
import { getTeacherTypes } from 'api/filtersData';

import * as filter from 'constants/select';
import { DEFAULT_FILTERS, DEFAULT_SORT_DATA, ACTIVE_TEACHERS } from 'components/Teachers/constants';

import { normalizeFilterData } from 'helpers/select';

import { ISortingData } from 'components/common/ColumnValueInTable/interface';
import { IGetTeacherParams, ITeacherData, IPatchTeacherParams } from 'components/Teachers/interface';
import { getSortingStatus } from 'components/common/ColumnValueInTable/helpers';
import { getTeachersListRequestParams } from 'components/Teachers/helpers';
import { normalizeTeachersData } from 'components/Teachers/utils';
import { SelectData } from 'types/filters';

export default class TeachersStore {
  @observable teachersList: ITeacherData[] = [];

  @observable filters = DEFAULT_FILTERS;

  @observable sortedData = DEFAULT_SORT_DATA;

  @observable selectedTeacherType: SelectData;

  @observable teacherInfo: ITeacherData;

  @observable isOpenModalAddNewTeacher = false;

  @observable isOpenModalDeleteTeacher = false;

  @observable isLoadingTeacherTypes = false;

  @observable isLoadingTeachers = false;

  @observable teachersTypes: SelectData[] = [];

  @computed get isLoading() {
    return this.isLoadingTeacherTypes
      || this.isLoadingTeachers;
  }

  @action getTeachersList = async (): Promise<void> => {
    try {
      this.isLoadingTeachers = true;
      const params: IGetTeacherParams = {
        active: ACTIVE_TEACHERS,
        ...this.sortedData,
        ...getTeachersListRequestParams(this.selectedTeacherType)
      };

      const {
        data: { data }
      } = await getTeacherList(params);

      this.teachersList = normalizeTeachersData(data);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingTeachers = false;
    }
  };

  @action getTeachersTypes = async (): Promise<void> => {
    try {
      this.isLoadingTeacherTypes = true;
      const {
        data: { data }
      } = await getTeacherTypes();

      this.teachersTypes = normalizeFilterData(data);
      this.filters.teachersTypes = this.teachersTypes;
      this.selectedTeacherType = filter.ALL_TEACHER_TYPES;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingTeacherTypes = false;
    }
  };

  @action changeTeacherType = (selectedType: SelectData) => {
    this.selectedTeacherType = selectedType;
  };

  @action changeSorted = (updatedSortedData: ISortingData[]) => {
    const { value, sortingArrow } = updatedSortedData.find((sortedValue) => sortedValue.isActive);

    const sortingState = getSortingStatus(sortingArrow);

    this.sortedData = {
      ...this.sortedData,
      sort_key: value,
      sort_order: sortingState
    };
  };

  @action updateModalAddNewTeacherState = (modalState: boolean) => {
    this.isOpenModalAddNewTeacher = modalState;
  };

  @action clearTeacherInfo = () => {
    this.teacherInfo = null;
  };

  @action updateModalDeleteTeacherState = (modalState: boolean, userId?: number) => {
    if (userId) {
      this.teacherInfo = this.teachersList.find((teacher) => teacher.userId === userId);
    }
    if (!modalState) {
      this.clearTeacherInfo();
      this.getTeachersList();
    }

    this.isOpenModalDeleteTeacher = modalState;
  };

  @action setFormData = (teacherInfo: ITeacherData) => {
    this.teacherInfo = teacherInfo;
  };

  @action saveFormData = async (params: IPatchTeacherParams) => {
    await patchTeacherInfo(params);
    await this.getTeachersList();
  };

  @action getPageData = async () => {
    await this.getTeachersTypes();
    await this.getTeachersList();
  };
}
