import { INavigationItem } from './interfaces';
import * as roles from '../../../constants/roles';
import * as navigationLists from './navigationItemsList';

export const setNavigationItemsList = (role: string): INavigationItem[] => {
  switch (role) {
    case roles.ADMIN:
      return navigationLists.adminNavigationList;
    case roles.GROUP_LEAD:
      return navigationLists.groupLeadNavigationList;
    case roles.STUDENT:
      return navigationLists.studentNavigationList;
    case roles.TEACHER:
      return navigationLists.teacherNavigationList;
    case roles.SUPER_ADMIN:
      return navigationLists.superAdminNavigationList;
    default:
      return [];
  }
};
