import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'store';
import WithPermissions from 'hocs/WithPermissions';
import Header from 'components/common/Header';
import ScheduleCalendar from 'components/Calendar/ScheduleCalendar';
import MobileCalendar from 'components/Calendar/MobileCalendar';
import EventsDetails from 'components/Calendar/EventDetails';
import EventsTable from 'components/Calendar/EventsTable';
import AddEvent from 'components/Modals/Event/AddEvent';
import EmptyState from 'components/common/EmptyState';
import { useStateModal } from 'components/Modals/helper';

import Filters from './Filters';

const Calendar = observer((props) => {
  const { permissions } = props;
  const { calendarStore } = useContext(RootStore);
  const { detailEvent, dayEvents } = calendarStore;

  const [modalState, updateModalToggle, updateModalState]: any = useStateModal(false);
  const [isMobileFiltersOpen, toggleIsMobileFiltersOpen, setIsMobileFiltersOpen]: any = useStateModal(false);

  return (
    <>
      <Header
        pageTitle="Calendar"
        onAddClick={updateModalToggle}
        onFiltersClick={toggleIsMobileFiltersOpen}
      >
        <Filters
          permissions={permissions}
          updateModalState={updateModalState}
          isMobileFiltersOpen={isMobileFiltersOpen}
          toggleIsMobileFiltersOpen={toggleIsMobileFiltersOpen}
          setIsMobileFiltersOpen={setIsMobileFiltersOpen}
        />
        <MobileCalendar />
      </Header>
      <div className="page-content calendar-page">
        <div className="page-col calendar-container">
          <div className="calendar-card m-hidden">
            <ScheduleCalendar />
          </div>
          <div className="calendar-card is-scrollable">
            {detailEvent ? (
              <EventsDetails permissions={permissions} />
            ) : (
              <EmptyState
                icon="sentiment_dissatisfied"
                title="No events has been planned yet"
                additionalClassName="has-spacings"
              />
            )}
          </div>
        </div>
        <div className="page-col is-stretched">
          {dayEvents ? (
            <EventsTable />
          ) : (
            <EmptyState
              icon="sentiment_dissatisfied"
              title="No events has been planned yet"
              additionalClassName="card has-spacings"
            />
          )}
        </div>
      </div>
      <AddEvent isOpen={modalState} updateModalState={updateModalState} />
    </>
  );
});

export default WithPermissions(Calendar);
